import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { API_ROUTE } from '@constants/index';
import {
  buildDelegableEmployee,
  DelegableEmployee,
  GenericPostResponse,
} from '@models/index';
import { ErrorService } from '@services/error/error.service';
import { FeatureFlagService } from '@services/feature-flag';
import {
  AssignTemporalAuthorizerInterface,
  CreateDelegationInterface,
  DelegationInterface,
  delegationsBuilder,
  DeleteDelegationInterface,
} from 'src/app/pages/delegations/models/delegation';
import { FEATURES } from 'src/environments/common';

@Injectable({
  providedIn: 'root',
})
export class DelegationsService {
  private readonly delegationsSubject = new BehaviorSubject<
    DelegationInterface[]
  >(null);

  public readonly delegations$ = this.delegationsSubject
    .asObservable()
    .pipe(filter((data): boolean => data !== null));

  public constructor(
    private errorService: ErrorService,
    private readonly http: HttpClient,
    private readonly featureFlagService: FeatureFlagService
  ) {}

  public getDelegations(id: string): Observable<DelegationInterface[]> {
    const params = 'service=GT&permission=A';
    const queryParams = new HttpParams({ fromString: params });

    return this.http
      .get<any>(API_ROUTE.DELEGATIONS.GET_DELEGATIONS(id), {
        params: queryParams,
      })
      .pipe(
        map((data): DelegationInterface[] =>
          delegationsBuilder(
            data,
            this.featureFlagService.isFeatureAvailable(
              FEATURES.SINGLE_DELEGATION
            )
          )
        ),
        tap((delegations: DelegationInterface[]): void => {
          this.delegationsSubject.next(delegations);
        }),
        catchError(
          (err): Observable<DelegationInterface[]> => {
            this.errorService.set('ERROR_DEFAULT', err);
            return of(null);
          }
        )
      );
  }

  public createDelegation(
    params: CreateDelegationInterface,
    managerId: string
  ): Observable<GenericPostResponse> {
    const paramsCopy = { ...params };

    if (
      !this.featureFlagService.isFeatureAvailable(FEATURES.SINGLE_DELEGATION)
    ) {
      delete paramsCopy.singleEmployees;
    }

    return this.http.post<any>(
      API_ROUTE.DELEGATIONS.CREATE_DELEGATION(managerId),
      paramsCopy
    );
  }

  public updateDelegation(
    params: CreateDelegationInterface,
    managerId: string
  ): Observable<GenericPostResponse> {
    const paramsCopy = { ...params };

    if (
      !this.featureFlagService.isFeatureAvailable(FEATURES.SINGLE_DELEGATION)
    ) {
      delete paramsCopy.singleEmployees;
    }

    return this.http.patch<any>(
      API_ROUTE.DELEGATIONS.UPDATE_DELEGATION(managerId),
      paramsCopy
    );
  }

  public deleteDelegation(
    params: DeleteDelegationInterface,
    managerId: string
  ): Observable<GenericPostResponse> {
    const queryParams = new HttpParams({
      fromString: 'service=GT&permission=A',
    });

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: params,
      params: queryParams,
    };

    return this.http.delete<any>(
      API_ROUTE.DELEGATIONS.DELETE_DELEGATION(managerId),
      options
    );
  }

  public findDelegableEmployee(
    managerId: string,
    text = ''
  ): Observable<DelegableEmployee[]> {
    const params = `search=${text}`;
    const queryParams = new HttpParams({ fromString: params });

    return this.http
      .get<any>(API_ROUTE.DELEGATIONS.DELEGABLE_EMPLOYEES(managerId), {
        params: queryParams,
      })
      .pipe(
        map((listEmployeesTeamManager): DelegableEmployee[] =>
          listEmployeesTeamManager.map(buildDelegableEmployee)
        )
      );
  }

  public findTemporalAuthorizerEmployees(
    managerId: string,
    text = ''
  ): Observable<DelegableEmployee[]> {
    const params = `search=${text}`;
    const queryParams = new HttpParams({ fromString: params });

    return this.http
      .get<any>(
        API_ROUTE.DELEGATIONS.LOAD_TEMPORAL_AUTHORIZER_EMPLOYEES(managerId),
        {
          params: queryParams,
        }
      )
      .pipe(
        map((listTemporalAuthorizers): DelegableEmployee[] =>
          listTemporalAuthorizers.map(buildDelegableEmployee)
        )
      );
  }

  public assignTemporalAuthorizer(
    employeeId: string,
    params: AssignTemporalAuthorizerInterface
  ): Observable<GenericPostResponse> {
    const paramsCopy = { ...params };

    return this.http.post<any>(
      API_ROUTE.DELEGATIONS.TEMPORAL_AUTHORIZER_EMPLOYEES(employeeId),
      paramsCopy
    );
  }

  public removeTemporalAuthorizer(
    employeeId: string
  ): Observable<GenericPostResponse> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.delete<any>(
      API_ROUTE.DELEGATIONS.TEMPORAL_AUTHORIZER_EMPLOYEES(employeeId),
      options
    );
  }
}
