import moment from 'moment';
import { Observable } from 'rxjs';
import { filter, map, skip, tap } from 'rxjs/operators';
import { ROUTE } from '@constants/index';
import { DayRecordDetailInterface } from '@models/day-record-detail';
import { GenericPostResponse } from '@models/generic-post-response';
import { WorkingDayData } from '@models/working-day-data';
import {
  EmployeeInfo,
  Rubric,
  TimeRecordEmitter,
  WorkingDayDetail,
  WorkingDayEmployee,
} from '..';
import { getCheckDayFormatted } from '../..';
import { WorkingDay } from '../../../pages/working-day/models/working-day';
import { EmployeeWorkingDay } from './employee-working-day';
import {
  buildDayRecordDetail,
  buildEmployeeInfo,
  EmployeeWorkingDayCreator,
  getCurrentDate,
} from './employee-working-day-creator';

export class EmployeeWorkingDayCreatorDefault extends EmployeeWorkingDayCreator {
  // XXX There is a problem with multiple subscriptions causing that some days you have data of another days set:
  //     We have to check that only the response where date is same that Url date are not filtered, discriminating those
  //     that are not in the Url.
  //
  // TODO changes made in commit for issue 4841 could fix this (use ionViewWillLeave and ngOnDestroy).
  //
  public static dayRecordDetailIsOk(
    dayRecordDetail: DayRecordDetailInterface
  ): boolean {
    const isStep1 = window.location.pathname.indexOf('step/1') > -1;
    const workingDay = window.location.pathname.indexOf('/working-day/') > -1;
    const paramsDate = EmployeeWorkingDayCreatorDefault.getParamsDate();

    const responseAndIsStep1 = !!dayRecordDetail && isStep1;

    const responseAndIsParamsDate =
      !!dayRecordDetail && dayRecordDetail.date === paramsDate;

    const today = moment()
      .format('YYYY-MM-DD')
      .toString();

    const detailDayIsToday =
      !!dayRecordDetail && workingDay && dayRecordDetail.date === today;

    return responseAndIsStep1 || responseAndIsParamsDate || detailDayIsToday;
  }

  private static getParamsDate(): string {
    const urlParts = window.location.pathname.split('/');
    const paramsDate = urlParts[urlParts.length - 2];
    return paramsDate;
  }

  public setCheckTimes(
    employeeWorkingDay: EmployeeWorkingDay,
    timeRecord: TimeRecordEmitter
  ): Observable<GenericPostResponse> {
    const { bodyParams } = timeRecord;

    const recordServiceType = timeRecord.isCheckIn
      ? 'setCheckIn'
      : 'setCheckOut';

    const date = moment(employeeWorkingDay.workingDayData.date);
    bodyParams.selectedDay = date.format('YYYY-MM-DD');
    bodyParams.forceCounter = date.isBefore(
      moment(getCurrentDate(employeeWorkingDay.employeeInfo)),
      'day'
    );

    if (employeeWorkingDay.alternativeWorkingDay) {
      bodyParams.alternativeWorkingDay =
        employeeWorkingDay.workingDayData.isAlternativeChosen;
    }

    if (!timeRecord.isCheckIn) {
      bodyParams.systemDate = moment().format('YYYY-MM-DD');
    }

    bodyParams.checkDate = getCheckDayFormatted(
      moment(employeeWorkingDay.workingDayData.date)
        .startOf('day')
        .hours(+bodyParams.checkTimeHour)
        .minutes(+bodyParams.checkTimeMinutes)
        .toDate(),
      employeeWorkingDay.workingDayData.minRegisterTime
    );

    return this.dayRecordService[recordServiceType](
      employeeWorkingDay.employeeInfo.id,
      bodyParams
    );
  }

  // eslint-disable-next-line class-methods-use-this
  public getRouteToGo(
    employeeWorkingDay: EmployeeWorkingDay,
    isWriteMode: boolean,
    isStep2: boolean
  ): string {
    const { checkIn, checkOut, date } = employeeWorkingDay.workingDayData;
    const isPastDay = moment(date).isBefore(
      moment(getCurrentDate(employeeWorkingDay.employeeInfo)),
      'days'
    );

    const shouldBeOnStep2 =
      checkIn && checkOut && employeeWorkingDay.rubric.permissionRegister;

    let route: string;

    if (shouldBeOnStep2) {
      const pastDayStep2Route = isWriteMode
        ? ROUTE.GET_WORKING_DAY_DETAILS(date)
        : ROUTE.SHOW_WORKING_DAY_DETAILS(date);

      route = isPastDay ? pastDayStep2Route : ROUTE.WORKING_DAY_STEP_2;
    } else if (isStep2) {
      route = isPastDay
        ? ROUTE.REGISTER_WORKING_DAY_DETAILS(date)
        : ROUTE.WORKING_DAY_STEP_1;
    }

    return route;
  }

  public setIsAlternativeWorkingDay(isAlternative: boolean): void {
    this.dayRecordDetailService.setIsAlternativeWorkingDay(isAlternative);
  }

  protected getEmployee(): Observable<EmployeeInfo> {
    return this.employeeService.employee$.pipe(
      filter((employee: WorkingDayEmployee): boolean => !!employee),
      map(
        (employee: WorkingDayEmployee): EmployeeInfo => {
          return buildEmployeeInfo(employee);
        }
      )
    );
  }

  // eslint-disable-next-line class-methods-use-this
  protected getWorkingDayDetail(
    requestedDate: Date
  ): Observable<WorkingDayDetail> {
    let callsToSkip = 0;

    const { currentDate } = this.dayRecordDetailService;
    const requestedDateIsDifferent =
      !currentDate || !moment(currentDate).isSame(requestedDate, 'day');

    if (requestedDateIsDifferent) {
      if (currentDate) {
        callsToSkip += 1;
      }
      this.dayRecordDetailService.currentDate = requestedDate;
    }

    // TODO revise all app subscriptions to this observable
    const obs = this.dayRecordDetailService.dayRecordDetail$.pipe(
      tap((dayRecordDetail: DayRecordDetailInterface): void => {
        if (!dayRecordDetail) {
          this.getWorkingDayDetailFromHTTP(requestedDate);
        }
      }),
      skip(callsToSkip),
      filter((dayRecordDetail: DayRecordDetailInterface): boolean =>
        EmployeeWorkingDayCreatorDefault.dayRecordDetailIsOk(dayRecordDetail)
      ),
      map((dayRecordDetail: DayRecordDetailInterface): {
        workingDayData: WorkingDayData;
        rubric: Rubric;
        alternativeWorkingDay?: WorkingDay;
      } => {
        return buildDayRecordDetail(dayRecordDetail, requestedDate);
      })
    );

    if (requestedDateIsDifferent) {
      this.getWorkingDayDetailFromHTTP(requestedDate);
    }

    return obs;
  }
}
