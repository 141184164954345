import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { WorkplacesService } from '@services/index';
import { Workplace } from '@models/workplace.model';

@Component({
  selector: 'app-workplace',
  templateUrl: './workplace.component.html',
  styleUrls: ['./workplace.component.scss'],
})
export class WorkplaceComponent implements OnInit {
  @Input() public selectionDisabled = false;
  @Input() public workLocation: string;
  public workplaceValue: Workplace;
  private NOT_INFORMED_KEY = '0';
  public validWorkplaces: Workplace[];
  public allWorkplaces: Workplace[];
  public selectedWorkplace = null;
  public selectPlaceholder: string;

  @Output() public newItemEvent = new EventEmitter<{ workplace: string }>();

  public constructor(private readonly workplacesService: WorkplacesService) {}

  public ngOnInit(): void {
    this.workplacesService.workplacesDetails$.subscribe(
      (workplaceList: Workplace[]): void => {
        const filteredWorkplaces = workplaceList.filter(
          (wp: Workplace): boolean => wp.key !== this.NOT_INFORMED_KEY
        );
        this.allWorkplaces = workplaceList;
        this.validWorkplaces = filteredWorkplaces;
        this.setWorkPlaceValue();
      }
    );
  }

  private setWorkPlaceValue(): void {
    this.workplaceValue = this.allWorkplaces.find(
      (workplace: Workplace): boolean => workplace.key === this.workLocation
    );
    this.selectPlaceholder =
      this.workplaceValue && this.workplaceValue.key === this.NOT_INFORMED_KEY
        ? this.workplaceValue.name
        : 'Seleccionar';
  }

  public onWorkplaceSelect(ev: any): void {
    this.selectedWorkplace = ev.target.value;
    this.newItemEvent.emit({ workplace: this.selectedWorkplace.key });
  }
}
