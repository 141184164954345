<ion-card-header class="resume-card__header">
  <ion-card-title class="header__title">{{
    weekDay | titlecase
  }}</ion-card-title>
  <ion-card-subtitle class="header__subtitle">{{ date }}</ion-card-subtitle>
  <span
    *ngIf="isMobile && !moreInfoOpened && hasPermissions"
    class="resume-card__link-collapsable"
    (click)="toggleCollapsible()"
  >
    {{ 'WORKING_DAY.MORE_INFO' | translate }}
  </span>
</ion-card-header>

<ion-card-content class="resume-card__content" *ngIf="hasPermissions">
  <ion-grid *ngIf="!isMobile || moreInfoOpened">
    <ion-row class="resume-card__data">
      <ion-col
        class="resume-card__info"
        *ngIf="alternativeWorkingDay && employeeWorkingDay"
      >
        <app-working-day-chooser
          [readOnly]="isReadOnly"
          [alternativeWorkingDayChosen]="alternativeWorkingDayChosen"
          [alternativeWorkingDay]="alternativeWorkingDay"
          [employeeWorkingDay]="employeeWorkingDay"
          (workingDayTypeChange)="alternativeWorkingDayChangeHandler($event)"
        ></app-working-day-chooser>
      </ion-col>
      <ng-container *ngIf="!alternativeWorkingDay">
        <ion-col class="resume-card__info">
          {{ 'WORKING_DAY.DAILY' | translate }}
          <ion-icon
            class="working-day__info-icon"
            ios="ios-information-circle"
            md="md-information-circle"
            *ngIf="!employeeWorkingDay.workingDayData.isJNE"
            (click)="openWorkingDayInfoModal()"
          ></ion-icon>
        </ion-col>
        <ion-col class="resume-card__daily resume-card__time">
          {{ employeeWorkingDay.workingDayData.workingDay | humanizeDuration }}
        </ion-col>
      </ng-container>
    </ion-row>
    <ion-row *ngIf="shouldShowJneLink()">
      <app-jne-link></app-jne-link>
    </ion-row>

    <div *ngIf="!isRegisterMode">
      <ng-container
        *ngIf="employeeWorkingDay.workingDayData.checkOut && isStep2"
      >
        <ion-row class="resume-card__data resume-card__working-day">
          <ion-col class="resume-card__info">
            {{ 'WORKING_DAY.START_TIME' | translate }}
          </ion-col>
          <ion-col class="resume-card__time resume-card__time--edit">
            <ion-spinner
              class="resume-checks-spinner"
              *ngIf="loadingChunks.checkIn"
            ></ion-spinner>

            <ng-container *ngIf="!loadingChunks.checkIn">
              {{ formatDate(employeeWorkingDay.workingDayData.checkIn) }}
              <app-image
                *ngIf="!isReadOnly"
                class="working-day__icon edit--checkin"
                src="assets/icon/edit.svg"
                alt="{{ 'ALT_IMG.EDIT_ICON' | translate }}"
                (click)="openEditModal(checkType.CheckIn)"
              ></app-image>
            </ng-container>
          </ion-col>
        </ion-row>
        <ion-row class="resume-card__data resume-card__working-day">
          <ion-col class="resume-card__info">
            {{ 'WORKING_DAY.END_TIME' | translate }}
          </ion-col>
          <ion-col class="resume-card__time resume-card__time--edit">
            <ion-spinner
              class="resume-checks-spinner"
              *ngIf="loadingChunks.checkOut"
            ></ion-spinner>

            <ng-container *ngIf="!loadingChunks.checkOut">
              {{ formatDate(employeeWorkingDay.workingDayData.checkOut) }}
              <app-image
                *ngIf="!isReadOnly"
                class="working-day__icon edit--checkout"
                src="assets/icon/edit.svg"
                alt="{{ 'ALT_IMG.EDIT_ICON' | translate }}"
                (click)="openEditModal(checkType.CheckOut)"
              ></app-image>
            </ng-container>
          </ion-col>
        </ion-row>
        <ion-row
          class="resume-card__data resume-card__data--border resume-card__working-day"
        >
          <ion-col class="resume-card__info">
            <strong>
              {{ 'WORKING_DAY.PRELIMINARY_REGISTRATION' | translate }}
            </strong>
          </ion-col>
          <ion-col class="resume-card__time resume-card__time--blank">
            <strong>{{ preliminaryRegistration() | humanizeDuration }}</strong>
          </ion-col>
        </ion-row>
      </ng-container>
      <ng-container *ngIf="isStep2 && getMinimumBreakTime()">
        <ion-row class="resume-card__data resume-card__break-time">
          <ion-col class="resume-card__info">
            {{ 'WORKING_DAY.MINIMUM_BREAK_TIME' | translate }}
          </ion-col>
          <ion-col class="resume-card__time resume-card__time--break-time">
            {{ getMinimumBreakTime() | humanizeDuration }}
            <ion-icon name="remove-circle-outline"></ion-icon>
          </ion-col>
        </ion-row>
        <ion-row class="resume-card__data resume-card__data--border resume-card__computation">
          <ion-col class="resume-card__info">
            <strong>
              {{ 'WORKING_DAY.PRELIMINARY_TIME_COMPUTATION' | translate }}
            </strong>
          </ion-col>
          <ion-col class="resume-card__time resume-card__time--blank">
            <strong>
              {{ preliminaryRegistrationForComputation() | humanizeDuration }}
            </strong>
          </ion-col>
        </ion-row>
      </ng-container>
      <ng-container *ngIf="isStep2 && hasRemoteWork && !isMobile">
        <ion-row class="resume-card__data resume-card__data--border">
          <ion-col class="resume-card__info"><b>{{ 'WORKING_DAY.WORKPLACE' | translate }}</b></ion-col>
          <ion-col>
            <app-workplace
              [ngClass]="{'not-selectable': isReadOnly}"
              [selectionDisabled]="isReadOnly"
              [workLocation]="workLocation"
              (newItemEvent)="addWorkplace($event)"
            ></app-workplace>
          </ion-col>
        </ion-row>
      </ng-container>
    </div>
    <ion-row *ngIf="isMobile && moreInfoOpened">
      <span
        class="resume-card__link-collapsable resume-card__link-collapsable--closing"
        (click)="toggleCollapsible()"
      >
        {{ 'WORKING_DAY.LESS_INFO' | translate }}
      </span>
    </ion-row>
  </ion-grid>
</ion-card-content>
