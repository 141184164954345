<ion-grid class="info-panel step-2--perfect">
  <ion-card
    class="wrapper wrapper--preliminary info-panel__card info-panel__card--first"
  >
    <app-preliminary-difference
      [preliminaryDifferenceAgreement]="preliminaryDifferenceAgreement"
      [isReadonly]="isReadonly"
    ></app-preliminary-difference>
  </ion-card>

  <app-effective-working-time
    [effectiveWorkingTime]="effectiveWorkingTime"
    [isReadonly]="isReadonly"
    [isDisabled]="false"
    (confirmSend)="send()"
  ></app-effective-working-time>
</ion-grid>
