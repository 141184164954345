import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueOrHyphen',
})
export class ValueOrHyphenPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  public transform(value: any): string {
    return value.toString() !== '' ? value.toString() : '-';
  }
}
