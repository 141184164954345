import { Component, Input } from '@angular/core';
import { CSS_MAP, STATES_MAP } from '@constants/autorizations.constants';
import { AuthInterface } from '@models/authorizations';
import { DateFormatter } from '../../utils/date-formatter/date-formatter.util';

@Component({
  selector: 'app-authorization-item',
  templateUrl: './authorization-item.component.html',
  styleUrls: ['./authorization-item.component.scss'],
})
export class AuthorizationItemComponent {
  @Input() public authListItem: AuthInterface;
  @Input() public loadingIndex: number;
  @Input() public index: number;
  @Input() public isDelegatingManager: boolean;
  @Input() public isDelegation = false;

  public employeeId: string;

  // eslint-disable-next-line class-methods-use-this
  public formatDate(date: string): string {
    return DateFormatter.getDay(date);
  }

  public isLoading(generic?: boolean): boolean {
    let isLoading = false;

    isLoading =
      (generic && Number.isFinite(this.loadingIndex)) ||
      this.loadingIndex === this.index;

    return isLoading;
  }

  public getStateText(): string {
    return (
      'AUTHORIZATIONS.AUTHORIZATIONS_LOG.' +
      `${STATES_MAP[this.authListItem.state]}`
    );
  }

  public getDotClass(): string {
    return CSS_MAP[this.authListItem.state];
  }
}
