import { Component, Input } from '@angular/core';
import { GLOBAL } from '@constants/index';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent {
  @Input() public progress: string;

  public step1 = GLOBAL.STEP_1;

  public step2 = GLOBAL.STEP_2;

  public getCurrentStepClass(step: string): string {
    return this.progress === step ? `step--current` : 'step';
  }
}
