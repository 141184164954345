import moment from 'moment';
import { ABSENCE_CODES } from '../../../shared/constants/workingDay.constants';
import { checkProperty, throwErrorIf } from '../../../shared/utils/validations';

export interface WorkingDayAbsence {
  code: string;
  title: string;
}

export interface WorkingDayProperty {
  label: string;
  time: string;
}

export interface DayLog {
  checkIn: Date;
  checkOut: Date;
  checkInSystem: Date;
  checkOutSystem: Date;
  isManuallyRegistered: boolean;
  day: Date;
  workingDay: number;
  workingDayAbsence: WorkingDayAbsence;
  workingDayHoliday: WorkingDayAbsence;
  agreementMargin: number;
  preliminaryRegistration: number;
  effectiveWorkingTime: number;
  difference: number;
  workingDayExtensionRequest: 'APPROVED' | 'DENIED' | 'PENDING';
  statusCompleted: boolean;
  statusConfirmed: boolean;
  consolidated: boolean;
  compensation: number;
  shouldAllowRegister?: boolean;
  isExceptionAbsence?: boolean;
  workplace: string;
}

export const HR_APPROVED_STATUS = 'A';
export const HR_DENIED_STATUS = 'R';
export const HR_PENDING_STATUS = 'P';

export const HR_ON_SITE_WORK = '1';
export const HR_FULL_REMOTEWORK = '2';
export const HR_PARTIAL_REMOTEWORK = '3';

function validateDayLog(data: any): void {
  const dayLogRequiredProperties = [
    { name: 'consolidated', nullable: true },
    { name: 'checkIn', nullable: true },
    { name: 'checkOut', nullable: true },
    { name: 'isManual', nullable: true },
    { name: 'compensation', nullable: true },
  ];

  const notValidCheckIn =
    data.checkIn !== null && !moment(data.checkIn, 'HH:mm').isValid();

  const notValidCheckOut =
    data.checkOut !== null && !moment(data.checkOut, 'HH:mm').isValid();

  const requestStatusIsNotCorrect = ![
    HR_APPROVED_STATUS,
    HR_DENIED_STATUS,
    HR_PENDING_STATUS,
    null,
  ].includes(data.workingDayExtensionRequest);

  dayLogRequiredProperties.forEach((field: any): void => {
    checkProperty(data, 'DayLog', field);
  });

  throwErrorIf(notValidCheckIn, 'DayLog checkIn should be a valid timestamp');
  throwErrorIf(notValidCheckOut, 'DayLog checkOut should be a valid timestamp');
  throwErrorIf(
    requestStatusIsNotCorrect,
    'Daylog workingDayExtensionRequest should be "A", "R" or "P"'
  );

  // TODO (dlopez-sopra): A more exhaustive validation should be done.
}

type ApprovalStatus = 'APPROVED' | 'DENIED' | 'PENDING' | null;

function buildWorkingDayExtensionRequest(
  rawWorkingDayExtensionRequest: string
): ApprovalStatus {
  let output: ApprovalStatus = null;

  if (rawWorkingDayExtensionRequest === HR_APPROVED_STATUS) {
    output = 'APPROVED';
  } else if (rawWorkingDayExtensionRequest === HR_DENIED_STATUS) {
    output = 'DENIED';
  } else if (rawWorkingDayExtensionRequest === HR_PENDING_STATUS) {
    output = 'PENDING';
  }

  return output;
}

function isExceptionAbsence(dayLog: any): boolean {
  const exceptionAbsences = Object.keys(ABSENCE_CODES);
  return (
    !!dayLog.workingDayAbsence &&
    exceptionAbsences.some(
      (absence): boolean =>
        ABSENCE_CODES[absence] === dayLog.workingDayAbsence.code
    )
  );
}

function shouldAllowRegister(dayLog: any): boolean {
  const isConfirmed = !!dayLog.isConfirmed;
  const isConsolidated = dayLog.consolidated;
  const workingDayHoliday = dayLog.workingDayHoliday || null;
  const workingDayAbsence = dayLog.workingDayAbsence || null;
  const isException = isExceptionAbsence(dayLog);

  return (
    !isConfirmed &&
    !isConsolidated &&
    !workingDayHoliday &&
    (isException || !workingDayAbsence)
  );
}

export function buildDayLog(data: any): DayLog {
  validateDayLog(data);
  const {
    checkIn,
    checkInSystem,
    checkOut,
    checkOutSystem,
    agreementMargin,
    registerDate,
    difference,
    effectiveWorkingTime,
    preliminaryRegistration,
    workingDay,
    workingDayAbsence,
    workingDayExtensionRequest,
    isConfirmed,
    isCompleted,
    isManual,
    consolidated,
    workingDayHoliday,
    compensation,
    workLocation,
  } = data;

  const [dateCheckIn, dateCheckOut, dateCheckInSystem, dateCheckOutSystem] = [
    checkIn,
    checkOut,
    checkInSystem,
    checkOutSystem,
  ].map(
    (checkTime): Date =>
      checkTime
        ? moment(checkTime, 'HH:mm')
            .seconds(0)
            .millisecond(0)
            .toDate()
        : null
  );

  return ({
    checkIn: dateCheckIn,
    checkOut: dateCheckOut,
    checkInSystem: dateCheckInSystem,
    checkOutSystem: dateCheckOutSystem,
    agreementMargin: +agreementMargin,
    day: moment(registerDate, 'YYYY-MM-DD')
      .startOf('day')
      .toDate(),
    difference,
    effectiveWorkingTime: +effectiveWorkingTime,
    preliminaryRegistration: +preliminaryRegistration,
    workingDay,
    statusConfirmed: !!isConfirmed,
    statusCompleted: !!isCompleted,
    workingDayAbsence: workingDayAbsence || null,
    workingDayHoliday: workingDayHoliday || null,
    workingDayExtensionRequest: buildWorkingDayExtensionRequest(
      workingDayExtensionRequest
    ),
    isManuallyRegistered: !!isManual,
    consolidated,
    compensation,
    shouldAllowRegister: shouldAllowRegister(data),
    isExceptionAbsence: isExceptionAbsence(data),
    workplace: workLocation || '0',
  } as any) as DayLog;
}
