<div>
  <ion-item lines="none">
    <span slot="start">
      <ion-icon
        slot="icon-only"
        class="arrow arrow-left"
        name="ios-arrow-back"
        (click)="previous()"
      ></ion-icon>
    </span>
    <span class="month-year">
      {{ MONTHS_LABELS[monthSelected - 1] }} {{ yearSelected }}
    </span>
    <span slot="end">
      <ion-icon
        slot="icon-only"
        class="arrow arrow-right"
        name="ios-arrow-forward"
        (click)="next()"
      ></ion-icon>
    </span>
  </ion-item>
  <ion-grid>
    <ion-row>
      <ion-col
        *ngFor="let daylabel of DAYS_LABELS"
        class="ion-text-center"
        class="week-day"
      >
        {{ daylabel }}
      </ion-col>
    </ion-row>
    <ion-row *ngFor="let week of weeks">
      <ion-col
        *ngFor="let day of week"
        (click)="selectDay(day)"
        class="ion-text-center"
        [ngClass]="{ 'col-day-off': day.off, 'col-day': !day.off }"
      >
        <span>
          <span [ngClass]="getDisabledClass(day)">{{ day.dayOfMonth }}</span>
        </span>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
