<ion-item class="search-employee" #searchInput lines="full">
  <ion-input
    type="text"
    [value]="filterText"
    [placeholder]="'SEARCH_EMPLOYEE_BY_NAME' | translate"
    [disabled]="disabled"
    class="search-employee__input"
    debounce="300"
    (ionChange)="onSearchInputChange($event)"
    (ionFocus)="setInputFocused(true)"
  >
  </ion-input>
  <ion-icon
    *ngIf="selectedEmployee && !disabled"
    size="large"
    class="search-employee__clean-icon"
    name="close"
    (click)="clean()"
  ></ion-icon>
  <div item-end class="search-button" (click)="searchEmployee()">
    <app-image
      class="search-button__icon"
      src="assets/icon/search.svg"
    ></app-image>
  </div>
</ion-item>
<ion-list *ngIf="shouldShowSearchResults()" class="employee-list">
  <ion-item
    *ngFor="let employee of employeesResults"
    class="item-employee"
    lines="none"
    (click)="onEmployeeSelect(employee)"
  >
    <app-image
      slot="start"
      class="avatar"
      src="assets/icon/default-avatar.png"
      [alt]="'ALT_IMG.AVATAR' | translate"
    ></app-image>
    <ion-label> {{ employee.firstName }} </ion-label>
  </ion-item>
</ion-list>
