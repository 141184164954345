import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SingleDelegateEmployee } from 'src/app/pages/delegations/models/delegation';

@Component({
  selector: 'app-drop-down-employee',
  templateUrl: './drop-down-employee.component.html',
  styleUrls: ['./drop-down-employee.component.scss'],
})
export class DropDownEmployeeComponent {
  @Input() public employees: SingleDelegateEmployee[] = [];
  @Input() public isEditMode = false;
  @Input() public isDisabled = false;
  @Input() public summaryText = 'DELEGATIONS.CREATE_MODAL.HAS_SELECTED_PERSONS';
  @Output() public removeSelectedEmployee = new EventEmitter<string>();

  public isOpen = false;

  public toggle(): void {
    this.isOpen = !this.isOpen;
  }

  public hasEmployees(): boolean {
    return this.employees.length > 0;
  }

  public isMultiSelectedOrDisabled(): boolean {
    return this.employees.length > 1 || this.isDisabled;
  }

  public isOneSelected(): boolean {
    return this.employees.length === 1;
  }

  public shouldShowEmployees(): boolean {
    return (
      (this.isOneSelected() ||
        (this.isMultiSelectedOrDisabled() && this.isOpen)) &&
      !this.isDisabled
    );
  }

  public removeEmployee(employee: SingleDelegateEmployee): void {
    if (this.employees.length === 2) {
      this.toggle();
    }
    this.removeSelectedEmployee.emit(employee.employeeId);
  }

  public getTranslation(): { [x: string]: string } {
    return {
      numEmployees: this.employees.length.toString(10),
    };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  public getCssClass() {
    const baseClass = 'container selected-employee';

    return this.isOneSelected()
      ? `${baseClass} selected-employee__one`
      : baseClass;
  }
}
