import moment from 'moment';

export function buildDateWithTime(data: string, startingDate?: Date): Date {
  if (!data) {
    return null;
  }

  const [hours, minutes] = data.split(':');
  return moment(startingDate)
    .startOf('day')
    .hours(+hours)
    .minutes(+minutes)
    .seconds(0)
    .milliseconds(0)
    .toDate();
}
