import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanizeDuration',
})
export class HumanizeDurationPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  public static humanize(value: number, showPositiveSign?: boolean): string {
    const MINUTES_PER_HOUR = 60;
    const POSITIVE_SIGN = showPositiveSign && value > 0 ? '+ ' : '';

    const ABSOLUTE_VALUE = Math.abs(value);
    const negative = value !== ABSOLUTE_VALUE;
    const sign = negative ? '- ' : POSITIVE_SIGN;

    const hours = Math.floor(Math.abs(value / MINUTES_PER_HOUR));
    const minutes = `0${ABSOLUTE_VALUE % MINUTES_PER_HOUR}`.slice(-2);
    const durationFormatted = `${hours}h ${minutes}min`;

    return sign + durationFormatted;
  }

  // eslint-disable-next-line class-methods-use-this
  public transform(value: number, showPositiveSign?: boolean): string {
    return HumanizeDurationPipe.humanize(value, showPositiveSign);
  }
}
