import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTE } from '@constants/index';
import { AuthorizationsPageModule } from './pages/authorizations/authorizations.module';
import { EmployeeChooserModule } from './pages/employee-chooser/employee-chooser.module';
import { ErrorPageModule } from './pages/error/error.module';
import { MaintenancePageModule } from './pages/maintenance/maintenance.module';
import { ErrorVersionModule } from './pages/error-version/error-version.module';
import { LogsPageModule } from './pages/logs/logs.module';
import { WorkingDayPageModule } from './pages/working-day/working-day.module';

export const routes: Routes = [
  {
    path: '',
    redirectTo: ROUTE.WORKING_DAY,
    pathMatch: 'full',
  },
  {
    path: ROUTE.IN_MAINTENANCE,
    loadChildren: () =>
      import('./pages/maintenance/maintenance.module').then(
        module => module.MaintenancePageModule
      ),
  },
  {
    path: ROUTE.ERROR,
    loadChildren: () =>
      import('./pages/error/error.module').then(
        module => module.ErrorPageModule
      ),
  },
  {
    path: ROUTE.ERROR_VERSION,
    loadChildren: () =>
      import('./pages/error-version/error-version.module').then(
        module => module.ErrorVersionModule
      ),
  },
  {
    path: ROUTE.WORKING_DAY,
    loadChildren: () =>
      import('./pages/working-day/working-day.module').then(
        module => module.WorkingDayPageModule
      ),
  },
  {
    path: ROUTE.LOGS,
    loadChildren: () =>
      import('./pages/logs/logs.module').then(module => module.LogsPageModule),
  },
  {
    path: ROUTE.AUTHORIZATIONS,
    loadChildren: () =>
      import('./pages/authorizations/authorizations.module').then(
        module => module.AuthorizationsPageModule
      ),
  },
  {
    path: ROUTE.DELEGATIONS,
    loadChildren: () =>
      import('./pages/delegations/delegations.module').then(
        module => module.DelegationsPageModule
      ),
  },
  {
    path: ROUTE.EMPLOYEE,
    loadChildren: () =>
      import('./pages/employee-chooser/employee-chooser.module').then(
        module => module.EmployeeChooserModule
      ),
  },

  {
    path: '**',
    redirectTo: 'page-not-found',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    EmployeeChooserModule,
    WorkingDayPageModule,
    LogsPageModule,
    AuthorizationsPageModule,
    ErrorPageModule,
    ErrorVersionModule,
    MaintenancePageModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
