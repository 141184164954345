<ion-content class="employee-logs page">
  <ion-grid>
    <ion-row class="employee-logs__header">
      <ion-col class="header__greeting" size-sm="5">
        <app-user-greeting
          [userName]="userName"
          translationId="GREETING.LOGS"
        ></app-user-greeting>
      </ion-col>
      <ion-col size-sm="7" class="header__balance" *ngIf="shouldShowCurrentBalanceCard()">
        <ion-row>
          <ion-col
            class="balance_layout"
            size-sm="6"
            size-xs="12"
          >
            <app-balance-previous-years
              [value]="balancePreviousYears"
            ></app-balance-previous-years>
          </ion-col>
          <ion-col
            class="balance_layout"
            size-sm="6"
            size-xs="12"
          >
            <app-current-balance
                [value]="currentBalance"
                [lockedValue]="lockedBalance"
            ></app-current-balance>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="!isMobile">
      <ion-col>
        <app-title-and-legends [friendsAndFamily]="friendsAndFamily"></app-title-and-legends>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <app-months-list
          *ngIf="!isLoading && months.length"
          [yearMonths]="months"
          [targetEmployeeId]="employeeId"
          [targetHasRemoteWork]="loggedUserHasRemoteWork"
        ></app-months-list>
        <ion-spinner *ngIf="isLoading" class="g-spinner"></ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
