<ion-textarea
  [disabled]="isReadOnly"
  class="c-textarea"
  [rows]="rowsCapacity"
  [formControl]="reasonTextAreaControl"
  [placeholder]="placeholder"
  (ionChange)="onReasonFormChange($event)"
>
</ion-textarea>
<ion-label class="char-count">{{ characters }}/ {{ maxLength }}</ion-label>
