<ion-grid class="info-panel step-2--excess">
  <ion-card
    class="wrapper wrapper--preliminary info-panel__card info-panel__card--first"
  >
    <app-preliminary-difference
      [preliminaryDifferenceAgreement]="preliminaryDifferenceAgreement"
      [isReadonly]="isReadonly"
    ></app-preliminary-difference>

    <ion-row>
      <ion-col>
        <ion-list lines="none" data-test="differences">
          <app-time-input
            *ngFor="let differenceInput of differenceInputs; first as isFirst"
            [data]="differenceInput"
            [timeInputClass]="differenceInput.class"
            [initialValues]="getTimeInputInitialValues(differenceInput)"
            (changeTime)="onDifferenceTimeChange($event, differenceInput.type)"
            class="time-input"
            [class.time-input--first]="isFirst"
            [isReadOnly]="isReadonly"
          ></app-time-input>

          <app-textarea
            *ngIf="shouldBeReasoned()"
            class="reason"
            [isMobile]="isMobile"
            [maxLength]="maxStepLength"
            [isReadOnly]="isReadonly"
            [reasonTextAreaControl]="reasonTextAreaControl"
            (changeTextarea)="onTextAreaChange($event)"
            [placeholder]="
              'WORKING_DAY.STEP_2.DIFFERENCE_INPUT.EXCESS.EXTENSION.PLACEHOLDER'
                | translate
            "
          ></app-textarea>

          <ion-item *ngIf="!isReadonly">
            <ion-label class="time-total">
              <span>
                {{ 'WORKING_DAY.STEP_2.DIFFERENCE_INPUT.TOTAL' | translate }}
              </span>
              <span class="time-total__value">
                {{ sumDifferences() | humanizeDuration }}
              </span>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-card>

  <app-effective-working-time
    [effectiveWorkingTime]="effectiveWorkingTime"
    [isReadonly]="isReadonly"
    [isDisabled]="!canContinue()"
    (confirmSend)="send()"
  ></app-effective-working-time>
</ion-grid>
