import { Routes } from '@angular/router';
import { ROUTE } from '@constants/index';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { Step1Component } from './components';
import { RootComponent } from './components/step-2/components/root/root.component';
import { WorkingDayPage } from './working-day.page';

export const workingDayRoutes: Routes = [
  {
    path: ROUTE.WORKING_DAY,
    canActivate: [AuthGuard],
    component: WorkingDayPage,
    children: [
      { path: '', pathMatch: 'full', redirectTo: ROUTE.STEP1 },
      { path: ROUTE.STEP1, component: Step1Component },
      { path: ROUTE.STEP2, component: RootComponent },
    ],
  },
];

export class WorkingDayRoutingModule {}
