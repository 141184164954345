import { Injectable } from '@angular/core';

declare const bbva: any;

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  // eslint-disable-next-line class-methods-use-this
  public isBBVANetwork(): Promise<boolean> {
    if (!bbva) {
      return Promise.reject(new Error('"bbva" library is not available'));
    }

    return new Promise((resolve): void => {
      if (bbva.front.global.Invoke() === undefined) resolve(false);
      bbva.front.global.Invoke('getNetworkStatus', (d: any): void => {
        resolve(d.bbvaNetwork);
      });
    });
  }
}
