import moment from 'moment';

export type AuthorizationStatus = 'P' | 'R' | 'A' | null;

export enum AuthorizationStatuses {
  APPROVED = 'A',
  REJECTED = 'R',
  PENDING = 'P',
}

export interface RejectData {
  rejectCode: string;
  rejectComment: string;
}

export interface ReasonKeyName {
  key: string;
  name: string;
}

export interface SuccessModalDataElement {
  title: string;
  value: string;
  strongValue?: string;
}

export interface AuthorizactionListSubscription {
  NAME: string;
  OBSERVABLE: string;
}

export interface AuthorizationListSubscriptionTypes {
  LOG: AuthorizactionListSubscription;
}

export class AuthorizationsFilter {
  public static readonly formatDate = 'YYYY-MM-DD';
  public employeeId: string;
  public dateFrom: string;
  public dateTo: string;
  public isApplied = false;

  public canApply(): boolean {
    const bothDatesAreFilled = !!this.dateFrom && !!this.dateTo;
    const noDateIsFilled = !this.dateFrom && !this.dateTo;

    const dateFromMoment = moment(
      this.dateFrom,
      AuthorizationsFilter.formatDate
    );
    const dateToMoment = moment(this.dateTo, AuthorizationsFilter.formatDate);
    const fromBeforeTo = dateFromMoment.isSameOrBefore(dateToMoment);

    const thereIsSomeData = bothDatesAreFilled || this.employeeId;
    const datesAreValid =
      (bothDatesAreFilled && fromBeforeTo) || noDateIsFilled;

    return thereIsSomeData && datesAreValid;
  }

  public apply(): void {
    this.isApplied = true;
  }

  public clean(): void {
    this.isApplied = false;
    this.employeeId = null;
    this.dateFrom = null;
    this.dateTo = null;
  }
}
