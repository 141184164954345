import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-error-content',
  templateUrl: './error-content.component.html',
  styleUrls: ['./error-content.component.scss'],
})
export class ErrorContentComponent {
  @Input() public excessCompensation = false;
  @Input() public distributedTime = false;
  @Input() public workplaceMissing = false;
  @Input() public title = '';
  @Input() public errorMessage = '';
  @Output() public closeModal = new EventEmitter<void>();

  public dismissModal(): void {
    this.closeModal.emit();
  }

  getTitle() {
    if (!this.title) {
      return 'REGISTER_TIME.MODAL_ERROR.TITLE';
    }
    return this.title;
  }

  public getMainMessageError(): string {
    return this.excessCompensation
      ? 'REGISTER_TIME.MODAL_ERROR.P3'
      : 'REGISTER_TIME.MODAL_ERROR.P1';
  }
}
