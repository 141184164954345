import { ROUTE } from './routes.constants';

const buildDelegations = (): any => {
  return {
    title: 'NAV_LINKS.DELEGATIONS',
    url: ROUTE.DELEGATIONS,
    shouldShowWarningSign: false,
  };
};

const buildAuthorizations = (): any => {
  return {
    title: 'NAV_LINKS.AUTHORIZATIONS',
    url: ROUTE.AUTHORIZATIONS,
  };
};

const buildEmployeeTabs = (): any[] => {
  return [
    {
      title: 'NAV_LINKS.WORKING_DAY',
      url: ROUTE.WORKING_DAY,
    },
    {
      title: 'NAV_LINKS.LOGS',
      url: ROUTE.LOGS,
      unconfirmedDaysCounter: 0,
    },
  ];
};

const buildAuthorisingEmployeeTabs = (): any[] => {
  return [...buildEmployeeTabs(), buildAuthorizations()];
};

const buildAuthorisingEmployeeWithDelegationsTabs = (): any[] => {
  return [...buildAuthorisingEmployeeTabs(), buildDelegations()];
};

export const TABS = {
  MANAGER: buildAuthorisingEmployeeWithDelegationsTabs(),
  MANAGER_WITHOUT_DELEGATIONS: buildAuthorisingEmployeeTabs(),
  COMMON_EMPLOYEE: buildEmployeeTabs(),
  EMPLOYEE_DELEGATE: buildAuthorisingEmployeeTabs(),
};
