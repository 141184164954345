export interface DayRecord {
  selectedDay: string;
  checkTimeHour: string;
  checkTimeMinutes: string;
  systemTimeHour: string;
  systemTimeMinutes: string;
  checkInTimeHour?: string;
  checkInTimeMinutes?: string;
  systemInTimeHour?: string;
  systemInTimeMinutes?: string;
  forceCounter: boolean;
  alternativeWorkingDay?: boolean;
  checkDate?: string; // TODO: Remove optionality when feature is consolidated
  systemDate?: string;
}

export interface DayRecordCheckOut {
  selectedDay: string;
  checkTimeHour: string;
  checkTimeMinutes: string;
  systemTimeHour: string;
  systemTimeMinutes: string;
  checkInTimeHour?: string;
  checkInTimeMinutes?: string;
  systemInTimeHour?: string;
  systemInTimeMinutes?: string;
  forceCounter: boolean;
  alternativeWorkingDay?: boolean;
  checkDate?: string; // TODO: Remove optionality when feature is consolidated
  systemDate?: string;
  checkInDate: string;
}

export interface CheckTimes {
  checkInTime?: Date;
  checkOutTime?: Date;
}

export interface TimeRecordEmitter {
  bodyParams: DayRecord;
  isCheckIn: boolean;
}

export interface TimeEditEmitter {
  isRegisterValid: boolean;
  hour: string;
  minute: string;
}

export interface FullTime {
  hours: string;
  minutes: string;
}

export interface DayRecordConfirm {
  selectedDay: string;
  recoverableTime?: number;
  notRecoverableTime?: number;
  excessPersonalReasonsTime?: number;
  excessExtensionDayTime?: number;
  commentExtensionDay?: string;
  workplace?: string;
}

export enum CheckType {
  CheckIn,
  CheckOut,
}
