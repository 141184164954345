import { Component, Input } from '@angular/core';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';
import { DayLog } from '../../models/day-log';

@Component({
  selector: 'app-month-log',
  templateUrl: './month-log.component.html',
  styleUrls: ['./month-log.component.scss'],
})
export class MonthLogComponent {
  @Input() public readOnly = false;
  @Input() public dayLogs: DayLog[];
  @Input() public targetEmployeeId: string;
  @Input() public targetHasRemoteWork: boolean;

  public isLoading: boolean;
  public isMobile: boolean;

  public constructor(
    private readonly platformCheckerService: PlatformCheckerService
  ) {
    this.isMobile = this.platformCheckerService.isMobile();
  }
}
