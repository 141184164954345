import moment from 'moment';

export class DateFormatter {
  public static formatTime(value = '0', max?: string): string {
    const timeValue = value.replace(/[^0-9]+/g, '');
    let output: string;

    if (+value < 0) {
      output = '00';
    } else {
      output = +timeValue > +max ? max : timeValue;
    }

    return `0${output}`.slice(-2);
  }

  public static getDay(dateString: string): string {
    const date = moment(dateString);
    let nameDay = date.format('dddd');
    nameDay = nameDay.charAt(0).toUpperCase() + nameDay.slice(1);
    const day = date.format('DD');
    const nameMonth = date.format('MMMM');
    const year = date.format('YYYY');

    return `${nameDay}, ${day} de ${nameMonth} de ${year}`;
  }

  public static makeADate(year: number, month: number, day: number): Date {
    return moment()
      .startOf('day')
      .year(year)
      .month(month)
      .date(day)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .toDate();
  }

  public static makeAHour(hours: number, minutes: number): Date {
    return moment()
      .startOf('day')
      .hours(hours)
      .minutes(minutes)
      .toDate();
  }

  public static addMinsToHour(
    hours: number,
    minutes: number,
    minsToAdd: number
  ): Date {
    return moment({ hour: hours, minutes })
      .add(minsToAdd, 'minutes')
      .toDate();
  }
}
