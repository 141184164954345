import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { WorkingDayEmployee } from '@models/working-day-employee';
import { EmployeeService } from '@services/employee/employee.service';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';
import { DayRecordDetailService } from '@services/index';
import moment from 'moment';
import { DayRecordDetailInterface } from '@models/day-record-detail';
import { ModalController } from '@ionic/angular';
import { NegativeBalanceAdjustmentModalComponent } from 'src/app/pages/working-day/components/modals/negative-balance-adjustment-modal/negative-balance-adjustment-modal.component';
import { SuccessInfoInterface } from 'src/app/pages/working-day/components/step-2/models';
import { GLOBAL } from '@constants/index';
import { HumanizeDurationPipe } from 'src/app/shared/pipes/humanize-duration.pipe';
import { DateFormatter } from 'src/app/shared';
import { YearMonth } from '../../../models/year-month';
import { LogsService } from '../../../services/logs.service';

@Component({
  selector: 'app-negative-balance-adjustment',
  templateUrl: './negative-balance-adjustment.component.html',
  styleUrls: ['./negative-balance-adjustment.component.scss'],
})
export class NegativeBalanceAdjustment {
  public dayRecordDeatil: DayRecordDetailInterface;
  public negativeCompensation = 0;
  public userName: string;
  public employeeId = '';
  public currentBalance: number;
  public balancePreviousYears: number;
  public lockedBalance: number;
  public showHideBalance = false;
  public maxStepLength = 250;
  public date: Date;
  public isSuccessAdjustment = false;
  public reasonValue: string;
  public minute: string;
  public hour: string;
  public isLoading = false;
  readonly cssInput = ['time-input__input', 'c-number-input'];

  private readonly maxHour = GLOBAL.MAX_HOUR;
  private readonly maxMinute = GLOBAL.MAX_MINUTE;

  public constructor(
    public employeeService: EmployeeService,
    public platformCheckerService: PlatformCheckerService,
    protected readonly dayRecordDetailService: DayRecordDetailService,
    private readonly logsService: LogsService,
    private readonly modalController: ModalController
  ) {}

  public ionViewDidEnter(): void {
    this.employeeService.employee$
      .pipe(
        concatMap(
          (employee: WorkingDayEmployee): Observable<YearMonth[]> => {
            this.userName = employee.firstName;
            this.employeeId = employee.id;
            this.currentBalance = employee.currentBalance;
            this.balancePreviousYears = employee.balancePreviousYears;
            this.lockedBalance = employee.lockedBalance;
            this.showHideBalance = employee.shouldHideBalance;
            return this.logsService.getAvailableYearMonths(employee.id);
          }
        )
      )
      .subscribe();
  }

  public shouldShowCurrentBalanceCard(): boolean {
    return this.currentBalance !== undefined && !this.showHideBalance;
  }

  public getRecordData() {
    this.dayRecordDetailService
      .getDayRecordDetail(
        this.employeeId,
        moment(this.date).format('YYYY-MM-DD')
      )
      .subscribe((dayrecord: DayRecordDetailInterface) => {
        this.dayRecordDeatil = dayrecord;
        this.negativeCompensation = dayrecord.differenceToCompensate;
        if (dayrecord.differenceToCompensate === 0) {
          this.hour = dayrecord.differenceToCompensate.toString();
          this.minute = dayrecord.differenceToCompensate.toString();
        } else {
          this.setTimeToCompensate(dayrecord.differenceToCompensate);
        }
        this.isLoading = false;
      });
  }

  public setTimeToCompensate(time: number): void {
    const timeToCompensate = new HumanizeDurationPipe().transform(time);
    const [, hour, minutes] = timeToCompensate.split(' ');
    const [hourValue] = hour.split('h', 1);
    const [minValue] = minutes.split('min', 1);
    this.hour = hourValue;
    this.minute = minValue;
  }

  public onChangeDate($event) {
    this.isLoading = true;
    this.date = moment($event, 'DD/MM/YYYY').toDate();
    this.getRecordData();
    this.isSuccessAdjustment = false;
  }

  public async send() {
    const modalOptions = {
      component: NegativeBalanceAdjustmentModalComponent,
      cssClass: 'register-time',
      componentProps: {
        title: 'LOGS.NEGATIVE_BALANCE.TITLE',
        titleError: 'LOGS.NEGATIVE_BALANCE.MODAL_ERROR.TITLE',
        data: this.getDataModal(),
        dateSelected: this.date,
        compensation: Number(this.hour) * 60 + Number(this.minute),
        reason: this.reasonValue,
        employeeId: this.employeeId,
        errorMessage: this.isSucess(),
      },
    };
    const modal = await this.modalController.create(modalOptions);
    modal.present();
    const dismissData = await modal.onDidDismiss();
    if (dismissData && dismissData.data) {
      if (!dismissData.data.error) {
        this.isSuccessAdjustment = true;
        this.currentBalance += Number(this.hour) * 60 + Number(this.minute);
        this.employeeService.getEmployee(this.employeeId).subscribe();
      }
    }
  }

  public getDataModal() {
    const successInfo: SuccessInfoInterface[] = [
      {
        name: 'WORKING_DAY.TOTAL_ACCUMULATED_BALANCE',
        time: this.currentBalance,
      },
      {
        name: 'WORKING_DAY.DIFFERENCE_COMPENSATE',
        time: this.negativeCompensation,
      },
      {
        name: 'WORKING_DAY.BALANCE_ADJUSTMENT_TO_DO',
        time: Number(this.hour) * 60 + Number(this.minute),
      },
    ];
    return successInfo;
  }

  public onChangeHour(): void {
    const isOclock = this.hour === '24';

    this.hour = isOclock
      ? '00'
      : DateFormatter.formatTime(this.hour, this.maxHour);
  }

  public onChangeMinute(): void {
    const isOclock = this.minute === '60';

    this.minute = isOclock
      ? '00'
      : DateFormatter.formatTime(this.minute, this.maxMinute);
  }

  public isSucess() {
    if (!this.date || !this.reasonValue) {
      return 'ERROR_PAGE.ERRORS.ERROR_GTAR08';
    }
    const adjustment = Number(this.hour) * 60 + Number(this.minute);
    if (adjustment === 0) {
      return 'ERROR_PAGE.ERRORS.ERROR_GTAR00';
    }

    if (this.currentBalance > 0) {
      return 'ERROR_PAGE.ERRORS.ERROR_GTAR01';
    }

    if (this.negativeCompensation === 0) {
      return 'ERROR_PAGE.ERRORS.ERROR_GTAR03';
    }

    if (this.currentBalance + adjustment > 0) {
      return 'ERROR_PAGE.ERRORS.ERROR_GTAR06';
    }

    if (Math.abs(this.negativeCompensation) < adjustment) {
      return 'ERROR_PAGE.ERRORS.ERROR_GTAR07';
    }

    return '';
  }
}
