<ng-template #noEditMode>
  <ion-col class="header__block">
    <app-image
      class="header__tick"
      src="assets/icon/success-tick.svg"
      [alt]="'ALT_IMG.SUCCESS_TICK' | translate"
    ></app-image>
    <ion-card-title>
      {{ getTitle() | translate }}
    </ion-card-title>
  </ion-col>
  <ion-col *ngIf="isMobile" class="header__block justify-end">
    <ion-text class="header__time">
      {{ fullTime }}
    </ion-text>
    <app-image
      class="header__edit-icon"
      src="assets/icon/edit.svg"
      (click)="editRegister()"
      [alt]="'ALT_IMG.EDIT_ICON' | translate"
    ></app-image>
  </ion-col>
</ng-template>

<ion-row [ngClass]="getHeaderClass()">
  <ng-container *ngIf="isEditMode; else noEditMode">
    <app-image
      *ngIf="!isMobile"
      class="header__watch"
      src="assets/icon/watch.svg"
      [alt]="'ALT_IMG.WATCH' | translate"
    ></app-image>
    <ion-card-title>{{ title | translate }}</ion-card-title>
  </ng-container>
</ion-row>
