import { Rubric, WorkingDayData } from '@models/index';
import { TimeCalulationsInterface } from '../interfaces/index';
import { WorkingDay } from '../models/working-day';
import { getPreliminaryRegister } from './time-calculation-helper';

export class TimeCalculationsPerfect implements TimeCalulationsInterface {
  private checkIn: Date;
  private checkOut: Date;
  private workingDay: number;
  private minimumBreakTime: number;
  private defaultValue = 0;

  public constructor(
    { checkIn, checkOut, workingDay, isAlternativeChosen }: WorkingDayData,
    { minimumBreakTime }: Rubric,
    private alternativeWorkingDay?: WorkingDay
  ) {
    this.checkIn = checkIn;
    this.checkOut = checkOut;
    this.workingDay =
      this.alternativeWorkingDay && isAlternativeChosen
        ? this.alternativeWorkingDay.duration
        : workingDay;
    this.minimumBreakTime =
      this.alternativeWorkingDay && isAlternativeChosen
        ? this.alternativeWorkingDay.minimumBreakTime
        : minimumBreakTime;
  }

  public getBypassedMinimumBreakTime(): number {
    return this.minimumBreakTime;
  }

  public getPreliminaryRegister(): number {
    return getPreliminaryRegister(this.checkIn, this.checkOut);
  }

  public getPreliminaryTimeToComputed(): number {
    return this.getPreliminaryRegister() - this.getBypassedMinimumBreakTime();
  }

  public getPreliminaryDifWorkingDay(): number {
    return this.getPreliminaryTimeToComputed() - this.workingDay;
  }

  public getEffectiveWorkingTime(): number {
    return this.getPreliminaryTimeToComputed();
  }

  public getDifferenceToCompensate(): number {
    return this.defaultValue;
  }

  public getAppliedMarginAgreement(): number {
    return this.defaultValue;
  }

  public getPendingExtensionTimeToApprove(): number {
    return this.defaultValue;
  }
}
