import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { API_ROUTE } from '@constants/index';
import { NetworkService } from '@services/network/network.service';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';
import { DayLog } from '../../models/day-log';
import { YearMonth } from '../../models/year-month';
import { LogsService } from '../../services/logs.service';

@Component({
  selector: 'app-months-list',
  templateUrl: './months-list.component.html',
  styleUrls: ['./months-list.component.scss'],
})
export class MonthsListComponent implements OnInit {
  @Input() public yearMonths: YearMonth[] = [];
  @Input() public dayLogs: DayLog[];
  @Input() public monthLogLoading = false;
  @Input() public targetEmployeeId: string;
  @Input() public currentEmployeeId: string;
  @Input() public readOnly = false;
  @Input() public targetHasRemoteWork: boolean;

  public isMobile: boolean;
  public chosenYearMonth: YearMonth;

  public constructor(
    private readonly logsService: LogsService,
    private readonly networkService: NetworkService,
    private readonly platformCheckerService: PlatformCheckerService
  ) {
    this.isMobile = this.platformCheckerService.isMobile();
  }

  public ngOnInit(): void {
    this.yearMonths = this.yearMonths.slice().sort(YearMonth.sortYearMonths);
    const [chosenYearMonth] = this.yearMonths;
    this.toggleChosenYearMonth(chosenYearMonth);
  }

  // eslint-disable-next-line class-methods-use-this
  public translateMonthNumber(monthNumber: number): string {
    return moment()
      .month(monthNumber - 1)
      .format('MMMM');
  }

  public toggleChosenYearMonth(yearMonth: YearMonth): void {
    if (yearMonth !== this.chosenYearMonth) {
      this.monthLogLoading = true;
      this.logsService
        .getYearMonthLogs(
          this.targetEmployeeId,
          yearMonth,
          this.currentEmployeeId
        )
        .subscribe((dayLogs): void => {
          this.dayLogs = dayLogs;
          this.monthLogLoading = false;
        });

      this.chosenYearMonth = yearMonth;
    }
  }

  public async downloadYearMonth(
    event: Event,
    yearMonth: YearMonth
  ): Promise<void> {
    event.stopPropagation();

    const month = yearMonth.getMonthFormatted();
    const parameter = `?periodo=${month}${yearMonth.year}`;
    const fileUrl =
      ((await this.isInternalNetwork())
        ? API_ROUTE.DOWNLOADHISTORIC.INTERNAL
        : API_ROUTE.DOWNLOADHISTORIC.EXTERNAL) + parameter;
    window.open(fileUrl, '_blank');
  }

  public async isInternalNetwork(): Promise<boolean> {
    return this.networkService.isBBVANetwork();
  }

  public shouldShowDownloadMonthLogButton(yearMonth: YearMonth): boolean {
    return (
      yearMonth.isMonthConsolidated &&
      !this.isMobile &&
      this.currentEmployeeId === undefined
    );
  }
}
