import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';
import {
  AuthenticationService,
  DelegationsService,
  EmployeeService,
  ErrorService,
  FeatureFlagService,
  StorageService,
  WorkplacesService,
} from '@services/index';
import { TabsInterface, WorkingDayEmployee } from '@models/index';
import { GLOBAL, ROUTE, STORAGE_KEY, TABS } from '@constants/index';
import { FEATURES } from 'src/environments/common';
import { HTTP } from '@ionic-native/http/ngx';
import { environment } from '../environments/environment';
import { DelegationInterface } from './pages/delegations/models/delegation';
import { StaticTextService, MinVersionService } from './shared';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public isMobile: boolean;
  public hasClosingMessage: boolean;
  public tabs: TabsInterface[];
  public employeeSubscription: Subscription;
  public DELEGATIONS_TAB = 3;
  public AUTHORIZATIONS_TAB = 2;
  public LOGS_TAB = 1;
  private isUnconfirmedDaysAvaible: boolean;
  public isLoading = false;

  public constructor(
    private readonly platform: Platform,
    private readonly splashScreen: SplashScreen,
    private readonly statusBar: StatusBar,
    private readonly screenOrientation: ScreenOrientation,
    private readonly translate: TranslateService,
    private readonly staticTextService: StaticTextService,
    private readonly minVersionService: MinVersionService,
    private readonly employeeService: EmployeeService,
    private readonly delegationsService: DelegationsService,
    private readonly platformCheckerService: PlatformCheckerService,
    private readonly authenticationService: AuthenticationService,
    private readonly storageService: StorageService,
    private readonly workplacesService: WorkplacesService,
    private readonly router: Router,
    public readonly errorService: ErrorService,
    private readonly featureFlagService: FeatureFlagService,
    private httpSSL: HTTP
  ) {
    this.isMobile = this.platformCheckerService.isMobile();
    this.initializeApp().then((): void => {
      if (this.router.url === `/${ROUTE.EMPLOYEE_CHOOSER}`) {
        this.tabs = [];
      } else if (this.authenticationService.inMaintenance()) {
        this.router.navigate(['/maintenance']);
      } else {
        this.httpSSL
          .setServerTrustMode('pinned')
          .then(() => {
            console.log('Congratulaions, you have set up SSL Pinning.');
          })
          .catch(() => {
            console.error('Opss, SSL pinning failed.');
          });
        this.isLoading = true;
        this.authenticate();
      }
    });
    this.isUnconfirmedDaysAvaible = featureFlagService.isFeatureAvailable(
      FEATURES.UNCONFIRMED_DAYS
    );
  }

  public shouldShowSpinner(): boolean {
    return this.isLoading && !this.errorService.thereIsAnError();
  }

  // eslint-disable-next-line class-methods-use-this
  public clearEmployeeCookies(): void {
    localStorage.removeItem(STORAGE_KEY.EMPLOYEE_ID);
  }

  public shouldShowTabs(): boolean {
    return (
      !this.isMobile &&
      this.tabs &&
      this.tabs.length &&
      !this.errorService.thereIsAnError()
    );
  }

  public async initializeApp(): Promise<void> {
    await this.platform.ready();
    this.statusBar.styleDefault();
    this.staticTextService.updateStaticText();
    this.staticTextService.updateEqualityPlanText();
    this.workplacesService.updateWorkplaceDetails();
    if (this.platformCheckerService.isCordova()) {
      this.minVersionService.getMinVersion();
      await this.screenOrientation.lock('portrait');
    }
    this.splashScreen.hide();
    this.translate.setDefaultLang('es');
    this.translate.use('es');
    moment.locale(GLOBAL.ES_LOCALE);
  }

  // eslint-disable-next-line class-methods-use-this
  public showSwapId(): boolean {
    return environment.swapId;
  }

  public getBackgroundClass(): string {
    return this.isMobile
      ? 'app-container app-container--mobile'
      : 'app-container';
  }

  private setTabs(employeeData: WorkingDayEmployee): void {
    if (!employeeData) {
      this.tabs = [];
      return;
    }
    if (employeeData.isManager || employeeData.isAuthorizer) {
      this.setTabsWhenManager(employeeData);
    } else if (employeeData.isEmployeeDelegate) {
      this.setTabsWhenEmployeeDelegated();
    } else {
      this.setTabsWhenCommonEmployee();
    }
    this.setUnconfirmedDaysBullet();
  }

  private setTabsWhenCommonEmployee(): void {
    this.tabs = TABS.COMMON_EMPLOYEE;
  }

  private setTabsWhenManager(employeeData: WorkingDayEmployee): void {
    this.tabs = TABS.MANAGER;
    this.setHasDelegations(employeeData.id);
  }

  private setHasDelegations(employeeId: string): void {
    this.delegationsService.getDelegations(employeeId).subscribe();

    this.delegationsService.delegations$.subscribe(
      (delegations: DelegationInterface[]): void => {
        this.tabs[this.DELEGATIONS_TAB].shouldShowWarningSign =
          delegations.length === 0;
      }
    );
  }

  private setUnconfirmedDaysBullet(): void {
    if (this.isUnconfirmedDaysAvaible) {
      this.employeeService.employee$.subscribe(
        (workingDayEmployee: WorkingDayEmployee): void => {
          this.tabs[this.LOGS_TAB].unconfirmedDaysCounter =
            workingDayEmployee.unconfirmedDaysCounter;
        }
      );
    }
  }

  private setTabsWhenEmployeeDelegated(): void {
    this.tabs = TABS.EMPLOYEE_DELEGATE;
  }

  private authenticate(): void {
    this.authenticationService.authenticateEmployee().subscribe(
      (): void => {
        this.employeeService
          .getEmployee(this.storageService.get('employeeId'))
          .pipe(
            filter((employee: WorkingDayEmployee): boolean => !!employee),
            tap((employee: WorkingDayEmployee): void => {
              this.hasClosingMessage = !!employee.closingMessage;
            })
          )
          .subscribe((employee: WorkingDayEmployee): void => {
            this.checkClosingService(employee);
            this.isLoading = false;
          });
      },
      (): void => {
        this.setTabs(null);
        this.isLoading = false;
        this.authenticationService.redirectToSSOLogin();
      }
    );
  }

  private checkClosingService(employee: WorkingDayEmployee): void {
    return employee.closingMessage
      ? this.errorService.set(
          employee.closingMessage,
          {
            error: 'Application in maintainment mode. ',
          },
          true
        )
      : this.setTabs(employee);
  }
}
