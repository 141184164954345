import { Component, Input } from '@angular/core';

interface Placeholders {
  firstName: string;
}

@Component({
  selector: 'app-user-greeting',
  templateUrl: './user-greeting.component.html',
  styleUrls: ['./user-greeting.component.scss'],
})
export class UserGreetingComponent {
  @Input() public userName: string;
  @Input() public translationId: string;

  public getPlaceholders(): Placeholders {
    return { firstName: this.userName };
  }
}
