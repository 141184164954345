import moment from 'moment';
import { WorkingDayData } from '@models/index';
import { isNumberFinite } from './validations';

export function getCheckDayDate(time: Date, minRegisterTime: number): Date {
  const checkTimeMoment = moment(time);

  if (isNumberFinite(minRegisterTime)) {
    const minRegisterMoment = moment(time)
      .startOf('day')
      .add(minRegisterTime, 'minutes');

    const dayIncrement = checkTimeMoment.isBefore(minRegisterMoment) ? 1 : 0;

    checkTimeMoment.add(dayIncrement, 'days');
  }

  return checkTimeMoment.toDate();
}

export function getCheckDayFormatted(...args: [Date, number]): string {
  return moment(getCheckDayDate(...args)).format('YYYY-MM-DD');
}

export class CheckTimeValidator {
  private readonly ERRORS = {
    TIME_OUT_OF_RANGE: 'TIME_OUT_OF_RANGE',
    START_DATE_ERROR: 'START_DATE_ERROR',
    END_DATE_ERROR: 'START_DATE_ERROR',
    END_DATE_AFTER_CURRENT_ERROR: 'END_DATE_AFTER_CURRENT_ERROR',
  };

  private error: string;
  private date: Date;
  private checkTimeDate: Date;
  private otherCheckTimeDate: Date;
  private isCheckIn: boolean;
  private registerTimeLimits: {
    min: number;
    max: number;
  };

  public static factory(
    workingDayData: WorkingDayData,
    checkTime: Date,
    otherCheckTime?: Date,
    isCheckIn = true
  ): CheckTimeValidator {
    const checkTimeValidator = new CheckTimeValidator();

    const { minRegisterTime, maxRegisterTime, date } = workingDayData;

    checkTimeValidator.registerTimeLimits = {
      min: minRegisterTime,
      max: maxRegisterTime,
    };

    checkTimeValidator.date = date;

    checkTimeValidator.checkTimeDate = moment(
      getCheckDayDate(
        moment(workingDayData.date)
          .startOf('day')
          .hours(checkTime.getHours())
          .minutes(checkTime.getMinutes())
          .toDate(),
        workingDayData.minRegisterTime
      )
    ).toDate();

    if (otherCheckTime) {
      checkTimeValidator.otherCheckTimeDate = moment(
        getCheckDayDate(
          moment(workingDayData.date)
            .startOf('day')
            .hours(otherCheckTime.getHours())
            .minutes(otherCheckTime.getMinutes())
            .toDate(),
          workingDayData.minRegisterTime
        )
      ).toDate();
      checkTimeValidator.isCheckIn = isCheckIn;
    }

    return checkTimeValidator;
  }

  public validate(validations: { shouldNotBeFuture: boolean }): void {
    if (
      isNumberFinite(this.registerTimeLimits.min) &&
      isNumberFinite(this.registerTimeLimits.max) &&
      this.isCheckTimeOutOfRange()
    ) {
      this.error = this.ERRORS.TIME_OUT_OF_RANGE;
    } else if (
      this.otherCheckTimeDate &&
      this.isCheckTimeInvalidComparedToOtherCheckTime()
    ) {
      this.error = this.isCheckIn
        ? this.ERRORS.START_DATE_ERROR
        : this.ERRORS.END_DATE_ERROR;
    } else if (
      validations &&
      validations.shouldNotBeFuture &&
      this.isTimeFuture()
    ) {
      this.error = this.ERRORS.END_DATE_AFTER_CURRENT_ERROR;
    }
  }

  public getError(): string {
    return this.error ? `WORKING_DAY.${this.error}` : null;
  }

  public hasError(): boolean {
    return !!this.error;
  }

  private isCheckTimeOutOfRange(): boolean {
    const minRegisterMoment = moment(this.date)
      .startOf('day')
      .minutes(this.registerTimeLimits.min);

    const isCheckIn = this.isCheckIn || !this.otherCheckTimeDate;

    return isCheckIn
      ? this.buildCheckInTime(minRegisterMoment)
      : this.buildCheckOutTime(minRegisterMoment);
  }

  private buildCheckInTime(minRegisterMoment: moment.Moment): boolean {
    return this.otherCheckTimeDate
      ? this.buildCheckTime(minRegisterMoment)
      : false;
  }

  private buildCheckOutTime(minRegisterMoment: moment.Moment): boolean {
    return this.buildCheckTime(minRegisterMoment);
  }

  private buildCheckTime(minRegisterMoment: moment.Moment): boolean {
    const { otherCheckTimeDate } = this;
    const { checkTimeDate } = this;

    const otherTimeMoment = this.buildTimeMoment(otherCheckTimeDate);
    const checkTimeMoment = this.buildTimeMoment(checkTimeDate);

    const initHour = this.isCheckIn ? checkTimeMoment : otherTimeMoment;
    const endHout = this.isCheckIn ? otherTimeMoment : checkTimeMoment;

    return (
      (endHout.isSameOrAfter(minRegisterMoment) &&
        initHour.isBefore(minRegisterMoment)) ||
      (endHout.isSameOrAfter(minRegisterMoment) && initHour.isAfter(endHout))
    );
  }

  private buildTimeMoment(timeDate: Date): moment.Moment {
    const minutes = moment(timeDate).diff(
      moment(timeDate).startOf('days'),
      'minutes'
    );

    return moment(this.date)
      .startOf('day')
      .minutes(minutes);
  }

  private isCheckTimeInvalidComparedToOtherCheckTime(): boolean {
    return this.isCheckIn
      ? moment(this.checkTimeDate).isSameOrAfter(this.otherCheckTimeDate)
      : moment(this.otherCheckTimeDate).isSameOrAfter(this.checkTimeDate);
  }

  private isTimeFuture(): boolean {
    return moment(this.checkTimeDate).isAfter(moment(), 'minute');
  }
}
