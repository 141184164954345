import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { RequestCacheService } from '@services/request-cache/request-cache.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class CachedRequestsInterceptor implements HttpInterceptor {
  public constructor(private cache: RequestCacheService) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const cachedResponse = this.cache.get(req);
    if (cachedResponse) {
      this.addLogWhenNotProd(req);
      return EMPTY;
    }
    this.cache.put(req);

    return next.handle(req);
  }

  // eslint-disable-next-line class-methods-use-this
  private addLogWhenNotProd(req: HttpRequest<any>): void {
    if (!environment.production) {
      // eslint-disable-next-line no-console
      console.log('FILTERED REQUEST FROM:', req.urlWithParams, req.method);
    }
  }
}
