import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent {
  @Input() public placeholder: string;
  @Input() public hasError: boolean;
  @Input() public disabled = false;
  @Input() public selectedDate: Date;
  @Output() public changeDate = new EventEmitter<string>();

  @ViewChild('dateInput', { read: ElementRef, static: true })
  private dateInput: ElementRef;

  public clicked = false;

  public onChangeDate(event: CustomEvent): void {
    this.changeDate.emit(event.detail.value);
  }

  public onClick(): void {
    this.clicked = !this.clicked;
  }

  @HostListener('document:click', ['$event'])
  public onClickOutside(event: CustomEvent): void {
    if (!this.dateInput.nativeElement.contains(event.target)) {
      this.clicked = false;
    }
  }

  public getSelectedDayAsText(): string {
    return this.selectedDate
      ? moment(this.selectedDate).format('DD/MM/YYYY')
      : '';
  }

  public dateSelected(event: Date): void {
    this.selectedDate = event;
    const selectedDateText = this.getSelectedDayAsText();
    this.changeDate.emit(selectedDateText);
    this.onClick();
  }

  public getLabelPosition(): string {
    return this.selectedDate ? 'floating' : 'fixed';
  }

  public backgroundValidator(): { [key: string]: boolean } {
    return {
      'containerBackground--error': this.hasError,
      containerBackground: true,
    };
  }
}
