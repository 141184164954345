import moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { STORAGE_KEY } from '@constants/global.constants';
import { DayRecordDetailInterface } from '@models/day-record-detail';
import { WorkingDayData } from '@models/working-day-data';
import { WorkingDayEmployee } from '@models/working-day-employee';
import {
  EmployeeInfo,
  GenericPostResponse,
  Rubric,
  WorkingDayDetail,
} from '..';
import { EmployeeWorkingDay } from './employee-working-day';
import {
  buildDayRecordDetail,
  buildEmployeeInfo,
  EmployeeWorkingDayCreator,
} from './employee-working-day-creator';

export class EmployeeWorkingDayCreatorOther extends EmployeeWorkingDayCreator {
  private managerId: string;

  public create(
    date: Date,
    employeeId?: string
  ): Observable<EmployeeWorkingDay> {
    this.managerId = this.storageService.get(STORAGE_KEY.EMPLOYEE_ID);

    if (!this.managerId) {
      return throwError('no employee in storage');
    }

    return super.create(date, employeeId);
  }

  // eslint-disable-next-line class-methods-use-this
  public setCheckTimes(): Observable<GenericPostResponse> {
    return throwError('It is not possible to change times of another employee');
  }

  // eslint-disable-next-line class-methods-use-this
  public setIsAlternativeWorkingDay(): Observable<GenericPostResponse> {
    return throwError(
      'It is not possible to change working day of another employee'
    );
  }

  // eslint-disable-next-line class-methods-use-this
  public getRouteToGo(): string {
    return null;
  }

  protected getEmployee(): Observable<EmployeeInfo> {
    return this.employeeService
      .getOtherEmployee(this.employeeId, this.managerId)
      .pipe(
        map(
          (employee: WorkingDayEmployee): EmployeeInfo => {
            return buildEmployeeInfo(employee);
          }
        )
      );
  }

  protected getWorkingDayDetail(date: Date): Observable<WorkingDayDetail> {
    const formattedDate = moment(date).format('YYYY-MM-DD');

    return this.dayRecordDetailService
      .getOtherDayRecordDetail(this.employeeId, formattedDate, this.managerId)
      .pipe(
        map((dayRecordDetail: DayRecordDetailInterface): {
          workingDayData: WorkingDayData;
          rubric: Rubric;
        } => {
          return buildDayRecordDetail(dayRecordDetail, date);
        })
      );
  }
}
