<ion-content class="employee_chooser">
  <ion-row>
    <ion-col class="employee_chooser__layout">
      <ion-item class="employee_chooser__input-id ">
        <ion-label>Employee id</ion-label>
        <ion-input
          [(ngModel)]="employeeId"
          (keyup.enter)="setEmployeeById()"
          data-test="employee-id-input"
        ></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col class="employee_chooser__buttons">
      <ion-button
        [disabled]="!employeeId"
        class="buttons__continue"
        (click)="setEmployeeById()"
      >
        {{ 'CONTINUE' | translate }}
      </ion-button>
    </ion-col>
  </ion-row>
</ion-content>
