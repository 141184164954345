<ion-header *ngIf="isMobile()" class="info-modal__header">
  <app-mobile-header
    (closeModal)="onDismissModal()"
    [title]="'WORKING_DAY.TITLE' | translate"
  ></app-mobile-header>
</ion-header>

<ion-content>
  <div class="wrapper">
    <section>
      <ion-buttons *ngIf="!isMobile()" class="top-buttons">
        <ion-icon
          class="top-buttons__close"
          size="large"
          name="close"
          (click)="onDismissModal()"
        ></ion-icon>
      </ion-buttons>

      <section class="content">
        <app-image class="content__icon" src="assets/icon/info.svg"></app-image>

        <h3 class="content__title">
          {{ title || 'WORKING_DAY.MODAL.INFO.DEFAULT' | translate }}
        </h3>

        <ion-text
          *ngIf="infoAsHTML"
          class="content__html"
          [innerHTML]="infoAsHTML"
        ></ion-text>

        <section *ngIf="infoAsList?.length" class="content__list">
          <div *ngFor="let item of infoAsList" class="list-item">
            <h4 *ngIf="item.title" class="list-item__title">
              {{ item.title | translate }}
            </h4>
            <p *ngIf="item.description" class="list-item__description">
              {{ item.description | translate }}
            </p>
          </div>
        </section>
      </section>
    </section>

    <ion-button class="confirm-button" (click)="onDismissModal()">
      {{ 'WORKING_DAY.MODAL.CLOSE' | translate }}
    </ion-button>
  </div>
</ion-content>
