import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VersionPack } from './models/version-pack.model';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  // eslint-disable-next-line class-methods-use-this
  public isFeatureAvailable(featureName: string): boolean {
    const versionPackForTheFeature = VersionPack.getVersionPackForFeature(
      featureName,
      environment.features
    );

    return (
      versionPackForTheFeature &&
      versionPackForTheFeature.isVersionLowerOrEqualThan(environment.version)
    );
  }
}
