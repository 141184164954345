<ion-content class="employee-logs page">
  <ion-grid>
    <ion-row class="employee-logs__header">
      <ion-col class="header__greeting" size-sm="5">
        <app-user-greeting
          [userName]="userName"
          translationId="GREETING.NEGATICE_BALANCE"
        ></app-user-greeting>
      </ion-col>
      <ion-col
        size-sm="7"
        class="header__balance"
        *ngIf="shouldShowCurrentBalanceCard()"
      >
        <ion-row>
          <ion-col class="balance_layout" size-sm="6" size-xs="12">
            <app-balance-previous-years
              [value]="balancePreviousYears"
            ></app-balance-previous-years>
          </ion-col>
          <ion-col class="balance_layout" size-sm="6" size-xs="12">
            <app-current-balance
              [value]="currentBalance"
              [lockedValue]="lockedBalance"
            ></app-current-balance>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row
      ><ion-col
        ><app-success-message-balance
          *ngIf="isSuccessAdjustment"
        ></app-success-message-balance></ion-col
    ></ion-row>
    <div *ngIf="!isSuccessAdjustment">
      <ion-row>
        <app-datepicker
          [placeholder]="'DATE.LABEL' | translate"
          class="filter__date filter__date--from"
          (changeDate)="onChangeDate($event)"
        ></app-datepicker>
      </ion-row>
      <ion-row>
        <p>
          <strong
            >{{ 'LOGS.NEGATIVE_BALANCE.DIFFERENCE_TO_COMPENSATE' | translate }}
            {{ negativeCompensation | humanizeDuration }}</strong
          >
        </p>
      </ion-row>
      <ion-spinner *ngIf="isLoading" class="g-spinner"></ion-spinner>
      <ion-row *ngIf="!isLoading">
        <p>
          <strong>{{
            'LOGS.NEGATIVE_BALANCE.BALANCE_ADJUSTMENT' | translate
          }}</strong>
        </p>
        <div class="time-input">
          <div class="time-input__label">h</div>
          <ion-input
            type="text"
            [ngClass]="cssInput"
            (ionChange)="onChangeHour()"
            placeholder="00"
            inputmode="numeric"
            [maxlength]="3"
            data-test="employee-id-input"
            [(ngModel)]="hour"
          ></ion-input>
        </div>

        <div class="time-input">
          <div class="time-input__label">min</div>
          <ion-input
            type="text"
            [ngClass]="cssInput"
            (ionChange)="onChangeMinute()"
            placeholder="00"
            inputmode="numeric"
            [maxlength]="3"
            data-test="employee-id-input"
            [(ngModel)]="minute"
          ></ion-input>
        </div>
      </ion-row>
      <ion-row>
        <p>
          <strong>{{
            'LOGS.NEGATIVE_BALANCE.ADJUSTMENT_REASON' | translate
          }}</strong>
        </p>
      </ion-row>
      <ion-row>
        <ion-radio-group [(ngModel)]="reasonValue" value="reason">
          <ion-item>
            <ion-label>{{
              'LOGS.NEGATIVE_BALANCE.REGISTRATION_FORGET' | translate
            }}</ion-label>
            <ion-radio
              slot="start"
              value="{{
                'LOGS.NEGATIVE_BALANCE.REGISTRATION_FORGET' | translate
              }}"
            ></ion-radio>
          </ion-item>
          <ion-item>
            <ion-label>{{
              'LOGS.NEGATIVE_BALANCE.REGISTRATION_INCOMPLETE' | translate
            }}</ion-label>
            <ion-radio
              slot="start"
              value="{{
                'LOGS.NEGATIVE_BALANCE.REGISTRATION_INCOMPLETE' | translate
              }}"
            ></ion-radio>
          </ion-item>

          <ion-item>
            <ion-label>{{
              'LOGS.NEGATIVE_BALANCE.REGISTRATION_ERROR' | translate
            }}</ion-label>
            <ion-radio
              slot="start"
              value="{{
                'LOGS.NEGATIVE_BALANCE.REGISTRATION_ERROR' | translate
              }}"
            ></ion-radio>
          </ion-item>

          <ion-item>
            <ion-label>{{
              'LOGS.NEGATIVE_BALANCE.REGISTRATION_VACATION_ERROR' | translate
            }}</ion-label>
            <ion-radio
              slot="start"
              value="{{
                'LOGS.NEGATIVE_BALANCE.REGISTRATION_VACATION_ERROR' | translate
              }}"
            ></ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-row>
      <ion-row>
        <ion-col class="buttons">
          <ion-button
            class="buttons__continue"
            (click)="send()"
            data-test="send"
          >
            {{ 'WORKING_DAY.SEND' | translate }}
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </ion-grid>
</ion-content>
