import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent {
  @Input() public title: string;
  @Output() public closeModal = new EventEmitter<void>();

  public onDismissModal(): void {
    this.closeModal.emit();
  }
}
