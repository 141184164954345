import moment from 'moment';

export function getPreliminaryRegister(checkIn: Date, checkOut: Date): number {
  let checkOutFinal = moment(checkOut);
  const limitDate = checkOutFinal.diff(checkIn, 'days');
  if (checkOutFinal.isBefore(moment(checkIn), 'minutes')) {
    checkOutFinal = checkOutFinal.add('24', 'hours');
  } else if (limitDate > 0) {
    checkOutFinal = checkOutFinal.subtract('24', 'hours');
  }
  return checkOutFinal.diff(checkIn, 'minutes');
}
