<section class="error__content">
  <app-image
    class="error__image"
    src="assets/icon/warning.svg"
    [alt]="'ALT_IMG.HELP' | translate"
  ></app-image>
  <h3 class="error__title">
    {{ getTitle() | translate }}
  </h3>
  <p class="error__text error__text--margin" *ngIf="workplaceMissing">
    {{ 'REGISTER_TIME.MODAL_ERROR.P4' | translate }}
  </p>

  <p class="error__text error__text--margin" *ngIf="!distributedTime && !errorMessage">
    {{ getMainMessageError() | translate }}
  </p>

  <p class="error__text" *ngIf="!distributedTime && !errorMessage">
    {{ 'REGISTER_TIME.MODAL_ERROR.P2' | translate }}
  </p>

  <p class="error__text" *ngIf="errorMessage">{{ errorMessage | translate}}</p>

  <div class="error__buttons">
    <ion-button class="error__button" (click)="dismissModal()">
      {{ 'REGISTER_TIME.MODAL_ERROR.OK' | translate }}
    </ion-button>
  </div>
</section>
