import moment from 'moment';
import { DayLog } from '../../../models/day-log';

export function isManualCheckTime(
  loggedCheckTime: Date,
  registeredCheckTime: Date,
  isManual: boolean
): boolean {
  return (
    isManual ||
    (loggedCheckTime &&
      registeredCheckTime &&
      Math.abs(
        moment(loggedCheckTime).diff(moment(registeredCheckTime), 'minutes')
      ) > 1)
  );
}

export function formatDate(d: Date): string {
  return d ? moment(d).format('dddd, DD.MM.YYYY') : '';
}

export function formatHour(h: Date): string {
  return h ? moment(h).format('HH:mm') : '';
}

export function getWorkingDayExtensionRequestClasses(
  workingDayExtensionRequestStatus: string
): { [index: string]: boolean } {
  return {
    'working-day-extension-request--approved':
      workingDayExtensionRequestStatus === 'APPROVED',
    'working-day-extension-request--denied':
      workingDayExtensionRequestStatus === 'DENIED',
    'working-day-extension-request--pending':
      workingDayExtensionRequestStatus === 'PENDING',
  };
}

export function formatDateSlug(d: Date): string {
  return moment(d).format('YYYY-MM-DD');
}

export function shouldHighlight(dayLog: DayLog): boolean {
  return (
    (dayLog.workingDayAbsence && !dayLog.isExceptionAbsence) ||
    !!dayLog.workingDayHoliday
  );
}
