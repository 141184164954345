<ion-app [ngClass]="getBackgroundClass()">
  <app-tabs [renderTabs]="tabs" *ngIf="shouldShowTabs()"></app-tabs>
  <ion-router-outlet
    *ngIf="!isMobile"
    [animated]="false"
    class="content"
  ></ion-router-outlet>
  <app-menu
    *ngIf="isMobile && tabs"
    class="menu-container"
    [links]="tabs"
    [hasClosingMessage]="hasClosingMessage"
  ></app-menu>
  <ion-fab
    vertical="top"
    horizontal="end"
    *ngIf="showSwapId()"
    class="fab-container"
  >
    <ion-fab-button
      class="swap-id-button"
      href="/employee-chooser"
      (click)="clearEmployeeCookies()"
      >Swap ID</ion-fab-button
    >
  </ion-fab>
  <ion-spinner *ngIf="shouldShowSpinner()" class="g-spinner"></ion-spinner>
</ion-app>
