import { Component, Input } from '@angular/core';
import {
  HISTORICAL_WARNING_DISCLAIMER,
  HISTORICAL_INFO_WITH_LINK_DISCLAIMER,
  HISTORICAL_INFO_WITHOUT_LINK_DISCLAIMER,
} from '../../../../shared/components/disclaimer/constants/disclaimers-constants';

@Component({
  selector: 'app-title-and-legends',
  templateUrl: './title-and-legends.component.html',
  styleUrls: ['./title-and-legends.component.scss'],
})
export class TitleAndLegendsComponent {
  @Input() public isOther = false;
  @Input() public friendsAndFamily = false; // Remove next prod pass
  public disclaimers = [
    HISTORICAL_WARNING_DISCLAIMER,
    HISTORICAL_INFO_WITHOUT_LINK_DISCLAIMER,
    HISTORICAL_INFO_WITH_LINK_DISCLAIMER,
  ];
}
