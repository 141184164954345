import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class DevEnvGuard implements CanActivate, CanLoad, CanActivateChild {
  public canActivate(): boolean {
    return this.isDev();
  }

  public canLoad(): boolean {
    return this.isDev();
  }

  public canActivateChild(): boolean {
    return this.isDev();
  }

  // eslint-disable-next-line class-methods-use-this
  public isDev(): boolean {
    return environment.swapId;
  }
}
