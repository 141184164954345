import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ErrorService } from '@services/error/error.service';
import { API_ROUTE } from '@constants/api.routes.constants';
import {
  buildTeamMemberList,
  TeamMemberListInterface,
} from '../models/team-member';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  private readonly teamMemberListSubject = new BehaviorSubject<
    TeamMemberListInterface
  >(null);

  public readonly teamMember$ = this.teamMemberListSubject.asObservable();

  public constructor(
    private errorService: ErrorService,
    public readonly httpClient: HttpClient
  ) {}

  public getTeamMembers(
    managerId: string,
    page = '1',
    itemsPage = '10'
  ): Observable<TeamMemberListInterface> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const options = { page, items_page: itemsPage };
    return this.httpClient
      .get<any>(API_ROUTE.LOGS.MANAGER_TEAM_MEMBERS(managerId), {
        params: options,
      })
      .pipe(
        map(buildTeamMemberList),
        tap((data: TeamMemberListInterface): void => {
          this.teamMemberListSubject.next(data);
        }),
        catchError(
          (err): Observable<TeamMemberListInterface> => {
            this.errorService.set('ERROR_DEFAULT', err);
            return of(null);
          }
        )
      );
  }
}
