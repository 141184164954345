import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  @Input() public alt: string;
  @Input() public src: string;

  @Output() public ionImgDidLoad = new EventEmitter<void>();
  @Output() public ionImgWillLoad = new EventEmitter<void>();
  @Output() public ionError = new EventEmitter<void>();

  private isLoadFinished: boolean;

  public constructor() {
    this.isLoadFinished = false;
  }

  public getImgAlt(): string {
    return this.isLoadFinished ? this.alt : '';
  }

  public handleLoadFinished(event: any): void {
    if (this.ionImgDidLoad.observers.length) {
      this.ionImgDidLoad.emit(event);
    }
    this.isLoadFinished = true;
  }

  public handleLoadFailed(event: any): void {
    if (this.ionError.observers.length) {
      this.ionError.emit(event);
    }
    this.isLoadFinished = true;
  }
}
