import { Authorizer } from '@models/index';

export function buildAuthorizer(): Authorizer {
  return {
    code: '1234567AT',
    name: 'NOMBRE AT',
    surnames: 'APELLIDOS',
    type: 'AT',
  };
}
