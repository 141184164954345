import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  EditDelegation,
  SingleDelegateEmployee,
} from 'src/app/pages/delegations/models/delegation';
import { DateFormatter, SuccessModalDataElement } from 'src/app/shared';

@Component({
  selector: 'app-success-data-modal',
  templateUrl: './success-data-modal.component.html',
  styleUrls: ['./success-data-modal.component.scss'],
})
export class SuccessDataModalComponent {
  @Input() public title: string;
  @Input() public headerTitle: string;
  @Input() public requestDate: string;
  @Input() public singleEmployees: SingleDelegateEmployee[] = [];
  @Input() public employeeName: string;
  @Input() public dataElements: SuccessModalDataElement[];
  @Input() public isMobile = false;
  @Input() public isFeatureDropDownAvailable = false;
  @Input() public editInfo: EditDelegation;

  public hasDateError: boolean;
  public hasAddedText = 'DELEGATIONS.CREATE_MODAL.HAS_ADDED_PERSONS';
  public hasDeletedText = 'DELEGATIONS.CREATE_MODAL.HAS_REMOVED_PERSONS';

  public constructor(private readonly modalController: ModalController) {}

  public onDismissModal(): void {
    this.modalController.dismiss();
  }

  // eslint-disable-next-line class-methods-use-this
  public formatDate(date: string): string {
    return DateFormatter.getDay(date);
  }

  public hasSingleEmployees(): boolean {
    return this.singleEmployees.length > 0;
  }

  public shouldShowSingleEmployees(): boolean {
    return (
      this.isFeatureDropDownAvailable &&
      this.hasSingleEmployees() &&
      !this.hasAddedEmployees() &&
      !this.hasDeletedEmployees()
    );
  }

  public hasAddedEmployees(): boolean {
    return this.editInfo && this.editInfo.addedEmployees.length > 0;
  }

  public hasDeletedEmployees(): boolean {
    return this.editInfo && this.editInfo.deletedEmployees.length > 0;
  }

  public isEditDelegation(): boolean {
    return this.hasDeletedEmployees() || this.hasAddedEmployees();
  }
}
