<ion-row>
  <ion-col class="message-info__img">
    <app-image src="assets/icon/info.svg"></app-image>
  </ion-col>
</ion-row>
<ion-row class="message-text">
  <ion-col>
    {{ message }}
  </ion-col>
</ion-row>
