import { parseExcessReason } from '../utils/parsers';
import { checkProperty } from '../utils/validations';

export interface AuthInterface {
  id: string;
  firstName: string;
  lastName: string;
  image: string;
  difference: number;
  reason: string;
  requestDate: string;
  state: string;
  isDayConsolidated: boolean;
  rejectionReason?: string;
  rejectionCode?: string;
  rejectionComment?: string;
  employeeIsNotDelegated?: boolean;
}

export interface AuthServerInterface {
  employeeId: string;
  name: string;
  surnames: string;
  image: string;
  difference: number;
  reason: string;
  requestDate: string;
  isDayConsolidated: boolean;
  rejectionReason?: string;
  rejectionCode?: string;
  rejectionComment?: string;
  state: string;
  employeeIsNotDelegated?: boolean;
}

export interface AuthListServerInterface {
  numberAuthorizationsPendings: number;
  numPages: number;
  listAuthorizationsPending: AuthServerInterface[];
}

export interface AuthsListInterface {
  authorizationsAmount: number;
  pages: number;
  authorizationsList: AuthInterface[];
}

export interface HistoryAuthsListServerInterface {
  numberAuthorizations: number;
  numPages: number;
  historicListAuthorizations: AuthServerInterface[];
}

export interface NewAuthItemValidate {
  requestDate: string;
  status: string;
  employeeId: string;
}

export function validateRawHistoryAuthsData(data: any): void {
  const historicAuthorizationsRequiredProperties = [
    { name: 'numPages', nullable: false },
    { name: 'numberAuthorizations', nullable: true },
    { name: 'historicListAuthorizations', nullable: true },
  ];

  const authorizationRequiredProperties = [
    { name: 'name', nullable: false },
    { name: 'difference', nullable: true },
    { name: 'reason', nullable: false },
    { name: 'requestDate', nullable: false },
    { name: 'isDayConsolidated', nullable: false },
    { name: 'employeeIsNotDelegated', nullable: false },
  ];

  historicAuthorizationsRequiredProperties.forEach((field: any): void => {
    checkProperty(data, 'Historic auths', field);
  });

  data.historicListAuthorizations.forEach((authorization: any): void => {
    authorizationRequiredProperties.forEach((field: any): void => {
      checkProperty(
        authorization,
        'Authorization from historicListAuthorizations',
        field
      );
    });
  });
}

function authorizationsListBuilder(authList: any): AuthInterface[] {
  return authList.map(
    (auth: AuthServerInterface): AuthInterface => {
      const reason = parseExcessReason(auth.reason);

      return {
        id: auth.employeeId,
        firstName: auth.name,
        lastName: auth.surnames,
        image: auth.image,
        difference: auth.difference,
        reason,
        requestDate: auth.requestDate,
        state: auth.state,
        isDayConsolidated: auth.isDayConsolidated,
        employeeIsNotDelegated: auth.employeeIsNotDelegated,
      };
    }
  );
}

export function authsHistoryBuilder(data: any): AuthsListInterface {
  validateRawHistoryAuthsData(data);
  return {
    authorizationsAmount: data.numberAuthorizations,
    pages: data.numPages,
    authorizationsList: authorizationsListBuilder(
      data.historicListAuthorizations
    ),
  };
}
