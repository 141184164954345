<ion-row>
  <ion-col>
    <app-time-info-card
      class="difference-agreement borderless"
      [infoList]="preliminaryDifferenceAgreement"
      [noPaddingBottom]="true"
      data-test="preliminary-difference"
    ></app-time-info-card>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col>
    <p class="subdescription" *ngIf="preliminaryDifferenceAgreement && showSubdescription()">
      {{ 'WORKING_DAY.STEP_2.DIFFERENCE_AGREEMENT.INSTRUCTIONS' | translate }}
    </p>

    <hr class="g-divider divider" />
  </ion-col>
</ion-row>
