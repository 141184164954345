import { Component, Input } from '@angular/core';
import { TimeInfo } from '@models/index';

@Component({
  selector: 'app-preliminary-difference',
  templateUrl: './preliminary-difference.component.html',
  styleUrls: ['./preliminary-difference.component.scss'],
})
export class PreliminaryDifferenceComponent {
  @Input() public isDisabled = false;
  @Input() public isReadonly = false;
  @Input() public preliminaryDifferenceAgreement: TimeInfo[];

  public constructor() {
    // do nothing.
  }

  public showSubdescription(): boolean {
    return (
      !this.isReadonly && this.preliminaryDifferenceAgreement[0].time !== 0
    );
  }
}
