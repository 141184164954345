import { Component } from '@angular/core';
import { TabsInterface, WorkingDayEmployee } from '@models/index';
import { ROUTE } from '@constants/index';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';

@Component({
  selector: 'app-authorizations',
  templateUrl: './authorizations.page.html',
  styleUrls: ['./authorizations.page.scss'],
})
export class AuthorizationsPage {
  public tabs: TabsInterface[];
  public employee: WorkingDayEmployee;
  public isMobile: boolean;

  public constructor(
    private readonly platformCheckerService: PlatformCheckerService
  ) {
    this.isMobile = this.platformCheckerService.isMobile();
    this.tabs = [
      {
        title: 'AUTHORIZATIONS.TABS.AUTHORIZATIONS_LOG',
        mobileTitle: 'AUTHORIZATIONS.MOBILE_TABS.AUTHORIZATIONS_LOG',
        url: `${ROUTE.AUTHORIZATIONS}/${ROUTE.AUTHORIZATIONS_LOG}`,
      },
    ];
  }
}
