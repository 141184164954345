<ion-grid class="info-panel step-2--defect">
  <ion-card
    class="wrapper wrapper--preliminary info-panel__card info-panel__card--first"
  >
    <app-preliminary-difference
      [preliminaryDifferenceAgreement]="preliminaryDifferenceAgreement"
      [isReadonly]="isReadonly"
    ></app-preliminary-difference>

    <ion-row>
      <ion-col>
        <ion-list lines="none" data-test="differences">
          <app-time-input
            *ngFor="let differenceInput of differenceInputs; first as isFirst"
            [data]="differenceInput"
            [timeInputClass]="differenceInput.class"
            [initialValues]="getTimeInputInitialValues(differenceInput)"
            (changeTime)="onDifferenceTimeChange($event, differenceInput.type)"
            class="time-input"
            [class.time-input--first]="isFirst"
            [isBBVACompany]="isBBVACompany()"
            [isReadOnly]="isReadonly"
          ></app-time-input>

          <ion-row
            *ngIf="shouldBeReasoned()"
            class="ion-justify-content-center ion-align-items-center time-input"
          >
            <ion-col size="cover" class="col"
              ><b>{{
                'WORKING_DAY.STEP_2.ABSENCE_REASON.TITLE' | translate
              }}</b></ion-col
            >
            <ion-col>
              <ion-select
                interface="popover"
                placeholder="{{
                  'WORKING_DAY.STEP_2.ABSENCE_REASON.SELECT' | translate
                }}"
                (ionChange)="absenceReasonSelect($event)"
                [disabled]="disableAbsenceButton"
                [(ngModel)]="selectedEqualityPlan"
              >
                <ion-list>
                  <ion-select-option
                    *ngFor="let specialAbsence of specialAbsences"
                    [value]="specialAbsence"
                  >
                    {{ specialAbsence.name }}
                  </ion-select-option>
                </ion-list>
              </ion-select>
            </ion-col>
          </ion-row>

          <ion-item *ngIf="!isReadonly">
            <ion-label class="time-total">
              <span>
                {{ 'WORKING_DAY.STEP_2.DIFFERENCE_INPUT.TOTAL' | translate }}
              </span>
              <span class="time-total__value">
                {{ sumDifferences() | humanizeDuration }}
              </span>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-card>

  <app-effective-working-time
    [effectiveWorkingTime]="effectiveWorkingTime"
    [isReadonly]="isReadonly"
    [isDisabled]="!canContinue()"
    (confirmSend)="send()"
  ></app-effective-working-time>
</ion-grid>
