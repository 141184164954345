import { checkProperty } from '../utils/validations';

export enum DifferenceInclination {
  Equal,
  Excess,
  Deffect,
}

export interface TextBlock {
  title?: string;
  description?: string;
}

export interface StaticText {
  messagesPreliminaryDiff: {
    lessZero: TextBlock[];
    greaterZero: TextBlock[];
    equalsZero: TextBlock[];
  };
  messagesMarginAgreementToApplied: TextBlock[];
  messagesMarginAgreement: TextBlock[];
}

export function validateStaticText(data: any): void {
  const staticTextRequiredProperties = [
    { name: 'messagesPreliminaryDiff', nullable: false },
    { name: 'messagesMarginAgreementToApplied', nullable: false },
    { name: 'messagesMarginAgreement', nullable: false },
  ];

  const messagesPreliminaryDiffRequiredProperties = [
    { name: 'lessZero', nullable: false },
    { name: 'greaterZero', nullable: false },
    { name: 'equalsZero', nullable: false },
  ];

  staticTextRequiredProperties.forEach((field: any): void => {
    checkProperty(data, 'Static texts', field);
  });

  messagesPreliminaryDiffRequiredProperties.forEach((field: any): void => {
    checkProperty(
      data.messagesPreliminaryDiff,
      'messagesPreliminaryDiff of Static texts',
      field
    );
  });
}

export function staticTextBuilder(data: any): StaticText {
  validateStaticText(data);
  return data;
}
