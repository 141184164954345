import moment from 'moment';
import { FEATURES } from 'src/environments/common';
import { FeatureFlagService } from '..';
import { WorkingDay } from '../../pages/working-day/models/working-day';
import { checkProperty, throwErrorIf } from '../utils/validations';
import { AuthorizationStatus } from './authorizations.model';
import { PermissionToRegisterInterface } from './working-day-employee';

export interface DayRecordDetailInterface {
  workplace?: string;
  workLocation?: string;
  equalityPlan?: string;
  equalityPlanDescription?: string;
  alternativeWorkingDay?: WorkingDay;
  date: string;
  registerDate: string;
  checkIn: string;
  checkOut: string;
  workingDay: number;
  minimumBreakTime: number;
  skipBreakTimeLimit: number;
  agreementMargin: number;
  adjustmentAgreementMargin: number;
  descriptionDayRecord: string;
  workingDayExtensionPersonalReasonTime: number;
  workingDayExtensionTime: number;
  preliminaryRegistration: number;
  effectiveWorkingTime: number;
  difference: number;
  preliminaryCountTime: number;
  preliminaryDiffDayRecordReference: number;
  excessWorkingDayReason: string;
  recoveryTime: number;
  notRecoveryTime: number;
  isConfirmed: boolean;
  workingDayExtensionAuthorizeManager: number;
  differenceToCompensate: number;
  permissionToRegister?: PermissionToRegisterInterface;
  workingDayExtensionStatus: AuthorizationStatus;
  isAlternativeWorkingDay: boolean;
  compensationNegativeBalance: number;
  compensationPositiveBalance: number;
  minRegisterTime?: number; // TODO: Remove optionality when feature is consolidated
  maxRegisterTime?: number; // TODO: Same as above
  isJNE?: boolean; // TODO: Remove optionality when feature is consolidated
}

export class DayRecordDetailBuilder {
  public dayRecordDetail: DayRecordDetailInterface;
  private isNightShiftAvailable: boolean;
  private isJNEAvailable: boolean;

  private constructor(
    private readonly rawData: any,
    private readonly selectedDay: any,
    featureFlagService: FeatureFlagService
  ) {
    this.isNightShiftAvailable = featureFlagService.isFeatureAvailable(
      FEATURES.NIGHT_SHIFT
    );
    this.isJNEAvailable = featureFlagService.isFeatureAvailable(FEATURES.JNE);
  }

  public static factory(
    data: any,
    selectedDay: string,
    featureFlagService: FeatureFlagService
  ): DayRecordDetailBuilder {
    const employeeBuilder = new DayRecordDetailBuilder(
      data,
      selectedDay,
      featureFlagService
    );

    employeeBuilder.validateRawData();
    employeeBuilder.buildDayRecordDetail();

    return employeeBuilder;
  }

  private buildDayRecordDetail(): void {
    this.dayRecordDetail = {
      isAlternativeWorkingDay: this.rawData.isAlternativeWorkingDay,
      alternativeWorkingDay: this.rawData.alternativeWorkingDay,
      date: moment(this.selectedDay).format('YYYY-MM-DD'),
      registerDate: this.rawData.registerDate,
      checkIn: this.rawData.checkIn,
      checkOut: this.rawData.checkOut,
      workingDay: this.rawData.workingDay,
      minimumBreakTime: this.rawData.minimumBreakTime,
      skipBreakTimeLimit: this.rawData.skipBreakTimeLimit,
      agreementMargin: this.rawData.agreementMargin,
      adjustmentAgreementMargin: this.rawData.adjustmentAgreementMargin,
      descriptionDayRecord: this.rawData.descriptionDayRecord,
      workingDayExtensionPersonalReasonTime: this.rawData
        .workingDayExtensionPersonalReasonTime,
      workingDayExtensionTime: this.rawData.workingDayExtensionTime,
      preliminaryRegistration: this.rawData.preliminaryRegistration,
      effectiveWorkingTime: this.rawData.effectiveWorkingTime,
      difference: this.rawData.difference,
      preliminaryCountTime: this.rawData.preliminaryCountTime,
      preliminaryDiffDayRecordReference: this.rawData
        .preliminaryDiffDayRecordReference,
      excessWorkingDayReason: this.rawData.excessWorkingDayReason,
      recoveryTime: this.rawData.recoveryTime,
      notRecoveryTime: this.rawData.notRecoveryTime,
      isConfirmed: this.rawData.isConfirmed,
      workingDayExtensionAuthorizeManager: this.rawData
        .workingDayExtensionAuthorizeManager,
      differenceToCompensate: this.rawData.differenceToCompensate,
      workingDayExtensionStatus: this.rawData.workingDayExtensionStatus,
      permissionToRegister: this.rawData.permissionToRegister,
      compensationPositiveBalance: this.rawData.compensationPositiveBalance,
      compensationNegativeBalance: this.rawData.compensationNegativeBalance,
      workLocation: this.rawData.workLocation,
      workplace: this.rawData.workplace,
      equalityPlan: this.rawData.equalityPlan,
      equalityPlanDescription: this.rawData.equalityPlanDescription,
    };

    if (this.isNightShiftAvailable) {
      this.dayRecordDetail.minRegisterTime = this.rawData.minRegisterTime;
      this.dayRecordDetail.maxRegisterTime = this.rawData.maxRegisterTime;
    }

    if (this.isJNEAvailable) {
      this.dayRecordDetail.isJNE = this.rawData.isJNE;
    }
  }

  private validateRawData(): void {
    const dayRecordRequiredProperties = [
      { name: 'registerDate', nullable: true },
      { name: 'checkIn', nullable: true },
      { name: 'checkOut', nullable: true },
      { name: 'workingDay', nullable: true },
      { name: 'minimumBreakTime', nullable: true },
      { name: 'skipBreakTimeLimit', nullable: true },
      { name: 'agreementMargin', nullable: true },
      { name: 'adjustmentAgreementMargin', nullable: true },
      { name: 'descriptionDayRecord', nullable: true },
      { name: 'workingDayExtensionPersonalReasonTime', nullable: true },
      { name: 'workingDayExtensionTime', nullable: true },
      { name: 'preliminaryRegistration', nullable: true },
      { name: 'effectiveWorkingTime', nullable: true },
      { name: 'difference', nullable: true },
      { name: 'preliminaryCountTime', nullable: true },
      { name: 'preliminaryDiffDayRecordReference', nullable: true },
      { name: 'recoveryTime', nullable: true },
      { name: 'notRecoveryTime', nullable: true },
      { name: 'excessWorkingDayReason', nullable: true },
      { name: 'workingDayExtensionStatus', nullable: true },
    ];

    if (this.isNightShiftAvailable) {
      dayRecordRequiredProperties.push(
        {
          name: 'minRegisterTime',
          nullable: true,
        },
        {
          name: 'maxRegisterTime',
          nullable: true,
        }
      );
    }

    if (this.isJNEAvailable) {
      dayRecordRequiredProperties.push({
        name: 'isJNE',
        nullable: true,
      });
    }

    const maxMinutesPerDay = 24 * 60;

    const hasCheckOutAndNotCheckIn =
      !this.rawData.checkIn && this.rawData.checkOut;

    const mustHaveExcessReason =
      this.rawData.workingDayExtensionTime &&
      this.rawData.workingDayExtensionTime > this.rawData.agreementMargin &&
      !this.rawData.excessWorkingDayReason;

    const excessLimitOneDay =
      this.rawData.workingDayExtensionTime < 0 ||
      this.rawData.workingDayExtensionTime > maxMinutesPerDay;

    const excessPersonalLimitOneDay =
      this.rawData.workingDayExtensionPersonalReasonTime < 0 ||
      this.rawData.workingDayExtensionPersonalReasonTime > maxMinutesPerDay;

    const workLimitOneDay =
      this.rawData.workingDay < 0 || this.rawData.workingDay > maxMinutesPerDay;

    dayRecordRequiredProperties.forEach((field: any): void => {
      checkProperty(this.rawData, 'Day Record Detail', field);
    });

    throwErrorIf(
      hasCheckOutAndNotCheckIn,
      'Employees must have a check in time if there is a checkout time'
    );
    throwErrorIf(
      mustHaveExcessReason,
      'Employees must reason why they exceed the working day time'
    );
    throwErrorIf(
      excessLimitOneDay,
      'Employees can only have excess time from 0 to 24 hours'
    );
    throwErrorIf(
      excessPersonalLimitOneDay,
      'Employees can only have excess time by personal reasons from 0 to 24 hours'
    );
    throwErrorIf(
      workLimitOneDay,
      'Employees can only have working day from 0 to 24 hours'
    );
  }
}
