import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { EmployeeWorkingDay } from '@models/employee-working-day/employee-working-day';
import { isBeforeCutoffDate } from '@models/employee-working-day/employee-working-day-creator';
import {
  AuthorizationStatus,
  AuthorizationStatuses,
  InfoList,
  StaticText,
  TextBlock,
  TimeInfo,
  TimeValues,
} from '@models/index';
import { StaticTextService } from '@services/index';
import { timeCalculationsFactory } from 'src/app/pages/working-day/factories';
import {
  isReadOnly,
  isShowMode,
} from 'src/app/pages/working-day/helper/working-day.helper';
import { TimeCalulationsInterface } from 'src/app/pages/working-day/interfaces';
import { WorkingDay } from 'src/app/pages/working-day/models/working-day';
import { WorkingDayInfoComponent } from '../../../modals';
import { INFO_LIST } from '../../constants/difference-input-data.constant';
import {
  buildRegiterTimeModalOptions,
  getSuccessInfo,
  isPastDay,
} from '../../helpers/step-2.helper';

@Component({
  selector: 'app-perfect-use-case',
  templateUrl: './perfect-use-case.component.html',
  styleUrls: ['./perfect-use-case.component.scss'],
})
export class PerfectUseCaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public isMobile: boolean;
  @Input() public isReadonly: boolean;
  @Input() public employeeWorkingDay: EmployeeWorkingDay;
  @Input() public alternativeWorkingDayChosen: boolean;
  @Input() public alternativeWorkingDay: WorkingDay;
  @Input() public checkTimesEdited: boolean;
  @Output() public confirmWorkingDay: EventEmitter<void> = new EventEmitter();

  public preliminaryDifferenceAgreement: TimeInfo[];
  public staticMessages: StaticText;
  public timeCalculationStrategy: TimeCalulationsInterface;
  public effectiveWorkingTime: TimeInfo[];

  private staticTextSubscription: Subscription;
  private isPastDay = false;
  private isShowMode = false;

  public constructor(
    private readonly staticTextService: StaticTextService,
    private readonly modalController: ModalController,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    this.staticTextSubscription = this.staticTextService.staticText$.subscribe(
      (staticText): void => {
        if (staticText) {
          this.staticMessages = staticText;
        }
      }
    );
    this.ngOnChanges();
  }

  public ngOnChanges(): void {
    this.timeCalculationStrategy = timeCalculationsFactory(
      this.employeeWorkingDay.workingDayData,
      this.employeeWorkingDay.rubric,
      this.alternativeWorkingDayChosen ? this.alternativeWorkingDay : null
    );
    this.checkTimesSubscription();
    this.updateEffectiveWorkTime();
    this.isPastDay = isPastDay(this.employeeWorkingDay);
    this.isShowMode = isShowMode(this.router.url);
    this.isReadonly = isReadOnly(
      this.employeeWorkingDay,
      this.isPastDay,
      this.isShowMode
    );
  }

  public ngOnDestroy(): void {
    this.staticTextSubscription.unsubscribe();
  }

  public isWorkplaceSelected(): boolean {
    return !(
      this.employeeWorkingDay.workingDayData.workLocation === undefined ||
      this.employeeWorkingDay.workingDayData.workLocation === '0'
    );
  }

  public workplaceMissing(): boolean {
    return (
      this.employeeWorkingDay.employeeInfo.hasRemoteWork &&
      !isBeforeCutoffDate(this.employeeWorkingDay.workingDayData.date) &&
      !this.isWorkplaceSelected()
    );
  }

  public async send(): Promise<void> {
    const timeValues = this.getTimeValues();
    const workplaceMissing = this.workplaceMissing();
    const isSuccess = !this.workplaceMissing();
    const differenceTimes = {};
    this.continueSend(
      timeValues,
      differenceTimes,
      isSuccess,
      false,
      workplaceMissing
    );
  }

  private async continueSend(
    timeValues: TimeValues,
    differenceTimes: any,
    isSuccess: boolean,
    excessCompensation: boolean,
    workplaceMissing: boolean
  ): Promise<void> {
    const successData = getSuccessInfo(timeValues, false);

    const modalOptions = buildRegiterTimeModalOptions(
      differenceTimes,
      isSuccess,
      excessCompensation,
      this.employeeWorkingDay,
      this.alternativeWorkingDay,
      this.alternativeWorkingDayChosen,
      this.isPastDay,
      successData,
      false,
      false,
      undefined,
      undefined,
      true,
      workplaceMissing
    );
    const modal = await this.modalController.create(modalOptions);
    await modal.present();
    const dismissData = await modal.onDidDismiss();
    if (dismissData && dismissData.data) {
      this.confirmWorkingDay.emit();
    }
  }

  private checkTimesSubscription(): void {
    if (this.employeeWorkingDay.workingDayData.checkOut) {
      const timeValues = this.getTimeValues();

      const infoListWithValues = this.setInfoListTimes(timeValues);
      this.preliminaryDifferenceAgreement = [
        infoListWithValues.preliminaryDifferenceAgreement,
      ];
    }
  }

  public updateEffectiveWorkTime(): void {
    this.effectiveWorkingTime = [
      {
        ...INFO_LIST.effectiveWorkingTime,
        time: this.getTimeValues().effectiveWorkTime,
        subtime: this.getTimeValues().effectiveWorkTimeDifference,
      },
    ];
  }

  private setInfoListTimes(timeValues: TimeValues): InfoList {
    const preliminaryList = { ...INFO_LIST };

    const preliminaryDifferenceAgreement: TimeInfo = {
      ...preliminaryList.preliminaryDifferenceAgreement,
      time: timeValues.preliminaryDiffValue,
      moreInfo: (): void => {
        const infoAsListKey = 'equalsZero';
        let infoAsList = [];
        if (this.staticMessages) {
          infoAsList = this.staticMessages.messagesPreliminaryDiff[
            infoAsListKey
          ];
        }

        const title = 'WORKING_DAY.MODAL.INFO.DIFFERENCE';
        this.openInfoModal(title, infoAsList);
      },
    };

    const effectiveWorkingTime: TimeInfo = {
      ...preliminaryList.effectiveWorkingTime,
      time: timeValues.effectiveWorkTime,
      subtime: timeValues.effectiveWorkTimeDifference,
    };

    return {
      preliminaryDifferenceAgreement,
      effectiveWorkingTime,
    };
  }

  private getTimeValues(): TimeValues {
    const preliminaryRegistration = this.timeCalculationStrategy.getPreliminaryRegister();

    const preliminaryRegistrationForComputation = this.timeCalculationStrategy.getPreliminaryTimeToComputed();

    const preliminaryDiffValue = this.timeCalculationStrategy.getPreliminaryDifWorkingDay();

    const effectiveWorkTime = this.timeCalculationStrategy.getEffectiveWorkingTime(
      0,
      this.employeeWorkingDay.workingDayData.differences.excess.other.time,
      this.employeeWorkingDay.workingDayData.authorizationStatus
    );
    const appliedMarginAgreement = this.timeCalculationStrategy.getAppliedMarginAgreement(
      0
    );

    const effectiveWorkTimeDifference = this.timeCalculationStrategy.getDifferenceToCompensate(
      0,
      this.getActualAuthorizationStatus()
    );

    return {
      preliminaryRegistration,
      preliminaryRegistrationForComputation,
      preliminaryDiffValue,
      appliedMarginAgreement,
      effectiveWorkTime,
      effectiveWorkTimeDifference,
    };
  }

  private getActualAuthorizationStatus(): AuthorizationStatus {
    const { authorizationStatus } = this.employeeWorkingDay.workingDayData;

    return this.shouldShowAuthorizationStatus(authorizationStatus)
      ? authorizationStatus
      : AuthorizationStatuses.PENDING;
  }

  private shouldShowAuthorizationStatus(
    authorizationStatus: AuthorizationStatus
  ): boolean {
    return authorizationStatus && !this.checkTimesEdited;
  }

  private async openInfoModal(
    title: string,
    infoAsList: TextBlock[]
  ): Promise<void> {
    const modalOptions = {
      component: WorkingDayInfoComponent,
      cssClass: 'register-time',
      componentProps: {
        infoAsList,
        title,
      },
    };
    const modal = await this.modalController.create(modalOptions);
    modal.present();
  }
}
