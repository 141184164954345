<section class="new-auth-card">
  <ion-grid>
    <ion-row class="new-auth-card__row">
      <ion-col class="img" size="1">
        <app-image
          class="img__avatar"
          src="assets/icon/default-avatar.png"
          alt="{{ 'ALT_IMG.AVATAR' | translate }}"
        ></app-image>
      </ion-col>
      <ion-col class="personal-data" size="11">
        <h4 class="personal-data__name">
          {{ authListItem.firstName }} {{ authListItem.lastName }}
        </h4>
        <p class="personal-data__date">
          {{ formatDate(authListItem.requestDate) }}
        </p>
        <p class="personal-data__difference">
          + {{ authListItem.difference | humanizeDuration }}
        </p>
        <p class="personal-data__reason-text">{{ authListItem.reason }}</p>
        <div class="state">
          <div [ngClass]="getDotClass()"></div>
          <span class="state__text">{{ getStateText() | translate }}</span>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</section>
