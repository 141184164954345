<ion-row class="titles">
  <ion-col class="step-1" [ngClass]="getCurrentStepClass(step1)" size-sm="4">
    <ion-text>{{ 'WIZARD.STEP_1' | translate }}</ion-text>
    {{ 'WIZARD.TIME_RECORD' | translate }}
  </ion-col>
  <ion-col class="step-2" [ngClass]="getCurrentStepClass(step2)" size-sm="8">
    <ion-text>{{ 'WIZARD.STEP_2' | translate }}</ion-text>
    {{ 'WIZARD.EFFECTIVE_WORK_TIME' | translate }}
  </ion-col>
</ion-row>
<ion-row>
  <ion-col size-sm="12">
    <ion-progress-bar [value]="progress"></ion-progress-bar>
  </ion-col>
</ion-row>
