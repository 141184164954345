<ion-grid [ngClass]="getGridTypeCssClass()">
  <ion-row class="disclaimer-content">
    <ion-col [ngClass]="getImageStep1CssClass()" sizeXs="12" sizeSm="0.5">
      <app-image
        class="disclaimer-content__icon"
        [src]="getImageSrc()"
        alt="{{ 'ALT_IMG.AVATAR' | translate }}"
      ></app-image>
    </ion-col>
    <ion-col [ngClass]="getTextStep1CssClass()">
      <h4 *ngIf="disclaimer.title" class="disclaimer-text__title">
        {{ disclaimer.title | translate }}
      </h4>
      <p class="disclaimer-text__text">{{ ((disclaimer.textFriend && friendsAndFamily) ? disclaimer.textFriend  : disclaimer.text) | translate }}
      <a
        *ngIf="disclaimer.link && disclaimer.textFriend && friendsAndFamily"
        [routerLink]="disclaimer.link"
        class="disclaimer-text__link"
        [innerHTML]="disclaimer.linkTitle | translate"
        >{{ disclaimer.linkTitle | translate }}</a
      ></p>
    </ion-col>
  </ion-row>
</ion-grid>
