import { Component } from '@angular/core';
import { ROUTE } from '@constants/index';

@Component({
  selector: 'app-success-message',
  templateUrl: './success-message.component.html',
  styleUrls: ['./success-message.component.scss'],
})
export class SuccessMessageComponent {
  public url = `/${ROUTE.LOGS}/${ROUTE.EMPLOYEE_LOGS}`;
}
