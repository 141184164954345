import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import moment from 'moment';
import { ROUTE } from '@constants/index';
import { EmployeeWorkingDay } from '@models/employee-working-day/employee-working-day';
import {
  DifferenceDataType,
  EmployeeConfirmDay,
  RegisterTimeSucess,
} from '@models/index';
import { EmployeeService } from '@services/index';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';
import { getCheckDayFormatted } from 'src/app/shared';

@Component({
  selector: 'app-register-time-modal',
  templateUrl: './register-time-modal.component.html',
  styleUrls: ['./register-time-modal.component.scss'],
})
export class RegisterTimeModalComponent {
  @Input() public employeeWorkingDay: EmployeeWorkingDay;
  @Input() public employeeId: string;
  @Input() public selectedDay = moment().toDate();
  @Input() public successData: RegisterTimeSucess[];
  @Input() public differenceTimes: { [type: number]: number };
  @Input() public excessReason: string;
  @Input() public isSuccess: boolean;
  @Input() public isPastDay: boolean;
  @Input() public hasAlternativeWorkingDay = false;
  @Input() public isAlternativeWorkingDay = false;
  @Input() public compensationNegativeBalance: number;
  @Input() public compensationPositiveBalance: number;
  @Input() public excessCompensation = false;
  @Input() public agreementMargin: number;
  @Input() public workplaceMissing: boolean;
  @Input() public hasRemoteWork = false;

  public isMobile = false;
  public isLoading = false;

  public constructor(
    private readonly modalController: ModalController,
    private readonly platformCheckerService: PlatformCheckerService,
    private readonly employeeService: EmployeeService,
    public readonly router: Router
  ) {
    this.isMobile = this.platformCheckerService.isMobile();
  }

  public isModifyMode(): boolean {
    return /edit$/.test(this.router.url);
  }

  public isStep2(): boolean {
    return new RegExp('/step/2').test(this.router.url);
  }

  public onDismissModal(): void {
    this.modalController.dismiss();
  }

  public shouldRedirectToShowMode(): boolean {
    return this.isModifyMode();
  }

  public getHeaderTitle(): string {
    return this.isPastDay ? 'LOGS.OTHER_EMPLOYEE_TITLE' : 'WORKING_DAY.TITLE';
  }

  public onConfirm(): void {
    this.isLoading = true;

    const bodyParams = this.formatConfirmParams();

    this.employeeService.confirmDay(this.employeeId, bodyParams).subscribe(
      (): void => {
        this.isLoading = false;

        // eslint-disable-next-line no-unused-expressions
        this.shouldRedirectToShowMode() &&
          this.router.navigate([
            ROUTE.SHOW_WORKING_DAY_DETAILS(this.selectedDay),
          ]);
        this.modalController.dismiss(true);
      },
      (): void => {
        this.isLoading = false;
      }
    );
  }

  public formatConfirmParams(): EmployeeConfirmDay {
    const shouldSendAllDataOnConfirmation = this.isModifyMode();
    const shouldSendEditionsOnStep2 = {
      checkInEdition:
        shouldSendAllDataOnConfirmation ||
        (this.isStep2() &&
          this.employeeWorkingDay.workingDayData.isEditedCheckIn),
      checkOutEdition:
        shouldSendAllDataOnConfirmation ||
        (this.isStep2() &&
          this.employeeWorkingDay.workingDayData.isEditedCheckOut),
    };
    const checkInTimeHour = this.employeeWorkingDay.workingDayData.checkIn
      .getHours()
      .toString()
      .padStart(2, '0');
    const checkInTimeMinutes = this.employeeWorkingDay.workingDayData.checkIn
      .getMinutes()
      .toString()
      .padStart(2, '0');
    const checkOutTimeHour = this.employeeWorkingDay.workingDayData.checkOut
      .getHours()
      .toString();
    const checkOutTimeMinutes = this.employeeWorkingDay.workingDayData.checkOut
      .getMinutes()
      .toString();
    const { minRegisterTime } = this.employeeWorkingDay.workingDayData;
    const checkInDate = getCheckDayFormatted(
      moment(this.employeeWorkingDay.workingDayData.date)
        .startOf('day')
        .hours(+checkInTimeHour)
        .minutes(+checkInTimeMinutes)
        .toDate(),
      minRegisterTime
    );
    const checkOutDate = getCheckDayFormatted(
      moment(this.employeeWorkingDay.workingDayData.date)
        .startOf('day')
        .hours(+checkOutTimeHour)
        .minutes(+checkOutTimeMinutes)
        .toDate(),
      minRegisterTime
    );

    const {
      workLocation,
      equalityPlan,
      equalityPlanDescription,
    } = this.employeeWorkingDay.workingDayData;
    const params: EmployeeConfirmDay = {
      selectedDay: this.selectedDay,
      hasAlternativeWorkingDay: this.hasAlternativeWorkingDay,
      agreementMargin: this.agreementMargin,
      checkInTimeHour,
      checkInTimeMinutes,
      checkOutTimeHour,
      checkOutTimeMinutes,
      checkInDate,
      checkOutDate,
      workLocation,
      equalityPlan,
      equalityPlanDescription,
    };

    if (shouldSendEditionsOnStep2.checkInEdition) {
      params.systemInTimeHour = this.employeeWorkingDay.workingDayData
        .isEditedCheckIn
        ? new Date().getHours().toString()
        : null;

      params.systemInTimeMinutes = this.employeeWorkingDay.workingDayData
        .isEditedCheckIn
        ? new Date().getMinutes().toString()
        : null;
    }

    if (shouldSendEditionsOnStep2.checkOutEdition) {
      params.systemOutTimeHour = this.employeeWorkingDay.workingDayData
        .isEditedCheckOut
        ? new Date().getHours().toString()
        : null;

      params.systemOutTimeMinutes = this.employeeWorkingDay.workingDayData
        .isEditedCheckOut
        ? new Date().getMinutes().toString()
        : null;

      params.systemInTimeHour = this.employeeWorkingDay.workingDayData
        .isEditedCheckIn
        ? new Date().getHours().toString()
        : null;

      params.systemInTimeMinutes = this.employeeWorkingDay.workingDayData
        .isEditedCheckIn
        ? new Date().getMinutes().toString()
        : null;
    }

    if (this.hasAlternativeWorkingDay) {
      params.alternativeWorkingDay = this.isAlternativeWorkingDay;
    }

    if (this.differenceTimes[DifferenceDataType.Recoverable]) {
      params.recoverableTime = this.differenceTimes[
        DifferenceDataType.Recoverable
      ];
    }
    if (this.differenceTimes[DifferenceDataType.Unrecoverable]) {
      params.notRecoverableTime = this.differenceTimes[
        DifferenceDataType.Unrecoverable
      ];
    }
    if (this.differenceTimes[DifferenceDataType.Personal]) {
      params.excessPersonalReasonsTime = this.differenceTimes[
        DifferenceDataType.Personal
      ];
    }
    if (this.differenceTimes[DifferenceDataType.Extension]) {
      params.excessExtensionDayTime = this.differenceTimes[
        DifferenceDataType.Extension
      ];
    }
    if ((this.excessReason || '').length > 0) {
      params.commentExtensionDay = this.excessReason;
    }
    if (this.compensationNegativeBalance) {
      params.compensationNegativeBalance = this.compensationNegativeBalance;
    }

    if (this.compensationPositiveBalance) {
      params.compensationPositiveBalance = this.compensationPositiveBalance;
    }

    params.forceCounter = !!this.isPastDay;

    return params;
  }
}
