export interface AutorizableEmployee {
  firstName: string;
  lastName: string;
  id: string;
  image: string;
}

export interface AutorizableEmployeeList {
  listEmployeesTeamManager: AutorizableEmployee[];
  numPages: number;
}

export function buildAutorizableEmployee(
  rawAutorizableEmployee: any
): AutorizableEmployee {
  return {
    firstName: rawAutorizableEmployee.name,
    lastName: rawAutorizableEmployee.surnames,
    id: rawAutorizableEmployee.code,
    image: rawAutorizableEmployee.image,
  };
}
