import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ROUTE } from '@constants/index';
import { ErrorBBVA } from './error-bbva';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  private error: ErrorBBVA;

  public constructor(
    private readonly router: Router,
    private readonly toastController: ToastController,
    private readonly translateService: TranslateService
  ) {}

  public set(
    errorMessage: string,
    error?: any,
    shouldHideGoHome = false
  ): void {
    this.error = ErrorBBVA.factory(errorMessage, error, shouldHideGoHome);

    if (this.error.error) {
      // eslint-disable-next-line no-console
      console.error(this.error.error);
    }

    this.router.navigate([ROUTE.ERROR]);
  }

  public getMessage(): string {
    return this.error ? this.error.message : '';
  }

  public shouldShowGoHome(): boolean {
    return this.error ? !this.error.hideGoHome : true;
  }

  public thereIsAnError(): boolean {
    return !!this.error;
  }

  public clear(): void {
    delete this.error;
  }

  public async makeToast(errorMessage: string): Promise<void> {
    await this.dismissToast();

    const translations = await this.translateService
      .get('ERROR_PAGE.ERRORS')
      .toPromise();

    const toast = await this.toastController.create({
      message: translations[errorMessage],
      position: 'top',
      duration: 6000,
      cssClass: 'g-toast',
      buttons: [
        {
          icon: 'close',
          handler: async (): Promise<boolean> => {
            await this.dismissToast();
            return true;
          },
        },
      ],
    });

    await toast.present();
  }

  private async dismissToast(): Promise<void> {
    const toast = await this.toastController.getTop();

    if (toast) {
      await toast.dismiss();
    }
  }
}
