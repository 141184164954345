<ion-toolbar class="toolbar" color="primary">
  <ion-buttons slot="start">
    <ion-menu-toggle slot="start" class="header__menu" menu="custom">
      <button clear class="toolbar__menu-button">
        <app-image
          class="header__menu-icon"
          src="assets/icon/menu.svg"
          [alt]="'ALT_IMG.MENU' | translate"
        ></app-image>
      </button>
    </ion-menu-toggle>
  </ion-buttons>
  <ion-title class="header__title">
    {{ title | translate }}
  </ion-title>
</ion-toolbar>
