import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SelectedDelegateEmployee } from '@components/search-employee/model/search-employee';
import { DelegableEmployee, Search, WorkingDayEmployee } from '@models/index';
import { DelegationsService, EmployeeService } from '@services/index';
import { SingleDelegateEmployee } from 'src/app/pages/delegations/models/delegation';
import { AssignmentConfirmationModalComponent } from '../assignment-confirmation-modal/assignment-confirmation-modal.component';

@Component({
  selector: 'app-assign-authorizer-modal',
  templateUrl: './assign-authorizer-modal.component.html',
  styleUrls: ['./assign-authorizer-modal.component.scss'],
})
export class AssignAuthorizerModalComponent implements OnInit, OnDestroy {
  @Input() public isMobile = false;

  public selectedAuthorizer: SingleDelegateEmployee = {
    employeeId: null,
    name: null,
  };

  public employeeId: string;
  public isLoading = false;
  public employeesInSearch: DelegableEmployee[] = [];

  private employeeSubscription: Subscription;

  public constructor(
    private readonly modalController: ModalController,
    private readonly employeeService: EmployeeService,
    private readonly delegationsService: DelegationsService
  ) {}

  public ngOnInit(): void {
    this.employeeSubscription = this.employeeService.employee$
      .pipe(filter((data): boolean => data !== null))
      .subscribe((employee: WorkingDayEmployee): void => {
        this.employeeId = employee.id;
      });
  }

  public setAuthorizer(): void {
    this.isLoading = true;
    const bodyParams = {
      idAuthorizer: this.selectedAuthorizer.employeeId,
    };
    this.delegationsService
      .assignTemporalAuthorizer(this.employeeId, bodyParams)
      .subscribe(
        (): void => {
          this.onDismissModal(true);
          this.isLoading = false;
          this.openConfirmationModal();
        },
        (): void => {
          this.isLoading = false;
        }
      );
  }

  public onDismissModal(accept: boolean): void {
    this.modalController.dismiss(accept);
  }

  private async openConfirmationModal(): Promise<void> {
    const modalOptions = this.buildAssignConfirmAuthorizerModalOptions();
    const modal = await this.modalController.create(modalOptions);
    await modal.present();
  }

  private buildAssignConfirmAuthorizerModalOptions(): any {
    return {
      component: AssignmentConfirmationModalComponent,
      cssClass: 'assign-authorizer',
      componentProps: {
        isMobile: this.isMobile,
        authorizerName: this.selectedAuthorizer.name,
      },
    };
  }

  public onSelectAuthorizer(employee: SelectedDelegateEmployee): void {
    this.selectedAuthorizer.employeeId = employee ? employee.id : null;
    this.selectedAuthorizer.name = employee ? employee.firstName : null;
  }

  public canAssign(): boolean {
    return (
      !!this.selectedAuthorizer.employeeId && !!this.selectedAuthorizer.name
    );
  }

  public ngOnDestroy(): void {
    this.employeeSubscription.unsubscribe();
  }

  public setTemporalAuthorizerEmployees(valueEvent: Search): any {
    this.delegationsService
      .findTemporalAuthorizerEmployees(
        valueEvent.managerId,
        valueEvent.filterText
      )
      .subscribe((employees): void => {
        this.employeesInSearch = employees;
      });
  }
}
