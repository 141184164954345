import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AUTHORIZER_TYPES } from '@constants/authorizer.constants';
import { Authorizer } from '@models/index';
import { EmployeeService } from '@services/index';
import {
  AssignAuthorizerModalComponent,
  UnassignConfirmationModalComponent,
} from 'src/app/pages/working-day/components/modals';

@Component({
  selector: 'app-authorizer',
  templateUrl: './authorizer.component.html',
  styleUrls: ['./authorizer.component.scss'],
})
export class AuthorizerComponent {
  @Input() public authorizer: Authorizer;
  @Input() public employeeId: string;
  @Input() public isMobile = false;
  @Input() public isDisabled = false;

  public constructor(
    private readonly modalController: ModalController,
    private readonly employeeService: EmployeeService
  ) {}

  public shouldShowRemoveAction(): boolean {
    return this.authorizer && this.authorizer.type === AUTHORIZER_TYPES.AT;
  }

  public async assignAuthorizer(): Promise<void> {
    if (this.isDisabled) {
      return;
    }
    const modalOptions = this.buildAssignAuthorizerModalOptions();
    const modal = await this.modalController.create(modalOptions);
    await modal.present();
    const dismissData = await modal.onDidDismiss();
    if (dismissData && dismissData.data) {
      this.refreshEmployee();
    }
  }

  public async unassignAuthorizer(): Promise<void> {
    if (this.isDisabled) {
      return;
    }
    const modalOptions = this.buildUnassignAuthorizerModalOptions();
    const modal = await this.modalController.create(modalOptions);
    await modal.present();
    const dismissData = await modal.onDidDismiss();
    if (dismissData && dismissData.data) {
      this.refreshEmployee();
    }
  }

  public getAuthorizerFullName(): string {
    let fullName = this.authorizer.name;
    if (this.authorizer.surnames) {
      fullName = `${this.authorizer.name} ${this.authorizer.surnames}`;
    }
    return fullName;
  }

  private refreshEmployee(): void {
    this.employeeService.getEmployee(this.employeeId).subscribe();
  }

  private buildAssignAuthorizerModalOptions(): any {
    return {
      component: AssignAuthorizerModalComponent,
      cssClass: 'assign-authorizer',
      componentProps: {
        isMobile: this.isMobile,
      },
    };
  }

  private buildUnassignAuthorizerModalOptions(): any {
    return {
      component: UnassignConfirmationModalComponent,
      cssClass: 'assign-authorizer',
      componentProps: {
        isMobile: this.isMobile,
        authorizer: this.authorizer,
        employeeId: this.employeeId,
      },
    };
  }
}
