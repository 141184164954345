import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { API_ROUTE } from '@constants/index';
import { staticTextBuilder, EqualityPlan } from '@models/index';
import { ErrorService } from '@services/error/error.service';
import { StaticText } from '../..';

@Injectable({
  providedIn: 'root',
})
export class StaticTextService {
  public constructor(
    private readonly http: HttpClient,
    private readonly errorService: ErrorService
  ) {}

  private readonly staticTextSubject = new BehaviorSubject<StaticText>(null);
  public staticText$ = this.staticTextSubject.asObservable();
  public equalityPlanTexts: EqualityPlan[];

  public updateStaticText(): void {
    const url = API_ROUTE.MESSAGE_STATICS;

    this.http.get<any>(`${url}`).subscribe((data: any): void => {
      try {
        const staticText = staticTextBuilder(data);
        this.staticTextSubject.next(staticText);
      } catch (err) {
        this.errorService.set('ERROR_DEFAULT', err);
      }
    });
  }

  public updateEqualityPlanText(): void {
    const url = API_ROUTE.EQUALITY_PLAN;

    this.http.get<any>(`${url}`).subscribe((data: EqualityPlan[]): void => {
      try {
        this.equalityPlanTexts = data;
      } catch (err) {
        this.errorService.set('ERROR_DEFAULT', err);
      }
    });
  }
}
