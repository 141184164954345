<ion-select
  interface="popover"
  [placeholder]="selectPlaceholder"
  class="dropdown"
  (ionChange)="onWorkplaceSelect($event)"
  [disabled]="selectionDisabled"
  [(ngModel)]="workplaceValue"
  >
  <ion-list>
    <ion-select-option
      *ngFor="let workplace of validWorkplaces"
      [value]="workplace"
    >
      {{ workplace.name }}
    </ion-select-option>
  </ion-list>
</ion-select>