import { Router } from '@angular/router';

export interface TabsInterface {
  title: string;
  mobileTitle?: string;
  url: string;
  isActivated?: (router: Router) => boolean;
  shouldShowWarningSign?: boolean;
  unconfirmedDaysCounter?: number;
}
