import moment from 'moment';
import {
  EmployeeInfo,
  Rubric,
  WorkingDayData,
  WorkingDayDataInterface,
} from '@models/index';
import { WorkingDay } from 'src/app/pages/working-day/models/working-day';
import { buildDateWithTime } from '../utils/date-parser/date-parser';

export function buildEmployeeInfo(): EmployeeInfo {
  return {
    firstName: 'RUBEN',
    lastName: 'VIDAL MINGUILLON',
    id: '3610682',
    isManager: true,
    isAuthorizer: true,
    isEmployeeDelegate: false,
    currentBalance: 50,
    lockedBalance: 0,
    shouldHideBalance: false,
    unconfirmedDaysCounter: 2,
    authorizer: null,
    balancePreviousYears: 10,
    shouldHaveHourlyMedicalLeave: false,
    hasRemoteWork: true,
    codCompany: '001',
    manager: {
      code: '3524535',
      name: 'ESTHER',
    },
  };
}

// TODO javier.galindo add more builders
const DATE = moment('20190924', 'YYYYMMDD').toDate();
export function buildWorkingDayData(
  editionCheckIn = false,
  editionCheckOut = false,
  add = { minRegisterTime: true },
  date = DATE
): WorkingDayData {
  const data: WorkingDayDataInterface = {
    date,
    workingDay: 420,
    isConfirmed: false,
    authorizationStatus: null,
    isAlternativeChosen: false,
    skipBreakTimeLimit: 0,
    differences: {
      excess: {
        personal: {
          time: 0,
        },
        other: {
          reason: '',
          time: 0,
          authorizedTime: 45,
        },
        negativeCompensation: {
          time: 0,
        },
      },
      deffect: {
        recoverable: {
          time: 0,
        },
        unrecoverable: {
          time: 0,
        },
        positiveCompensation: {
          time: 0,
        },
      },
    },
    differenceToCompensate: 210,
    workLocation: '1',
    workplace: '1',
    equalityPlan: '1',
    equalityPlanDescription: 'Descripcion de prueba',
  };

  if (add.minRegisterTime) {
    data.minRegisterTime = 100;
    data.maxRegisterTime = 100;
  }

  const checkInTime = '09:02';
  const checkOutTime = '18:02';

  const workingDayData = WorkingDayData.factory(
    data,
    buildDateWithTime(!editionCheckIn ? checkInTime : '00:00', date),
    buildDateWithTime(!editionCheckOut ? checkOutTime : '00:00', date)
  );

  if (editionCheckIn) {
    workingDayData.checkIn = buildDateWithTime(checkInTime, date);
  }

  if (editionCheckOut) {
    workingDayData.checkOut = buildDateWithTime(checkOutTime, date);
  }

  return workingDayData;
}

export function buildAlternativeWorkingDayData(): WorkingDay {
  return {
    agreementMargin: 15,
    description:
      'Entrada: 08:00h.<br><br>Salida: 15:00h.<br><br>Se computan como trabajo efectivo 15 minutos de descanso obligatorio.<br>',
    duration: 420,
    minimumBreakTime: 0,
  };
}

function buildWorkingDay(hour: string): WorkingDayData {
  const workingDayData = buildWorkingDayData();
  workingDayData.checkOut = buildDateWithTime(hour, DATE);
  return workingDayData;
}

export function buildWorkingDayDefectData(): WorkingDayData {
  return buildWorkingDay('12:02');
}

export function buildWorkingDayNoDifferenceData(): WorkingDayData {
  return buildWorkingDay('16:32');
}

export function buildWorkingDayExcessData(): WorkingDayData {
  return buildWorkingDay('19:02');
}

export function buildRubric(): Rubric {
  return {
    minimumBreakTime: 30,
    workingDayInfo: '<p>lala</p>',
    permissionRegister: {
      permissionGTA: true,
      absenceOrHolidays: false,
      needRegisterWorkingDay: true,
      withoutPlanning: false,
    },
    agreementMargin: 15,
  };
}
