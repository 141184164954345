<ion-row>
  <ion-col>
    <ion-card class="wrapper info-panel__card info-panel__card--last">
      <app-time-info-card
        class="effective-working-time"
        [class.borderless]="isMobile"
        [infoList]="effectiveWorkingTime"
      ></app-time-info-card>
    </ion-card>
  </ion-col>
</ion-row>
<ion-row *ngIf="!isReadonly">
  <ion-col class="buttons">
    <ion-button
      class="buttons__continue"
      (click)="send()"
      [disabled]="isDisabled"
      data-test="send"
    >
      {{ 'WORKING_DAY.SEND' | translate }}
    </ion-button>
  </ion-col>
</ion-row>
