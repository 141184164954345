import { Routes } from '@angular/router';
import { ROUTE } from '@constants/index';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { Step1Component } from '../working-day/components';
import { RootComponent } from '../working-day/components/step-2/components/root/root.component';
import { WorkingDayPage } from '../working-day/working-day.page';
import { EmployeeLogsComponent } from './components/employee-logs/employee-logs.component';
import { TeamLogsComponent } from './components/team-logs/team-logs.component';
import { TeamMemberLogsPage } from './components/team-member-logs/team-member-logs.page';
import { LogsPage } from './logs.page';
import { NegativeBalanceAdjustment } from './components';

export const logsRoutes: Routes = [
  {
    path: ROUTE.LOGS,
    canActivate: [AuthGuard],
    component: LogsPage,
    children: [
      { path: '', pathMatch: 'full', redirectTo: ROUTE.EMPLOYEE },
      { path: ROUTE.EMPLOYEE, component: EmployeeLogsComponent },
      {
        path: `${ROUTE.EMPLOYEE}/${ROUTE.NEGATIVE_BALANCE_ADJUSTMENT}`,
        component: NegativeBalanceAdjustment,
      },
      { path: ROUTE.TEAM, component: TeamLogsComponent, pathMatch: 'full' },
      { path: `${ROUTE.TEAM}/:teamEmployeeId`, component: TeamMemberLogsPage },
      {
        path: `${ROUTE.WORKING_DAY_DETAILS}/:date`,
        component: WorkingDayPage,
        children: [
          { path: 'register', component: Step1Component },
          { path: 'show', component: RootComponent },
          { path: 'edit', component: RootComponent },
        ],
      },
    ],
  },
];

export class LogsRoutingModule {}
