export function hasOwnProperty(obj: any, propertyName: string): boolean {
  return Object.prototype.hasOwnProperty.call(obj, propertyName);
}

interface Property {
  name: string;
  nullable: boolean;
}

export function checkProperty(
  data: any,
  dataName: string,
  property: Property
): void {
  const dataIsNullOrUndefined =
    data[property.name] === null ||
    data[property.name] === undefined ||
    data[property.name] === '';
  if (!hasOwnProperty(data, property.name)) {
    throw new Error(`${dataName} must have ${property.name} field`);
  } else if (!property.nullable && dataIsNullOrUndefined) {
    throw new Error(
      `${property.name} in ${dataName} can't be null or undefined`
    );
  }
}

export function throwErrorIf(condition: boolean, messageError: string): void {
  if (condition) {
    throw new Error(messageError);
  }
}

export function isNumberFinite(value: number): boolean {
  return !!value || value === 0;
}
