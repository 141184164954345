export interface DelegableEmployee {
  firstName: string;
  lastName: string;
  id: string;
  image: string;
}

export function buildDelegableEmployee(
  rawDelegableEmployee: any
): DelegableEmployee {
  return {
    firstName: rawDelegableEmployee.name,
    lastName: rawDelegableEmployee.surnames,
    id: rawDelegableEmployee.employeeId,
    image: rawDelegableEmployee.image,
  };
}
