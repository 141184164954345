import { Component, Input } from '@angular/core';
import { DisclaimerInterface, TYPES } from './models/disclaimer';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent {
  @Input() public disclaimer: DisclaimerInterface;
  @Input() public friendsAndFamily = false;

  public getImageSrc(): string {
    return this.disclaimer.type === TYPES.INFO
      ? 'assets/icon/info.svg'
      : 'assets/icon/warning.svg';
  }

  public getGridTypeCssClass(): { [key: string]: boolean } {
    return {
      disclaimer: true,
      'disclaimer-info': this.disclaimer.type === TYPES.INFO,
      'disclaimer-warning': this.disclaimer.type === TYPES.WARNING,
    };
  }

  public getTextStep1CssClass(): { [key: string]: boolean } {
    return {
      'disclaimer-text': true,
      'disclaimer-text--step1': this.disclaimer.isStep1,
    };
  }

  public getImageStep1CssClass(): { [key: string]: boolean } {
    return {
      'disclaimer-content__image': true,
      'disclaimer-content__image--step1': this.disclaimer.isStep1,
    };
  }
}
