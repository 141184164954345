import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EmployeeWorkingDay } from '@models/employee-working-day/employee-working-day';
import { WorkingDay } from '../../models/working-day';
import { WorkingDayInfoComponent } from '../modals/working-day-info/working-day-info.component';

@Component({
  selector: 'app-working-day-chooser',
  templateUrl: './working-day-chooser.component.html',
  styleUrls: ['./working-day-chooser.component.scss'],
})
export class WorkingDayChooserComponent implements OnInit {
  @Output() public workingDayTypeChange = new EventEmitter<{
    isAlternative: boolean;
  }>();

  @Input() public alternativeWorkingDayChosen = false;
  @Input() public alternativeWorkingDay: WorkingDay;
  @Input() public employeeWorkingDay: EmployeeWorkingDay;
  @Input() public readOnly = false;

  public alternativeChecked = false;

  public constructor(public modalController: ModalController) {}

  public onChangeHandler(event: any): void {
    this.alternativeChecked = event.target.value;
    this.workingDayTypeChange.emit({
      isAlternative: this.alternativeChecked,
    });
  }

  public async openWorkingDayInfoModal(alternative = false): Promise<false> {
    const modalOptions = {
      component: WorkingDayInfoComponent,
      cssClass: 'register-time',
      componentProps: {
        infoAsHTML: alternative
          ? this.alternativeWorkingDay.description
          : this.employeeWorkingDay.rubric.workingDayInfo,
      },
    };
    const modal = await this.modalController.create(modalOptions);
    await modal.present();
    return false;
  }

  public ngOnInit(): void {
    this.alternativeChecked = this.alternativeWorkingDayChosen;
  }
}
