export class ErrorBBVA {
  private constructor(
    public readonly message: string,
    public readonly error?: any,
    public readonly hideGoHome?: boolean
  ) {
    //--
  }

  public static factory(
    message: string,
    error?: Error,
    hideGoHome?: boolean
  ): ErrorBBVA {
    return new ErrorBBVA(message, error, hideGoHome);
  }
}
