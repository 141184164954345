import { ServerWorkingDay } from '../interfaces/server-working-day';

export class WorkingDay {
  private constructor(
    public duration: number,
    public minimumBreakTime: number,
    public agreementMargin: number,
    public description: string
  ) {}

  public static factory({
    workingDay,
    minimumBreakTime,
    agreementMargin,
    descriptionDayRecord,
  }: ServerWorkingDay): WorkingDay {
    return new WorkingDay(
      workingDay,
      minimumBreakTime,
      agreementMargin,
      descriptionDayRecord
    );
  }
}
