export enum TYPES {
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export interface DisclaimerInterface {
  type: TYPES;
  title?: string;
  link?: string;
  linkTitle?: string;
  text: string;
  textFriend?: string;
  isStep1: boolean;
}
