<ion-item class="item {{ 'difference-time-input--' + timeInputClass }}">
  <ion-avatar class="avatar">
    <app-image
      class="avatar__image"
      [src]="data.avatar.image"
      [alt]="data.avatar.alt | translate"
    ></app-image>
  </ion-avatar>

  <ion-label class="text ion-text-wrap">
    <h2 class="text__title">{{ data.title | translate }}</h2>
    <p
      class="text__explanation"
      *ngIf="data.explanation"
      [innerHTML]="(isBBVACompany ? data.explanation.textBBVA : data.explanation.text) | translate: data.explanation.data"
    ></p>
  </ion-label>

  <section class="time" slot="end">
    <form [formGroup]="control" class="time__form">
      <div *ngIf="isAuthorizationReviewed()">
        <ion-icon
          [ngClass]="getAuthStatusIndicatorClass()"
          [src]="getAuthStatusIndicatorIconURL()"
        ></ion-icon>
      </div>
      <div
        *ngFor="let control of timeInputControls; first as isFirst"
        [ngClass]="['time-input', control.id]"
      >
        <div class="time-input__label">{{ control.label }}</div>

        <ion-input
          type="text"
          [ngClass]="getTimeInputInputCssClasses()"
          [class.time-input__input--first]="isFirst"
          placeholder="00"
          inputmode="numeric"
          [maxlength]="3"
          [formControlName]="control.id"
          (ionBlur)="onBlurNumber(control.id)"
          [readonly]="isReadOnly"
        ></ion-input>
      </div>
      <div class="time__sign">
        <ion-icon *ngIf="hasSignIcon()" [name]="getIconGivenType()"></ion-icon>
      </div>
    </form>
  </section>
</ion-item>

<hr class="g-divider divider" *ngIf="isReadOnly && isLast" />
