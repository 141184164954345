<ion-list class="day-list">
  <ion-item *ngFor="let dayLog of dayLogs" lines="full">
    <div class="day-content">
      <h1>{{ formatDate(dayLog.day) | titlecase }}</h1>
      <div class="not-registered" *ngIf="shouldShowRegisterAction(dayLog)">
        <span class="not-registered__value">
          {{ 'LOGS.NOT_REGISTERED' | translate }}
        </span>
      </div>
      <div class="day-content__data">
        <div class="absence" *ngFor="let absence of getAbsences(dayLog)">
          <span class="absence__value">
            {{ absence }}
          </span>
        </div>
        <div
          class="day-property"
          *ngFor="let property of getDayLogProperties(dayLog)"
        >
          <span class="day-property__label">{{
            property.label | translate
          }}</span>
          <span class="day-property__content">{{ property.time }}</span>
        </div>
        <div class="day-property" *ngIf="targetHasRemoteWork">
          <span class="day-property__label"><b>{{ 'WORKING_DAY.WORKPLACE' | translate }}</b></span>
          <span class="day-property__content">{{ formatWorkplace(dayLog.workplace) }}</span>
        </div>
        <div class="day-property record-status">
          <span
            class="record-status__value"
            [ngClass]="getDayStatusClass(dayLog.statusCompleted)"
            >{{
              (dayLog.statusCompleted
                ? 'LOGS.COMPLETED'
                : 'LOGS.NOT_COMPLETED'
              ) | translate
            }}</span
          >
        </div>
      </div>
      <div class="actions">
        <ion-button
          *ngIf="shouldShowRegisterAction(dayLog)"
          size="small"
          [routerLink]="getRoute('register', dayLog.day)"
        >
          <ion-icon name="timer"></ion-icon>{{ 'LOGS.REGISTER' | translate }}
        </ion-button>
        <ion-button
          *ngIf="shouldShowDetail(dayLog)"
          size="small"
          [routerLink]="getRoute('show', dayLog.day)"
          [queryParams]="getRouteQueryParams()"
          ><ion-icon name="eye"></ion-icon
          >{{ 'LOGS.DETAIL' | translate }}</ion-button
        >
        <ion-button
          *ngIf="shouldShowModifyAction(dayLog)"
          size="small"
          [routerLink]="getRoute('edit', dayLog.day)"
          ><ion-icon name="options"></ion-icon
          >{{ 'LOGS.MODIFY' | translate }}</ion-button
        >
      </div>
    </div>
  </ion-item>
</ion-list>
