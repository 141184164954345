<div #dateInput>
  <ion-item class="datepicker" [ngClass]="backgroundValidator()" lines="full">
    <ion-label [position]="getLabelPosition()" class="datepicker__label">
      {{ placeholder }}
    </ion-label>
    <ion-input
      class="datepicker__input"
      name="dateEvent"
      type="text"
      (ionChange)="onChangeDate($event)"
      [value]="getSelectedDayAsText()"
      [disabled]="disabled"
    ></ion-input>
    <div
      class="datepicker__wrapper-icon"
      slot="end"
      (click)="onClick()"
      *ngIf="!disabled"
    >
      <ion-icon name="calendar"></ion-icon>
    </div>
  </ion-item>
  <ng-container *ngIf="clicked && !disabled">
    <div class="calendar-picker">
      <app-calendar
        (selectDate)="dateSelected($event)"
        [daySelected]="selectedDate"
      ></app-calendar>
    </div>
  </ng-container>
</div>
