import { VersionPack } from '@services/feature-flag/models/version-pack.model';

export const FEATURES = {
  AUTHORIZATIONS_FILTER: 'AUTHORIZATIONS_FILTER',
  SINGLE_DELEGATION: 'SINGLE_DELEGATION',
  DROPDOWN_EMPLOPYEES: 'DROPDOWN_EMPLOPYEES',
  EDIT_DELEGATION: 'EDIT_DELEGATION',
  CHECKTIMES_ON_CONFIRM: 'CHECKTIMES_ON_CONFIRM',
  NIGHT_SHIFT: 'NIGHT_SHIFT',
  WEBSOCKETS: 'WEBSOCKETS',
  UNCONFIRMED_DAYS: 'UNCONFIRMED_DAYS',
  MENU_BADGES: 'MENU_BADGES',
  TEMPORAL_AUTHORIZER: 'TEMPORAL_AUTHORIZER',
  JNE: 'JNE',
};

export const features = [
  new VersionPack('3.0.0', [FEATURES.AUTHORIZATIONS_FILTER]),
  new VersionPack('3.1.0', [FEATURES.CHECKTIMES_ON_CONFIRM]),
  new VersionPack('3.2.0', [
    FEATURES.SINGLE_DELEGATION,
    FEATURES.DROPDOWN_EMPLOPYEES,
    FEATURES.EDIT_DELEGATION,
    FEATURES.NIGHT_SHIFT,
    FEATURES.UNCONFIRMED_DAYS,
    FEATURES.WEBSOCKETS,
    FEATURES.MENU_BADGES,
  ]),
  new VersionPack('3.3.0', [FEATURES.TEMPORAL_AUTHORIZER, FEATURES.JNE]),
];
