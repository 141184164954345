import { Component, OnDestroy, ViewChild } from '@angular/core';
import {
  ActivatedRoute,
  Event,
  NavigationCancel,
  NavigationEnd,
  Router,
} from '@angular/router';
import { IonContent } from '@ionic/angular';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { filter, finalize, tap } from 'rxjs/operators';
import { GLOBAL, ROUTE } from '@constants/index';
import { EmployeeWorkingDay } from '@models/employee-working-day/employee-working-day';
import {
  createNoNeedToRegisterMessage,
  EmployeeWorkingDayCreator,
  MessageType,
} from '@models/employee-working-day/employee-working-day-creator';
import { EmployeeWorkingDayCreatorDefault } from '@models/employee-working-day/employee-working-day-creator-default';
import { EmployeeWorkingDayCreatorOther } from '@models/employee-working-day/employee-working-day-creator-other';
import { TimeRecordEmitter } from '@models/index';
import {
  DayRecordDetailService,
  EmployeeService,
  FeatureFlagService,
  SocketService,
  StorageService,
} from '@services/index';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';
import { buildDateWithTime } from 'src/app/shared/utils/date-parser/date-parser';
import { FEATURES } from 'src/environments/common';
import {
  STEP_2_PAGE_DISCLAIMER,
  WORKING_DAY_PAGE_DISCLAIMER,
} from '../../shared/components/disclaimer/constants/disclaimers-constants';
import { WorkingDay } from './models/working-day';
import { DayRecordService } from './services/day-record.service';

@Component({
  selector: 'app-working-day',
  templateUrl: './working-day.page.html',
  styleUrls: ['./working-day.page.scss'],
})
export class WorkingDayPage implements OnDestroy {
  @ViewChild(IonContent, { static: true }) public content: IonContent;
  public wizardProgress = GLOBAL.STEP_1;
  public workingDay: number;
  public agreementMargin: number;
  public valueForStep2 = GLOBAL.STEP_2;
  public workingDayInfo: string;
  public isResumeOpened = false;
  public isMobile: boolean;
  public noNeedToRegisterMessage: MessageType;
  public checkInTime: Date;
  public checkOutTime: Date;
  public registerMode = false;
  public readOnly = false;
  public currentStep: string;
  public disclaimer = WORKING_DAY_PAGE_DISCLAIMER;
  public step2Disclaimer = STEP_2_PAGE_DISCLAIMER;
  public pastDay: Date;
  public employeeWorkingDay: EmployeeWorkingDay;
  public alternativeWorkingDay: WorkingDay;
  public alternativeWorkingDayChosen = false;
  public otherEmployeeID: string;
  public checkTimesEdited: boolean;
  public employeeWorkingDayCreator: EmployeeWorkingDayCreator;
  public isCheckinLoading = false;
  public isCheckoutLoading = false;
  public isEmployeeLoading = false;
  public isEmployeeLoadingFromConfirmation = false;
  public isTimeEditionLoading = false;
  public isTemporalAuthorizerAvailable: boolean;
  public workplace: string;
  public workLocation: string;
  private employeeWorkingDaySubscription: Subscription;
  private socketCheckInSubscription: Subscription;
  private socketCheckOutSubscription: Subscription;
  private socketConfirmSubscription: Subscription;
  private showSuccessMessage = false;

  public constructor(
    private readonly employeeService: EmployeeService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dayRecordService: DayRecordService,
    private readonly dayDetailService: DayRecordDetailService,
    private readonly storageService: StorageService,
    private readonly platformCheckerService: PlatformCheckerService,
    private readonly socketService: SocketService,
    private readonly featureFlagService: FeatureFlagService
  ) {
    this.router.events.subscribe((e: Event): void => {
      if (e instanceof NavigationEnd || e instanceof NavigationCancel) {
        this.setStep();
        this.registerMode = this.isRegisterMode();
      }
    });
    this.isTemporalAuthorizerAvailable = featureFlagService.isFeatureAvailable(
      FEATURES.TEMPORAL_AUTHORIZER
    );
    if (this.isWebsocketsFeatured()) {
      this.initializeSockets();
    }
  }

  private initializeSockets(): void {
    this.socketService.initSocketConnections(
      this.storageService.get('employeeId')
    );
    this.socketCheckInSubscription = this.socketService.checkInSocket$
      .pipe(filter((data: TimeRecordEmitter): boolean => !!data))
      .subscribe((data: TimeRecordEmitter): void => {
        this.dayRecordService.updateCheckTimes(
          'checkInTime',
          data.bodyParams.checkTimeHour,
          data.bodyParams.checkTimeMinutes,
          null
        );
      });
    this.socketCheckOutSubscription = this.socketService.checkOutSocket$
      .pipe(filter((data: TimeRecordEmitter): boolean => !!data))
      .subscribe((data: TimeRecordEmitter): void => {
        this.dayRecordService.updateCheckTimes(
          'checkOutTime',
          data.bodyParams.checkTimeHour,
          data.bodyParams.checkTimeMinutes,
          null
        );
      });
    this.socketConfirmSubscription = this.socketService.confirmSocket$
      .pipe(
        filter(
          (data: any): boolean => !!data && !!this.employeeWorkingDayCreator
        )
      )
      .subscribe((): void => {
        this.confirmDay();
      });
  }

  private isWebsocketsFeatured(): boolean {
    return this.featureFlagService.isFeatureAvailable(FEATURES.WEBSOCKETS);
  }

  public static getUrl(): string {
    return window.location.pathname;
  }

  public ionViewDidEnter(): void {
    this.isEmployeeLoading = true;
    this.isMobile = this.platformCheckerService.isMobile();
    const { params, queryParams } = this.route.snapshot;
    this.pastDay = params.date
      ? moment(params.date, 'YYYY-MM-DD')
          .startOf('day')
          .toDate()
      : null;

    this.otherEmployeeID = queryParams.id;

    if (this.otherEmployeeID) {
      this.employeeWorkingDayCreator = new EmployeeWorkingDayCreatorOther(
        this.employeeService,
        this.dayDetailService,
        this.dayRecordService,
        this.storageService
      );
    } else {
      this.employeeWorkingDayCreator = new EmployeeWorkingDayCreatorDefault(
        this.employeeService,
        this.dayDetailService,
        this.dayRecordService,
        this.storageService
      );
    }

    const date = this.pastDay;

    this.employeeWorkingDaySubscription = this.employeeWorkingDayCreator
      .create(date, this.otherEmployeeID)
      .subscribe((employeeWorkingDay: EmployeeWorkingDay): void => {
        if (employeeWorkingDay) {
          this.isEmployeeLoading = false;
          this.setEmployee(employeeWorkingDay);

          if (employeeWorkingDay.workingDayData.isConfirmed) {
            this.scrollToTop();
          }
          this.alternativeWorkingDay = employeeWorkingDay.alternativeWorkingDay;

          this.setStep();

          this.checkInTime = employeeWorkingDay.workingDayData.checkIn;
          this.checkOutTime = employeeWorkingDay.workingDayData.checkOut;
          this.workplace = employeeWorkingDay.workingDayData.workplace;
          this.workLocation = employeeWorkingDay.workingDayData.workLocation;
          this.decideRoute();
        }
      });
  }

  public shouldShowBalance(): boolean {
    return !this.pastDay || this.isWriteMode();
  }

  public getBalanceOffset(): number {
    return this.pastDay && !this.isMobile ? 5 : 0;
  }

  public getBackLink(): string {
    return this.otherEmployeeID
      ? `/${ROUTE.LOGS}/${ROUTE.EMPLOYEE_TEAM_LOGS}/${this.otherEmployeeID}`
      : `/${ROUTE.LOGS}/${ROUTE.EMPLOYEE_LOGS}`;
  }

  public isStep2(): boolean {
    return this.currentStep === '2';
  }

  public isStep1(): boolean {
    return this.currentStep === '1';
  }

  public isReadOnly(): boolean {
    const isConfirmed =
      this.employeeWorkingDay &&
      this.employeeWorkingDay.workingDayData.isConfirmed;

    return (isConfirmed && !this.pastDay) || this.isShowMode();
  }

  public shouldShowWizard(): boolean {
    return (
      !this.isReadOnly() &&
      !this.isMobile &&
      (!(
        this.employeeWorkingDay &&
        this.employeeWorkingDay.workingDayData.isConfirmed
      ) ||
        this.isModifyMode()) &&
      !this.noNeedToRegisterMessage
    );
  }

  public hasRemoteWork(): boolean {
    return this.employeeWorkingDay.employeeInfo.hasRemoteWork;
  }

  public getFullEmployeeName(): string {
    let fullName: string;

    if (this.employeeWorkingDay) {
      const { firstName, lastName } = this.employeeWorkingDay.employeeInfo;
      fullName = `${firstName} ${lastName}`;
    } else {
      fullName = '';
    }
    return fullName;
  }

  public getWorkingDayDuration(): number {
    const { workingDayData, alternativeWorkingDay } = this.employeeWorkingDay;

    return workingDayData.isAlternativeChosen
      ? alternativeWorkingDay.duration
      : workingDayData.workingDay;
  }

  public sendCheckTimes(timeRecord: TimeRecordEmitter): void {
    this.setIsLoading(true, timeRecord.isCheckIn);
    this.employeeWorkingDayCreator
      .setCheckTimes(this.employeeWorkingDay, timeRecord)
      .pipe(
        tap((): void => {
          if (this.isWebsocketsFeatured()) {
            this.socketService.updateRegisterSocket(timeRecord);
          }
        }),
        finalize((): void => {
          this.setIsLoading(false, timeRecord.isCheckIn);
        })
      )
      .subscribe();
  }

  public shouldShowSuccessMessage(): boolean {
    const dayIsConfirmed =
      this.employeeWorkingDay &&
      this.employeeWorkingDay.workingDayData.isConfirmed;

    const isNotHistoric =
      !this.isModifyMode() && !this.isShowMode() && !this.isRegisterMode();

    const isTodayConfirmed =
      !this.otherEmployeeID && isNotHistoric && dayIsConfirmed;

    return this.showSuccessMessage || isTodayConfirmed;
  }

  public ngOnDestroy(): void {
    this.ionViewWillLeave();
  }

  public ionViewWillLeave(): void {
    this.employeeWorkingDaySubscription.unsubscribe();

    if (this.socketCheckInSubscription) {
      this.socketCheckInSubscription.unsubscribe();
    }
    if (this.socketCheckOutSubscription) {
      this.socketCheckOutSubscription.unsubscribe();
    }
    if (this.socketConfirmSubscription) {
      this.socketConfirmSubscription.unsubscribe();
    }
  }

  public openFloatingResume(isOpened: boolean): void {
    this.isResumeOpened = isOpened;
  }

  public onCheckTimesEdited(timeRecord: TimeRecordEmitter): void {
    if (
      this.featureFlagService.isFeatureAvailable(FEATURES.CHECKTIMES_ON_CONFIRM)
    ) {
      this.setCheckTimeVariables(timeRecord);
    } else {
      this.sendCheckTimes(timeRecord);
    }
  }

  public triggerWorkingDayChanges(): void {
    const employeeWorkingDayCopy = { ...this.employeeWorkingDay };
    this.employeeWorkingDay = employeeWorkingDayCopy;
  }

  public getMobileClass(className: string): { [key: string]: boolean } {
    return { [className]: this.isResumeOpened && this.isMobile };
  }

  public getMobileClassToCardResume(): { [key: string]: boolean } {
    return this.getMobileClass('card__resume--floating');
  }

  public async scrollToTop(): Promise<void> {
    if (this.content) {
      await this.content.scrollToTop();
    }
  }

  public onWorkingDayConfirm(): void {
    this.isEmployeeLoadingFromConfirmation = true;
    this.confirmDay();
    if (this.isWebsocketsFeatured()) {
      this.socketService.updateConfirmSocket(this.showSuccessMessage);
    }
  }

  public confirmDay(): void {
    this.employeeWorkingDayCreator.refreshWorkingDayData(
      this.employeeWorkingDay.workingDayData.date,
      (): void => {
        this.isEmployeeLoadingFromConfirmation = false;
      }
    );
    this.showSuccessMessage = true;
  }

  public alternativeWorkingDayChangeHandler({
    isAlternative,
  }: {
    isAlternative: boolean;
  }): void {
    this.alternativeWorkingDayChosen = isAlternative;
    this.employeeWorkingDay.workingDayData.isAlternativeChosen = this.alternativeWorkingDayChosen;
    this.setEmployee(this.employeeWorkingDay);
  }

  public workplaceSelectedHandler({ workplace }: { workplace: string }): void {
    this.employeeWorkingDay.workingDayData.workLocation = workplace;
    this.setEmployee(this.employeeWorkingDay);
  }

  public isPanelLoading(): boolean {
    return (
      this.isTimeEditionLoading ||
      this.isEmployeeLoading ||
      this.isEmployeeLoadingFromConfirmation
    );
  }

  public shouldShowCurrentBalance(): boolean {
    return (
      this.employeeWorkingDay &&
      this.employeeWorkingDay.employeeInfo &&
      !this.employeeWorkingDay.employeeInfo.shouldHideBalance
    );
  }

  public getContentScrollState(): string {
    return this.isResumeOpened ? 'fixed' : '';
  }

  private isWriteMode(): boolean {
    return this.isRegisterMode() || this.isModifyMode();
  }

  private setCheckTimeVariables(timeRecord: TimeRecordEmitter): void {
    const { checkTimeHour, checkTimeMinutes } = timeRecord.bodyParams;

    const time = buildDateWithTime(`${checkTimeHour}:${checkTimeMinutes}`);

    if (timeRecord.isCheckIn) {
      this.employeeWorkingDay.workingDayData.checkIn = time;
    } else {
      this.employeeWorkingDay.workingDayData.checkOut = time;
    }

    this.checkTimesEdited = true;
    this.triggerWorkingDayChanges();
  }

  private decideRoute(): void {
    const route = this.employeeWorkingDayCreator.getRouteToGo(
      this.employeeWorkingDay,
      this.isWriteMode(),
      this.isStep2()
    );

    if (route && this.router.routerState.snapshot.url !== route) {
      this.router.navigate([route]);
    }
  }

  private setStep(): void {
    const pathName = WorkingDayPage.getUrl();

    const step = this.getStepByUrl(pathName);
    this.currentStep = step;
    this.wizardProgress = GLOBAL[`STEP_${step}`];
  }

  private isRegisterMode(): boolean {
    return /register$/.test(this.router.url);
  }

  private isShowMode(): boolean {
    return /show/.test(this.router.url);
  }

  private isModifyMode(): boolean {
    return /edit$/.test(this.router.url);
  }

  private getStepByUrl(url?: string): string {
    let step: '1' | '2';
    if (this.isShowMode() || this.isModifyMode()) {
      step = '2';
    } else if (this.isRegisterMode()) {
      step = '1';
    } else if (this.otherEmployeeID) {
      step = '2';
    } else {
      step = (url ? url[url.length - 1] : '1') as '1' | '2';
    }

    return step;
  }

  private setEmployee(employeeWorkingDay: EmployeeWorkingDay): void {
    this.employeeWorkingDay = employeeWorkingDay;

    const {
      rubric,
      workingDayData,
      alternativeWorkingDay,
    } = employeeWorkingDay;

    this.agreementMargin = rubric.agreementMargin;
    this.workingDayInfo = rubric.workingDayInfo;
    this.noNeedToRegisterMessage = createNoNeedToRegisterMessage(
      employeeWorkingDay
    );

    this.workingDay = this.getWorkingDayDuration();
    this.alternativeWorkingDay = alternativeWorkingDay;
    this.checkTimesEdited = false;
    this.alternativeWorkingDayChosen = workingDayData.isAlternativeChosen;
    this.workplace = workingDayData.workplace;
  }

  private setIsLoading(isLoading: boolean, isCheckin: boolean): void {
    this.isTimeEditionLoading = isLoading;

    if (isCheckin) {
      this.isCheckinLoading = isLoading;
    } else {
      this.isCheckoutLoading = isLoading;
    }
  }
}
