<ion-header *ngIf="isMobile" class="register-modal__header">
  <app-mobile-header
    (closeModal)="onDismissModal()"
    [title]="getHeaderTitle() | translate"
  ></app-mobile-header>
</ion-header>
<ion-content class="modal-content">
  <ion-buttons *ngIf="!isMobile" class="modal-content__wrapper-icon">
    <ion-icon
      class="close-icon close-icon--content"
      size="large"
      name="close"
      (click)="onDismissModal()"
    ></ion-icon>
  </ion-buttons>
  <app-success-content
    *ngIf="isSuccess"
    [data]="successData"
    [workplace]="workplace"
    (closeModal)="onDismissModal()"
    (confirm)="onConfirm()"
    [selectedDate]="selectedDay"
    [isLoading]="isLoading"
  ></app-success-content>
  <app-error-content
    *ngIf="!isSuccess"
    [excessCompensation]="excessCompensation"
    [workplaceMissing]="workplaceMissing"
    [distributedTime]="distributedTime"
    (closeModal)="onDismissModal()"
  ></app-error-content>
</ion-content>
