<ion-toolbar class="mobile-header__toolbar">
  <ion-title class="mobile-header__title">
    {{ title }}
  </ion-title>
  <ion-buttons slot="end">
    <ion-icon
      class="close-icon close-icon--header"
      size="large"
      name="close"
      (click)="onDismissModal()"
    ></ion-icon>
  </ion-buttons>
</ion-toolbar>
