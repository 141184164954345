import { DisclaimerInterface, TYPES } from '../models/disclaimer';

export const WORKING_DAY_PAGE_DISCLAIMER: DisclaimerInterface = {
  type: TYPES.INFO,
  title: 'DISCLAIMERS.STEP_1_DISCLAIMER.TITLE',
  text: 'DISCLAIMERS.STEP_1_DISCLAIMER.TEXT',
  isStep1: true,
};

export const STEP_2_PAGE_DISCLAIMER: DisclaimerInterface = {
  type: TYPES.INFO,
  text: 'DISCLAIMERS.STEP_2_DISCLAIMER.TEXT',
  isStep1: false,
};

export const HISTORICAL_WARNING_DISCLAIMER: DisclaimerInterface = {
  type: TYPES.WARNING,
  text: 'DISCLAIMERS.HISTORICAL_DISCLAIMERS.WARNING_DISCLAIMER.TEXT',
  isStep1: false,
};

export const INFO_POPUP_ERROR: DisclaimerInterface = {
  type: TYPES.WARNING,
  text: 'DISCLAIMERS.HISTORICAL_DISCLAIMERS.INFO_POPUP.TEXT',
  isStep1: false,
};

export const HISTORICAL_INFO_WITH_LINK_DISCLAIMER: DisclaimerInterface = {
  type: TYPES.INFO,
  text: 'DISCLAIMERS.HISTORICAL_DISCLAIMERS.INFO_WITH_LINK_DISCLAIMER.TEXT',
  isStep1: false,
};

export const HISTORICAL_INFO_WITHOUT_LINK_DISCLAIMER: DisclaimerInterface = {
  type: TYPES.INFO,
  text: 'DISCLAIMERS.HISTORICAL_DISCLAIMERS.INFO_WITHOUT_LINK_DISCLAIMER.TEXT',
  textFriend:
    'DISCLAIMERS.HISTORICAL_DISCLAIMERS.INFO_WITHOUT_LINK_DISCLAIMER.TEXT_FRIENDS', // remove friendsAndFamily
  isStep1: false,
  link: '/logs/employee/negative-balance-adjustment',
  linkTitle: 'enlace',
};
