<ion-header *ngIf="isMobile" class="assign-authorizer__header">
  <app-mobile-header
    class="assign-authorizer__header-mobile"
    (closeModal)="onDismissModal(false)"
    [title]="headerTitle | translate"
  ></app-mobile-header>
</ion-header>

<ion-content class="modal-content">
  <ion-buttons *ngIf="!isMobile" class="modal-content__wrapper-icon">
    <ion-icon
      class="close-icon close-icon--content"
      size="large"
      name="close"
      (click)="onDismissModal(false)"
    ></ion-icon>
  </ion-buttons>

  <div class="modal-wrapper">
    <section class="header">
      <div class="header__wrapper">
        <span class="header__title">
          {{ 'WORKING_DAY.MODAL.ASSIGN_AUTHORIZER_MODAL.TITLE' | translate }}
        </span>
        <span class="header__title">
          {{
            'WORKING_DAY.MODAL.ASSIGN_AUTHORIZER_MODAL.TITLE_MORE' | translate
          }}
        </span>
      </div>
    </section>

    <section class="data">
      <p class="data__text">
        {{ 'WORKING_DAY.MODAL.ASSIGN_AUTHORIZER_MODAL.TEXT_FIRST' | translate }}
      </p>
      <p
        class="data__text"
        [innerHTML]="
          'WORKING_DAY.MODAL.ASSIGN_AUTHORIZER_MODAL.TEXT_SECOND' | translate
        "
      ></p>
      <p class="data__text">
        {{ 'WORKING_DAY.MODAL.ASSIGN_AUTHORIZER_MODAL.TEXT_THIRD' | translate }}
      </p>
    </section>

    <section class="search">
      <span class="search__title">
        {{
          'WORKING_DAY.MODAL.ASSIGN_AUTHORIZER_MODAL.SEARCH_TITLE' | translate
        }}
      </span>
      <app-search-employee
        class="search__component"
        (selectEmployee)="onSelectAuthorizer($event)"
        (searchMethod)="setTemporalAuthorizerEmployees($event)"
        [resultData]="employeesInSearch"
      ></app-search-employee>
      <hr class="g-divider divider" />
    </section>

    <section class="button-wrapper">
      <app-button-loading
        class="button-wrapper__assign"
        [isLoading]="isLoading"
        [buttonText]="
          'WORKING_DAY.MODAL.AUTHORIZER_MODAL.BUTTON_ACCEPT' | translate
        "
        [isDisabled]="!canAssign()"
        (clickButton)="setAuthorizer()"
      ></app-button-loading>
      <ion-button
        class="button-wrapper__cancel"
        fill="clear"
        (click)="onDismissModal(false)"
      >
        {{ 'WORKING_DAY.MODAL.AUTHORIZER_MODAL.BUTTON_CANCEL' | translate }}
      </ion-button>
    </section>
  </div>
</ion-content>
