import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-authorizer-info-modal',
  templateUrl: './authorizer-info-modal.component.html',
  styleUrls: ['./authorizer-info-modal.component.scss'],
})
export class AuthorizerInfoModalComponent {
  @Input() public isMobile = false;

  public constructor(private readonly modalController: ModalController) {}

  public onDismissModal(accept: boolean): void {
    this.modalController.dismiss(accept);
  }
}
