<ion-row>
  <ion-col class="card_layout" size-xs="12" size-sm="6">
    <ion-card class="c-card">
      <app-register-time
        [isEditMode]="!isRegisterCheckInValid"
        description="REGISTER_TIME.SUCCESS_START_TIME"
        mainTitle="REGISTER_TIME.START_TIME"
        [hour]="checkInHourRegistered"
        [minute]="checkInMinuteRegistered"
        [isCheckIn]="true"
        [hasDateError]="hasCheckInTimeError"
        [dateErrorMessage]="checkInErrorMessage | translate"
        (saveTime)="saveTimeRecord($event)"
        (edit)="registerCheckInIsFinished($event)"
        (cancel)="cancelCheckIn()"
        data-test="check-in"
        [isLoading]="isCheckinLoading"
      ></app-register-time>
    </ion-card>
  </ion-col>
  <ion-col class="card_layout" size-xs="12" size-sm="6">
    <ion-card class="c-card">
      <app-register-time
        [isEditMode]="!isRegisterCheckOutValid"
        description="REGISTER_TIME.SUCCESS_END_TIME"
        mainTitle="REGISTER_TIME.END_TIME"
        [hour]="checkOutHourRegistered"
        [minute]="checkOutMinuteRegistered"
        [hasDateError]="hasCheckOutTimeError"
        [dateErrorMessage]="checkOutErrorMessage | translate"
        (saveTime)="saveTimeRecord($event)"
        (edit)="registerCheckOutIsFinished($event)"
        (cancel)="cancelCheckOut()"
        [canSave]="isRegisterCheckInValid"
        data-test="check-out"
        [isLoading]="isCheckoutLoading"
        [workingDay]="employeeWorkingDay"
      ></app-register-time>
    </ion-card>
  </ion-col>
</ion-row>
