import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TextBlock } from '@models/index';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';

@Component({
  selector: 'app-working-day-info',
  templateUrl: './working-day-info.component.html',
  styleUrls: ['./working-day-info.component.scss'],
})
export class WorkingDayInfoComponent {
  @Input() public infoAsHTML: string;
  @Input() public infoAsList: TextBlock[];
  @Input() public title: string;

  public constructor(
    private readonly modalController: ModalController,
    private readonly platformCheckerService: PlatformCheckerService
  ) {}

  public async onDismissModal(): Promise<void> {
    await this.modalController.dismiss();
  }

  public isMobile(): boolean {
    return this.platformCheckerService.isMobile();
  }
}
