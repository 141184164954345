<ion-list *ngIf="yearMonths" class="year-months-logs-list">
  <ion-item
    *ngFor="let yearMonth of yearMonths"
    (click)="toggleChosenYearMonth(yearMonth)"
    lines="none"
    class="year-months-logs-list__year-month"
  >
    <ion-grid>
      <ion-row>
        <ion-col class="year-months-logs-list__header">
          <ion-icon
            [icon]="
              yearMonth == chosenYearMonth ? 'ios-arrow-up' : 'ios-arrow-down'
            "
            class="year-months-logs-list__fold-arrow"
          ></ion-icon>
          <ion-label
            class="year-months-logs-list__title"
            id="{{ yearMonth.year + '-' + yearMonth.month + '-logs' }}"
          >
            {{ translateMonthNumber(yearMonth.month) | uppercase }}
            {{ yearMonth.year }}
            <span
              *ngIf="yearMonth.isMonthConsolidated"
              class="year-months-logs-list__title year-months-logs-list__title--consolidated"
              >{{ ' (' + ('LOGS.CONSOLIDATED' | translate) + ')' }}</span
            >
          </ion-label>
          <ion-button
            class="year-months-logs-list__download-button"
            expand="full"
            fill="clear"
            *ngIf="shouldShowDownloadMonthLogButton(yearMonth)"
            (click)="downloadYearMonth($event, yearMonth)"
          >
            <ion-icon
              class="year-months-logs-list__button"
              src="assets/icon/save-for-later.svg"
            ></ion-icon>
            {{ 'LOGS.DOWNLOAD' | translate }}
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="yearMonth && yearMonth == chosenYearMonth">
        <ion-col class="year-months-logs-list__content">
          <app-month-log
            *ngIf="!monthLogLoading"
            [dayLogs]="dayLogs"
            [readOnly]="readOnly"
            [targetEmployeeId]="!!currentEmployeeId && targetEmployeeId"
            [targetHasRemoteWork]="targetHasRemoteWork"
          ></app-month-log>
          <ion-spinner class="g-spinner" *ngIf="monthLogLoading"></ion-spinner>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>
</ion-list>
