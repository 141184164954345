<div class="c-working-day-chooser">
  <span class="c-working-day-chooser__choose-text">{{
    'WORKING_DAY.CHOOSE' | translate
  }}</span>
  <ion-radio-group
    [allowEmptySelection]="false"
    (ionChange)="onChangeHandler($event)"
    class="c-working-day-chooser__group"
  >
    <ion-row class="ion-align-items-center">
      <ion-col class="ion-align-items-center">
        <ion-radio
          class="c-working-day-chooser__option--regular-day"
          [checked]="!alternativeChecked"
          [disabled]="readOnly"
          [value]="false"
          mode="md"
        ></ion-radio>
        <span class="c-working-day-chooser__working-day-label">{{
          'WORKING_DAY.DAILY' | translate
        }}</span>
        <ion-icon
          class="c-working-day-chooser__info-icon"
          ios="ios-information-circle"
          md="md-information-circle"
          (click)="openWorkingDayInfoModal()"
        ></ion-icon>
      </ion-col>
      <ion-col class="ion-align-items-center">
        <span class="c-working-day-chooser__duration">
          {{ employeeWorkingDay.workingDayData.workingDay | humanizeDuration }}
        </span>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center">
      <ion-col class="ion-align-items-center">
        <ion-radio
          class="c-working-day-chooser__option--alternative-day"
          [checked]="alternativeChecked"
          [disabled]="readOnly"
          [value]="true"
          mode="md"
        ></ion-radio>
        <span class="c-working-day-chooser__working-day-label">
          {{ 'WORKING_DAY.ALTERNATIVE' | translate }}
        </span>
        <ion-icon
          class="c-working-day-chooser__info-icon"
          ios="ios-information-circle"
          md="md-information-circle"
          (click)="openWorkingDayInfoModal(true)"
        ></ion-icon>
      </ion-col>
      <ion-col>
        <span class="c-working-day-chooser__duration">{{
          alternativeWorkingDay.duration | humanizeDuration
        }}</span>
      </ion-col>
    </ion-row>
  </ion-radio-group>
</div>
