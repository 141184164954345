import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { GLOBAL } from '@constants/index';
import { EmployeeWorkingDay } from '@models/employee-working-day/employee-working-day';
import {
  DayRecord,
  DayRecordCheckOut,
  TimeEditEmitter,
  TimeRecordEmitter,
} from '@models/index';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';
import { DateFormatter, getCheckDayFormatted } from 'src/app/shared';

@Component({
  selector: 'app-register-time',
  templateUrl: './register-time.component.html',
  styleUrls: ['./register-time.component.scss'],
})
export class RegisterTimeComponent implements OnInit {
  @Input() public mainTitle: string;
  @Input() public actionButtonLabel = 'SAVE';
  @Input() public description: string;
  @Input() public hour: string;
  @Input() public minute: string;
  @Input() public isCheckIn = false;
  @Input() public hasDateError: boolean;
  @Input() public isEditMode: boolean;
  @Input() public shouldHideHeaderIcon = false;
  @Input() public shouldShowCancel = false;
  @Input() public dateErrorMessage: string;
  @Input() public isPastDay = false;
  @Input() public canSave = true;
  @Input() public isLoading = false;
  @Input() public workingDay: EmployeeWorkingDay;

  @Output() public saveTime = new EventEmitter<TimeRecordEmitter>();
  @Output() public edit = new EventEmitter<TimeEditEmitter>();
  @Output() public cancel = new EventEmitter<void>();

  public registerTimeForm: FormGroup;
  public hourSystem: string;
  public minuteSystem: string;
  public dateSystem: string;
  public selectedDay: string;

  private readonly maxHour = GLOBAL.MAX_HOUR;
  private readonly maxMinute = GLOBAL.MAX_MINUTE;

  private previousHour: string;
  private previousMinute: string;

  public constructor(
    private readonly platformCheckerService: PlatformCheckerService
  ) {}

  private static formatNumber(num: string): string {
    return num.length < 2 ? `0${num}`.slice(-2) : num;
  }

  public ngOnInit(): void {
    this.generateDate();
    this.registerTimeForm = new FormGroup({
      hour: new FormControl(this.hour, [
        Validators.max(Number(this.maxHour)),
        Validators.min(0),
      ]),
      minute: new FormControl(this.minute, [
        Validators.max(Number(this.maxMinute)),
        Validators.min(0),
      ]),
    });

    this.previousHour = this.hour;
    this.previousMinute = this.minute;
  }

  public generateDate(): void {
    const date = new Date();
    if (!this.hour) {
      this.hour = RegisterTimeComponent.formatNumber(
        date.getHours().toString()
      );
    }

    if (!this.minute) {
      this.minute = RegisterTimeComponent.formatNumber(
        date.getMinutes().toString()
      );
    }

    this.dateSystem = date.toISOString().slice(0, 10);
  }

  public generateDateOnSave(): void {
    const date = new Date();
    this.hourSystem = RegisterTimeComponent.formatNumber(
      date.getHours().toString()
    );
    this.minuteSystem = RegisterTimeComponent.formatNumber(
      date.getMinutes().toString()
    );
  }

  public save(): void {
    if (!this.isLoading) {
      this.generateDateOnSave();
      let bodyParams: DayRecord;
      if (this.isCheckIn) {
        bodyParams = this.createBodyCheckInParams();
      } else {
        bodyParams = this.createBodyCheckOutParams();
      }

      this.saveTime.emit({
        bodyParams,
        isCheckIn: this.isCheckIn,
      });

      this.previousHour = this.hour;
      this.previousMinute = this.minute;
    }
  }

  public isMobile(): boolean {
    return this.platformCheckerService.isMobile();
  }

  private createBodyCheckInParams(): DayRecord {
    this.selectedDay = this.workingDay
      ? moment(this.workingDay.workingDayData.date)
          .format('YYYY-MM-DD')
          .toString()
      : this.dateSystem;

    return {
      selectedDay: this.selectedDay,
      checkTimeHour: this.hour,
      checkTimeMinutes: this.minute,
      systemTimeHour: this.hourSystem,
      systemTimeMinutes: this.minuteSystem,
      forceCounter: this.isPastDay,
    };
  }

  private createBodyCheckOutParams(): DayRecordCheckOut {
    this.selectedDay = this.workingDay
      ? moment(this.workingDay.workingDayData.date)
          .format('YYYY-MM-DD')
          .toString()
      : this.dateSystem;

    const checkInTimeHour = this.workingDay.workingDayData.checkIn
      .getHours()
      .toString()
      .padStart(2, '0');

    const checkInTimeMinutes = this.workingDay.workingDayData.checkIn
      .getMinutes()
      .toString()
      .padStart(2, '0');

    const checkInDate = getCheckDayFormatted(
      moment(this.workingDay.workingDayData.date)
        .startOf('day')
        .hours(+checkInTimeHour)
        .minutes(+checkInTimeMinutes)
        .toDate(),
      this.workingDay.workingDayData.minRegisterTime
    );

    return {
      selectedDay: this.selectedDay,
      checkInTimeHour,
      checkInTimeMinutes,
      systemInTimeHour: this.hourSystem,
      systemInTimeMinutes: this.minuteSystem,
      checkTimeHour: this.hour,
      checkTimeMinutes: this.minute,
      systemTimeHour: this.hourSystem,
      systemTimeMinutes: this.minuteSystem,
      forceCounter: this.isPastDay,
      checkInDate,
    };
  }

  public cancelEdition(): void {
    this.hour = this.previousHour;
    this.minute = this.previousMinute;

    this.registerTimeForm.setValue({
      hour: this.hour,
      minute: this.minute,
    });

    this.cancel.emit();
  }

  public onChangeHour(): void {
    let { hour } = this.registerTimeForm.value;
    const isOclock = hour === '24';

    hour = isOclock ? '00' : DateFormatter.formatTime(hour, this.maxHour);
    this.registerTimeForm.get('hour').setValue(hour);
    this.hour = hour;
  }

  public onBlurHour(): void {
    const { hour } = this.registerTimeForm.value;
    this.hour = RegisterTimeComponent.formatNumber(hour);
  }

  public onChangeMinute(): void {
    let { minute } = this.registerTimeForm.value;
    const isOclock = minute === '60';

    minute = isOclock ? '00' : DateFormatter.formatTime(minute, this.maxMinute);
    this.registerTimeForm.get('minute').setValue(minute);
    this.minute = minute;
  }

  public onBlurMinute(): void {
    const { minute } = this.registerTimeForm.value;
    this.minute = RegisterTimeComponent.formatNumber(minute);
  }

  public editRegister(): void {
    this.edit.emit({
      isRegisterValid: false,
      hour: this.hour,
      minute: this.minute,
    });

    this.shouldShowCancel = true;
  }

  public registerFormInputClass(): { [key: string]: boolean } {
    return {
      item: true,
      'c-number-input': true,
      'c-number-input--error': this.hasDateError,
      'c-number-input--disabled': this.isEditMode,
    };
  }

  public registerTimeContentClass(): { [key: string]: boolean } {
    return {
      'register-time__content': true,
      'register-time__content--error': this.hasDateError,
      'register-time__content--edit': this.isEditMode,
      'register-time__content--no-edit': !this.isEditMode,
    };
  }

  public isMobileAndNoEditMode(): boolean {
    return this.isMobile() && !this.isEditMode;
  }

  public showEditButton(): boolean {
    return !(this.isMobile() || this.isEditMode);
  }
}
