<div class="head">
  <div class="filter-label" (click)="toggleFiltervisibility()">
    <ion-icon
      *ngIf="!filter.isApplied"
      name="list"
      class="filter-label__icon"
    ></ion-icon>
    <ion-icon
      *ngIf="filter.isApplied"
      src="assets/icon/filter.svg"
      class="filter-label__icon filter-label__icon--applied"
    ></ion-icon>
    <span class="filter-label__text">{{ 'FILTER.LABEL' | translate }}</span>
  </div>
  <span
    *ngIf="isMobile && filter.isApplied"
    class="filter-label__clean"
    (click)="cleanFilter()"
  >
    {{ 'FILTER.CLEAN' | translate }}
  </span>
</div>

<ion-grid>
  <ion-row [ngClass]="getFilterClasses()">
    <ion-col class="filter__control-container" size-lg="4" size-xs="12">
      <div *ngIf="isMobile" class="filter__control-name">
        {{ 'FILTER.EMPLOYEE' | translate }}
      </div>
      <app-search-employee
        #searchEmployee
        class="filter__search"
        (selectEmployee)="onSelectEmployee($event)"
        (searchMethod)="setAutorizationEmployees($event)"
        [resultData]="employeesInSearch"
      ></app-search-employee>
    </ion-col>
    <ion-col
      size-lg="2"
      size-xs="12"
      class="filter__control-container filter__control-container--date"
    >
      <div *ngIf="isMobile" class="filter__control-name">
        {{ 'FILTER.PERIOD' | translate }}
      </div>
      <app-datepicker
        #dateFrom
        [placeholder]="'DATE.FROM' | translate"
        class="filter__date filter__date--from"
        (changeDate)="onChangeDate(filterDateType.from, $event)"
      ></app-datepicker>
    </ion-col>
    <ion-col
      size-lg="2"
      size-xs="12"
      class="filter__control-container filter__control-container--date"
    >
      <app-datepicker
        #dateTo
        [placeholder]="'DATE.TO' | translate"
        class="filter__date filter__date--to"
        (changeDate)="onChangeDate(filterDateType.to, $event)"
      ></app-datepicker>
    </ion-col>
    <ion-col
      size-lg="4"
      size-xs="12"
      class="filter__control-container filter__control-container--actions"
    >
      <div *ngIf="!isMobile" class="filter__actions">
        <ion-button
          class="filter__do"
          (click)="onApplyFilter()"
          [disabled]="!canApply()"
        >
          {{ 'FILTER.APPLY' | translate }}
        </ion-button>
        <span
          *ngIf="canCleanFilter()"
          class="filter-label__text filter__control-name--clean"
          (click)="cleanFilter()"
          >{{ 'FILTER.CLEAN' | translate }}</span
        >
      </div>
    </ion-col>
    <ion-col
      *ngIf="isMobile"
      size-sm="12"
      class="filter__control-container--apply"
    >
      <ion-button
        class="filter__do"
        (click)="onApplyFilter()"
        [disabled]="!filter.canApply()"
      >
        {{ 'FILTER.APPLY' | translate }}
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
