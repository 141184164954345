<ion-content class="page">
  <ng-container *ngIf="isMobile">
    <h5 class="auth__title">
      {{ 'AUTHORIZATIONS.MOBILE_TITLE_LOG' | translate }}
    </h5>

    <p class="auth__subtitle">
      {{ 'AUTHORIZATIONS.MOBILE_SUBTITLE_LOG' | translate }}
    </p>
  </ng-container>
  <ng-container *ngIf="!isMobile">
    <app-user-greeting
      class="auth__greeting"
      [userName]="userName"
      [translationId]="'GREETING.AUTHORIZATIONS_LOG'"
    ></app-user-greeting>
  </ng-container>

  <app-authorizations-filter
    [ngClass]="getFilterComponentClass()"
    *ngIf="isFilterFeatured()"
    [isMobile]="isMobile"
    [areActionsDisabled]="isListLoading"
    (applyFilter)="onApplyFilter($event)"
  ></app-authorizations-filter>

  <ion-spinner *ngIf="isListLoading" class="g-spinner"></ion-spinner>
  <app-message
    *ngIf="shouldShowInfoMessage()"
    [message]="infoMessage"
  ></app-message>
  <ng-container *ngIf="!isMobile">
    <ion-list *ngIf="completeAuthList?.length" class="list">
      <app-authorization-item
        *ngFor="let item of completeAuthList; index as i"
        class="new-authorization__item"
        [authListItem]="item"
        [loadingIndex]="loadingIndex"
        [index]="i"
        (reject)="presentRejectDataModal(item, i)"
        (success)="presentSuccesDataModal(item, i)"
        (modify)="presentModifySuccess(item, i)"
        [isDelegatingManager]="isDelegatingManager"
      ></app-authorization-item>
    </ion-list>
  </ng-container>
  <ng-container *ngIf="isMobile">
    <ion-list *ngIf="completeAuthList?.length" class="list">
      <ion-item-sliding *ngFor="let item of completeAuthList; index as i">
        <ion-item lines="none" no-padding>
          <app-authorization-item-mobile
            class="new-authorization__item"
            [authListItem]="item"
          ></app-authorization-item-mobile>
        </ion-item>
      </ion-item-sliding>
    </ion-list>
  </ng-container>
  <ion-infinite-scroll
    [disabled]="this.page >= this.pages"
    threshold="1.25rem"
    (ionInfinite)="loadData($event)"
  >
    <ion-infinite-scroll-content [loadingText]="'LOADING' | translate">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
