<ion-content class="modal-content">
  <ion-buttons class="modal-content__wrapper-icon">
    <ion-icon
      class="close-icon close-icon--content"
      size="large"
      name="close"
      (click)="dismissModal()"
    ></ion-icon>
  </ion-buttons>
  <app-success-content
    *ngIf="!errorMessage"
    title="{{ title | translate }}"
    [data]="data"
    (closeModal)="dismissModal()"
    (confirm)="onConfirm()"
    [selectedDate]="dateSelected"
  ></app-success-content>
  <app-error-content
    *ngIf="errorMessage"
    (closeModal)="dismissModal()"
    errorMessage="{{ errorMessage | translate }}"
    title="{{ titleError | translate }}"
  ></app-error-content>
</ion-content>
