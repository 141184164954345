/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class TimeCalculationsService {
  public refAgreementWorkingDay(
    workingDay: number,
    agreementMargin: number
  ): number {
    return workingDay + agreementMargin;
  }

  public preliminaryRegistration(
    checkInTime: Date,
    checkOutTime: Date
  ): number {
    return moment(checkOutTime).diff(moment(checkInTime), 'minutes');
  }

  public preliminaryRegistrationForComputation(
    preliminaryRegistration: number,
    breakTime: number
  ): number {
    return preliminaryRegistration - breakTime;
  }

  public effectiveWorkTime(
    isExcess: boolean,
    preliminaryRegistrationComputation: number,
    recoverableTime: number
  ): number {
    return isExcess
      ? preliminaryRegistrationComputation - recoverableTime
      : preliminaryRegistrationComputation + recoverableTime;
  }

  public preliminaryDifWorkingDay(
    preliminaryRegistration: number,
    breakTime: number,
    preliminaryRegistrationComputation: number,
    workingDay: number
  ): number {
    return breakTime
      ? preliminaryRegistrationComputation - workingDay
      : preliminaryRegistration - workingDay;
  }

  public authorizedExtensionWorkingDay(
    agreementMarginAdjustment: number,
    laborReasonsTime: number
  ): number {
    return laborReasonsTime - agreementMarginAdjustment;
  }

  public effectiveWorkTimeDifference(
    effectiveWorkingTime: number,
    workingDay: number,
    agreementMarginAdjustment = 0
  ): number {
    return effectiveWorkingTime - workingDay - agreementMarginAdjustment;
  }

  public appliedMarginAgreement(
    marginAgreement: number,
    extensionTime: number
  ): number {
    return extensionTime < marginAgreement ? extensionTime : marginAgreement;
  }

  public pendingExtensionTimeToApprove(
    extensionTime: number,
    marginAgreement: number,
    agreementMarginAdjustment = 0
  ): number {
    return extensionTime <= marginAgreement
      ? 0
      : extensionTime - agreementMarginAdjustment;
  }
}
