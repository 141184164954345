import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NegativeBalanceSuccess } from '@models/negative-balance-success-modal.model';
import moment from 'moment';
import { LogsService } from 'src/app/pages/logs/services/logs.service';

@Component({
  selector: 'app-negative-balance-adjustment-modal',
  templateUrl: './negative-balance-adjustment-modal.component.html',
  styleUrls: ['./negative-balance-adjustment-modal.component.scss'],
})
export class NegativeBalanceAdjustmentModalComponent {
  @Input() public title: string;
  @Input() public titleError: string;
  @Input() public data: NegativeBalanceSuccess[];
  @Input() public dateSelected: Date;
  @Input() public compensation: number;
  @Input() public reason: string;
  @Input() public employeeId: string;
  @Input() public errorMessage: string;

  public constructor(
    private readonly modalController: ModalController,
    private readonly logsService: LogsService
  ) {}

  public dismissModal(): void {
    this.modalController.dismiss();
  }

  public onConfirm(): void {
    const dateFormat = moment(this.dateSelected).format('YYYY-MM-DD');
    this.logsService
      .updateBalanceNegativeByDay(
        dateFormat,
        this.compensation,
        this.reason,
        this.employeeId
      )
      .subscribe(
        response => {
          this.dimiss(response);
        },
        error => {
          this.dimiss(error);
        }
      );
  }

  dimiss(data) {
    this.modalController.dismiss(data);
  }
}
