<p class="mobile-subtitle">
  {{ 'LOGS.TEAM_LOGS.MOBILE_SUBTITLE' | translate }}
</p>

<ion-list lines="full">
  <ion-item *ngFor="let teamMember of teamMembers" class="item">
    <app-image
      class="member__avatar"
      src="assets/icon/default-avatar.png"
      [alt]="'ALT_IMG.AVATAR' | translate"
      (click)="loadHistoric.emit(teamMember.employeeId)"
    ></app-image>
    <div class="data">
      <p class="name" (click)="loadHistoric.emit(teamMember.employeeId)">
        {{ teamMember.fullName }}
      </p>
      <div class="balances">
        <div class="balance balance--normal">
          <p class="balance__label">
            {{ 'LOGS.TEAM_LOGS.TIME' | translate }}
          </p>
          <p class="balance__value balance__value--normal">
            {{ teamMember.currentBalance | humanizeDuration: true }}
          </p>
        </div>
        <div class="balance balance--locked" *ngIf="!!teamMember.lockedBalance">
          <p class="balance__label balance__label--locked">
            {{ 'LOGS.TEAM_LOGS.LOCKED' | translate }}
          </p>
          <p class="balance__value balance__value--locked">
            {{ teamMember.lockedBalance | humanizeDuration: true }}
          </p>
        </div>
      </div>
    </div>
  </ion-item>
</ion-list>
