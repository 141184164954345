export class YearMonth {
  private constructor(
    public readonly year: number,
    public readonly month: number,
    public readonly isMonthConsolidated?: boolean
  ) {
    //--
  }

  public static factory(
    year: number,
    month: number,
    isMonthConsolidated?: boolean
  ): YearMonth {
    return new YearMonth(year, month, isMonthConsolidated);
  }

  public getMonthFormatted(): string {
    return `0${this.month}`.slice(-2);
  }

  public static sortYearMonths(a: YearMonth, b: YearMonth): number {
    return b.year === a.year ? b.month - a.month : b.year - a.year;
  }
}
