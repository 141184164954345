import { ThreeLevelVersion } from './feature-version.model';

export class VersionPack {
  public readonly version: ThreeLevelVersion;
  public readonly features: string[];

  public constructor(version: string, features: string[]) {
    this.version = new ThreeLevelVersion(version);
    this.features = features;
  }

  public isVersionLowerOrEqualThan(version: string): boolean {
    const otherVersion = new ThreeLevelVersion(version);
    return this.version.isLowerOrEqual(otherVersion);
  }

  public hasFeature(featureName: string): boolean {
    return this.features.includes(featureName);
  }

  public static getVersionPackForFeature(
    featureName: string,
    features: VersionPack[]
  ): VersionPack {
    const versionFeaturesForFeature = features.find(
      (element: VersionPack): boolean => {
        return element.hasFeature(featureName);
      }
    );

    return versionFeaturesForFeature;
  }
}
