<table class="table">
  <caption></caption>
  <thead>
    <tr class="list-column">
      <th class="list-column__employee" scope="col">
        {{ 'LOGS.TEAM_LOGS.EMPLOYEE' | translate }}
      </th>
      <th class="list-column__time" scope="col">
        {{ 'LOGS.TEAM_LOGS.PREVIOUS_YEARS' | translate }}
      </th>
      <th class="list-column__time" scope="col">
        {{ 'LOGS.TEAM_LOGS.TIME' | translate }}
      </th>
      <th class="list-column__locked" scope="col">
        {{ 'LOGS.TEAM_LOGS.LOCKED' | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let teamMember of teamMembers" class="row">
      <td class="member" (click)="loadHistoric.emit(teamMember.employeeId)">
        <app-image
          class="member__avatar"
          src="assets/icon/default-avatar.png"
          [alt]="'ALT_IMG.AVATAR' | translate"
        ></app-image>
        <div class="member__name">
          <p class="name">
            {{ teamMember.fullName }}
          </p>
        </div>
      </td>
      <td class="data data--balance-previus">
        <p class="data__value">
          {{ teamMember.balancePreviousYears | humanizeDuration: true }}
        </p>
      </td>
      <td class="data data--balance">
        <p class="data__value">
          {{ teamMember.currentBalance | humanizeDuration: true }}
        </p>
      </td>
      <td class="data data--locked">
        <p class="data__value" *ngIf="!!teamMember.lockedBalance">
          {{ teamMember.lockedBalance | humanizeDuration: true }}
        </p>
      </td>
    </tr>
  </tbody>
</table>
