import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthorizationStatuses, FullTime } from '@models/index';
import {
  TimeInputControl,
  TimeInputData,
  TimeInputDataType,
} from '@models/time-input.model';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';
import { DateFormatter } from 'src/app/shared/utils/date-formatter/date-formatter.util';

const iconGivenSign = {
  [TimeInputDataType.Negative]: 'remove-circle-outline',
  [TimeInputDataType.Positive]: 'add-circle-outline',
};

const authorizationStatusIndicators = {
  [AuthorizationStatuses.APPROVED]: 'assets/icon/success-tick.svg',
  [AuthorizationStatuses.REJECTED]: 'assets/icon/delete.svg',
};

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
})
export class TimeInputComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public data: TimeInputData;
  @Input() public readonly initialValues: FullTime;
  @Input() public isReadOnly = false;
  @Input() public isLast = false;
  @Input() public timeInputClass: string;
  @Input() public isBBVACompany: boolean;
  @Output() public changeTime = new EventEmitter<number>();

  public control: FormGroup;
  public timeInputControls: TimeInputControl[];

  private readonly ngUnsubscribe = new Subject<void>();
  private previousValues: FullTime = {
    hours: '',
    minutes: '',
  };

  public constructor(
    private readonly platformCheckerService: PlatformCheckerService
  ) {
    const isMobile = this.platformCheckerService.isMobile();
    this.timeInputControls = [
      {
        id: 'hours',
        label: isMobile ? 'Hrs' : 'h',
      },
      {
        id: 'minutes',
        label: isMobile ? 'Min' : 'min',
      },
    ];
  }

  public ngOnChanges(changes): void {
    if (changes.initialValues) {
      const { currentValue, previousValue } = changes.initialValues;

      if (
        !previousValue ||
        currentValue.hours !== previousValue.hours ||
        currentValue.minutes !== previousValue.minutes
      ) {
        this.pipeFormValues(this.initialValues);
      }
    }
  }

  public ngOnInit(): void {
    this.createFormGroup();
    this.pipeFormValues(this.initialValues);
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public getIconGivenType(): string {
    return iconGivenSign[this.data.sumSign];
  }

  public hasSignIcon(): boolean {
    return (
      this.data.sumSign === TimeInputDataType.Negative ||
      this.data.sumSign === TimeInputDataType.Positive
    );
  }

  public onBlurNumber(formName: string): void {
    const { value } = this.control.get(formName);
    const newValue = DateFormatter.formatTime(value, '60');
    this.control.get(formName).setValue(newValue);
  }

  private createFormGroup(): void {
    const validators = [
      Validators.maxLength(3),
      Validators.pattern('[0-9]*'),
      Validators.max(59),
      Validators.min(0),
    ];

    const initialHours = this.initialValues ? this.initialValues.hours : '';
    const initialMinutes = this.initialValues ? this.initialValues.minutes : '';

    this.control = new FormBuilder().group({
      hours: new FormControl(initialHours, validators),
      minutes: new FormControl(initialMinutes, validators),
    });

    this.control.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((newValues: FullTime): void => {
        this.pipeFormValues(newValues);
        this.emitNewValues();
      });
  }

  private pipeFormValues(newValues: FullTime): void {
    if (!this.control) {
      return;
    }

    if (!this.control.valid || !newValues) {
      this.control.setValue(this.previousValues, { emitEvent: false });
      return;
    }

    this.control.setValue(newValues, { emitEvent: false });

    this.previousValues = newValues;
  }

  public emitNewValues(): void {
    const durationInMinutes = moment
      .duration(+this.control.value.hours, 'hours')
      .add(+this.control.value.minutes, 'minutes')
      .asMinutes();

    this.changeTime.emit(durationInMinutes);
  }

  public getTimeInputInputCssClasses(): string[] {
    return this.isReadOnly
      ? ['time-input__input', 'c-number-input', 'time-input__input--read-only']
      : ['time-input__input', 'c-number-input'];
  }

  public isAuthorizationReviewed(): boolean {
    return (
      this.data.authorizationStatus === AuthorizationStatuses.APPROVED ||
      this.data.authorizationStatus === AuthorizationStatuses.REJECTED
    );
  }

  public getAuthStatusIndicatorIconURL(): string {
    return authorizationStatusIndicators[this.data.authorizationStatus];
  }

  public getAuthStatusIndicatorClass(): string {
    const authStatusIndicatorClasses = {
      [AuthorizationStatuses.APPROVED]:
        'authorization-status-indicator--approved',
      [AuthorizationStatuses.REJECTED]:
        'authorization-status-indicator--rejected',
    };
    return `authorization-status-indicator ${
      authStatusIndicatorClasses[this.data.authorizationStatus]
    }`;
  }
}
