import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-register-time-header',
  templateUrl: './register-time-header.component.html',
  styleUrls: ['./register-time-header.component.scss'],
})
export class RegisterTimeHeaderComponent {
  @Input() public isEditMode: boolean;
  @Input() public isMobile: boolean;
  @Input() public title: string;
  @Input() public description: string;
  @Input() public hour: string;
  @Input() public minute: string;
  @Output() public edit = new EventEmitter<void>();

  public get fullTime(): string {
    return `${this.hour}:${this.minute}`;
  }

  public editRegister(): void {
    this.edit.emit();
  }

  public getTitle(): string {
    return !this.isEditMode && this.isMobile ? this.title : this.description;
  }

  public getHeaderClass(): { [key: string]: boolean } {
    return {
      header: true,
      'header--edit': this.isEditMode,
      'header--no-edit': !this.isEditMode,
    };
  }
}
