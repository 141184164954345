import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { RegisterTimeSucess } from '@models/index';
import { DateFormatter } from 'src/app/shared';

@Component({
  selector: 'app-success-content',
  templateUrl: './success-content.component.html',
  styleUrls: ['./success-content.component.scss'],
})
export class SuccessContentComponent implements OnInit {
  @Input() public data: RegisterTimeSucess[];
  @Input() public selectedDate: Date;
  @Input() public isLoading = false;
  @Input() public workplace: string;
  @Input() public title: string;

  @Output() public closeModal = new EventEmitter<void>();
  @Output() public confirm = new EventEmitter<void>();

  public dateString: string;

  public ngOnInit(): void {
    this.dateString = DateFormatter.getDay(
      moment(this.selectedDate).format('YYYY-MM-DD')
    );
  }

  public dismissModal(): void {
    this.closeModal.emit();
  }

  public confirmRegister(): void {
    this.confirm.emit();
  }

  public getTitle() {
    return this.title ? this.title : 'REGISTER_TIME.MODAL_SUCCESS.TITLE';
  }
}
