import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { MenuController, NavController, Platform } from '@ionic/angular';
import { filter } from 'rxjs/operators';
import { TabsInterface } from '@models/index';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { FeatureFlagService } from '@services/index';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';
import { StorageService } from '@services/storage/storage.service';
import { environment } from 'src/environments/environment';

declare const cordova: any;

export interface BBVALogout {
  BBVALogin: {
    configuration(
      env: string,
      success: (success: string) => void,
      error: (error: { code: number }) => void
    );
    requestLogout(
      success: (success: string) => void,
      jsonError: (jsonError: { code: number }) => void
    ): void;
  };
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnChanges, OnInit {
  @Input() public hasClosingMessage: boolean;
  @Input() public links: TabsInterface[] = [];

  public tabTitle: string;
  public isCordova = false;
  public isMobile = false;
  public version: string;
  public isFeatureMenuBadgesAvailable = false;

  private currentUrl: string;

  public constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly menuController: MenuController,
    private readonly platformCheckerService: PlatformCheckerService,
    private readonly appVersion: AppVersion,
    private readonly platform: Platform,
    private readonly storage: StorageService,
    private readonly navController: NavController,
    private readonly featureFlagService: FeatureFlagService
  ) {
    this.isMobile = this.platformCheckerService.isMobile();
    this.isCordova = this.platformCheckerService.isCordova();
    this.isFeatureMenuBadgesAvailable = this.featureFlagService.isFeatureAvailable(
      'MENU_BADGES'
    );

    if (this.isCordova) {
      this.platform.ready().then((): void => {
        this.appVersion.getVersionNumber().then((version): void => {
          this.version = version;
        });
      });
    }

    this.router.events
      .pipe(
        filter((event): boolean => {
          return (
            event instanceof NavigationEnd || event instanceof NavigationCancel
          );
        })
      )
      .subscribe((): void => {
        this.checkForLinks();
      });
  }

  public ngOnInit(): void {
    const currentUrl = this.links
      ? this.links.find((link): boolean => this.isSelectedLink(link))
      : '';

    this.currentUrl = currentUrl ? currentUrl.url : '';
  }

  public ngOnChanges(): void {
    this.checkForLinks();
  }

  public shouldShowMenu(): boolean {
    return (
      this.isMobile &&
      this.isUserAuthenticated() &&
      !this.hasClosingMessage &&
      !MenuComponent.isErrorVersion()
    );
  }

  public static isErrorVersion(): boolean {
    return window.location.pathname.indexOf('/error-version') > -1;
  }

  public shouldShowUnconfirmedDays(link: TabsInterface): boolean {
    return link.unconfirmedDaysCounter && this.isFeatureMenuBadgesAvailable;
  }

  public shouldShowWarningSign(link: TabsInterface): boolean {
    return link.shouldShowWarningSign && this.isFeatureMenuBadgesAvailable;
  }

  public navigateToRoot(): void {
    this.navController.navigateRoot(['/']);
    window.location.href = '/';
  }

  public async navigateTo(url: string): Promise<void> {
    if (!this.currentUrl || url !== this.currentUrl) {
      this.menuController.close();

      await this.router.navigate([url]);
      this.currentUrl = url;
    }
  }

  public isUserAuthenticated(): boolean {
    return this.authenticationService.isAuthenticated();
  }

  public closeSession(): void {
    if (environment.production) {
      this.platform.ready().then((): void => {
        (cordova.plugins as BBVALogout).BBVALogin.configuration(
          'PROD',
          (): void => {
            (cordova.plugins as BBVALogout).BBVALogin.requestLogout(
              (): void => {
                this.resetStatus();
              },
              (jsonError): void => {
                throw new Error(
                  `Error requesting BBVALogin code ${jsonError.code.toString()}`
                );
              }
            );
          },
          (error): void => {
            throw new Error(
              `Error in BBVALogin configuration code ${error.code.toString()}`
            );
          }
        );
      });
    } else {
      this.resetStatus();
    }
  }

  private checkForLinks(): void {
    if (this.links) {
      this.links.forEach((link: TabsInterface): void => {
        if (this.isSelectedLink(link)) {
          this.tabTitle = link.title;
        }
      });
    }
  }

  private isSelectedLink(link: TabsInterface): boolean {
    return this.router.url.startsWith(`/${link.url}`);
  }

  private resetStatus(): void {
    localStorage.clear();
    this.storage.clearAll();
    this.navigateToRoot();
  }
}
