<ion-content class="page" data-test="working-day-page">
  <div
    [slot]="getContentScrollState()"
    class="backdrop"
    *ngIf="isResumeOpened"
  ></div>
  <ion-grid [slot]="getContentScrollState()" class="working-day">
    <ion-row *ngIf="pastDay">
      <ion-col>
        <a
          [routerLink]="getBackLink()"
          class="working-day__back-to-historic"
          data-test="histoy-back-button"
        >
          <ion-icon name="md-arrow-back"></ion-icon>
          <span *ngIf="!otherEmployeeID">
            {{ 'LOGS.BACK_TO_LOGS' | translate }}
          </span>
          <app-member-avatar
            *ngIf="otherEmployeeID"
            [userName]="getFullEmployeeName()"
          ></app-member-avatar>
        </a>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-spinner class="top-spinner" *ngIf="isEmployeeLoading"></ion-spinner>

      <ng-container *ngIf="!isEmployeeLoading">
        <ion-col
          *ngIf="!pastDay && !isMobile"
          class="working-day__greeting"
          size-sm="5"
        >
          <app-user-greeting
            *ngIf="employeeWorkingDay?.employeeInfo"
            [userName]="employeeWorkingDay?.employeeInfo?.firstName"
            translationId="GREETING.WORKING_DAY"
          ></app-user-greeting>
        </ion-col>

        <ion-col
          *ngIf="shouldShowBalance()"
          size-sm="7"
          size-xs="12"
          [offset]="getBalanceOffset()"
        >
          <ion-row>
            <ion-col
              class="balance_layout"
              size-sm="6"
              size-xs="12"
            >
              <app-balance-previous-years
                *ngIf="shouldShowCurrentBalance()"
                [value]="employeeWorkingDay?.employeeInfo?.balancePreviousYears"
              ></app-balance-previous-years>
            </ion-col>
            <ion-col
              class="balance_layout"
              size-sm="6"
              size-xs="12"
            >
            <app-current-balance
                  class="current-balance"
                  *ngIf="shouldShowCurrentBalance()"
                  [value]="employeeWorkingDay.employeeInfo.currentBalance"
                  [lockedValue]="employeeWorkingDay.employeeInfo.lockedBalance"
                ></app-current-balance>
            </ion-col>
          </ion-row>
        </ion-col>
      </ng-container>
    </ion-row>
    <ion-row *ngIf="shouldShowWizard()">
      <ion-col class="working-day__wizard">
        <app-wizard [progress]="wizardProgress"></app-wizard>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="shouldShowSuccessMessage()">
      <ion-col><app-success-message></app-success-message></ion-col>
    </ion-row>
    <ion-row
      *ngIf="
        employeeWorkingDay?.workingDayData.isConfirmed && !pastDay && !isMobile
      "
    >
      <ion-col>
        <h3 class="working-day__detail-title">
          {{ 'WORKING_DAY.CONFIRMATION.DETAIL' | translate }}
        </h3>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="pastDay && isReadOnly()">
      <ion-col>
        <h3 class="working-day__detail-title">
          {{ 'LOGS.WORKING_DAY_DETAIL' | translate }}
        </h3>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col
        class="working-day__main-column card-layout card-layout--resume"
        size-xs="12"
        size-sm="4"
      >
        <ion-card
          class="c-card card__resume"
          [ngClass]="getMobileClassToCardResume()"
        >
          <div class="spin-wrapper" *ngIf="isEmployeeLoading">
            <ion-spinner class="spin-wrapper__spin"></ion-spinner>
          </div>
          <app-wd-resume
            *ngIf="!isEmployeeLoading && employeeWorkingDay"
            [alternativeWorkingDay]="alternativeWorkingDay"
            [alternativeWorkingDayChosen]="alternativeWorkingDayChosen"
            [employeeWorkingDay]="employeeWorkingDay"
            [rubric]="employeeWorkingDay.rubric"
            [isStep2]="wizardProgress === valueForStep2"
            [isReadOnly]="isReadOnly()"
            [isPastDay]="!!this.pastDay"
            [isRegisterMode]="registerMode"
            [hasPermissions]="!noNeedToRegisterMessage"
            [loadingChunks]="{checkIn: isCheckinLoading, checkOut: isCheckoutLoading}"
            [workLocation]="workLocation"
            (floating)="openFloatingResume($event)"
            (saveCheckTimesEdition)="onCheckTimesEdited($event)"
            (alternativeWorkingDayChange)="
              alternativeWorkingDayChangeHandler($event)
            "
            (workplaceSelected)="workplaceSelectedHandler($event)"
            data-test="resume"
            [hasRemoteWork]="hasRemoteWork()"
          ></app-wd-resume>
          <ion-spinner
            class="resume-spin"
            *ngIf="!employeeWorkingDay"
          ></ion-spinner>
        </ion-card>
      </ion-col>
      <ion-col *ngIf="noNeedToRegisterMessage" size-xs="12" size-sm="8">
        <ion-card class="c-card absence-day">
          <app-image
            src="assets/icon/success-tick.svg"
            [alt]="'ALT_IMG.SUCCESS_TICK' | translate"
            class="absence-day__image"
          ></app-image>
          <h1
            class="absence-day__text"
            [innerHTML]="'WORKING_DAY.' + noNeedToRegisterMessage | translate"
          ></h1>
        </ion-card>
      </ion-col>
      <ion-col *ngIf="isMobile && hasRemoteWork() && isStep2()">
        <ion-card class="c-card">
          <ion-row>
            <ion-col><b>{{ 'WORKING_DAY.WORKPLACE' | translate }}</b></ion-col>
            <ion-col>
              <app-workplace
              [selectionDisabled]="isReadOnly()"
              (newItemEvent)="workplaceSelectedHandler($event)"
              [workLocation]="workLocation"
            ></app-workplace>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-col>

      <ion-col
        *ngIf="!noNeedToRegisterMessage"
        class="card-layout step-content"
        size-xs="12"
        size-sm="8"
      >
        <div class="spin-wrapper" *ngIf="isPanelLoading()">
          <ion-spinner class="spin-wrapper__spin"></ion-spinner>
        </div>
        <ng-container *ngIf="!isPanelLoading() && employeeWorkingDay">
          <app-working-day-step-1
            *ngIf="isStep1()"
            (setCheckTimes)="sendCheckTimes($event)"
            data-test="step-1-component"
            [isCheckinLoading]="isCheckinLoading"
            [isCheckoutLoading]="isCheckoutLoading"
            [employeeWorkingDay]="employeeWorkingDay"
          ></app-working-day-step-1>
          <app-root
            *ngIf="isStep2()"
            [employeeWorkingDay]="employeeWorkingDay"
            [alternativeWorkingDayChosen]="alternativeWorkingDayChosen"
            [alternativeWorkingDay]="alternativeWorkingDay"
            [isReadonly]="isReadOnly()"
            [isMobile]="isMobile"
            [checkTimesEdited]="checkTimesEdited"
            (confirmWorkingDay)="onWorkingDayConfirm()"
          ></app-root>
        </ng-container>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="isTemporalAuthorizerAvailable">
      <ion-col class="card-layout" size-xs="12" size-sm="4">
        <ion-card class="c-card  card-authorizer">
          <app-authorizer
            *ngIf="employeeWorkingDay?.employeeInfo"
            [authorizer]="employeeWorkingDay?.employeeInfo?.authorizer"
            [isMobile]="isMobile"
            [employeeId]="employeeWorkingDay.employeeInfo.id"
          ></app-authorizer>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="isStep2()">
      <ion-col class="card-layout" size-xs="12" size-sm="4">
        <app-agreement-margin-resume
          [workingDay]="workingDay"
          [agreementMargin]="agreementMargin"
        ></app-agreement-margin-resume>
      </ion-col>
      <ion-col class="card-layout disclaimer__step-2" size-xs="12" size-sm="8">
        <app-disclaimer [disclaimer]="step2Disclaimer"></app-disclaimer>
      </ion-col>
    </ion-row>
    <ion-row class="disclaimer" *ngIf="isStep1()">
      <ion-col>
        <app-disclaimer [disclaimer]="disclaimer"></app-disclaimer>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
