import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RequestCacheService {
  public cache = new Map();
  public maxAge = 1000;

  public get(
    req: HttpRequest<any>
  ): { url: string; lastRead: number; method: string } {
    const KEY = this.getKey(req);
    const cached = this.cache.get(KEY);

    if (cached) {
      const isExpired = cached.lastRead < Date.now() - this.maxAge;
      if (isExpired) {
        this.cache.delete(KEY);
        return undefined;
      }
    }

    return cached;
  }

  public put(req: HttpRequest<any>): void {
    const KEY = this.getKey(req);
    const entry = {
      url: req.urlWithParams,
      lastRead: Date.now(),
      method: req.method,
    };
    this.cache.set(KEY, entry);
  }

  // eslint-disable-next-line class-methods-use-this
  private getKey(req: HttpRequest<any>): string {
    return `${req.method}#${req.urlWithParams}`;
  }
}
