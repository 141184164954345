<ion-content>
  <div class="error">
    <ion-icon class="error__icon" name="warning"></ion-icon>
    <span class="error__text" id="error_textHeader">
      {{ 'ERROR_VERSION.ERROR_HEADER' | translate }}
    </span>
  </div>
  <div class="error">
    <span class="error__text" id="error_textCorporate">
      {{ 'ERROR_VERSION.CORPORATE' | translate }}
    </span>
  </div>
  <div class="button">
    <ion-button class="error__button-home" href="{{ android }}">
      <a id="androidText" class="textButton">{{
        'ERROR_VERSION.ANDROIDCORPORATE' | translate
      }}</a>
    </ion-button>
  </div>
  <div class="error">
    <span class="error__text" id="error_textPersonal">
      {{ 'ERROR_VERSION.PERSONAL' | translate }}
    </span>
  </div>
  <div class="button">
    <ion-button class="error__button-home" href="{{ androidPersonal }}">
      <a id="androidText" class="textButton">{{
        'ERROR_VERSION.ANDROIDPERSONAL' | translate
      }}</a>
    </ion-button>
  </div>
</ion-content>