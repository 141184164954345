<ion-toolbar slot="top" *ngIf="shouldDisplayTabs()" class="toolbar">
  <div class="toolbar__tabs">
    <div
      *ngFor="let tab of renderTabs"
      class="tab-wrapper"
      [class.tab-wrapper--selected]="tab === currentTab"
    >
      <a class="tab" (click)="navigateToTabUrl(tab)">
        <ion-label class="tab__title"
          >{{ tab.title | translate }}

          <div
            id="badge_log"
            class="tab__badge"
            mode="md"
            *ngIf="tab.unconfirmedDaysCounter"
          >
            {{ tab.unconfirmedDaysCounter }}
          </div>
          <app-image
            *ngIf="tab.shouldShowWarningSign"
            id="badge_delegations"
            class="tab__delegations-icon"
            src="assets/icon/warning.svg"
          ></app-image>
        </ion-label>
      </a>
    </div>
  </div>
</ion-toolbar>
