import moment from 'moment';
import { AuthorizationStatus } from '@models/index';

export function hasMinimumBreakTime(
  checkIn: Date,
  checkOut: Date,
  skipBreakTimeLimit: number
): boolean {
  let checkOutFinal = moment(checkOut);
  if (checkOutFinal.isBefore(moment(checkIn), 'minutes')) {
    checkOutFinal = moment(checkOut).add('24', 'hours');
  }
  const checkTimeDifference = checkOutFinal.diff(checkIn, 'minutes');

  return checkTimeDifference > skipBreakTimeLimit;
}

export interface TimeCalulationsInterface {
  getPreliminaryRegister(): number;
  getPreliminaryTimeToComputed(): number;
  getPreliminaryDifWorkingDay(): number;
  getEffectiveWorkingTime(
    personalOrAssimilatedTime: number,
    workingDayExtensionTime: number,
    extensionStatus: AuthorizationStatus
  ): number;
  getDifferenceToCompensate(
    workingDayExtensionTime: number,
    extensionStatus: AuthorizationStatus
  ): number;
  getAppliedMarginAgreement(workingDayExtensionTime: number): number;
  getPendingExtensionTimeToApprove(workingDayExtensionTime: number): number;
  getBypassedMinimumBreakTime(): number;
}
