<section class="success__content">
  <app-image
    src="assets/icon/help.svg"
    alt="ALT_IMG.HELP | translate"
  ></app-image>
  <h3 class="success__title">
    {{ getTitle() | translate }}
  </h3>
  <p class="success__date">{{ dateString }}</p>
  <div class="modal-data">
    <ng-container *ngFor="let item of data">
      <p>
        <span class="modal-data__name">
          <strong>{{ item.name | translate }}</strong>
        </span>
        <span class="modal-data__time" data-test="modal-data-item-value">{{
          item.time | humanizeDuration
        }}</span>
      </p>
      <p class="nopadding-top" *ngIf="item.info">
        <span class="modal-data__info">
          {{ item.info | translate }}
        </span>
      </p>
    </ng-container>
  </div>
  <div class="button-wrapper">
    <app-button-loading
      class="button-wrapper__confirm"
      [isLoading]="isLoading"
      [buttonText]="'REGISTER_TIME.MODAL_SUCCESS.CONFIRM' | translate"
      (clickButton)="confirmRegister()"
    ></app-button-loading>
    <ion-button
      class="button-wrapper__cancel"
      fill="clear"
      (click)="dismissModal()"
      [disabled]="isLoading"
    >
      {{ 'REGISTER_TIME.MODAL_SUCCESS.CANCEL' | translate }}
    </ion-button>
  </div>
</section>
