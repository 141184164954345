import { environment } from 'src/environments/environment';

export const API_ROUTE = {
  MIN_VERSION: `${environment.apiUrl}/min-version`,
  AUTHENTICATION: `${environment.apiUrl}/login`,
  EMPLOYEE: `${environment.apiUrl}/employee-info/`,
  OTHER_EMPLOYEE: (employeeId: string, managerId: string): string =>
    `${environment.apiUrl}/employee-info/manager/${managerId}/${employeeId}`,
  LOGS: {
    EMPLOYEE_AVAILABLE_YEAR_MONTHS: `${environment.apiUrl}/historic/months/`,
    EMPLOYEE_OTHER_AVAILABLE_YEAR_MONTHS: (
      employeeId: string,
      managerId: string
    ): string =>
      `${environment.apiUrl}/historic/manager/${managerId}/months/${employeeId}`,
    EMPLOYEE_MONTH_LOGS: `${environment.apiUrl}/historic/`,
    EMPLOYEE_OTHER_MONTH_LOGS: (
      employeeId: string,
      managerId: string
    ): string =>
      `${environment.apiUrl}/historic/manager/${managerId}/${employeeId}`,
    MANAGER_TEAM_MEMBERS: (managerId: string): string =>
      `${environment.apiUrl}/historic/manager/${managerId}/team`,
    UPDATE_BALANCE_COMPENSATION: (employeeId: string): string =>
      `${environment.apiUrl}/employee-info/compensation/${employeeId}`,
  },
  DAY_RECORD: {
    CHECK_IN: `${environment.apiUrl}/employee-info/day-record/check-in/`,
    CHECK_OUT: `${environment.apiUrl}/employee-info/day-record/check-out/`,
    CONFIRMATION: `${environment.apiUrl}/employee-info/day-record/confirmation/`,
    DETAIL(employeeId: string): string {
      return `${environment.apiUrl}/historic/day-record-detail/${employeeId}`;
    },
    OTHER_DETAIL(employeeId: string, managerId: string): string {
      return `${environment.apiUrl}/historic/manager/${managerId}/day-record-detail/${employeeId}`;
    },
  },
  MESSAGE_STATICS: `${environment.apiUrl}/messages-statics`,
  EQUALITY_PLAN: `${environment.apiUrl}/equality-plan`,
  AUTHORIZATIONS: {
    REASONS_REJECTION: `${environment.apiUrl}/reasons-rejection`,
    HISTORIC(idManager: string): string {
      return `${environment.apiUrl}/authorizations/manager/historic/${idManager}`;
    },
    AUTORIZATION_EMPLOYEES(managerId: string): string {
      return `${environment.apiUrl}/team-manager/${managerId}`;
    },
  },
  DELEGATIONS: {
    GET_DELEGATIONS(id: string): string {
      return `${environment.apiUrl}/delegations/${id}`;
    },
    CREATE_DELEGATION(id: string): string {
      return `${environment.apiUrl}/delegations/${id}`;
    },
    DELEGABLE_EMPLOYEES(id: string): string {
      return `${environment.apiUrl}/delegations/load-delegable-employees/${id}`;
    },
    DELETE_DELEGATION(id: string): string {
      return `${environment.apiUrl}/delegations/${id}`;
    },
    UPDATE_DELEGATION(id: string): string {
      return `${environment.apiUrl}/delegations/${id}`;
    },
    LOAD_TEMPORAL_AUTHORIZER_EMPLOYEES(id: string): string {
      return `${environment.apiUrl}/delegations/load-temporal-authorizers/${id}`;
    },
    TEMPORAL_AUTHORIZER_EMPLOYEES(id: string): string {
      return `${environment.apiUrl}/delegations/temporal-authorizers/${id}`;
    },
  },
  DOWNLOADHISTORIC: {
    INTERNAL:
      'https://portalintranet.es.igrupobbva/EHRA/ehra_mult_web_ehragestor_02/controlJornadaController',
    EXTERNAL:
      'https://portalintranet.grupobbva.com/EHRA/ehra_mult_web_ehragestor_02/controlJornadaController',
  },
  JNE_DOCUMENT: {
    INTERNAL:
      'https://portalintranet.es.igrupobbva/EHRA/ehra_mult_web_ehragestor_02/reduccionJornadaController',
    EXTERNAL:
      'https://portalintranet.grupobbva.com/EHRA/ehra_mult_web_ehragestor_02/reduccionJornadaController',
  },
  ERROR_VERSION: {
    ANDROID:
      'https://play.google.com/store/apps/details?id=com.bbva.rrhh.controldejornada',
    ANDROID_PERSONAL:
      'https://public-dot-bbva-mobile-center.appspot.com/install?idApp=g8kCP824n097DmoLIY7F&idVersion=LastPublished',
  },
  WORKPLACES: `${environment.apiUrl}/work-locations/`,
};

export const SOCKET_ROUTE = {
  CHECK_IN: (employeeId: string): string =>
    `${environment.socketUrl}/ws/check-in/${employeeId}`,
  CHECK_OUT: (employeeId: string): string =>
    `${environment.socketUrl}/ws/check-out/${employeeId}`,
  CONFIRM: (employeeId: string): string =>
    `${environment.socketUrl}/ws/confirm/${employeeId}`,
};
