import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ROUTE } from '@constants/routes.constants';
import { ErrorVersionComponent } from './error-version.component';

const routes: Routes = [
  {
    path: ROUTE.ERROR_VERSION,
    component: ErrorVersionComponent,
  },
];
@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
  ],
  declarations: [ErrorVersionComponent],
})
export class ErrorVersionModule {}
