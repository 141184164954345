import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { EmployeeService } from '@services/index';

@Injectable()
export class AuthGuard implements CanActivate {
  public constructor(private employeeService: EmployeeService) {}

  // eslint-disable-next-line class-methods-use-this
  public canActivate(): Observable<boolean> {
    return this.employeeService.employee$.pipe(
      filter((employee): boolean => !!employee),
      map((employee): boolean => !!employee)
    );
  }
}
