import moment from 'moment';
import { checkProperty } from '../../../shared/utils/validations';

export interface SingleDelegateEmployee {
  employeeId: string;
  name: string;
}

export interface DelegationInterfaceServer {
  employeeId: string;
  name: string;
  image: string;
  initialDate: Date;
  finalDate: Date;
  singleEmployees: SingleDelegateEmployee[];
}

export interface DelegationInterface {
  delegatedEmployeeId: string;
  fullName: string;
  image: string;
  initialDate: Date;
  finalDate: Date;
  singleEmployees: SingleDelegateEmployee[];
}

export interface CreateDelegationInterface {
  startDate: string;
  endDate: string;
  employeeId: string;
  singleEmployees: string[];
}

export interface AssignTemporalAuthorizerInterface {
  idAuthorizer: string;
}

export interface DeleteDelegationInterface {
  startDate: string;
  employeeId: string;
  endDate?: string;
  delegationType?: 'I' | 'G';
}

export interface EditDelegation {
  addedEmployees: SingleDelegateEmployee[];
  deletedEmployees: SingleDelegateEmployee[];
}

export interface ErrorDelegation {
  errorCode: string;
  errorMessage: string;
}

export function getDelegationType(delegation: DelegationInterface): 'I' | 'G' {
  return delegation.singleEmployees && delegation.singleEmployees.length > 0
    ? 'I'
    : 'G';
}

export function isIndividual(delegation: DelegationInterface): boolean {
  const { singleEmployees } = delegation;
  return singleEmployees && singleEmployees.length > 0;
}

export function isActiveDelegation(delegation: DelegationInterface): boolean {
  return moment(delegation.initialDate).isSameOrBefore(moment(), 'days');
}

export function validateRawDelegationData(
  data: any,
  isSingleDelegationAvailable: boolean
): void {
  const delegationRequiredProperties = [
    { name: 'employeeId', nullable: false },
    { name: 'name', nullable: true },
  ];

  if (isSingleDelegationAvailable) {
    delegationRequiredProperties.push({
      name: 'singleEmployees',
      nullable: true,
    });
  }

  delegationRequiredProperties.forEach((field: any): void => {
    checkProperty(data, 'Delegation', field);
  });
}

function delegationListBuilder(
  delegationsList: DelegationInterfaceServer[],
  isSingleDelegationAvailable: boolean
): DelegationInterface[] {
  return delegationsList.map(
    (delegation): DelegationInterface => ({
      delegatedEmployeeId: delegation.employeeId,
      fullName: delegation.name,
      image: delegation.image,
      initialDate: moment(delegation.initialDate, 'YYYY-MM-DD').toDate(),
      finalDate: moment(delegation.finalDate, 'YYYY-MM-DD').toDate(),
      singleEmployees: isSingleDelegationAvailable
        ? delegation.singleEmployees
        : [],
    })
  );
}

export function delegationsBuilder(
  data: any[],
  isSingleDelegationAvailable: boolean
): DelegationInterface[] {
  data.forEach((dataElement): void =>
    validateRawDelegationData(dataElement, isSingleDelegationAvailable)
  );

  return delegationListBuilder(data, isSingleDelegationAvailable);
}
