import { Component, Input } from '@angular/core';
import { CSS_MAP, STATES_MAP } from '@constants/autorizations.constants';
import { AuthInterface } from '@models/authorizations';
import { DateFormatter } from 'src/app/shared';

@Component({
  selector: 'app-authorization-item-mobile',
  templateUrl: './authorization-item-mobile.component.html',
  styleUrls: ['./authorization-item-mobile.component.scss'],
})
export class AuthorizationItemMobileComponent {
  @Input() public authListItem: AuthInterface;
  @Input() public isDelegatingManager: boolean;

  public isSelected = false;

  // eslint-disable-next-line class-methods-use-this
  public formatDate(date: string): string {
    return DateFormatter.getDay(date);
  }

  public getStateText(): string {
    return (
      'AUTHORIZATIONS.AUTHORIZATIONS_LOG.' +
      `${STATES_MAP[this.authListItem.state]}`
    );
  }

  public getDotClass(): string {
    return CSS_MAP[this.authListItem.state];
  }
}
