<ion-header *ngIf="isMobile" class="unassign__header">
  <app-mobile-header
    class="unassign__header-mobile"
    (closeModal)="onDismissModal(false)"
    [title]="headerTitle | translate"
  ></app-mobile-header>
</ion-header>

<ion-content class="modal-content">
  <ion-buttons *ngIf="!isMobile" class="modal-content__wrapper-icon">
    <ion-icon
      class="close-icon close-icon--content"
      size="large"
      name="close"
      (click)="onDismissModal(false)"
    ></ion-icon>
  </ion-buttons>

  <div class="modal-wrapper">
    <section class="header">
      <span class="header__title">
        {{ 'WORKING_DAY.MODAL.UNASSING_AUTHORIZER_MODAL.TITLE' | translate }}
      </span>
    </section>

    <section class="data">
      <span class="data__text">
        {{ 'WORKING_DAY.MODAL.UNASSING_AUTHORIZER_MODAL.TEXT' | translate }}
      </span>

      <p class="data__authorizer-name">
        {{ getAuthorizerFullName() }}
      </p>
    </section>

    <section class="button-wrapper">
      <ion-button
        class="button-wrapper__confirm"
        (click)="UnassignAuthorizer()"
      >
        {{
          'WORKING_DAY.MODAL.UNASSING_AUTHORIZER_MODAL.BUTTON_ACCEPT'
            | translate
        }}
      </ion-button>
      <ion-button
        class="button-wrapper__cancel"
        fill="clear"
        (click)="onDismissModal(false)"
      >
        {{
          'WORKING_DAY.MODAL.UNASSING_AUTHORIZER_MODAL.BUTTON_CANCEL'
            | translate
        }}
      </ion-button>
    </section>
  </div>
</ion-content>
