import { Rubric, WorkingDayData } from '@models/index';
import {
  hasMinimumBreakTime,
  TimeCalulationsInterface,
} from '../interfaces/index';
import { WorkingDay } from '../models/working-day';
import { getPreliminaryRegister } from './time-calculation-helper';

export class TimeCalculationsForDefect implements TimeCalulationsInterface {
  private readonly checkIn: Date;
  private readonly checkOut: Date;
  private readonly workingDay: number;
  private readonly minimumBreakTime: number;
  private skipBreakTimeLimit: number;
  private readonly defaultValue = 0;

  public constructor(
    {
      checkIn,
      checkOut,
      workingDay,
      skipBreakTimeLimit,
      isAlternativeChosen,
    }: WorkingDayData,
    { minimumBreakTime }: Rubric,
    private alternativeWorkingDay?: WorkingDay
  ) {
    this.checkIn = checkIn;
    this.checkOut = checkOut;
    this.workingDay =
      this.alternativeWorkingDay && isAlternativeChosen
        ? this.alternativeWorkingDay.duration
        : workingDay;
    this.minimumBreakTime =
      this.alternativeWorkingDay && isAlternativeChosen
        ? this.alternativeWorkingDay.minimumBreakTime
        : minimumBreakTime;
    this.skipBreakTimeLimit = skipBreakTimeLimit;
  }

  public getBypassedMinimumBreakTime(): number {
    return hasMinimumBreakTime(
      this.checkIn,
      this.checkOut,
      this.skipBreakTimeLimit
    )
      ? this.minimumBreakTime
      : 0;
  }

  public getPreliminaryRegister(): number {
    return getPreliminaryRegister(this.checkIn, this.checkOut);
  }

  public getPreliminaryTimeToComputed(): number {
    return this.getPreliminaryRegister() - this.getBypassedMinimumBreakTime();
  }

  public getPreliminaryDifWorkingDay(): number {
    return this.getPreliminaryTimeToComputed() - this.workingDay;
  }

  public getEffectiveWorkingTime(assimilatedAbsences = 0): number {
    return assimilatedAbsences > 0
      ? this.getPreliminaryTimeToComputed() + assimilatedAbsences
      : this.getPreliminaryTimeToComputed();
  }

  public getDifferenceToCompensate(absencesSubjecToRecuperation = 0): number {
    return absencesSubjecToRecuperation > 0
      ? -absencesSubjecToRecuperation
      : this.defaultValue;
  }

  public getAppliedMarginAgreement(): number {
    return this.defaultValue;
  }

  public getPendingExtensionTimeToApprove(): number {
    return this.defaultValue;
  }
}
