import { TreatableError, TreatableErrorType } from '@models/errors.model';

const ERROR = {
  AUTHENTICATION: 'ERROR_AUTHENTICATION',
  SEARCH_TEXT_VALUE_ERROR: 'ERROR_SEARCH_TEXT_VALUE_ERROR',
  UPDATE_HOURS: 'ERROR_UPDATE_HOURS',
  GET: 'ERROR_GET',
  CONSOLIDATED: 'ERROR_CONSOLIDATED',
  DATA: 'ERROR_DATA',
  NOT_FOUND: 'ERROR_NOT_FOUND',
  DATES: 'ERROR_DATES',
  SYSTEM: 'ERROR_SYSTEM',
  AUTH: 'ERROR_AUTH',
  DELEGATIONS: 'ERROR_DELEGATIONS',
  DEFAULT: 'ERROR_DEFAULT',
  GTAR00: 'ERROR_GTAR00',
  GTAR01: 'ERROR_GTAR01',
  GTAR02: 'ERROR_GTAR02',
  GTAR03: 'ERROR_GTAR03',
  GTAR04: 'ERROR_GTAR04',
  GTAR05: 'ERROR_GTAR05',
  GTAR06: 'ERROR_GTAR06',
  GTAR07: 'ERROR_GTAR07',
};
export const ERRORS: { [key: string]: TreatableError } = {
  AUTHENTICATION_BASIC_AUTH_ERROR: {
    type: TreatableErrorType.ErrorPage,
    message: ERROR.AUTHENTICATION,
  },
  AUTHENTICATION_HASH_ERROR: {
    type: TreatableErrorType.ErrorPage,
    message: ERROR.AUTHENTICATION,
  },
  AUTHENTICATION_VALUE_ERROR: {
    type: TreatableErrorType.ErrorPage,
    message: ERROR.AUTHENTICATION,
  },
  SEARCH_TEXT_VALUE_ERROR: {
    type: TreatableErrorType.ErrorPage,
    message: ERROR.SEARCH_TEXT_VALUE_ERROR,
  },
  LOGIN_ERROR: {
    type: TreatableErrorType.ErrorPage,
    message: ERROR.AUTHENTICATION,
  },
  consultarDatosFechaJornada: {
    type: TreatableErrorType.ErrorPage,
    message: ERROR.GET,
  },
  ERROR_TO_CHECK_IN_CONSOLIDATED_DAY: {
    type: TreatableErrorType.Toast,
    message: ERROR.CONSOLIDATED,
  },
  ERROR_TO_CHECK_OUT_CONSOLIDATED_DAY: {
    type: TreatableErrorType.Toast,
    message: ERROR.CONSOLIDATED,
  },
  ERROR_FECHA_LIMITE_REGISTRO_EXCEDIDA: {
    type: TreatableErrorType.Toast,
    message: ERROR.CONSOLIDATED,
  },
  ERROR_TECNICO_ACTUALIZAR_GTA: {
    type: TreatableErrorType.Toast,
    message: ERROR.UPDATE_HOURS,
  },
  ERROR_TO_CONFIRM_CONSOLIDATED_DAY: {
    type: TreatableErrorType.Toast,
    message: ERROR.CONSOLIDATED,
  },
  DATOS_ENTRADA_INCORRECTOS: {
    type: TreatableErrorType.Toast,
    message: ERROR.DATA,
  },
  VALIDATION_ERROR: {
    type: TreatableErrorType.Toast,
    message: ERROR.DATA,
  },
  ERROR_TECNICO_CONSULTAR_HISTORICO_GTA: {
    type: TreatableErrorType.ErrorPage,
    message: ERROR.GET,
  },
  ERROR_OBTENER_EQUIPO_MANAGER: {
    type: TreatableErrorType.ErrorPage,
    message: ERROR.GET,
  },
  PAGE_NOT_EXIST: {
    type: TreatableErrorType.ErrorPage,
    message: ERROR.NOT_FOUND,
  },
  'Error al recuperar los datos básicos del empleado.': {
    type: TreatableErrorType.ErrorPage,
    message: ERROR.GET,
  },
  ERROR_TECNICO_AUTORIZACIONES_MANAGER: {
    type: TreatableErrorType.ErrorPage,
    message: ERROR.GET,
  },
  ERROR_FECHA_LIMITE_MANAGER: {
    type: TreatableErrorType.ErrorPage,
    message: ERROR.DATES,
  },
  'org.springframework.http.converter.HttpMessageNotReadableException': {
    type: TreatableErrorType.Toast,
    message: ERROR.SYSTEM,
  },
  ERROR_TECNICO_VALIDAR_AUTORIZACION: {
    type: TreatableErrorType.Toast,
    message: ERROR.AUTH,
  },
  SIN_CAMBIOS_DETECTADOS: {
    type: TreatableErrorType.Toast,
    message: ERROR.AUTH,
  },
  FECHAS_INCORRECTAS_DELEGACION: {
    type: TreatableErrorType.Toast,
    message: ERROR.DELEGATIONS,
  },
  102: {
    type: TreatableErrorType.Toast,
    message: ERROR.DELEGATIONS,
  },
  ERROR_TECNICO_ELIMINAR_DELEGACION: {
    type: TreatableErrorType.ErrorPage,
    message: ERROR.DELEGATIONS,
  },
  DEFAULT_400: {
    type: TreatableErrorType.Toast,
    message: ERROR.DEFAULT,
  },
  DEFAULT_500: {
    type: TreatableErrorType.ErrorPage,
    message: ERROR.DEFAULT,
  },
  GTAR00: {
    type: TreatableErrorType.Toast,
    message: ERROR.GTAR00,
  },
  GTAR01: {
    type: TreatableErrorType.Toast,
    message: ERROR.GTAR01,
  },
  GTAR02: {
    type: TreatableErrorType.Toast,
    message: ERROR.GTAR02,
  },
  GTAR03: {
    type: TreatableErrorType.Toast,
    message: ERROR.GTAR03,
  },
  GTAR04: {
    type: TreatableErrorType.Toast,
    message: ERROR.GTAR04,
  },
  GTAR05: {
    type: TreatableErrorType.Toast,
    message: ERROR.GTAR05,
  },
  GTAR06: {
    type: TreatableErrorType.Toast,
    message: ERROR.GTAR06,
  },
  GTAR07: {
    type: TreatableErrorType.Toast,
    message: ERROR.GTAR07,
  },
};
