import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ErrorService } from '@services/index';

@Component({
  selector: 'error-page',
  templateUrl: './error.page.html',
  styleUrls: ['./error.page.scss'],
})
export class ErrorPage {
  public constructor(
    public readonly errorService: ErrorService,
    private readonly modalController: ModalController
  ) {
    if (!this.errorService.thereIsAnError()) {
      this.restart();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public restart(): void {
    window.open('/', '_self');
  }

  public async ionViewDidEnter(): Promise<void> {
    if (await this.modalController.getTop()) {
      await this.modalController.dismiss();
    }
  }

  public getErrorMessage(): string {
    return this.errorService.shouldShowGoHome()
      ? `ERROR_PAGE.ERRORS.${this.errorService.getMessage()}`
      : this.errorService.getMessage();
  }
}
