import { checkProperty } from '../utils/validations';

export interface Authentication {
  token: string;
  target?: string;
  employeeId?: string;
}

export function validateAuthenticationData(data: any): void {
  const authenticationRequiredProperties = [
    { name: 'token', nullable: true },
    { name: 'employeeId', nullable: true },
  ];

  authenticationRequiredProperties.forEach((field: any): void => {
    checkProperty(data, 'Authentication', field);
  });
}

export function authenticationDataBuilder(data: any): Authentication {
  validateAuthenticationData(data);
  return {
    token: data.token,
    employeeId: data.employeeId,
  };
}
