import { Component } from '@angular/core';
import { API_ROUTE } from '@constants/index';
import { NetworkService } from '@services/network/network.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-jne-link',
  templateUrl: './jne-link.component.html',
  styleUrls: ['./jne-link.component.scss'],
})
export class JneLinkComponent {
  public constructor(private readonly networkService: NetworkService) {}

  public async showJneInfo(): Promise<void> {
    const fileUrl = (await this.isInternalNetwork())
      ? API_ROUTE.JNE_DOCUMENT.INTERNAL
      : API_ROUTE.JNE_DOCUMENT.EXTERNAL;
    window.open(fileUrl, '_blank');
  }

  public async isInternalNetwork(): Promise<boolean> {
    return environment.production
      ? this.networkService.isBBVANetwork()
      : Promise.resolve(false);
  }
}
