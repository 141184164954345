import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { EmployeeWorkingDay } from '@models/employee-working-day/employee-working-day';
import { isBeforeCutoffDate } from '@models/employee-working-day/employee-working-day-creator';
import {
  AuthorizationStatus,
  AuthorizationStatuses,
  DifferenceDataType,
  FullTime,
  InfoList,
  StaticText,
  TextBlock,
  TimeInfo,
  TimeInputData,
  TimeValues,
  EqualityPlan,
} from '@models/index';
import { StaticTextService } from '@services/index';
import { timeCalculationsFactory } from 'src/app/pages/working-day/factories';
import {
  isReadOnly,
  isShowMode,
} from 'src/app/pages/working-day/helper/working-day.helper';
import { TimeCalulationsInterface } from 'src/app/pages/working-day/interfaces';
import { WorkingDay } from 'src/app/pages/working-day/models/working-day';
import { WorkingDayInfoComponent } from '../../../modals';
import {
  getDefect,
  INFO_LIST,
} from '../../constants/difference-input-data.constant';
import {
  buildRegiterTimeModalOptions,
  getSuccessInfo,
  isPastDay,
  sumDifferences,
  updateCompensationExplanation,
} from '../../helpers/step-2.helper';

interface DifferenceValue {
  value: number;
  shouldReason?: boolean;
}

export const NO_SELECTED_ABSENCE = '0';
export const COMPANY_BBVA = '001';

@Component({
  selector: 'app-defect-use-case',
  templateUrl: './defect-use-case.component.html',
  styleUrls: ['./defect-use-case.component.scss'],
})
export class DefectUseCaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public isMobile: boolean;
  @Input() public isModifyMode: boolean;
  @Input() public isReadonly: boolean;
  @Input() public employeeWorkingDay: EmployeeWorkingDay;
  @Input() public alternativeWorkingDayChosen: boolean;
  @Input() public alternativeWorkingDay: WorkingDay;
  @Input() public checkTimesEdited: boolean;
  @Output() public confirmWorkingDay: EventEmitter<void> = new EventEmitter();

  public staticMessages: StaticText;
  public timeCalculationStrategy: TimeCalulationsInterface;
  public preliminaryDifferenceAgreement: TimeInfo[];
  public effectiveWorkingTime: TimeInfo[];
  public previewCurrentBalance = 0;
  public differenceTimesEdited: boolean;
  public differenceInputs: TimeInputData[] = [];
  public savedValues: { [index: number]: { hours: string; minutes: string } };
  public specialAbsences: EqualityPlan[] = [];
  public selectedEqualityPlan: EqualityPlan = null;
  public equalityPlanValue = '';
  public disableAbsenceButton = false;

  private staticTextSubscription: Subscription;
  private differenceValues: { [type: number]: DifferenceValue } = {
    [DifferenceDataType.Recoverable]: {
      value: 0,
    },
    [DifferenceDataType.Unrecoverable]: {
      value: 0,
      shouldReason: true,
    },
    [DifferenceDataType.PositiveCompensation]: {
      value: 0,
    },
  };

  private isPastDay = false;
  private isShowMode = false;

  public constructor(
    private readonly staticTextService: StaticTextService,
    private readonly modalController: ModalController,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    this.staticTextSubscription = this.staticTextService.staticText$.subscribe(
      (staticText): void => {
        if (staticText) {
          this.staticMessages = staticText;
        }
      }
    );
    this.initEqualityPlanTexts();
    this.initPreviewCurrentBalance();
    this.ngOnChanges();
  }

  public ngOnChanges(): void {
    this.timeCalculationStrategy = timeCalculationsFactory(
      this.employeeWorkingDay.workingDayData,
      this.employeeWorkingDay.rubric,
      this.alternativeWorkingDayChosen ? this.alternativeWorkingDay : null
    );
    this.checkTimesSubscription();
    this.updateEffectiveWorkTime();
    this.isPastDay = isPastDay(this.employeeWorkingDay);
    this.isShowMode = isShowMode(this.router.url);
    this.isReadonly = isReadOnly(
      this.employeeWorkingDay,
      this.isPastDay,
      this.isShowMode
    );
  }

  public onDifferenceTimeChange(
    minutes: number,
    type: DifferenceDataType
  ): void {
    this.differenceTimesEdited = true;
    this.changeDifferenceTime(minutes, type);
  }

  public changeDifferenceTime(minutes: number, type: DifferenceDataType): void {
    this.differenceValues[type].value = minutes;
    this.updateEffectiveWorkTime();
    if (
      type === DifferenceDataType.NegativeCompensation ||
      type === DifferenceDataType.PositiveCompensation
    ) {
      updateCompensationExplanation(
        DifferenceDataType.PositiveCompensation,
        this.differenceInputs,
        this.differenceValues,
        this.employeeWorkingDay.employeeInfo,
        this.isModifyMode,
        this.isReadonly,
        this.previewCurrentBalance
      );
    }
  }

  public getTimeInputInitialValues(differenceInput: TimeInputData): FullTime {
    const difference = this.differenceValues[differenceInput.type].value;

    return {
      hours: `0${Math.floor(difference / 60)}`.slice(-2),
      minutes: `0${difference % 60}`.slice(-2),
    };
  }

  public isRequestedCompensationAvailable(): boolean {
    return (
      Math.abs(
        this.previewCurrentBalance -
          this.employeeWorkingDay.employeeInfo.lockedBalance
      ) >= Math.abs(this.getRequestedBalanceToCompensate())
    );
  }

  public sumDifferences(onlyReasonable: boolean): number {
    const keys = [
      DifferenceDataType.Unrecoverable,
      DifferenceDataType.Recoverable,
      DifferenceDataType.PositiveCompensation,
    ];
    return sumDifferences(onlyReasonable, keys, this.differenceValues);
  }

  public isWorkplaceSelected(): boolean {
    return !(
      this.employeeWorkingDay.workingDayData.workLocation === undefined ||
      this.employeeWorkingDay.workingDayData.workLocation === '0'
    );
  }

  public workplaceMissing(): boolean {
    return (
      this.employeeWorkingDay.employeeInfo.hasRemoteWork &&
      !isBeforeCutoffDate(this.employeeWorkingDay.workingDayData.date) &&
      !this.isWorkplaceSelected()
    );
  }

  public async send(): Promise<void> {
    const timeValues = this.getTimeValues();

    const preliminaryDiffValue = Math.abs(timeValues.preliminaryDiffValue);
    const excessCompensation = this.isRequestedCompensationAvailable();
    const workplaceMissing = this.workplaceMissing();
    const isSuccess =
      this.sumDifferences(false) === preliminaryDiffValue &&
      excessCompensation &&
      !this.workplaceMissing();

    const distributedTime = this.sumDifferences(false) === preliminaryDiffValue;
    const differenceTimes = {};
    Object.keys(this.differenceValues).forEach(
      (differenceValueKey: string): void => {
        const differenceValue = this.differenceValues[differenceValueKey];
        if (
          [
            DifferenceDataType.Recoverable,
            DifferenceDataType.Unrecoverable,
          ].includes(+differenceValueKey)
        ) {
          differenceTimes[differenceValueKey] = differenceValue.value;
        }
      }
    );

    this.continueSend(
      timeValues,
      differenceTimes,
      isSuccess,
      excessCompensation,
      distributedTime,
      workplaceMissing
    );
  }

  private async continueSend(
    timeValues: TimeValues,
    differenceTimes: any,
    isSuccess: boolean,
    excessCompensation: boolean,
    distributedTime: boolean,
    workplaceMissing: boolean
  ): Promise<void> {
    const successData = getSuccessInfo(timeValues, false);
    this.setEqualityPlanSend();
    const modalOptions = buildRegiterTimeModalOptions(
      differenceTimes,
      isSuccess,
      excessCompensation,
      this.employeeWorkingDay,
      this.alternativeWorkingDay,
      this.alternativeWorkingDayChosen,
      this.isPastDay,
      successData,
      false,
      true,
      undefined,
      this.differenceValues,
      distributedTime,
      workplaceMissing
    );
    const modal = await this.modalController.create(modalOptions);
    await modal.present();
    const dismissData = await modal.onDidDismiss();
    if (dismissData && dismissData.data) {
      this.confirmWorkingDay.emit();
    }
  }

  private getRequestedBalanceToCompensate(): number {
    return +this.differenceValues[DifferenceDataType.PositiveCompensation]
      .value;
  }

  private checkTimesSubscription(): void {
    if (this.employeeWorkingDay.workingDayData.checkOut) {
      const timeValues = this.getTimeValues();

      const infoListWithValues = this.setInfoListTimes(timeValues);
      this.preliminaryDifferenceAgreement = [
        infoListWithValues.preliminaryDifferenceAgreement,
      ];

      this.setDifferenceInputsData();
      this.setDifferenceInputsInitialValues();
      this.restartValues();
    }
  }

  private getTimeValues(): TimeValues {
    const preliminaryRegistration = this.timeCalculationStrategy.getPreliminaryRegister();

    const preliminaryRegistrationForComputation = this.timeCalculationStrategy.getPreliminaryTimeToComputed();

    const preliminaryDiffValue = this.timeCalculationStrategy.getPreliminaryDifWorkingDay();

    const effectiveWorkTime = this.timeCalculationStrategy.getEffectiveWorkingTime(
      this.differenceValues[DifferenceDataType.Unrecoverable].value,
      this.employeeWorkingDay.workingDayData.differences.excess.other.time,
      this.getActualAuthorizationStatus()
    );
    const extensionTime = 0;

    const appliedMarginAgreement = this.timeCalculationStrategy.getAppliedMarginAgreement(
      extensionTime
    );

    const effectiveWorkTimeDifference = this.timeCalculationStrategy.getDifferenceToCompensate(
      this.differenceValues[DifferenceDataType.Recoverable].value,
      this.getActualAuthorizationStatus()
    );

    return {
      preliminaryRegistration,
      preliminaryRegistrationForComputation,
      preliminaryDiffValue,
      appliedMarginAgreement,
      effectiveWorkTime,
      effectiveWorkTimeDifference,
    };
  }

  private setInfoListTimes(timeValues: TimeValues): InfoList {
    const preliminaryList = { ...INFO_LIST };

    const preliminaryDifferenceAgreement: TimeInfo = {
      ...preliminaryList.preliminaryDifferenceAgreement,
      time: timeValues.preliminaryDiffValue,
      moreInfo: (): void => {
        const infoAsListKey = 'lessZero';
        const infoAsList = this.staticMessages
          ? this.staticMessages.messagesPreliminaryDiff[infoAsListKey]
          : [];

        const title = 'WORKING_DAY.MODAL.INFO.DIFFERENCE';
        this.openInfoModal(title, infoAsList);
      },
    };

    const effectiveWorkingTime: TimeInfo = {
      ...preliminaryList.effectiveWorkingTime,
      time: timeValues.effectiveWorkTime,
      subtime: timeValues.effectiveWorkTimeDifference,
    };

    return {
      preliminaryDifferenceAgreement,
      effectiveWorkingTime,
    };
  }

  private shouldShowAuthorizationStatus(
    authorizationStatus: AuthorizationStatus
  ): boolean {
    return (
      authorizationStatus &&
      !this.checkTimesEdited &&
      !this.differenceTimesEdited
    );
  }

  private getActualAuthorizationStatus(): AuthorizationStatus {
    const { authorizationStatus } = this.employeeWorkingDay.workingDayData;

    return this.shouldShowAuthorizationStatus(authorizationStatus)
      ? authorizationStatus
      : AuthorizationStatuses.PENDING;
  }

  private async openInfoModal(
    title: string,
    infoAsList: TextBlock[]
  ): Promise<void> {
    const modalOptions = {
      component: WorkingDayInfoComponent,
      cssClass: 'register-time',
      componentProps: {
        infoAsList,
        title,
      },
    };
    const modal = await this.modalController.create(modalOptions);
    modal.present();
  }

  private setDifferenceInputsData(): void {
    const shouldShowBalance = !this.employeeWorkingDay.employeeInfo
      .shouldHideBalance;
    const { currentBalance } = this.employeeWorkingDay.employeeInfo;
    const hasNotBalance = currentBalance === 0;
    const hasShouldHaveHourlyMedicalLeave = this.employeeWorkingDay.employeeInfo
      .shouldHaveHourlyMedicalLeave;

    this.differenceInputs = getDefect(
      this.hasPositiveBalance(shouldShowBalance, currentBalance, hasNotBalance),
      hasShouldHaveHourlyMedicalLeave
    );
  }

  private hasPositiveBalance(
    shouldShowBalance: boolean,
    currentBalance: number,
    hasNotBalance: boolean
  ): boolean {
    const hasPositiveCompensationNoBalance =
      hasNotBalance &&
      this.employeeWorkingDay.workingDayData.differences.deffect
        .positiveCompensation.time > 0;
    return (
      (currentBalance > 0 || hasPositiveCompensationNoBalance) &&
      shouldShowBalance
    );
  }

  private setDifferenceInputsInitialValues(): void {
    const durationToTimeObject = (
      duration: number
    ): { hours: string; minutes: string } => ({
      hours: `0${Math.floor(duration / 60)}`.slice(-2),
      minutes: `0${duration % 60}`.slice(-2),
    });

    this.savedValues = {
      [DifferenceDataType.Recoverable as number]: durationToTimeObject(
        this.employeeWorkingDay.workingDayData.differences.deffect.recoverable
          .time
      ),
      [DifferenceDataType.Unrecoverable as number]: durationToTimeObject(
        this.employeeWorkingDay.workingDayData.differences.deffect.unrecoverable
          .time
      ),
      [DifferenceDataType.PositiveCompensation as number]: durationToTimeObject(
        this.employeeWorkingDay.workingDayData.differences.deffect
          .positiveCompensation.time
      ),
    };
  }

  private restartValues(): void {
    const {
      workingDayData: { differences },
    } = this.employeeWorkingDay;

    this.changeDifferenceTime(
      differences.deffect.unrecoverable.time,
      DifferenceDataType.Unrecoverable
    );

    const initialMinutes = Math.abs(
      this.preliminaryDifferenceAgreement[0].time || 0
    );
    const { isConfirmed } = this.employeeWorkingDay.workingDayData;

    this.changeDifferenceTime(
      isConfirmed ? differences.deffect.recoverable.time : initialMinutes,
      DifferenceDataType.Recoverable
    );
    this.changeDifferenceTime(
      differences.deffect.positiveCompensation.time,
      DifferenceDataType.PositiveCompensation
    );
  }

  public ngOnDestroy(): void {
    this.staticTextSubscription.unsubscribe();
  }

  private initPreviewCurrentBalance(): void {
    const { currentBalance } = this.employeeWorkingDay.employeeInfo;
    this.previewCurrentBalance = currentBalance;
    if (this.isModifyMode || this.isReadonly) {
      const { differences } = this.employeeWorkingDay.workingDayData;
      this.previewCurrentBalance =
        currentBalance + differences.deffect.positiveCompensation.time;
    }
  }

  private updateEffectiveWorkTime(): void {
    this.effectiveWorkingTime = [
      {
        ...INFO_LIST.effectiveWorkingTime,
        time: this.getTimeValues().effectiveWorkTime,
        subtime: this.getTimeValues().effectiveWorkTimeDifference,
      },
    ];
  }

  public isBBVACompany(): boolean {
    return this.employeeWorkingDay.employeeInfo.codCompany === COMPANY_BBVA;
  }

  public shouldBeReasoned(): boolean {
    return this.sumDifferences(true) > 0 && this.isBBVACompany();
  }

  public absenceReasonSelect(ev: any): void {
    this.selectedEqualityPlan = ev.target.value;
  }

  public canContinue(): boolean {
    return !this.shouldBeReasoned() || !!this.selectedEqualityPlan;
  }

  private initEqualityPlanTexts(): void {
    this.specialAbsences = this.staticTextService.equalityPlanTexts;
    this.setEqualityPlanValue();
  }

  private setEqualityPlanValue(): void {
    this.equalityPlanValue = this.employeeWorkingDay.workingDayData.equalityPlan;
    this.setEqualityPlanObject();
  }

  private setEqualityPlanObject(): void {
    this.disableAbsenceButton =
      this.equalityPlanValue !== NO_SELECTED_ABSENCE && this.isReadonly;
    if (this.equalityPlanValue !== NO_SELECTED_ABSENCE) {
      this.selectedEqualityPlan = this.specialAbsences[
        Number(this.equalityPlanValue) - 1
      ];
    }
  }

  private setEqualityPlanSend(): void {
    this.employeeWorkingDay.workingDayData.equalityPlan = NO_SELECTED_ABSENCE;
    this.employeeWorkingDay.workingDayData.equalityPlanDescription = '';
    if (this.selectedEqualityPlan && this.shouldBeReasoned()) {
      this.employeeWorkingDay.workingDayData.equalityPlan = this.selectedEqualityPlan.key;
      this.employeeWorkingDay.workingDayData.equalityPlanDescription = this.selectedEqualityPlan.name;
    }
  }
}
