import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-error-message-balance',
  templateUrl: './error-message-balance.component.html',
  styleUrls: ['./error-message-balance.component.scss'],
})
export class ErrorMessageBalanceComponent {
  @Input() errorMessage = '';

  public constructor(private readonly modalController: ModalController) {}

  dimiss() {
    this.modalController.dismiss();
  }
}
