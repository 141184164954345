import { AuthorizationListSubscriptionTypes } from '..';

export const STATES_MAP = {
  A: 'ACCEPTED',
  R: 'REJECTED',
  P: '',
};

export const CSS_MAP = {
  A: 'bullet bullet--accepted',
  R: 'bullet bullet--rejected',
  P: '',
};

export const AUTHORIZATION_LIST_SUBSCRIPTIONS: AuthorizationListSubscriptionTypes = {
  LOG: {
    NAME: 'logAuthListSubs',
    OBSERVABLE: 'historicAuthsList$',
  },
};
