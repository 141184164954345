import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-member-avatar',
  templateUrl: './member-avatar.component.html',
  styleUrls: ['./member-avatar.component.scss'],
})
export class MemberAvatarComponent {
  @Input() public userName = '';
}
