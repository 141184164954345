import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import moment from 'moment';
import { EmployeeWorkingDay } from '@models/employee-working-day/employee-working-day';
import { CheckType, TimeRecordEmitter } from '@models/index';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';
import { CheckTimeValidator } from 'src/app/shared';
import { INFO_POPUP_ERROR } from '../../../../../shared/components/disclaimer/constants/disclaimers-constants';

const baseKey = 'REGISTER_TIME.MODAL_EDIT';
const checkTypeKey = {
  [CheckType.CheckIn]: 'START',
  [CheckType.CheckOut]: 'END',
};

function getContraryCheckType(checkType: CheckType): CheckType {
  return checkType === CheckType.CheckIn
    ? CheckType.CheckOut
    : CheckType.CheckIn;
}

function getKeyGivenType(checkTpe: CheckType, key: string): string {
  const base = `${baseKey}.${key}_`;
  const tail = checkTypeKey[checkTpe];

  return `${base}${tail}`;
}

@Component({
  selector: 'app-edit-time-modal',
  templateUrl: './edit-time-modal.component.html',
  styleUrls: ['./edit-time-modal.component.scss'],
})
export class EditTimeModalComponent {
  @Input() public workingDay = 0;
  @Input() public editingCheckTime: Date;
  @Input() public otherCheckTime: Date;
  @Input() public checkType: CheckType = CheckType.CheckIn;
  @Input() public isPastDay: boolean;
  @Input() public workingDayEditing: EmployeeWorkingDay;

  public dateErrorMessage: string;
  public hasDateError: boolean;
  public readonly disclaimersPopup = [INFO_POPUP_ERROR];
  public constructor(
    private readonly modalController: ModalController,
    private readonly platformCheckerService: PlatformCheckerService
  ) {}

  public onDismissModal(): void {
    this.modalController.dismiss();
  }

  public isMobile(): boolean {
    return this.platformCheckerService.isMobile();
  }

  public getOtherCheckTimeFormatted(): string {
    return this.otherCheckTime
      ? moment(this.otherCheckTime).format('HH:mm')
      : '--';
  }

  public getInputTitleKey(): string {
    return getKeyGivenType(this.checkType, 'TIME');
  }

  public getOtherCheckNameKey(): string {
    return getKeyGivenType(getContraryCheckType(this.checkType), 'TIME');
  }

  public getTitleKey(): string {
    return getKeyGivenType(this.checkType, 'TITLE');
  }

  public getDateFromOtherCheckOut(): string {
    const DATE_PATTERN = 'dddd[,] DD [de] MMMM [de] YYYY';
    if (this.workingDayEditing.workingDayData.date) {
      const date = moment(this.workingDayEditing.workingDayData.date).format(
        DATE_PATTERN
      );

      return `${date.charAt(0).toUpperCase()}${date.slice(1)}`;
    }
    return '';
  }

  public getThisCheckTimeHour(): string {
    return moment(this.editingCheckTime).format('HH');
  }

  public getThisCheckTimeMinute(): string {
    return moment(this.editingCheckTime).format('mm');
  }

  public onSaveTime(dataEvent: TimeRecordEmitter): void {
    const { checkTimeHour, checkTimeMinutes } = dataEvent.bodyParams;

    const newCheckTime = moment()
      .hours(+checkTimeHour)
      .minutes(+checkTimeMinutes)
      .toDate();

    const validator = CheckTimeValidator.factory(
      this.workingDayEditing.workingDayData,
      newCheckTime,
      this.otherCheckTime,
      this.isCheckIn()
    );

    validator.validate({
      shouldNotBeFuture: !this.isPastDay,
    });

    if (validator.hasError()) {
      this.dateErrorMessage = validator.getError();
    } else {
      this.modalController.dismiss(dataEvent);
    }
  }

  public isCheckIn(): boolean {
    return this.checkType === CheckType.CheckIn;
  }
}
