import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Authorizer } from '@models/index';
import { DelegationsService } from '@services/index';

@Component({
  selector: 'app-unassign-confirmation-modal',
  templateUrl: './unassign-confirmation-modal.component.html',
  styleUrls: ['./unassign-confirmation-modal.component.scss'],
})
export class UnassignConfirmationModalComponent {
  @Input() public authorizer: Authorizer;
  @Input() public employeeId: string;
  @Input() public isMobile = false;

  public constructor(
    private readonly modalController: ModalController,
    private readonly delegationsService: DelegationsService
  ) {}

  public onDismissModal(accept: boolean): void {
    this.modalController.dismiss(accept);
  }

  public UnassignAuthorizer(): void {
    this.delegationsService
      .removeTemporalAuthorizer(this.employeeId)
      .subscribe((): void => {
        this.onDismissModal(true);
      });
  }

  public getAuthorizerFullName(): string {
    let fullName = this.authorizer.name;
    if (this.authorizer.surnames) {
      fullName = `${this.authorizer.name} ${this.authorizer.surnames}`;
    }
    return fullName;
  }
}
