import moment from 'moment';
import { EmployeeWorkingDay } from '@models/employee-working-day/employee-working-day';
import { getCurrentDate } from '@models/employee-working-day/employee-working-day-creator';
import {
  DifferenceDataType,
  EmployeeInfo,
  TimeInputData,
  TimeValues,
} from '@models/index';
import { HumanizeDurationPipe } from 'src/app/shared/pipes/humanize-duration.pipe';
import { WorkingDay } from '../../../models/working-day';
import {
  AuthorizerInfoModalComponent,
  RegisterTimeModalComponent,
} from '../../modals';
import { DifferenceValue, SuccessInfoInterface } from '../models';

export function buildAuthorizerInfoModalOptions(isMobile: boolean): any {
  return {
    component: AuthorizerInfoModalComponent,
    cssClass: 'register-time',
    componentProps: {
      isMobile,
    },
  };
}

export function buildRegiterTimeModalOptions(
  differenceTimes: any,
  isSuccess: boolean,
  excessCompensation: boolean,
  employeeWorkingDay: EmployeeWorkingDay,
  alternativeWorkingDay: WorkingDay,
  alternativeWorkingDayChosen: boolean,
  pastDay: boolean,
  successData: SuccessInfoInterface[],
  isExcess: boolean,
  isDeffect: boolean,
  reason: string,
  differenceValues: { [type: number]: DifferenceValue },
  distributedTime: boolean,
  workplaceMissing: boolean
): any {
  return {
    component: RegisterTimeModalComponent,
    cssClass: 'register-time',
    componentProps: {
      employeeWorkingDay,
      employeeId: employeeWorkingDay.employeeInfo.id,
      successData,
      differenceTimes,
      excessReason: reason,
      isSuccess,
      selectedDay: employeeWorkingDay.workingDayData.date,
      isPastDay: pastDay,
      hasAlternativeWorkingDay: !!alternativeWorkingDay,
      isAlternativeWorkingDay: alternativeWorkingDayChosen,
      distributedTime,
      workplaceMissing,

      compensationNegativeBalance: isExcess
        ? differenceValues[DifferenceDataType.NegativeCompensation].value
        : 0,
      compensationPositiveBalance: isDeffect
        ? differenceValues[DifferenceDataType.PositiveCompensation].value
        : 0,
      excessCompensation: !excessCompensation,
      agreementMargin: employeeWorkingDay.rubric.agreementMargin,
    },
  };
}

export function getSuccessInfo(
  timeValues: TimeValues,
  isExcess: boolean
): SuccessInfoInterface[] {
  let successInfo: SuccessInfoInterface[] = [
    {
      name: 'WORKING_DAY.PRELIMINARY_REGISTRATION',
      time: timeValues.preliminaryRegistration,
    },
    {
      name: 'WORKING_DAY.EFFECTIVE_WORK_TIME',
      time: timeValues.effectiveWorkTime,
    },
    {
      name: 'WORKING_DAY.DIFFERENCE_TO_COMPENSATE',
      time: timeValues.effectiveWorkTimeDifference,
    },
  ];

  if (isExcess) {
    successInfo = [
      {
        name: 'WORKING_DAY.PRELIMINARY_REGISTRATION',
        time: timeValues.preliminaryRegistration,
      },
      {
        name: 'WORKING_DAY.EFFECTIVE_WORK_TIME',
        time: timeValues.effectiveWorkTime,
      },
      {
        name: 'WORKING_DAY.AGREEMENT_MARGIN_ADJUSTMET',
        time: timeValues.appliedMarginAgreement,
      },
    ];

    const extensionInfo: SuccessInfoInterface = {
      name: 'WORKING_DAY.DIFFERENCE_TO_COMPENSATE',
      time: timeValues.effectiveWorkTimeDifference,
    };

    if (timeValues.effectiveWorkTimeDifference > 0) {
      extensionInfo.info = 'WORKING_DAY.DIFFERENCE_REQUIRES_VALIDATION';
    }

    successInfo.push(extensionInfo);
  }

  return successInfo;
}

export function isPastDay(employeeWorkingDay: EmployeeWorkingDay): boolean {
  return !moment(employeeWorkingDay.workingDayData.date).isSame(
    moment(getCurrentDate(employeeWorkingDay.employeeInfo)),
    'days'
  );
}

export function updateCompensationExplanation(
  type: DifferenceDataType,
  differenceInputs: TimeInputData[],
  differenceValues: { [type: number]: DifferenceValue },
  employeeInfo: EmployeeInfo,
  isModifyMode: boolean,
  isReadonly: boolean,
  previewCurrentBalance: number
): void {
  const { currentBalance } = employeeInfo;
  const hasCurrentBalance = currentBalance !== 0;

  const shouldUpdateCompensation =
    hasCurrentBalance || (!hasCurrentBalance && isModifyMode) || isReadonly;

  if (shouldUpdateCompensation) {
    const compensationInput = differenceInputs.find(
      (input): boolean => input.type === type
    );
    if (compensationInput) {
      let newBalance =
        Math.abs(previewCurrentBalance - employeeInfo.lockedBalance) -
        differenceValues[type].value;

      newBalance = Math.max(newBalance, 0);
      compensationInput.explanation = {
        ...compensationInput.explanation,
        data: {
          ...compensationInput.explanation.data,
          currentBalance: HumanizeDurationPipe.humanize(newBalance),
        },
      };
    }
  }
}

export function sumDifferences(
  onlyReasonable = false,
  keys: DifferenceDataType[],
  differenceValues: { [type: number]: DifferenceValue }
): number {
  return keys.reduce((partialSum, key): number => {
    const differenceValue = differenceValues[key];
    let newValue = differenceValue.value;

    if (onlyReasonable) {
      newValue = differenceValue.shouldReason ? newValue : 0;
    }

    return partialSum + newValue;
  }, 0);
}
