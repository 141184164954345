import { EmployeeWorkingDay } from '@models/employee-working-day/employee-working-day';

export function isShowMode(url: string): boolean {
  return /show/.test(url);
}

export function isReadOnly(
  employeeWorkingDay: EmployeeWorkingDay,
  pastDay: boolean,
  showMode: boolean
): boolean {
  const isConfirmed =
    employeeWorkingDay && employeeWorkingDay.workingDayData.isConfirmed;

  return (isConfirmed && !pastDay) || showMode;
}
