<section class="authorizer">
  <ion-row>
    <ion-col size-xs="3" size-sm="3">
      <app-image
        class="authorizer-icon"
        src="assets/icon/hci.png"
      ></app-image>
    </ion-col>
    <ion-col size-xs="9" size-sm="9">
      <ion-row class="authorizer-label">
        <ion-col size-xs="12" size-sm="12">
          <div *ngIf="authorizer">
            <span
              class="authorizer-label__value"
              [innerHTML]="
                'WORKING_DAY.AUTHORIZER.YOUR_AUTHORIZER_IS' | translate
              "
            ></span>
            <p class="authorizer-label__name">
              {{ getAuthorizerFullName() }}
            </p>
          </div>
          <span
            *ngIf="!authorizer"
            class="authorizer-label__bold authorizer-label__value"
            >{{ 'WORKING_DAY.AUTHORIZER.WITHOUT_AUTHORIZER' | translate }}</span
          >
        </ion-col>
      </ion-row>
      <ion-row class="authorizer-action">
        <ion-col size-xs="12" size-sm="12">
          <span
            *ngIf="shouldShowRemoveAction()"
            class="authorizer-action__value"
            (click)="unassignAuthorizer()"
            >{{ 'WORKING_DAY.AUTHORIZER.REMOVE' | translate }}</span
          >
          <span
            *ngIf="!authorizer"
            class="authorizer-action__value"
            (click)="assignAuthorizer()"
            >{{ 'WORKING_DAY.AUTHORIZER.ASIGN' | translate }}</span
          >
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</section>
