<ion-header *ngIf="isMobile" class="authorizer-info__header">
  <app-mobile-header
    class="authorizer-info__header-mobile"
    (closeModal)="onDismissModal(false)"
    [title]="headerTitle | translate"
  ></app-mobile-header>
</ion-header>

<ion-content class="modal-content">
  <ion-buttons *ngIf="!isMobile" class="modal-content__wrapper-icon">
    <ion-icon
      class="close-icon close-icon--content"
      size="large"
      name="close"
      (click)="onDismissModal(false)"
    ></ion-icon>
  </ion-buttons>

  <div class="modal-wrapper">
    <section class="header">
      <div class="row">
        <span class="header__title">
          {{ 'WORKING_DAY.MODAL.AUTHORIZER_MODAL.TITLE' | translate }}
        </span>
      </div>
    </section>

    <section class="data">
      <div class="row">
        <span class="data__text-init">
          {{ 'WORKING_DAY.MODAL.AUTHORIZER_MODAL.TEXT_INIT' | translate }}
        </span>
      </div>
      <div class="row">
        <div class="data__image">
          <ion-card class="c-card card-authorizer">
            <app-authorizer [isDisabled]="true"></app-authorizer>
          </ion-card>
        </div>
      </div>
      <div class="row">
        <span class="data__text-end">
          {{ 'WORKING_DAY.MODAL.AUTHORIZER_MODAL.TEXT_END' | translate }}
        </span>
      </div>
    </section>

    <section class="button-wrapper">
      <ion-button
        class="button-wrapper__confirm"
        (click)="onDismissModal(true)"
      >
        {{ 'WORKING_DAY.MODAL.AUTHORIZER_MODAL.BUTTON_ACCEPT' | translate }}
      </ion-button>
      <ion-button
        class="button-wrapper__cancel"
        fill="clear"
        (click)="onDismissModal(false)"
      >
        {{ 'WORKING_DAY.MODAL.AUTHORIZER_MODAL.BUTTON_CANCEL' | translate }}
      </ion-button>
    </section>
  </div>
</ion-content>
