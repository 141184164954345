import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-assignment-confirmation-modal',
  templateUrl: './assignment-confirmation-modal.component.html',
  styleUrls: ['./assignment-confirmation-modal.component.scss'],
})
export class AssignmentConfirmationModalComponent {
  @Input() public authorizerName: string;
  @Input() public isMobile = false;

  public constructor(private readonly modalController: ModalController) {}

  public onDismissModal(): void {
    this.modalController.dismiss();
  }
}
