import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TimeInfo } from '@models/index';

@Component({
  selector: 'app-effective-working-time',
  templateUrl: './effective-working-time.component.html',
  styleUrls: ['./effective-working-time.component.scss'],
})
export class EffectiveWorkingTimeComponent {
  @Input() public isDisabled = false;
  @Input() public isMobile: boolean;
  @Input() public isReadonly: boolean;
  @Input() public effectiveWorkingTime: TimeInfo[];
  @Output() public confirmSend: EventEmitter<void> = new EventEmitter();

  public constructor() {
    // do nothing.
  }

  public send(): void {
    this.confirmSend.emit();
  }
}
