import { features } from './common';

export const environment = {
  swapId: true,
  production: false,
  apiUrl: 'https://pre.controljornada1.bbva.com/api/v1',
  bbvaSSO: 'https://dev-bbva-launcher.appspot.com/s/to/controljor',
  socketUrl: 'wss://pre.controljornada1.bbva.com',
  features,
  version: '3.3.0',
  inMaintenance: false,
};
