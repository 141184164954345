import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import moment from 'moment';
import { DatepickerComponent } from '@components/index';
import { SelectedDelegateEmployee } from '@components/search-employee/model/search-employee';
import { SearchEmployeeComponent } from '@components/search-employee/search-employee.component';
import {
  AuthorizationsFilter,
  AutorizableEmployee,
  Search,
} from '@models/index';
import { AuthorizationsService } from '@services/index';

enum FilterDate {
  from = 'dateFrom',
  to = 'dateTo',
}

@Component({
  selector: 'app-authorizations-filter',
  templateUrl: './authorizations-filter.component.html',
  styleUrls: ['./authorizations-filter.component.scss'],
})
export class AuthorizationsFilterComponent {
  @ViewChild('dateFrom', { static: true })
  public dateFromComponent: DatepickerComponent;

  @ViewChild('dateTo', { static: true })
  public dateToComponent: DatepickerComponent;

  @ViewChild('searchEmployee', { static: true })
  public searchEmployeeComponent: SearchEmployeeComponent;

  @Input() public areActionsDisabled = false;
  @Input() public isMobile = false;

  @Output() private applyFilter = new EventEmitter<AuthorizationsFilter>();

  public filter = new AuthorizationsFilter();
  public isFilterVisible = false;
  public filterDateType = FilterDate;
  public employeesInSearch: AutorizableEmployee[] = [];

  public constructor(
    private readonly authorizationsService: AuthorizationsService
  ) {}

  public toggleFiltervisibility(): void {
    if (this.isMobile) {
      this.isFilterVisible = !this.isFilterVisible;
    }
  }

  public cleanFilter(): void {
    this.isFilterVisible = false;
    this.filter.clean();

    this.dateFromComponent.dateSelected(null);
    this.dateToComponent.dateSelected(null);
    this.searchEmployeeComponent.clean();

    this.applyFilter.emit(this.filter);
  }

  public onSelectEmployee(employee: SelectedDelegateEmployee): void {
    if (employee) {
      this.filter.employeeId = employee.id;
    } else {
      delete this.filter.employeeId;
    }
  }

  public onChangeDate(filterDate: FilterDate, date: string): void {
    this.filter[filterDate] = date
      ? moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : '';
  }

  public onApplyFilter(): void {
    this.isFilterVisible = false;
    this.filter.apply();

    this.applyFilter.emit(this.filter);
  }

  public canApply(): boolean {
    return this.filter.canApply() && !this.areActionsDisabled;
  }

  public canCleanFilter(): boolean {
    return this.filter.isApplied && !this.areActionsDisabled;
  }

  // eslint-disable-next-line class-methods-use-this
  public getFilterClasses(): { [key: string]: boolean } {
    return {
      filter: true,
      'filter--hidden': !this.shouldShowFilter(),
      'filter--mobile': this.isMobile,
    };
  }

  private shouldShowFilter(): boolean {
    return !this.isMobile || this.isFilterVisible;
  }

  public setAutorizationEmployees(valueEvent: Search): any {
    this.authorizationsService
      .findAutorizableEmployees(valueEvent.managerId, valueEvent.filterText)
      .subscribe((employees): void => {
        this.employeesInSearch = employees.listEmployeesTeamManager;
      });
  }
}
