import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { concatMap, filter, tap } from 'rxjs/operators';
import { API_ROUTE } from '@constants/api.routes.constants';
import { ROUTE, STORAGE_KEY } from '@constants/index';
import { Authentication, authenticationDataBuilder } from '@models/index';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';
import { StorageService } from '@services/storage/storage.service';
import { environment } from 'src/environments/environment';

declare const cordova: any;

interface BBVALoginPlugin {
  BBVALogin: {
    requestAccessToken(
      successCallback: (token: string) => void,
      errorCallback: (error: { code: number }) => void
    );
    configuration(
      env: string,
      success: (success: any) => void,
      error: (error: { code: number }) => void
    );
  };
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    }),
  };

  public static getTokenFromMobilePlugin(): Observable<string> {
    return from(
      new Promise<string>((resolve, reject): void => {
        (cordova.plugins as BBVALoginPlugin).BBVALogin.configuration(
          'PROD',
          (): void => {
            (cordova.plugins as BBVALoginPlugin).BBVALogin.requestAccessToken(
              (token): void => {
                resolve(token);
              },
              (error): void => {
                reject(error);
              }
            );
          },
          (error): void => {
            reject(error);
          }
        );
      })
    );
  }

  public constructor(
    private readonly http: HttpClient,
    private readonly storageService: StorageService,
    private readonly router: Router,
    private readonly platformCheckerService: PlatformCheckerService
  ) {}

  public static getTokenFromUrlSearchParams(): string {
    const paramsFromURL = new URLSearchParams(window.location.search);
    const token = paramsFromURL.get('token');
    return token;
  }

  public static navigateToBbvaSso(): void {
    window.location.href = environment.bbvaSSO;
  }

  public isAuthenticated(): boolean {
    return this.storageService.check(STORAGE_KEY.TOKEN);
  }

  // eslint-disable-next-line class-methods-use-this
  public inMaintenance(): boolean {
    return environment.inMaintenance;
  }

  // eslint-disable-next-line class-methods-use-this
  public redirectToSSOLogin(): void {
    if (environment.production) {
      AuthenticationService.navigateToBbvaSso();
    } else {
      this.router.navigate([`/${ROUTE.EMPLOYEE_CHOOSER}`]);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private buildLoginParams(token: string, employeeId: string): Authentication {
    const bypassedToken = environment.production ? token : 'ourSecretToken';

    return {
      token: bypassedToken,
      employeeId,
    };
  }

  public authenticateEmployee(): Observable<void> {
    const currentEmployeeId: string =
      this.storageService.get('employeeId') ||
      localStorage.getItem('employeeId');

    const isProductionCordova =
      environment.production && this.platformCheckerService.isCordova();

    const token$ = isProductionCordova
      ? AuthenticationService.getTokenFromMobilePlugin()
      : of(AuthenticationService.getTokenFromUrlSearchParams());

    return token$.pipe(
      concatMap(
        (token): Observable<any> => {
          if (environment.production && !token) {
            this.redirectToSSOLogin();
            return null;
          }

          return this.requestLogin(
            this.buildLoginParams(token, currentEmployeeId)
          );
        }
      ),
      filter((data): boolean => !!data),
      tap((data): void => {
        this.saveLoginData(data);
      })
    );
  }

  public saveLoginData(data: any): void {
    const authenticationData = authenticationDataBuilder(data);

    this.storageService.set(STORAGE_KEY.TOKEN, authenticationData.token);
    this.storageService.set(
      STORAGE_KEY.EMPLOYEE_ID,
      authenticationData.employeeId
    );
  }

  private requestLogin(params: any): Observable<any> {
    const authenticationUrl = API_ROUTE.AUTHENTICATION;
    return this.http.post<any>(
      `${authenticationUrl}`,
      params,
      this.httpOptions
    );
  }
}
