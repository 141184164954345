import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { WorkingDayPageModule } from '../working-day/working-day.module';
import {
  EmployeeLogsComponent,
  MonthLogComponent,
  MonthsListComponent,
  NegativeBalanceAdjustment,
  TeamListDesktopComponent,
  TeamListMobileComponent,
  TeamLogsComponent,
  TitleAndLegendsComponent,
} from './components';
import { WorkingDayItemDesktopComponent } from './components/month-log/working-day-item-desktop/working-day-item-desktop.component';
import { WorkingDayItemMobileComponent } from './components/month-log/working-day-item-mobile/working-day-item-mobile.component';
import { TeamMemberLogsPage } from './components/team-member-logs/team-member-logs.page';
import { LogsPage } from './logs.page';
import { logsRoutes } from './logs.routes';
import { SuccessMessageBalanceComponent } from './components/employee-logs/negative-balance-adjustment/success-message-balance/success-message-balance.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(logsRoutes),
    SharedModule,
    TranslateModule.forChild(),
    WorkingDayPageModule,
  ],
  declarations: [
    LogsPage,
    EmployeeLogsComponent,
    TeamLogsComponent,
    MonthLogComponent,
    MonthsListComponent,
    TitleAndLegendsComponent,
    TeamMemberLogsPage,
    WorkingDayItemDesktopComponent,
    WorkingDayItemMobileComponent,
    TeamListDesktopComponent,
    TeamListMobileComponent,
    NegativeBalanceAdjustment,
    SuccessMessageBalanceComponent,
  ],
  exports: [MonthsListComponent],
})
export class LogsPageModule {}
