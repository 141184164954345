<ion-toolbar class="title-and-legends">
  <ion-grid>
    <ion-row class="title-and-legends__main-row">
      <ion-col class="title-and-legends__title" sizeSm="3" *ngIf="!isOther">
        <h3>{{ 'LOGS.TITLE' | translate }}</h3>
      </ion-col>
      <ion-col
        class="title-and-legends__title--other"
        sizeSm="3"
        *ngIf="isOther"
      >
        <h3>{{ 'LOGS.OTHER_EMPLOYEE_TITLE' | translate }}</h3>
      </ion-col>
      <ion-col sizeMd="8" sizeSm="9" class="ion-align-items-stretch">
        <ion-row class="title-and-legends__legends">
          <ion-col
            class="title-and-legends__approval-status title-and-legends__approval-status--approved"
          >
            <ion-text>
              <span class="bullet bullet--approved"></span>
              <span>{{ 'LOGS.APPROVED_REQUEST' | translate }}</span>
            </ion-text>
          </ion-col>
          <ion-col
            class="title-and-legends__approval-status title-and-legends__approval-status--denied"
          >
            <ion-text>
              <span class="bullet bullet--denied"></span>
              <span>{{ 'LOGS.DENIED_REQUEST' | translate }}</span>
            </ion-text>
          </ion-col>
          <ion-col
            class="title-and-legends__approval-status title-and-legends__approval-status--pending"
          >
            <ion-text>
              <span class="bullet bullet--pending"></span>
              <span>{{ 'LOGS.PENDING_REQUEST' | translate }}</span>
            </ion-text>
          </ion-col>
          <ion-col class="title-and-legends__manual-log">
            <ion-text>
              <ion-icon icon="person"></ion-icon>
              <span>{{ 'LOGS.MANUAL_LOG' | translate }}</span>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row *ngFor="let disclaimer of disclaimers">
      <ion-col>
        <app-disclaimer [friendsAndFamily]="friendsAndFamily" [disclaimer]="disclaimer"></app-disclaimer>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-toolbar>
