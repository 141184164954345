<ng-container>
  <app-defect-use-case
    *ngIf="useCase === UseCase.Defect"
    [alternativeWorkingDayChosen]="alternativeWorkingDayChosen"
    [alternativeWorkingDay]="alternativeWorkingDay"
    [employeeWorkingDay]="employeeWorkingDay"
    [isReadonly]="isReadOnly"
    [isMobile]="isMobile"
    (confirmWorkingDay)="onWorkingDayConfirm()"
  ></app-defect-use-case>
  <app-perfect-use-case
    *ngIf="useCase === UseCase.Perfect"
    [alternativeWorkingDayChosen]="alternativeWorkingDayChosen"
    [alternativeWorkingDay]="alternativeWorkingDay"
    [employeeWorkingDay]="employeeWorkingDay"
    [isReadonly]="isReadOnly"
    [isMobile]="isMobile"
    (confirmWorkingDay)="onWorkingDayConfirm()"
  ></app-perfect-use-case>
  <app-excess-use-case
    *ngIf="useCase === UseCase.Excess"
    [employeeWorkingDay]="employeeWorkingDay"
    [alternativeWorkingDayChosen]="alternativeWorkingDayChosen"
    [alternativeWorkingDay]="alternativeWorkingDay"
    [isReadonly]="isReadOnly"
    [isModifyMode]="isModifyMode"
    [checkTimesEdited]="checkTimesEdited"
    (confirmWorkingDay)="onWorkingDayConfirm()"
  ></app-excess-use-case>
</ng-container>
