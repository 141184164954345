<ion-header *ngIf="isMobile" class="confirm-authorizer__header">
  <app-mobile-header
    class="confirm-authorizer__header-mobile"
    (closeModal)="onDismissModal(false)"
    [title]="headerTitle | translate"
  ></app-mobile-header>
</ion-header>

<ion-content class="modal-content">
  <ion-buttons *ngIf="!isMobile" class="modal-content__wrapper-icon">
    <ion-icon
      class="close-icon close-icon--content"
      size="large"
      name="close"
      (click)="onDismissModal()"
    ></ion-icon>
  </ion-buttons>

  <div class="modal-wrapper">
    <app-image
      class="image"
      src="assets/icon/success-tick.svg"
      [alt]="'ALT_IMG.SUCCESS_TICK' | translate"
    ></app-image>
    <section class="header">
      <span class="header__title">
        {{ 'WORKING_DAY.MODAL.CONFIRM_AUTHORIZER_MODAL.TEXT' | translate }}
      </span>
    </section>

    <section class="data">
      <span class="data__authorizer-name">
        {{ authorizerName }}
      </span>
    </section>

    <section class="button-wrapper">
      <ion-button class="button-wrapper__confirm" (click)="onDismissModal()">
        {{ 'WORKING_DAY.MODAL.AUTHORIZER_MODAL.BUTTON_ACCEPT' | translate }}
      </ion-button>
    </section>
  </div>
</ion-content>
