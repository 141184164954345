import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { EmployeeWorkingDay } from '@models/employee-working-day/employee-working-day';
import { isBeforeCutoffDate } from '@models/employee-working-day/employee-working-day-creator';
import {
  AuthorizationStatus,
  AuthorizationStatuses,
  DifferenceDataType,
  FullTime,
  InfoList,
  StaticText,
  TextBlock,
  TimeInfo,
  TimeInputData,
  TimeValues,
} from '@models/index';
import { StaticTextService } from '@services/index';
import {
  isReadOnly,
  isShowMode,
} from 'src/app/pages/working-day/helper/working-day.helper';
import { HumanizeDurationPipe } from 'src/app/shared/pipes/humanize-duration.pipe';
import { timeCalculationsFactory } from '../../../../factories';
import { TimeCalulationsInterface } from '../../../../interfaces';
import { WorkingDay } from '../../../../models/working-day';
import { TimeCalculationsService } from '../../../../services/time-calculations/time-calculations.service';
import { WorkingDayInfoComponent } from '../../../modals/working-day-info/working-day-info.component';
import {
  EXCESS_INPUT_KEY,
  getExcess,
  INFO_LIST,
} from '../../constants/difference-input-data.constant';
import {
  buildAuthorizerInfoModalOptions,
  buildRegiterTimeModalOptions,
  getSuccessInfo,
  isPastDay,
  sumDifferences,
  updateCompensationExplanation,
} from '../../helpers/step-2.helper';
import { DifferenceValue } from '../../models';

const extensionTimeExplanations = {
  [AuthorizationStatuses.APPROVED]: `${EXCESS_INPUT_KEY}.EXTENSION.EXPLANATION_AUTHORIZED`,
  [AuthorizationStatuses.REJECTED]: `${EXCESS_INPUT_KEY}.EXTENSION.EXPLANATION_REJECTED`,
  [AuthorizationStatuses.PENDING]: `${EXCESS_INPUT_KEY}.EXTENSION.EXPLANATION`,
};
@Component({
  selector: 'app-excess-use-case',
  templateUrl: './excess-use-case.component.html',
  styleUrls: ['./excess-use-case.component.scss'],
})
export class ExcessUseCaseComponent implements OnChanges, OnDestroy, OnInit {
  @Input() public isMobile: boolean;
  @Input() public isModifyMode: boolean;
  @Input() public isReadonly: boolean;
  @Input() public employeeWorkingDay: EmployeeWorkingDay;
  @Input() public alternativeWorkingDayChosen: boolean;
  @Input() public alternativeWorkingDay: WorkingDay;
  @Input() public checkTimesEdited: boolean;

  @Output() public confirmWorkingDay: EventEmitter<void> = new EventEmitter();

  public staticMessages: StaticText;
  public timeCalculationStrategy: TimeCalulationsInterface;
  public preliminaryDifferenceAgreement: TimeInfo[];
  public initialValues: FullTime;
  public effectiveWorkingTime: TimeInfo[];
  public previewCurrentBalance = 0;
  public differenceTimesEdited: boolean;
  public differenceInputs: TimeInputData[] = [];
  public savedValues: { [index: number]: { hours: string; minutes: string } };
  public maxStepLength = 250;
  public reasonTextAreaControl = new FormControl();

  private staticTextSubscription: Subscription;
  private differenceValues: { [type: number]: DifferenceValue } = {
    [DifferenceDataType.Personal]: {
      value: 0,
    },
    [DifferenceDataType.Extension]: {
      value: 0,
      shouldReason: true,
    },
    [DifferenceDataType.NegativeCompensation]: {
      value: 0,
    },
  };

  private isPastDay = false;
  private isShowMode = false;

  public constructor(
    private readonly timeCalculationsService: TimeCalculationsService,
    private readonly modalController: ModalController,
    private readonly staticTextService: StaticTextService,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    this.staticTextSubscription = this.staticTextService.staticText$.subscribe(
      (staticText): void => {
        if (staticText) {
          this.staticMessages = staticText;
        }
      }
    );

    this.initPreviewCurrentBalance();
    this.ngOnChanges();
  }

  public ngOnChanges(): void {
    this.timeCalculationStrategy = timeCalculationsFactory(
      this.employeeWorkingDay.workingDayData,
      this.employeeWorkingDay.rubric,
      this.alternativeWorkingDayChosen ? this.alternativeWorkingDay : null
    );

    this.checkTimesSubscription();
    updateCompensationExplanation(
      DifferenceDataType.Extension,
      this.differenceInputs,
      this.differenceValues,
      this.employeeWorkingDay.employeeInfo,
      this.isModifyMode,
      this.isReadonly,
      this.previewCurrentBalance
    );
    this.isPastDay = isPastDay(this.employeeWorkingDay);
    this.isShowMode = isShowMode(this.router.url);
    this.isReadonly = isReadOnly(
      this.employeeWorkingDay,
      this.isPastDay,
      this.isShowMode
    );
  }

  public shouldBeReasoned(): boolean {
    return (
      this.sumDifferences(true) > this.employeeWorkingDay.rubric.agreementMargin
    );
  }

  public canContinue(): boolean {
    return (
      !this.shouldBeReasoned() ||
      (!!this.reasonTextAreaControl.value &&
        this.reasonTextAreaControl.value.trim().length > 0)
    );
  }

  public onTextAreaChange(reason: string): void {
    this.reasonTextAreaControl.setValue(reason);
  }

  private updateExtensionExplanation(): void {
    const extensionInput = this.differenceInputs.find(
      (input): boolean => input.type === DifferenceDataType.Extension
    );

    if (extensionInput) {
      const extensionValue = this.differenceValues[DifferenceDataType.Extension]
        .value;

      const appliedMargin = this.timeCalculationsService.appliedMarginAgreement(
        this.employeeWorkingDay.rubric.agreementMargin,
        extensionValue
      );

      const pendingExtensionToApprove = this.timeCalculationsService.pendingExtensionTimeToApprove(
        extensionValue,
        this.employeeWorkingDay.rubric.agreementMargin,
        appliedMargin
      );

      const newAuthorizationStatus = this.getActualAuthorizationStatus();
      extensionInput.authorizationStatus = newAuthorizationStatus;

      extensionInput.explanation = {
        ...extensionInput.explanation,
        text: extensionTimeExplanations[newAuthorizationStatus],
        data: {
          ...extensionInput.explanation.data,
          appliedMargin: HumanizeDurationPipe.humanize(appliedMargin),
          pendingExtensionToApprove: HumanizeDurationPipe.humanize(
            pendingExtensionToApprove
          ),
        },
      };
    }
  }

  private checkTimesSubscription(): void {
    if (this.employeeWorkingDay.workingDayData.checkOut) {
      const timeValues = this.getTimeValues();

      const infoListWithValues = this.setInfoListTimes(timeValues);
      this.preliminaryDifferenceAgreement = [
        infoListWithValues.preliminaryDifferenceAgreement,
      ];

      this.setDifferenceInputsData();
      this.setDifferenceInputsInitialValues();
      this.restartValues();
      this.updateExtensionExplanation();
    }
  }

  public isRequestedCompensationAvailable(): boolean {
    const savedNegCompensation = this.employeeWorkingDay.workingDayData
      .differences.excess.negativeCompensation.time;
    return (
      Math.abs(
        this.previewCurrentBalance -
          savedNegCompensation +
          this.employeeWorkingDay.employeeInfo.lockedBalance
      ) >= Math.abs(this.getRequestedBalanceToCompensate())
    );
  }

  private getRequestedBalanceToCompensate(): number {
    return +this.differenceValues[DifferenceDataType.NegativeCompensation]
      .value;
  }

  public sumDifferences(onlyReasonable: boolean): number {
    const keys = [
      DifferenceDataType.Extension,
      DifferenceDataType.NegativeCompensation,
      DifferenceDataType.Personal,
    ];
    return sumDifferences(onlyReasonable, keys, this.differenceValues);
  }

  public isWorkplaceSelected(): boolean {
    return !(
      this.employeeWorkingDay.workingDayData.workLocation === undefined ||
      this.employeeWorkingDay.workingDayData.workLocation === '0'
    );
  }

  public workplaceMissing(): boolean {
    return (
      this.employeeWorkingDay.employeeInfo.hasRemoteWork &&
      !isBeforeCutoffDate(this.employeeWorkingDay.workingDayData.date) &&
      !this.isWorkplaceSelected()
    );
  }

  public async send(): Promise<void> {
    const timeValues = this.getTimeValues();

    const preliminaryDiffValue = Math.abs(timeValues.preliminaryDiffValue);
    const excessCompensation = this.isRequestedCompensationAvailable();
    const workplaceMissing = this.workplaceMissing();

    const isSuccess =
      this.sumDifferences(false) === preliminaryDiffValue &&
      excessCompensation &&
      !this.workplaceMissing();

    const distributedTime = this.sumDifferences(false) === preliminaryDiffValue;

    const differenceTimes = {};
    Object.keys(this.differenceValues).forEach(
      (differenceValueKey: string): void => {
        const differenceValue = this.differenceValues[differenceValueKey];
        if (
          [DifferenceDataType.Personal, DifferenceDataType.Extension].includes(
            +differenceValueKey
          )
        ) {
          differenceTimes[differenceValueKey] = differenceValue.value;
        }
      }
    );

    if (
      this.shouldShowAuthorizerInfoModal(
        isSuccess,
        excessCompensation,
        timeValues
      )
    ) {
      const modalOptions = buildAuthorizerInfoModalOptions(this.isMobile);
      const modal = await this.modalController.create(modalOptions);
      await modal.present();
      const dismissData = await modal.onDidDismiss();
      if (dismissData && dismissData.data) {
        this.continueSend(
          timeValues,
          differenceTimes,
          isSuccess,
          excessCompensation,
          distributedTime,
          workplaceMissing
        );
      }
    } else {
      this.continueSend(
        timeValues,
        differenceTimes,
        isSuccess,
        excessCompensation,
        distributedTime,
        workplaceMissing
      );
    }
  }

  private async continueSend(
    timeValues: TimeValues,
    differenceTimes: any,
    isSuccess: boolean,
    excessCompensation: boolean,
    distributedTime: boolean,
    workplaceMissing: boolean
  ): Promise<void> {
    const successData = getSuccessInfo(timeValues, true);

    const modalOptions = buildRegiterTimeModalOptions(
      differenceTimes,
      isSuccess,
      excessCompensation,
      this.employeeWorkingDay,
      this.alternativeWorkingDay,
      this.alternativeWorkingDayChosen,
      this.isPastDay,
      successData,
      true,
      false,
      this.reasonTextAreaControl.value,
      this.differenceValues,
      distributedTime,
      workplaceMissing
    );

    const modal = await this.modalController.create(modalOptions);
    await modal.present();
    const dismissData = await modal.onDidDismiss();
    if (dismissData && dismissData.data) {
      this.confirmWorkingDay.emit();
    }
  }

  public onDifferenceTimeChange(
    minutes: number,
    type: DifferenceDataType
  ): void {
    this.differenceTimesEdited = true;
    this.changeDifferenceTime(minutes, type);
  }

  public changeDifferenceTime(minutes: number, type: DifferenceDataType): void {
    this.differenceValues[type].value = minutes;
    this.updateEffectiveWorkTime();
    this.updateExtensionExplanation();
  }

  public getValueForDifferenceToCompensate(): number {
    const { Extension } = DifferenceDataType;
    const dataType = Extension;
    return this.differenceValues[dataType].value;
  }

  public getTimeInputInitialValues(differenceInput: TimeInputData): FullTime {
    const difference = this.differenceValues[differenceInput.type].value;

    return {
      hours: `0${Math.floor(difference / 60)}`.slice(-2),
      minutes: `0${difference % 60}`.slice(-2),
    };
  }

  private getTimeValues(): TimeValues {
    const preliminaryRegistration = this.timeCalculationStrategy.getPreliminaryRegister();

    const preliminaryRegistrationForComputation = this.timeCalculationStrategy.getPreliminaryTimeToComputed();

    const preliminaryDiffValue = this.timeCalculationStrategy.getPreliminaryDifWorkingDay();

    const effectiveWorkTime = this.timeCalculationStrategy.getEffectiveWorkingTime(
      this.differenceValues[DifferenceDataType.Personal].value,
      this.employeeWorkingDay.workingDayData.differences.excess.other.time,
      this.getActualAuthorizationStatus()
    );
    const extensionTime = this.differenceValues[DifferenceDataType.Extension]
      .value;

    const appliedMarginAgreement = this.timeCalculationStrategy.getAppliedMarginAgreement(
      extensionTime
    );

    const effectiveWorkTimeDifference = this.timeCalculationStrategy.getDifferenceToCompensate(
      this.getValueForDifferenceToCompensate(),
      this.getActualAuthorizationStatus()
    );

    return {
      preliminaryRegistration,
      preliminaryRegistrationForComputation,
      preliminaryDiffValue,
      appliedMarginAgreement,
      effectiveWorkTime,
      effectiveWorkTimeDifference,
    };
  }

  private async openInfoModal(
    title: string,
    infoAsList: TextBlock[]
  ): Promise<void> {
    const modalOptions = {
      component: WorkingDayInfoComponent,
      cssClass: 'register-time',
      componentProps: {
        infoAsList,
        title,
      },
    };
    const modal = await this.modalController.create(modalOptions);
    modal.present();
  }

  private setInfoListTimes(timeValues: TimeValues): InfoList {
    const preliminaryList = { ...INFO_LIST };

    const preliminaryDifferenceAgreement: TimeInfo = {
      ...preliminaryList.preliminaryDifferenceAgreement,
      time: timeValues.preliminaryDiffValue,
      moreInfo: (): void => {
        const infoAsListKey = 'greaterZero';
        const infoAsList = this.staticMessages
          ? this.staticMessages.messagesPreliminaryDiff[infoAsListKey]
          : [];

        const title = 'WORKING_DAY.MODAL.INFO.DIFFERENCE';
        this.openInfoModal(title, infoAsList);
      },
    };

    const effectiveWorkingTime: TimeInfo = {
      ...preliminaryList.effectiveWorkingTime,
      time: timeValues.effectiveWorkTime,
      subtime: timeValues.effectiveWorkTimeDifference,
    };

    return {
      preliminaryDifferenceAgreement,
      effectiveWorkingTime,
    };
  }

  private setDifferenceInputsData(): void {
    const shouldShowBalance = !this.employeeWorkingDay.employeeInfo
      .shouldHideBalance;
    const { currentBalance } = this.employeeWorkingDay.employeeInfo;
    const hasNotBalance = currentBalance === 0;

    this.differenceInputs = getExcess(
      this.hasNegativeBalance(shouldShowBalance, currentBalance, hasNotBalance),
      currentBalance
    );
  }

  private hasNegativeBalance(
    shouldShowBalance: boolean,
    currentBalance: number,
    hasNotBalance: boolean
  ): boolean {
    const hasNegativeCompensationNoBalance =
      hasNotBalance &&
      this.employeeWorkingDay.workingDayData.differences.excess
        .negativeCompensation.time > 0;

    return (
      (currentBalance < 0 || hasNegativeCompensationNoBalance) &&
      shouldShowBalance
    );
  }

  private shouldShowAuthorizationStatus(
    authorizationStatus: AuthorizationStatus
  ): boolean {
    return (
      authorizationStatus &&
      !this.checkTimesEdited &&
      !this.differenceTimesEdited
    );
  }

  private getActualAuthorizationStatus(): AuthorizationStatus {
    const { authorizationStatus } = this.employeeWorkingDay.workingDayData;

    return this.shouldShowAuthorizationStatus(authorizationStatus)
      ? authorizationStatus
      : AuthorizationStatuses.PENDING;
  }

  private setDifferenceInputsInitialValues(): void {
    const durationToTimeObject = (
      duration: number
    ): { hours: string; minutes: string } => ({
      hours: `0${Math.floor(duration / 60)}`.slice(-2),
      minutes: `0${duration % 60}`.slice(-2),
    });

    this.savedValues = {
      [DifferenceDataType.Personal as number]: durationToTimeObject(
        this.employeeWorkingDay.workingDayData.differences.excess.personal.time
      ),
      [DifferenceDataType.Extension as number]: durationToTimeObject(
        this.employeeWorkingDay.workingDayData.differences.excess.other.time
      ),
      [DifferenceDataType.NegativeCompensation as number]: durationToTimeObject(
        this.employeeWorkingDay.workingDayData.differences.excess
          .negativeCompensation.time
      ),
    };
  }

  private restartValues(): void {
    const {
      workingDayData: { differences },
    } = this.employeeWorkingDay;

    this.reasonTextAreaControl.setValue(differences.excess.other.reason);

    this.changeDifferenceTime(
      differences.excess.other.time,
      DifferenceDataType.Extension
    );

    const initialMinutes = Math.abs(
      this.preliminaryDifferenceAgreement[0].time || 0
    );
    const { isConfirmed } = this.employeeWorkingDay.workingDayData;

    this.changeDifferenceTime(
      isConfirmed ? differences.excess.personal.time : initialMinutes,
      DifferenceDataType.Personal
    );
    this.changeDifferenceTime(
      differences.excess.negativeCompensation.time,
      DifferenceDataType.NegativeCompensation
    );
  }

  public ngOnDestroy(): void {
    this.staticTextSubscription.unsubscribe();
  }

  private initPreviewCurrentBalance(): void {
    const { currentBalance } = this.employeeWorkingDay.employeeInfo;
    this.previewCurrentBalance = currentBalance;
    if (this.isModifyMode || this.isReadonly) {
      const { differences } = this.employeeWorkingDay.workingDayData;
      this.previewCurrentBalance =
        currentBalance - differences.excess.negativeCompensation.time;
    } else {
      this.previewCurrentBalance = this.employeeWorkingDay.employeeInfo.currentBalance;
    }
  }

  private shouldShowAuthorizerInfoModal(
    isSuccess: boolean,
    excessCompensation: boolean,
    timeValues: TimeValues
  ): boolean {
    return (
      !this.employeeWorkingDay.employeeInfo.authorizer &&
      isSuccess &&
      excessCompensation &&
      timeValues.effectiveWorkTimeDifference > 0
    );
  }

  private updateEffectiveWorkTime(): void {
    this.effectiveWorkingTime = [
      {
        ...INFO_LIST.effectiveWorkingTime,
        time: this.getTimeValues().effectiveWorkTime,
        subtime: this.getTimeValues().effectiveWorkTimeDifference,
      },
    ];
  }
}
