import { Component } from '@angular/core';
import { API_ROUTE } from '@constants/index';

@Component({
  selector: 'app-error-version',
  templateUrl: './error-version.component.html',
  styleUrls: ['./error-version.component.scss'],
})
export class ErrorVersionComponent {
  public android = API_ROUTE.ERROR_VERSION.ANDROID;
  public androidPersonal = API_ROUTE.ERROR_VERSION.ANDROID_PERSONAL;
}
