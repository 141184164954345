enum VersionChunk {
  Major = 0,
  Medium = 1,
  Minor = 2,
}

export class ThreeLevelVersion {
  public readonly major: number;
  public readonly medium: number;
  public readonly minor: number;

  public constructor(fullVersion: string) {
    const versionChunks = fullVersion.split('.');

    this.major = parseInt(versionChunks[VersionChunk.Major], 10);
    this.medium = parseInt(versionChunks[VersionChunk.Medium], 10);
    this.minor = parseInt(versionChunks[VersionChunk.Minor], 10);
  }

  public isLowerOrEqual(other: ThreeLevelVersion): boolean {
    const isHigherByMajor = this.major > other.major;
    const isMajorEqual = this.major === other.major;

    const isHigherByMedium = isMajorEqual && this.medium > other.medium;
    const isMediumEqual = this.medium === other.medium;

    const isHigherByMinor = isMediumEqual && this.minor > other.minor;

    return !isHigherByMajor && !isHigherByMedium && !isHigherByMinor;
  }
}
