<ion-content>
  <div class="error">
    <ion-icon class="error__icon" name="warning"></ion-icon>
    <span class="error__text">
      {{getErrorMessage() | translate}}
    </span>
    <ion-button
      class="error__button-home"
      (click)="restart()"
      *ngIf="this.errorService.shouldShowGoHome()"
    >
      {{'ERROR_PAGE.HOME' | translate}}
    </ion-button>
  </div>
</ion-content>
