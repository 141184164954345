<ion-card class="c-card agreement-margin-resume" *ngIf="workingDay">
  <ion-row class="agreement-margin-resume__section">
    <ion-col class="agreement-margin-resume__name">
      {{ 'WORKING_DAY.AGREEMENT_MARGIN' | translate }}
      <ion-icon
        class="margin-icon"
        ios="ios-information-circle"
        md="md-information-circle"
        (click)="openMarginModal()"
      >
      </ion-icon>
    </ion-col>
    <ion-col
      class="agreement-margin-resume__agreement agreement-margin-resume__time"
    >
      {{ agreementMargin | humanizeDuration }}
    </ion-col>
  </ion-row>
  <ion-row class="agreement-margin-resume__section">
    <ion-col class="agreement-margin-resume__name">
      {{ 'WORKING_DAY.REF_AGREEMENT_WORKING_DAY' | translate }}
    </ion-col>
    <ion-col
      class="agreement-margin-resume__agreement-ref agreement-margin-resume__time"
    >
      {{ refAgreementWorkingDay() | humanizeDuration }}
    </ion-col>
  </ion-row>
</ion-card>
