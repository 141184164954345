import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ROUTE } from '@constants/index';
import { TabsInterface, WorkingDayEmployee } from '@models/index';
import { EmployeeService } from '@services/index';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';

const isEmployeeLogsActivated = (router: Router): boolean => {
  return (
    router.url.startsWith(`/${ROUTE.LOGS}/${ROUTE.WORKING_DAY_DETAILS}`) &&
    !router.url.includes('show?id=')
  );
};

const isTeamLogsActivated = (router: Router): boolean => {
  return (
    router.url.startsWith(`/${ROUTE.LOGS}/${ROUTE.WORKING_DAY_DETAILS}`) &&
    router.url.includes('show?id=')
  );
};

@Component({
  selector: 'app-logs',
  templateUrl: './logs.page.html',
  styleUrls: ['./logs.page.scss'],
})
export class LogsPage implements OnDestroy {
  public tabs: TabsInterface[];
  public isMobile: boolean;
  public employeeSubscription: Subscription;
  public workingDayEmployee: WorkingDayEmployee;

  public constructor(
    private readonly platformCheckerService: PlatformCheckerService,
    private readonly employeeService: EmployeeService
  ) {
    this.isMobile = this.platformCheckerService.isMobile();
  }

  public ionViewDidEnter(): void {
    this.employeeSubscription = this.employeeService.employee$.subscribe(
      (employee: WorkingDayEmployee): void => {
        if (
          employee.isManager ||
          employee.isEmployeeDelegate ||
          employee.isAuthorizer
        ) {
          this.setTabsWhenManager();
        } else {
          this.setTabsWhenCommonEmployee();
        }
      }
    );
  }

  private setTabsWhenCommonEmployee(): void {
    this.tabs = [
      {
        title: 'LOGS.TABS.EMPLOYEE_LOGS',
        url: `${ROUTE.LOGS}/${ROUTE.EMPLOYEE_LOGS}`,
        isActivated: isEmployeeLogsActivated,
      },
    ];
  }

  private setTabsWhenManager(): void {
    this.tabs = [
      {
        title: 'LOGS.TABS.EMPLOYEE_LOGS',
        url: `${ROUTE.LOGS}/${ROUTE.EMPLOYEE_LOGS}`,
        isActivated: isEmployeeLogsActivated,
      },
      {
        title: 'LOGS.TABS.EMPLOYEE_TEAM_LOGS',
        url: `${ROUTE.LOGS}/${ROUTE.EMPLOYEE_TEAM_LOGS}`,
        isActivated: isTeamLogsActivated,
      },
    ];
  }

  public ngOnDestroy(): void {
    if (this.employeeSubscription) {
      this.employeeSubscription.unsubscribe();
    }
  }

  public getRouterClass(): string {
    if (this.isMobile && this.tabs.length === 1) {
      return 'outlet--no-tabs';
    }
    return '';
  }

  public hasRemoteWork(): boolean {
    return this.workingDayEmployee.hasRemoteWork;
  }
}
