import { FeatureFlagService } from '@services/index';
import { FEATURES } from 'src/environments/common';
import { WorkingDay } from '../../pages/working-day/models/working-day';
import { checkProperty } from '../utils/validations';
import { WorkingDayData } from './working-day-data';

export interface Manager {
  code: string;
  name: string;
}

export interface Authorizer {
  code: string;
  name: string;
  surnames: string;
  type: string;
}

export interface PermissionToRegisterInterface {
  permissionGTA: boolean;
  absenceOrHolidays: boolean;
  needRegisterWorkingDay: boolean;
  withoutPlanning: boolean;
}

export interface EmployeeInfo {
  firstName: string;
  lastName: string;
  id: string;
  isAuthorizer: boolean;
  isManager: boolean;
  isEmployeeDelegate: boolean;
  currentBalance: number;
  lockedBalance: number;
  shouldHideBalance: boolean;
  unconfirmedDaysCounter: number;
  currentDate?: string; // TODO Remove optionality when feature flag is deleted
  authorizer?: Authorizer; // TODO Remove optionality when feature flag is deleted
  manager?: Manager; // TODO Remove optionality when feature flag is deleted
  balancePreviousYears: number;
  shouldHaveHourlyMedicalLeave: boolean;
  hasRemoteWork: boolean;
  codCompany: string;
}

export interface Rubric {
  minimumBreakTime: number;
  workingDayInfo: string;
  agreementMargin: number;
  permissionRegister?: PermissionToRegisterInterface;
}

export interface WorkingDayDetail {
  workingDayData: WorkingDayData;
  rubric: Rubric;
  alternativeWorkingDay?: WorkingDay;
}

export interface WorkingDayEmployee {
  hasRemoteWork: boolean;
  shouldHaveHourlyMedicalLeave: boolean;
  firstName: string;
  lastName: string;
  id: string;
  isAuthorizer: boolean;
  isManager: boolean;
  isEmployeeDelegate: boolean;
  isDelegatingManager: boolean;
  currentBalance: number;
  lockedBalance: number;
  shouldHideBalance: boolean;
  closingMessage: string;
  unconfirmedDaysCounter?: number;
  currentDate?: string; // TODO Remove optionality when feature flag is deleted
  authorizer?: Authorizer; // TODO Remove optionality when feature flag is deleted
  manager?: Manager; // TODO Remove optionality when feature flag is deleted
  balancePreviousYears: number;
  codCompany?: string;
  friendsAndFamily?: boolean;
}

export interface EmployeeConfirmDay {
  selectedDay: Date;
  recoverableTime?: number;
  notRecoverableTime?: number;
  excessPersonalReasonsTime?: number;
  excessExtensionDayTime?: number;
  commentExtensionDay?: string;
  forceCounter?: boolean;
  hasAlternativeWorkingDay?: boolean;
  alternativeWorkingDay?: boolean;
  compensationNegativeBalance?: number;
  compensationPositiveBalance?: number;
  agreementMargin: number;
  checkInTimeHour?: string;
  checkInTimeMinutes?: string;
  systemInTimeHour?: string;
  systemInTimeMinutes?: string;
  checkOutTimeHour?: string;
  checkOutTimeMinutes?: string;
  systemOutTimeHour?: string;
  systemOutTimeMinutes?: string;
  checkInDate?: string;
  checkOutDate?: string;
  workLocation?: string;
  equalityPlan?: string;
  equalityPlanDescription?: string;
}

export class EmployeeBuilder {
  private constructor(
    private readonly rawData: any,
    featureFlagService: FeatureFlagService
  ) {
    this.isNightShiftAvailable = featureFlagService.isFeatureAvailable(
      FEATURES.NIGHT_SHIFT
    );
    this.isUnconfirmedDaysAvailable = featureFlagService.isFeatureAvailable(
      FEATURES.UNCONFIRMED_DAYS
    );
    this.isTemporalAuthorizerAvailable = featureFlagService.isFeatureAvailable(
      FEATURES.TEMPORAL_AUTHORIZER
    );
  }

  public workingDayEmployee: WorkingDayEmployee;
  private isNightShiftAvailable: boolean;
  private isUnconfirmedDaysAvailable: boolean;
  private isTemporalAuthorizerAvailable: boolean;

  public static factory(
    data: any,
    featureFlagService: FeatureFlagService
  ): EmployeeBuilder {
    const employeeBuilder = new EmployeeBuilder(data, featureFlagService);

    employeeBuilder.validateRawUserData();
    employeeBuilder.buildWorkingDayEmployee();
    return employeeBuilder;
  }

  private getClosingMessage(): string {
    return this.rawData.closingService && this.rawData.closingService.message
      ? this.rawData.closingService.message
      : null;
  }

  private buildWorkingDayEmployee(): void {
    this.workingDayEmployee = {
      firstName: this.rawData.employee.name,
      lastName: this.rawData.employee.surnames,
      id: this.rawData.employee.code,
      isAuthorizer: !!this.rawData.employee.isAuthorizer,
      isManager: !!this.rawData.employee.manager,
      isEmployeeDelegate: !!this.rawData.employee.employeeDelegate,
      isDelegatingManager: !!this.rawData.employee.delegatingManager,
      currentBalance: this.rawData.employee.currentBalance,
      lockedBalance: this.rawData.employee.lockedBalance,
      shouldHideBalance: this.rawData.employee.shouldHideBalance,
      shouldHaveHourlyMedicalLeave: this.rawData.employee
        .shouldHaveHourlyMedicalLeave,
      closingMessage: this.getClosingMessage(),
      balancePreviousYears: this.rawData.employee.balancePreviousYears,
      hasRemoteWork: this.rawData.employee.hasRemoteWork,
      codCompany: this.rawData.employee.codCompany,
      friendsAndFamily: this.rawData.employee.friendsAndFamily,
    };

    if (this.isNightShiftAvailable) {
      this.workingDayEmployee.currentDate = this.rawData.employee.currentDate;
    }

    if (this.isUnconfirmedDaysAvailable) {
      this.workingDayEmployee.unconfirmedDaysCounter = this.rawData.employee.unconfirmedDaysCounter;
    }

    if (this.isTemporalAuthorizerAvailable) {
      this.workingDayEmployee.authorizer = this.rawData.employee.authorizerInfo;
      this.workingDayEmployee.manager = this.rawData.employee.managerInfo;
    }
  }

  private validateRawUserData(): void {
    const userRequiredProperties = [
      { name: 'closingService', nullable: false },
      { name: 'employee', nullable: false },
    ];

    const userDataRequiredProperties = [
      { name: 'name', nullable: false },
      { name: 'surnames', nullable: false },
      { name: 'code', nullable: false },
      { name: 'employeeDelegate', nullable: true },
      { name: 'isAuthorizer', nullable: true },
      { name: 'manager', nullable: true },
      { name: 'delegatingManager', nullable: true },
      { name: 'currentBalance', nullable: true },
      { name: 'lockedBalance', nullable: true },
      { name: 'shouldHideBalance', nullable: true },
      { name: 'balancePreviousYears', nullable: true },
      { name: 'shouldHaveHourlyMedicalLeave', nullable: false },
      { name: 'hasRemoteWork', nullable: true },
    ];

    const authorizerDataRequiredProperties = [
      { name: 'name', nullable: false },
      { name: 'surnames', nullable: false },
      { name: 'code', nullable: false },
      { name: 'type', nullable: false },
    ];

    const managerDataRequiredProperties = [
      { name: 'code', nullable: false },
      { name: 'name', nullable: false },
    ];

    if (this.isNightShiftAvailable) {
      userDataRequiredProperties.push({ name: 'currentDate', nullable: true });
    }

    if (this.isUnconfirmedDaysAvailable) {
      userDataRequiredProperties.push({
        name: 'unconfirmedDaysCounter',
        nullable: true,
      });
    }

    if (this.isTemporalAuthorizerAvailable) {
      userDataRequiredProperties.push({
        name: 'authorizerInfo',
        nullable: true,
      });
    }

    if (this.isTemporalAuthorizerAvailable) {
      userDataRequiredProperties.push({
        name: 'managerInfo',
        nullable: true,
      });
    }

    userRequiredProperties.forEach((field: any): void => {
      checkProperty(this.rawData, 'Employees', field);
    });

    userDataRequiredProperties.forEach((field: any): void => {
      checkProperty(this.rawData.employee, 'Employees', field);
    });

    if (
      this.isTemporalAuthorizerAvailable &&
      this.rawData.employee.authorizerInfo
    ) {
      authorizerDataRequiredProperties.forEach((field: any): void => {
        checkProperty(
          this.rawData.employee.authorizerInfo,
          'Authorizer',
          field
        );
      });
    }

    if (
      this.isTemporalAuthorizerAvailable &&
      this.rawData.employee.managerInfo
    ) {
      managerDataRequiredProperties.forEach((field: any): void => {
        checkProperty(this.rawData.employee.managerInfo, 'Manager', field);
      });
    }
  }
}
