import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private data: { [index: string]: string } = {};

  public set(name: string, value: string): void {
    this.data[name] = value;
  }

  public check(name: string): boolean {
    return !!this.data[name];
  }

  public get(name: string): string {
    return this.data[name];
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public getAll(): {} {
    return this.data;
  }

  public remove(name: string): void {
    delete this.data[name];
  }

  public clearAll(): void {
    this.data = {};
  }
}
