import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-current-balance',
  templateUrl: './current-balance.component.html',
  styleUrls: ['./current-balance.component.scss'],
})
export class CurrentBalanceComponent {
  @Input() public value = 0;
  @Input() public lockedValue = 0;
}
