import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { STORAGE_KEY } from '@constants/index';
import { WorkingDayEmployee } from '@models/working-day-employee';
import {
  EmployeeService,
  PlatformCheckerService,
  StorageService,
} from '@services/index';
import { YearMonth } from '../../models/year-month';
import { LogsService } from '../../services/logs.service';

@Component({
  selector: 'app-team-member-logs',
  templateUrl: './team-member-logs.page.html',
  styleUrls: ['./team-member-logs.page.scss'],
})
export class TeamMemberLogsPage {
  public months: YearMonth[];
  public userName = '';
  public isLoading = false;
  public targetEmployeeId = '';
  public currentEmployeeId = '';
  public targetHasRemoteWork = null;
  public isMobile = false;

  public constructor(
    public activatedRoute: ActivatedRoute,
    public teamEmployeeService: EmployeeService,
    private readonly storageService: StorageService,
    private readonly logsService: LogsService,
    private readonly platformCheckerService: PlatformCheckerService
  ) {
    this.isMobile = this.platformCheckerService.isMobile();
    this.activatedRoute.params.subscribe((params: Params): void => {
      this.currentEmployeeId = this.storageService.get(STORAGE_KEY.EMPLOYEE_ID);

      this.isLoading = true;

      this.teamEmployeeService
        .getOtherEmployee(params.teamEmployeeId, this.currentEmployeeId)
        .pipe(
          concatMap(
            (employee: WorkingDayEmployee): Observable<YearMonth[]> => {
              this.userName = `${employee.firstName} ${employee.lastName}`;
              this.targetEmployeeId = employee.id;
              this.targetHasRemoteWork = employee.hasRemoteWork;

              return this.logsService.getAvailableYearMonths(
                employee.id,
                this.currentEmployeeId
              );
            }
          )
        )
        .subscribe((months: YearMonth[]): void => {
          this.isLoading = false;
          this.months = months;
        });
    });
  }
}
