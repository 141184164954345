import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-loading',
  templateUrl: './button-loading.component.html',
  styleUrls: ['./button-loading.component.scss'],
})
export class ButtonLoadingComponent {
  @Input() public isLoading: boolean;

  @Input() public buttonText: string;

  @Input() public isDisabled: boolean;

  @Output() public clickButton = new EventEmitter<any>();

  public emitClick(): void {
    this.clickButton.emit();
  }
}
