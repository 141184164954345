import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { EmployeeWorkingDay } from '@models/employee-working-day/employee-working-day';
import { WorkingDay } from 'src/app/pages/working-day/models/working-day';
import { timeCalculationsFactory } from '../../../../factories';

enum UseCase {
  Defect,
  Perfect,
  Excess,
}

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnChanges {
  public readonly UseCase = UseCase;

  public useCase: UseCase = this.UseCase.Perfect;
  @Input() public employeeWorkingDay: EmployeeWorkingDay;
  @Input() public isReadonly: boolean;
  @Input() public isMobile: boolean;
  @Input() public alternativeWorkingDayChosen: boolean;
  @Input() public alternativeWorkingDay: WorkingDay;
  @Input() public checkTimesEdited: boolean;
  @Input() public isModifyMode: boolean;

  @Output() public confirmWorkingDay: EventEmitter<void> = new EventEmitter();

  public ngOnChanges(): void {
    this.setCase();
  }

  public setCase(): void {
    const difference = this.getPreliminaryDifWorkingDay();
    if (difference < 0) {
      this.useCase = this.UseCase.Defect;
    } else if (difference > 0) {
      this.useCase = this.UseCase.Excess;
    } else {
      this.useCase = this.UseCase.Perfect;
    }
  }

  private getPreliminaryDifWorkingDay(): number {
    const {
      workingDayData,
      rubric,
      alternativeWorkingDay,
    } = this.employeeWorkingDay;
    return timeCalculationsFactory(
      workingDayData,
      rubric,
      alternativeWorkingDay
    ).getPreliminaryDifWorkingDay();
  }

  public onWorkingDayConfirm(): void {
    this.confirmWorkingDay.emit();
  }
}
