<ion-header *ngIf="isMobile()" class="edit-modal__header">
  <app-mobile-header
    (closeModal)="onDismissModal()"
    [title]="'WORKING_DAY.TITLE' | translate"
  ></app-mobile-header>
</ion-header>
<ion-content class="modal-content">
  <ion-buttons *ngIf="!isMobile()" class="modal-content__wrapper-icon">
    <ion-icon
      class="close-icon"
      size="large"
      name="close"
      (click)="onDismissModal()"
    ></ion-icon>
  </ion-buttons>

  <section class="header">
    <app-image
      class="header__icon"
      src="assets/icon/edit.svg"
      alt="{{ 'ALT_IMG.EDIT_ICON' | translate }}"
    ></app-image>
    <h2 class="header__title">{{ getTitleKey() | translate }}</h2>
    <p class="header__date">{{ getDateFromOtherCheckOut() }}</p>
  </section>

  <section class="data">
    <hr class="g-divider divider divider--top" />

    <div class="row working-day">
      <span class="working-day__name row__name">
        {{ 'REGISTER_TIME.MODAL_EDIT.WORKING_DAY' | translate }}
      </span>
      <span class="working-day__time row__time">
        {{ workingDay | humanizeDuration }}
      </span>
    </div>

    <div class="row other-check">
      <span class="other-check__name row__name">
        {{ getOtherCheckNameKey() | translate }}
      </span>
      <span class="other-check__time row__time">
        {{ getOtherCheckTimeFormatted() }}
      </span>
    </div>

    <hr class="g-divider divider divider--bottom" />
  </section>

  <section class="register">
    <h3 class="register__title">{{ getInputTitleKey() | translate }}</h3>
    <app-register-time
      [actionButtonLabel]="'UPDATE'"
      [isEditMode]="true"
      [shouldHideHeaderIcon]="true"
      [shouldShowCancel]="true"
      (cancel)="onDismissModal()"
      (saveTime)="onSaveTime($event)"
      [hasDateError]="!!dateErrorMessage"
      [dateErrorMessage]="dateErrorMessage"
      [hour]="getThisCheckTimeHour()"
      [minute]="getThisCheckTimeMinute()"
      [isCheckIn]="isCheckIn()"
      [isPastDay]="isPastDay"
      [workingDay]="workingDayEditing"
    ></app-register-time>
  </section>
  <section>
    <ion-row *ngFor="let disclaimer of disclaimersPopup">
      <ion-col>
        <app-disclaimer
          class="pop-up"
          [disclaimer]="disclaimer"
        ></app-disclaimer>
      </ion-col>
    </ion-row>
  </section>
</ion-content>
