import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { GLOBAL } from '@constants/global.constants';
import { CalendarDay } from '@models/datepicker';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  @Input() public daySelected: Date;
  @Output() public selectDate = new EventEmitter();

  public MONTHS_LABELS = [];
  public DAYS_LABELS = [];
  public weeks: any;
  public years: number[];

  public yearSelected = new Date().getFullYear();
  public monthSelected = new Date().getMonth() + 1;
  public currentYear = new Date().getFullYear();
  public currentMonth = new Date().getMonth() + 1;
  public currentDay = new Date().getDate();
  public dayHighlighted: number;
  public monthHighlighted: number;
  public yearHighlighted: number;

  public constructor(private readonly translate: TranslateService) {}

  public ngOnInit(): void {
    this.initCalendarLabels();
    this.initOptions();
    this.setWeeks();
  }

  public initOptions(): void {
    this.yearSelected = new Date().getFullYear();
    this.monthSelected = new Date().getMonth() + 1;
    this.setDateHighlighted();
  }

  public initCalendarLabels(): void {
    moment.locale(GLOBAL.ES_LOCALE);
    this.MONTHS_LABELS = moment
      .months()
      .map(
        (month: string): string =>
          month.charAt(0).toUpperCase() + month.slice(1)
      );
    this.DAYS_LABELS = moment
      .weekdaysShort(true)
      .map((month: string): string => month.slice(0, 3).toUpperCase());
  }

  public setDateHighlighted(): void {
    if (this.daySelected) {
      this.currentYear = this.daySelected.getFullYear();
      this.currentMonth = this.daySelected.getMonth() + 1;
      this.currentDay = this.daySelected.getDate();
      this.yearSelected = this.daySelected.getFullYear();
      this.monthSelected = this.daySelected.getMonth() + 1;
      this.dayHighlighted = this.currentDay;
      this.monthHighlighted = this.currentMonth;
      this.yearHighlighted = this.currentYear;
    }
  }

  public get daysMonthViewData(): CalendarDay[] {
    return [
      ...this.daysPreviousMonthToShow,
      ...this.daysMonthToShow,
      ...this.daysNextMonthToShow,
    ];
  }

  public get lastDayPreviousMonth(): number {
    return new Date(this.yearSelected, this.monthSelected - 1, 0).getDate();
  }

  public get lastDayMonth(): number {
    return new Date(this.yearSelected, this.monthSelected, 0).getDate();
  }

  public get weekLastDayMonth(): number {
    return new Date(this.yearSelected, this.monthSelected, 0).getDay();
  }

  public setWeeks(): void {
    this.weeks = this.daysMonthViewData.reduce(
      (weeks, day): any[][] => {
        let week = weeks.pop();
        if (week.length === 7) {
          weeks.push(week);
          week = [day];
        } else {
          week.push(day);
        }
        weeks.push(week);

        return weeks;
      },
      [[]]
    );
  }

  public previous(): void {
    if (this.monthSelected === 1) {
      this.monthSelected = 12;
      this.yearSelected -= 1;
    } else {
      this.monthSelected -= 1;
    }

    this.setWeeks();
  }

  public next(): void {
    if (this.monthSelected === 12) {
      this.monthSelected = 1;
      this.yearSelected += 1;
    } else {
      this.monthSelected += 1;
    }

    this.setWeeks();
  }

  public selectDay(day: CalendarDay): void {
    if (!day.off) {
      const date = new Date(
        this.yearSelected,
        this.monthSelected - 1,
        day.dayOfMonth
      );
      this.daySelected = date;
      this.selectDate.emit(date);
    }
  }

  public get daysPreviousMonthToShow(): CalendarDay[] {
    const firstWeekDayOfMonth =
      new Date(this.yearSelected, this.monthSelected - 1, 1).getDay() - 1;

    const numDaysPreviousMonth =
      firstWeekDayOfMonth === -1 ? 6 : firstWeekDayOfMonth;

    return Array(numDaysPreviousMonth)
      .fill(this.lastDayPreviousMonth)
      .map((value, key): number => value - key)
      .reverse()
      .map((day): { dayOfMonth: number; off: boolean } => ({
        dayOfMonth: day,
        off: true,
      }));
  }

  public get daysMonthToShow(): CalendarDay[] {
    return Array(this.lastDayMonth)
      .fill(0)
      .map(
        (value, key): CalendarDay => {
          const dia = key + 1;
          return { dayOfMonth: dia, off: false };
        }
      );
  }

  public get daysNextMonthToShow(): CalendarDay[] {
    const daysNextMonthToShow =
      this.weekLastDayMonth === 0 ? 0 : 7 - this.weekLastDayMonth;

    return Array(daysNextMonthToShow)
      .fill(0)
      .map(
        (value, key): CalendarDay => {
          const dia = key + 1;
          return { dayOfMonth: dia, off: true };
        }
      );
  }

  public getDisabledClass(day: CalendarDay): string {
    const defaultClass = 'day';
    if (day.off) {
      return `${defaultClass} day-off`;
    }

    if (this.isDayHighlighted(day.dayOfMonth)) {
      return `${defaultClass} day-selected`;
    }

    return defaultClass;
  }

  public isDayHighlighted(day): boolean {
    return (
      this.yearSelected === this.yearHighlighted &&
      this.monthSelected === this.monthHighlighted &&
      day === this.dayHighlighted
    );
  }
}
