<ion-grid class="wrapper" [class.wrapper--no-padding-bottom]="noPaddingBottom">
  <ion-row
    *ngFor="
      let info of infoList;
      first as isFirst;
      let idx = index;
      trackBy: trackByFn
    "
    [ngClass]="getRowClass(info, isFirst)"
  >
    <ion-col size="7">
      <div class="row__description text text--with-info">
        {{ info.description | translate }}
        <ion-icon
          *ngIf="!!info.moreInfo"
          class="more-info"
          icon="information-circle"
          (click)="info.moreInfo()"
        ></ion-icon>
      </div>
      <div
        *ngIf="info.subdescription && info.subtime !== 0"
        class="row__subdescription text text--subdescription"
      >
        {{ info.subdescription | translate }}
      </div>
    </ion-col>

    <ion-col class="time-col" [size]="getTimeColSize()">
      <div class="row__time text">
        {{ info.time | humanizeDuration }}
      </div>
      <div *ngIf="info.subtime" class="row__subtime text--secondary">
        {{ info.subtime | humanizeDuration }}
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
