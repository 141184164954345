<ng-container>
  <ion-spinner class="g-spinner" *ngIf="isLoading"></ion-spinner>
  <ng-container *ngIf="!isLoading">
    <app-working-day-item-desktop
      [dayLogs]="dayLogs"
      [readOnly]="readOnly"
      *ngIf="!isMobile"
      [targetEmployeeId]="targetEmployeeId"
      [targetHasRemoteWork]="targetHasRemoteWork"
    ></app-working-day-item-desktop>
    <app-working-day-item-mobile
      [dayLogs]="dayLogs"
      [readOnly]="readOnly"
      *ngIf="isMobile"
      [targetEmployeeId]="targetEmployeeId"
      [targetHasRemoteWork]="targetHasRemoteWork"
    ></app-working-day-item-mobile>
  </ng-container>
</ng-container>
