import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

function getCharNumber(reasonTextAreaValue: string): number {
  const valuedReason = reasonTextAreaValue || '';

  const characters = valuedReason.length;
  const lineBreak = valuedReason.split('\n').length - 1;
  const addLineBreak = lineBreak * 2;

  return characters + addLineBreak;
}

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements OnInit {
  @Input() public isMobile = false;
  @Input() public maxLength = 250;
  @Input() public reasonTextAreaControl = new FormControl();
  @Input() public isReadOnly = false;
  @Input() public placeholder = '';

  @Output() public changeTextarea = new EventEmitter<string>();

  public characters = 0;
  public rowsCapacity = 5;

  public ngOnInit(): void {
    this.characters = getCharNumber(this.reasonTextAreaControl.value);

    if (!this.isMobile) {
      this.rowsCapacity = 8;
    }
  }

  public getTrimmedText(newValue: string): string {
    const charsToDiscount = newValue
      .split('')
      .reduce((prev, c): number => prev + (c === '\n' ? 2 : 0), 0);

    return newValue.slice(0, this.maxLength - charsToDiscount);
  }

  public onReasonFormChange(event: any): void {
    this.characters = getCharNumber(event.target.value);

    const trimmedText = this.getTrimmedText(event.target.value);
    this.reasonTextAreaControl.setValue(trimmedText);
    this.changeTextarea.emit(trimmedText);
  }
}
