<ion-col class="jne-link" (click)="showJneInfo()">
  <ion-icon
    class="jne-link-icon"
    ios="ios-information-circle"
    md="md-information-circle"
  ></ion-icon>
  <span class="jne-link-label">
    {{ 'WORKING_DAY.INDIVIDUAL_AGREEMENT_DAY' | translate }}
  </span>
</ion-col>
