import {
  DifferenceDataType,
  InfoList,
  TimeInfoRowType,
  TimeInputData,
  TimeInputDataType,
} from '@models/index';
import { HumanizeDurationPipe } from 'src/app/shared/pipes/humanize-duration.pipe';

const STEP_2_KEY = 'WORKING_DAY.STEP_2';
const DEFECT_INPUT_KEY = `${STEP_2_KEY}.DIFFERENCE_INPUT.DEFECT`;
export const EXCESS_INPUT_KEY = `${STEP_2_KEY}.DIFFERENCE_INPUT.EXCESS`;
const LOCALE_DIFFERENCE_KEY = `${STEP_2_KEY}.DIFFERENCE`;
const LOCALE_EFFECTIVE_WORKING_TIME = `${STEP_2_KEY}.EFFECTIVE_WORKING_TIME`;
const PERSONAL_EXCESS_TIME_INPUT = {
  avatar: {
    image: 'assets/icon/personal-motives.svg',
    alt: `${EXCESS_INPUT_KEY}.PERSONAL_REASONS.TITLE`,
  },
  class: 'personal-excess',
  title: `${EXCESS_INPUT_KEY}.PERSONAL_REASONS.TITLE`,
  type: DifferenceDataType.Personal,
  sumSign: TimeInputDataType.Negative,
};

const negativeCompensationTimeInput = (
  currentBalance: number
): TimeInputData => ({
  avatar: {
    image: 'assets/icon/balance.svg',
    alt: `${EXCESS_INPUT_KEY}.NEGATIVE_BALANCE.TITLE`,
  },
  class: 'negative-compensation',
  title: `${EXCESS_INPUT_KEY}.NEGATIVE_BALANCE.TITLE`,
  explanation: {
    text: `${EXCESS_INPUT_KEY}.NEGATIVE_BALANCE.EXPLANATION`,
    data: { currentBalance: HumanizeDurationPipe.humanize(currentBalance) },
  },
  type: DifferenceDataType.NegativeCompensation,
  sumSign: TimeInputDataType.None,
});

const EXTENSION_TIME_INPUT = {
  avatar: {
    image: 'assets/icon/difference-excess-extension.svg',
    alt: `${EXCESS_INPUT_KEY}.EXTENSION.TITLE`,
  },
  class: 'extension-excess',
  title: `${EXCESS_INPUT_KEY}.EXTENSION.TITLE`,
  explanation: {
    text: `${EXCESS_INPUT_KEY}.EXTENSION.EXPLANATION`,
    data: { appliedMargin: 0, pendingExtensionToApprove: 0 },
  },
  type: DifferenceDataType.Extension,
  shouldReason: true,
  sumSign: TimeInputDataType.None,
  authorizationStatus: null,
};

const RECOVERABLE_TIME_INPUT = {
  avatar: {
    image: 'assets/icon/difference-deffect-recoverable.svg',
    alt: `${DEFECT_INPUT_KEY}.RECOVERABLE.TITLE`,
  },
  class: 'recoverable-defect',
  title: `${DEFECT_INPUT_KEY}.RECOVERABLE.TITLE`,
  explanation: {
    text: `${DEFECT_INPUT_KEY}.RECOVERABLE.EXPLANATION`,
    textBBVA: `${DEFECT_INPUT_KEY}.RECOVERABLE.EXPLANATION`,
  },
  type: DifferenceDataType.Recoverable,
  sumSign: TimeInputDataType.None,
};

const POSITIVE_COMPENSATION_TIME_INPUT = {
  avatar: {
    image: 'assets/icon/balance.svg',
    alt: `${DEFECT_INPUT_KEY}.POSITIVE_BALANCE.TITLE`,
  },
  class: 'positive-compensation',
  title: `${DEFECT_INPUT_KEY}.POSITIVE_BALANCE.TITLE`,
  explanation: {
    text: `${DEFECT_INPUT_KEY}.POSITIVE_BALANCE.EXPLANATION`,
    data: { currentBalance: HumanizeDurationPipe.humanize(0) },
  },
  type: DifferenceDataType.PositiveCompensation,
  sumSign: TimeInputDataType.None,
};

const UNRECOVERABLE_TIME_INPUT = {
  avatar: {
    image: 'assets/icon/difference-deffect-unrecoverable.svg',
    alt: `${DEFECT_INPUT_KEY}.UNRECOVERABLE.TITLE`,
  },
  class: 'unrecoverable-defect',
  title: `${DEFECT_INPUT_KEY}.UNRECOVERABLE.TITLE`,
  explanation: {
    text: `${DEFECT_INPUT_KEY}.UNRECOVERABLE.EXPLANATION`,
    textBBVA: `${DEFECT_INPUT_KEY}.UNRECOVERABLE.EXPLANATION_BBVA`,
  },
  sumSign: TimeInputDataType.Positive,
  type: DifferenceDataType.Unrecoverable,
};

const UNRECOVERABLE_MEDICAL_HOURS_TIME_INPUT = {
  avatar: {
    image: 'assets/icon/difference-deffect-unrecoverable.svg',
    alt: `${DEFECT_INPUT_KEY}.UNRECOVERABLE_MEDICAL_HOURS.TITLE`,
  },
  class: 'unrecoverable-defect',
  title: `${DEFECT_INPUT_KEY}.UNRECOVERABLE_MEDICAL_HOURS.TITLE`,
  explanation: {
    text: `${DEFECT_INPUT_KEY}.UNRECOVERABLE_MEDICAL_HOURS.EXPLANATION`,
    textBBVA: `${DEFECT_INPUT_KEY}.UNRECOVERABLE_MEDICAL_HOURS.EXPLANATION_BBVA`,
  },
  sumSign: TimeInputDataType.Positive,
  type: DifferenceDataType.Unrecoverable,
};

export function getExcess(
  hasBalance: boolean,
  currentBalance: number
): TimeInputData[] {
  return hasBalance
    ? [
        PERSONAL_EXCESS_TIME_INPUT,
        negativeCompensationTimeInput(currentBalance),
        EXTENSION_TIME_INPUT,
      ]
    : [PERSONAL_EXCESS_TIME_INPUT, EXTENSION_TIME_INPUT];
}

export function getDefect(
  hasBalance: boolean,
  hasShouldHaveHourlyMedicalLeave: boolean
): TimeInputData[] {
  const unrecoverableTime = hasShouldHaveHourlyMedicalLeave
    ? UNRECOVERABLE_MEDICAL_HOURS_TIME_INPUT
    : UNRECOVERABLE_TIME_INPUT;
  return hasBalance
    ? [
        RECOVERABLE_TIME_INPUT,
        unrecoverableTime,
        POSITIVE_COMPENSATION_TIME_INPUT,
      ]
    : [RECOVERABLE_TIME_INPUT, unrecoverableTime];
}

export const INFO_LIST: InfoList = {
  preliminaryDifferenceAgreement: {
    type: TimeInfoRowType.Main,
    description: `${LOCALE_DIFFERENCE_KEY}.DESCRIPTION`,
    time: null,
  },
  effectiveWorkingTime: {
    type: TimeInfoRowType.Main,
    description: `${LOCALE_EFFECTIVE_WORKING_TIME}.TITLE`,
    subdescription: `${LOCALE_EFFECTIVE_WORKING_TIME}.EXPLANATION`,
    time: null,
    subtime: null,
  },
};
