<ion-card class="c-card c-card--min-height">
  <section class="success-message">
    <app-image
      class="success-message__tick"
      src="assets/icon/success-tick.svg"
      [alt]="'ALT_IMG.SUCCESS_TICK' | translate"
    ></app-image>
    <h4 class="success-message__title">
      {{ 'WORKING_DAY.CONFIRMATION.THANKS' | translate }}
    </h4>
    <p class="success-message__desc">
      {{ 'WORKING_DAY.CONFIRMATION.SUCCESS' | translate }}
    </p>
  </section>
</ion-card>
