import { Component, Input } from '@angular/core';
import { TimeInfo, TimeInfoRowType } from '@models/time-info-card';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';

@Component({
  selector: 'app-time-info-card',
  templateUrl: './time-info-card.component.html',
  styleUrls: ['./time-info-card.component.scss'],
})
export class TimeInfoCardComponent {
  @Input() public infoList: TimeInfo[] = [];
  @Input() public noPaddingBottom = false;

  public isMobile: boolean;

  public constructor(
    private readonly platformCheckerService: PlatformCheckerService
  ) {
    this.isMobile = this.platformCheckerService.isMobile();
  }

  // eslint-disable-next-line class-methods-use-this
  public trackByFn(index, item): string {
    return item.id;
  }

  // eslint-disable-next-line class-methods-use-this
  public getRowClass(
    infoRow: TimeInfo,
    isFirst: boolean
  ): { [key: string]: boolean } {
    return {
      row: true,
      'row--main': infoRow.type === TimeInfoRowType.Main,
      'row--first': isFirst,
    };
  }

  public getTimeColSize(): number {
    return this.isMobile ? 4 : 5;
  }
}
