import { HttpClient } from '@angular/common/http';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API_ROUTE, ROUTE } from '@constants/index';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';
import { ErrorService } from '@services/error/error.service';

declare const device: any;

@Injectable({
  providedIn: 'root',
})
export class MinVersionService {
  public version: string;
  public isCordova = false;

  public constructor(
    private readonly http: HttpClient,
    private readonly errorService: ErrorService,
    private readonly router: Router,
    private readonly platformCheckerService: PlatformCheckerService,
    private readonly appVersion: AppVersion
  ) {
    this.isCordova = this.platformCheckerService.isCordova();
  }

  public getMinVersion(): void {
    const url = API_ROUTE.MIN_VERSION;
    this.http.get<any>(`${url}`).subscribe((data: any): void => {
      try {
        const isAndroid = this.platformCheckerService.isCordova()
          ? device.platform === 'Android'
          : false;

        if (isAndroid) {
          this.appVersion.getVersionNumber().then((version): void => {
            this.checkMinimumVersion(version, data);
          });
        }
      } catch (err) {
        this.errorService.set('ERROR_DEFAULT', err);
      }
    });
  }

  public checkMinimumVersion(actualVersion: string, data: any): void {
    const localVersion = actualVersion.split('.').join('');
    const minVersion = data.minVersion.split('.').join('');
    if (Number(localVersion) < Number(minVersion)) {
      this.router.navigate([ROUTE.ERROR_VERSION]);
    }
  }
}
