import _ from 'lodash';
import { checkProperty } from '../../../shared/utils/validations';

export interface TeamMember {
  employeeId: string;
  fullName: string;
  currentBalance: number;
  lockedBalance: number;
  balancePreviousYears: number;
}

export class TeamMemberListInterface {
  public pages: string;
  public listTeamMember: TeamMember[];
}

export function validateTeamMember(rawTeamMember: any): void {
  const teamMemberRequiredProperties = [
    { name: 'code', nullable: false },
    { name: 'name', nullable: false },
  ];

  teamMemberRequiredProperties.forEach((field: any): void => {
    checkProperty(rawTeamMember, 'Team member', field);
  });
}

export function buildTeamMember(rawTeamMember: any): TeamMember {
  validateTeamMember(rawTeamMember);
  return {
    fullName: rawTeamMember.name,
    employeeId: rawTeamMember.code,
    currentBalance: rawTeamMember.currentBalance,
    balancePreviousYears: rawTeamMember.balancePreviousYears,
    lockedBalance: rawTeamMember.lockedBalance || 0,
  };
}

export function buildTeamMemberList(
  rawTeamMemberList: any
): TeamMemberListInterface {
  const listEmployeesTeamManager = _.uniqBy(
    rawTeamMemberList.listEmployeesTeamManager,
    'code'
  );

  return {
    pages: rawTeamMemberList.numPages,
    listTeamMember: listEmployeesTeamManager.map(buildTeamMember),
  };
}
