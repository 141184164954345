import moment from 'moment';
import { AuthorizationStatus } from '.';
import { getCheckDayDate } from '../utils';
import { buildDateWithTime } from '../utils/date-parser/date-parser';

export class Time {
  public constructor(public time = 0) {}
}

export class ExcessOtherTime extends Time {
  public constructor(
    public time: number,
    public reason: string,
    public authorizedTime: number
  ) {
    super(time);
  }
}

export interface WorkingDayDataDifferences {
  excess: {
    personal: Time;
    other: ExcessOtherTime;
    negativeCompensation: Time;
  };
  deffect: {
    recoverable: Time;
    unrecoverable: Time;
    positiveCompensation: Time;
  };
}

export interface WorkingDayDataInterface {
  date: Date;
  workingDay: number;
  isConfirmed: boolean;
  authorizationStatus: AuthorizationStatus;
  isAlternativeChosen: boolean;
  differences: WorkingDayDataDifferences;
  differenceToCompensate: number;
  skipBreakTimeLimit: number;
  minRegisterTime?: number;
  maxRegisterTime?: number;
  isJNE?: boolean;
  workLocation?: string;
  workplace: string;
  equalityPlan: string;
  equalityPlanDescription: string;
}

export class WorkingDayData implements WorkingDayDataInterface {
  private privateCheckOut: Date;
  private privateCheckIn: Date;
  private privateDate: Date;
  private privateMinRegisterTime?: number;
  private privateIsEditedCheckIn = false;
  private privateIsEditedCheckOut = false;

  public workingDay: number;
  public isConfirmed: boolean;
  public authorizationStatus: AuthorizationStatus;
  public isAlternativeChosen: boolean;
  public differences: WorkingDayDataDifferences;
  public differenceToCompensate: number;
  public skipBreakTimeLimit: number;

  public maxRegisterTime?: number;
  public isJNE?: boolean;

  public workLocation: string;

  public workplace: string;
  public equalityPlan: string;
  public equalityPlanDescription: string;

  public get checkIn(): Date {
    return this.privateCheckIn;
  }

  public set checkIn(time: Date) {
    if (
      !this.privateCheckIn ||
      !moment(time).isSame(moment(this.privateCheckIn), 'minutes')
    ) {
      this.privateIsEditedCheckIn = true;
      this.privateCheckIn = time ? this.setCheckTime(time) : null;
    }
  }

  public get checkOut(): Date {
    return this.privateCheckOut;
  }

  public set checkOut(time: Date) {
    if (
      !this.privateCheckOut ||
      !moment(time).isSame(moment(this.privateCheckOut), 'minutes')
    ) {
      this.privateIsEditedCheckOut = true;
      this.privateCheckOut = time ? this.setCheckTime(time) : null;
    }
  }

  public get date(): Date {
    return this.privateDate;
  }

  public set date(date: Date) {
    this.privateDate = date;
    this.recalculateCheckTimes();
  }

  public get minRegisterTime(): number {
    return this.privateMinRegisterTime;
  }

  public set minRegisterTime(time: number) {
    this.privateMinRegisterTime = time;
    this.recalculateCheckTimes();
  }

  public get isEditedCheckIn(): boolean {
    return this.privateIsEditedCheckIn;
  }

  public get isEditedCheckOut(): boolean {
    return this.privateIsEditedCheckOut;
  }

  private constructor() {
    // do nothing.
  }

  public static factory(
    data: WorkingDayDataInterface,
    checkIn: Date,
    checkOut: Date
  ): WorkingDayData {
    const workingDayData = new WorkingDayData();

    workingDayData.date = data.date;
    workingDayData.workingDay = data.workingDay;
    workingDayData.isConfirmed = data.isConfirmed;
    workingDayData.authorizationStatus = data.authorizationStatus;
    workingDayData.isAlternativeChosen = data.isAlternativeChosen;
    workingDayData.differences = data.differences;
    workingDayData.differenceToCompensate = data.differenceToCompensate;
    workingDayData.skipBreakTimeLimit = data.skipBreakTimeLimit;
    workingDayData.minRegisterTime = data.minRegisterTime;
    workingDayData.maxRegisterTime = data.maxRegisterTime;
    workingDayData.privateCheckIn = checkIn;
    workingDayData.privateCheckOut = checkOut;
    workingDayData.isJNE = data.isJNE;
    workingDayData.workLocation = data.workLocation;
    workingDayData.workplace = data.workplace;
    workingDayData.equalityPlan = data.equalityPlan;
    workingDayData.equalityPlanDescription = data.equalityPlanDescription;

    workingDayData.privateIsEditedCheckIn = false;
    workingDayData.privateIsEditedCheckOut = false;

    return workingDayData;
  }

  private setCheckTime(time: Date): Date {
    const timeWithDate = buildDateWithTime(
      moment(time).format('HH:mm'),
      this.date
    );

    return getCheckDayDate(timeWithDate, this.minRegisterTime);
  }

  private recalculateCheckTimes(): void {
    this.checkIn = this.privateCheckIn;
    this.checkOut = this.privateCheckOut;
  }
}
