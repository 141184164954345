import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { TextareaComponent } from '@components/textarea/textarea.component';
import {
  AuthorizationItemComponent,
  AuthorizationItemMobileComponent,
  AuthorizerComponent,
  ButtonLoadingComponent,
  BalancePreviousYearsComponent,
  CalendarComponent,
  CurrentBalanceComponent,
  DatepickerComponent,
  DisclaimerComponent,
  DropDownEmployeeComponent,
  ImageComponent,
  MemberAvatarComponent,
  MenuComponent,
  MessageComponent,
  MobileHeaderComponent,
  RegisterTimeComponent,
  RegisterTimeHeaderComponent,
  SearchEmployeeComponent,
  SuccessDataModalComponent,
  TabsComponent,
  UserGreetingComponent,
} from './components';
import { HeaderComponent } from './components/header/header.component';
import { HumanizeDurationPipe } from './pipes/humanize-duration.pipe';
import { ValueOrHyphenPipe } from './pipes/value-or-hyphen.pipe';

@NgModule({
  declarations: [
    AuthorizerComponent,
    AuthorizationItemComponent,
    AuthorizationItemMobileComponent,
    BalancePreviousYearsComponent,
    CalendarComponent,
    CurrentBalanceComponent,
    DatepickerComponent,
    DisclaimerComponent,
    DropDownEmployeeComponent,
    HeaderComponent,
    HumanizeDurationPipe,
    ImageComponent,
    MenuComponent,
    MessageComponent,
    MobileHeaderComponent,
    RegisterTimeComponent,
    RegisterTimeHeaderComponent,
    SearchEmployeeComponent,
    TabsComponent,
    UserGreetingComponent,
    ValueOrHyphenPipe,
    MemberAvatarComponent,
    TextareaComponent,
    SuccessDataModalComponent,
    ButtonLoadingComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forChild(),
  ],
  exports: [
    AuthorizerComponent,
    AuthorizationItemComponent,
    AuthorizationItemMobileComponent,
    BalancePreviousYearsComponent,
    CalendarComponent,
    DatepickerComponent,
    DisclaimerComponent,
    DropDownEmployeeComponent,
    HeaderComponent,
    HumanizeDurationPipe,
    ImageComponent,
    MenuComponent,
    MessageComponent,
    MobileHeaderComponent,
    RegisterTimeComponent,
    RegisterTimeHeaderComponent,
    SearchEmployeeComponent,
    TabsComponent,
    UserGreetingComponent,
    ValueOrHyphenPipe,
    MemberAvatarComponent,
    CurrentBalanceComponent,
    SuccessDataModalComponent,
    TextareaComponent,
    ButtonLoadingComponent,
  ],
  entryComponents: [SuccessDataModalComponent],
})
export class SharedModule {}
