import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-balance-previous-years',
  templateUrl: './balance-previous-years.component.html',
  styleUrls: ['./balance-previous-years.component.scss'],
})
export class BalancePreviousYearsComponent {
  @Input() public value = 0;

  // TODO: remove this code, the associated class assignation,
  // and the associated class declaration ASAP after 16/01/2023,
  // since it won't be useful anymore.
  // eslint-disable-next-line class-methods-use-this
  public shouldHideCard(): boolean {
    const limitYear = 2023;
    const limitMonth = 0;
    const limitDay = 16;

    const limitDate = new Date(limitYear, limitMonth, limitDay);
    const today = new Date();

    return today < limitDate;
  }
}
