export const GLOBAL = {
  STEP_1: '0.30',
  STEP_2: '1',
  MAX_HOUR: '23',
  MAX_MINUTE: '59',
  ES_LOCALE: 'es_ES',
};

export const TARGET = {
  WORKING_DAY: 'working-day',
  LOGS: 'logs',
  AUTHORIZATIONS: 'authorizations',
  DELEGATIONS: 'delegations',
};

export const STORAGE_KEY = {
  TOKEN: 'token',
  EMPLOYEE_ID: 'employeeId',
};
