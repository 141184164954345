<ng-container *ngIf="shouldShowMenu()">
  <ion-menu side="start" menuId="custom" class="menu">
    <ion-content color="primary" class="menu__content">
      <ion-list class="menu__list">
        <ion-item
          *ngFor="let link of links"
          class="link-wrapper menu-links"
          (click)="navigateTo(link.url)"
        >
          {{ link.title | translate }}

          <div
            *ngIf="shouldShowUnconfirmedDays(link)"
            id="badge_historical"
            class="badge-app"
            mode="md"
          >
            {{ link.unconfirmedDaysCounter }}
          </div>

          <app-image
            *ngIf="shouldShowWarningSign(link)"
            id="badge_delegations"
            class="delegations-icon"
            src="assets/icon/warning.svg"
          ></app-image>
        </ion-item>
      </ion-list>
    </ion-content>
    <ion-footer *ngIf="isCordova">
      <ion-list class="menu__list" lines="none">
        <ion-item (click)="closeSession()" class="link-wrapper logout">{{
          'LOGIN.LOGOUT' | translate
        }}</ion-item>
      </ion-list>
      <div class="menu__version" *ngIf="version">
        <ion-label> {{ 'VERSION' | translate }}: {{ version }} </ion-label>
      </div>
    </ion-footer>
  </ion-menu>

  <ion-header>
    <app-header [title]="tabTitle"></app-header>
  </ion-header>
</ng-container>

<ion-router-outlet main class="main"></ion-router-outlet>
