import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { API_ROUTE } from '@constants/index';
import { ErrorService } from '@services/error/error.service';
import { Workplace } from '@models/index';

@Injectable({
  providedIn: 'root',
})
export class WorkplacesService {
  public readonly workplacesDetailsSubject = new BehaviorSubject<Workplace[]>(
    []
  );

  public readonly workplacesDetails$ = this.workplacesDetailsSubject.asObservable();

  public constructor(
    private readonly http: HttpClient,
    private readonly errorService: ErrorService
  ) {}

  public updateWorkplaceDetails(): void {
    this.http.get<any>(API_ROUTE.WORKPLACES).subscribe((data: any): void => {
      try {
        this.workplacesDetailsSubject.next(data);
      } catch (err) {
        this.errorService.set('ERROR_WORKPLACES', err);
      }
    });
  }
}
