import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { GLOBAL } from '@constants/index';
import { EmployeeWorkingDay } from '@models/employee-working-day/employee-working-day';
import { getCurrentDate } from '@models/employee-working-day/employee-working-day-creator';
import { DayRecord, EmployeeInfo, TimeRecordEmitter } from '@models/index';
import { EmployeeService } from '@services/employee/employee.service';
import { CheckTimeValidator } from 'src/app/shared';
import { buildDateWithTime } from 'src/app/shared/utils/date-parser/date-parser';
import { DayRecordService } from '../../services/day-record.service';

@Component({
  selector: 'app-working-day-step-1',
  templateUrl: './step-1.component.html',
  styleUrls: ['./step-1.component.scss'],
})
export class Step1Component implements OnInit, OnChanges {
  @Input() public employeeWorkingDay: EmployeeWorkingDay;
  @Input() public isCheckinLoading = false;
  @Input() public isCheckoutLoading = false;

  @Output() public setCheckTimes = new EventEmitter<TimeRecordEmitter>();

  public isRegisterCheckInValid = false;
  public isRegisterCheckOutValid = false;
  public employee: EmployeeInfo;
  public checkInHourRegistered: string;
  public checkInMinuteRegistered: string;
  public checkOutHourRegistered: string;
  public checkOutMinuteRegistered: string;
  public hasCheckOutTimeError: boolean;
  public wizardProgress = GLOBAL.STEP_1;
  public hasPermissionToRegisterTime = true;
  public confirmIsSuccess = false;
  public employeeId: string;
  public hasCheckInTimeError: boolean;
  public dayRecordSubscription: Subscription;
  public checkInErrorMessage: string;
  public checkOutErrorMessage: string;

  public constructor(
    public employeeService: EmployeeService,
    public readonly dayRecordService: DayRecordService
  ) {}

  public ngOnChanges(props: any): void {
    const isBecauseCheckin =
      props.isCheckinLoading && props.isCheckinLoading.currentValue;
    const isBecauseCheckout =
      props.isCheckoutLoading && props.isCheckoutLoading.currentValue;
    if (!isBecauseCheckin && !isBecauseCheckout) {
      this.ngOnInit();
    }
  }

  public ngOnInit(): void {
    const { employeeInfo, workingDayData } = this.employeeWorkingDay;

    this.employee = employeeInfo;
    this.confirmIsSuccess = workingDayData.isConfirmed;
    this.employeeId = employeeInfo.id;

    const momentCheckIn = workingDayData.checkIn
      ? moment(workingDayData.checkIn)
      : moment();
    this.checkInHourRegistered = momentCheckIn.format('HH');
    this.checkInMinuteRegistered = momentCheckIn.format('mm');
    if (workingDayData.checkIn) {
      this.isRegisterCheckInValid = true;
    }

    const momentCheckOut = workingDayData.checkOut
      ? moment(workingDayData.checkOut)
      : moment();
    this.checkOutHourRegistered = momentCheckOut.format('HH');
    this.checkOutMinuteRegistered = momentCheckOut.format('mm');
    if (workingDayData.checkOut) {
      this.isRegisterCheckOutValid = true;
    }
  }

  private isPastDay(): boolean {
    return !this.isCurrentDay();
  }

  private isCurrentDay(): boolean {
    return moment(this.employeeWorkingDay.workingDayData.date).isSame(
      moment(getCurrentDate(this.employeeWorkingDay.employeeInfo)),
      'day'
    );
  }

  public registerCheckInIsFinished(dataEvent: any): void {
    this.isRegisterCheckInValid = dataEvent.isRegisterValid;
  }

  public registerCheckOutIsFinished(dataEvent: any): void {
    this.isRegisterCheckOutValid = dataEvent.isRegisterValid;
  }

  public cancelCheckIn(): void {
    this.isRegisterCheckInValid = true;
  }

  public cancelCheckOut(): void {
    this.isRegisterCheckOutValid = true;
  }

  public saveTimeRecord(dataEvent: TimeRecordEmitter): void {
    const params = dataEvent.bodyParams;

    if (this.isPastDay()) {
      params.selectedDay = moment(
        this.employeeWorkingDay.workingDayData.date
      ).format('YYYY-MM-DD');
      params.forceCounter = true;
    }

    const checkTime = buildDateWithTime(
      `${params.checkTimeHour}:${params.checkTimeMinutes}`,
      this.employeeWorkingDay.workingDayData.date
    );

    if (dataEvent.isCheckIn) {
      const otherCheckTime = buildDateWithTime(
        `${this.checkOutHourRegistered}:${this.checkOutMinuteRegistered}`,
        this.employeeWorkingDay.workingDayData.date
      );

      const validator = CheckTimeValidator.factory(
        this.employeeWorkingDay.workingDayData,
        checkTime,
        this.isRegisterCheckOutValid ? otherCheckTime : null,
        true
      );
      validator.validate({ shouldNotBeFuture: false });

      this.checkIn(params, validator.getError());
    } else {
      const otherCheckTime = buildDateWithTime(
        `${this.checkInHourRegistered}:${this.checkInMinuteRegistered}`,
        this.employeeWorkingDay.workingDayData.date
      );

      const validator = CheckTimeValidator.factory(
        this.employeeWorkingDay.workingDayData,
        checkTime,
        otherCheckTime,
        false
      );
      validator.validate({ shouldNotBeFuture: true });

      this.checkOut(params, validator.getError());
    }
  }

  public checkIn(params: DayRecord, validationErrorMessage: string): void {
    if (validationErrorMessage) {
      this.checkInErrorMessage = validationErrorMessage;
      this.hasCheckInTimeError = true;
      return;
    }

    const timeRecord: TimeRecordEmitter = {
      bodyParams: params,
      isCheckIn: true,
    };

    this.setCheckTimes.emit(timeRecord);
    this.hasCheckInTimeError = false;
  }

  private checkOut(params: DayRecord, validationErrorMessage: string): void {
    if (validationErrorMessage) {
      this.checkOutErrorMessage = validationErrorMessage;
      this.hasCheckOutTimeError = true;
      return;
    }

    const timeRecord: TimeRecordEmitter = {
      bodyParams: params,
      isCheckIn: false,
    };

    this.setCheckTimes.emit(timeRecord);
    this.hasCheckOutTimeError = false;
  }
}
