import { AuthorizationStatus } from './authorizations.model';

export interface TimeInputControl {
  id: string;
  label: string;
}

export enum TimeInputDataType {
  Positive,
  Negative,
  None,
}

export enum DifferenceDataType {
  Recoverable,
  Unrecoverable,
  Personal,
  Extension,
  NegativeCompensation,
  PositiveCompensation,
}

export interface TimeInputData {
  avatar: {
    image: string;
    alt: string;
  };
  class: string;
  title: string;
  explanation?: {
    text: string;
    data?: any;
  };
  type: DifferenceDataType;
  sumSign: TimeInputDataType;
  shouldReason?: boolean;
  authorizationStatus?: AuthorizationStatus;
}
