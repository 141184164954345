import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { EmployeeChooserComponent } from './employee-chooser.component';
import { EmployeeChooserRoutes } from './employee-chooser.routes';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule.forChild(EmployeeChooserRoutes),
    SharedModule,
    TranslateModule.forChild(),
  ],
  declarations: [EmployeeChooserComponent],
})
export class EmployeeChooserModule {}
