export enum TimeInfoRowType {
  Main,
  Secondary,
}

export interface TimeInfo {
  type: TimeInfoRowType;
  description: string;
  subdescription?: string;
  // @TODO: jordi.marti revisar la posibilidad de hacerlo obligatorio (time)
  time?: number;
  subtime?: number;
  moreInfo?: () => void; // TODO: alex.temina determine functionality
}

export interface InfoCard {
  id: string;
  infoList: TimeInfo[];
}

export interface TimeValues {
  preliminaryRegistration: number;
  preliminaryRegistrationForComputation: number;
  preliminaryDiffValue: number;
  appliedMarginAgreement: number;
  effectiveWorkTime: number;
  effectiveWorkTimeDifference: number;
}

export interface InfoList {
  preliminaryDifferenceAgreement: TimeInfo;
  effectiveWorkingTime: TimeInfo;
}
