import { Component, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { WorkingDayEmployee } from '@models/working-day-employee';
import { EmployeeService } from '@services/employee/employee.service';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';
import { YearMonth } from '../../models/year-month';
import { LogsService } from '../../services/logs.service';

@Component({
  selector: 'app-employee-logs',
  templateUrl: './employee-logs.component.html',
  styleUrls: ['./employee-logs.component.scss'],
})
export class EmployeeLogsComponent implements OnDestroy {
  private employeeSubscription: Subscription;

  public isLoading = false;
  public months: YearMonth[] = [];
  public userName: string;
  public employeeId = '';
  public currentBalance: number;
  public balancePreviousYears: number;
  public lockedBalance: number;
  public isMobile = false;
  public showHideBalance = false;
  public friendsAndFamily = false;
  public loggedUserHasRemoteWork: boolean;

  public constructor(
    public employeeService: EmployeeService,
    public platformCheckerService: PlatformCheckerService,
    private readonly logsService: LogsService
  ) {}

  public ionViewDidEnter(): void {
    this.isLoading = true;
    this.isMobile = this.platformCheckerService.isMobile();
    this.employeeSubscription = this.employeeService.employee$
      .pipe(
        concatMap(
          (employee: WorkingDayEmployee): Observable<YearMonth[]> => {
            this.userName = employee.firstName;
            this.employeeId = employee.id;
            this.currentBalance = employee.currentBalance;
            this.balancePreviousYears = employee.balancePreviousYears;
            this.lockedBalance = employee.lockedBalance;
            this.showHideBalance = employee.shouldHideBalance;
            this.loggedUserHasRemoteWork = employee.hasRemoteWork;
            this.friendsAndFamily = employee.friendsAndFamily;

            return this.logsService.getAvailableYearMonths(employee.id);
          }
        )
      )
      .subscribe((months: YearMonth[]): void => {
        this.isLoading = false;

        this.months = months;
      });
  }

  public ngOnDestroy(): void {
    this.ionViewDidLeave();
  }

  public ionViewDidLeave(): void {
    this.employeeSubscription.unsubscribe();
  }

  public shouldShowCurrentBalanceCard(): boolean {
    return this.currentBalance !== undefined && !this.showHideBalance;
  }
}
