<ion-card class="new-auth-card c-card--min-height ">
  <ion-card-content class="new-auth-card__content">
    <ion-grid>
      <ion-row class="new-auth-card__row">
        <ion-col class="col-center" size="12">
          <ion-grid>
            <ion-row>
              <ion-col class="img" size="1">
                <app-image
                  class="img__avatar"
                  src="assets/icon/default-avatar.png"
                  [alt]="'ALT_IMG.AVATAR' | translate"
                ></app-image>
              </ion-col>
              <ion-col class="personal-data" size="3">
                <h4 class="personal-data__name">
                  {{ authListItem.firstName + ' ' + authListItem.lastName }}
                </h4>
                <p class="personal-data__date">
                  {{ formatDate(authListItem.requestDate) }}
                </p>
                <p class="personal-data__difference">
                  + {{ authListItem.difference | humanizeDuration }}
                </p>
              </ion-col>
              <ion-col class="reason" size="3">
                <p class="reason__label">
                  {{
                    'AUTHORIZATIONS.PENDING_AUTHORIZATIONS.REASON' | translate
                  }}
                </p>
                <p class="reason__text">{{ authListItem.reason }}</p>
              </ion-col>
              <ion-col class="state" size="2">
                <ng-container class="state__separation">
                  <span [ngClass]="getDotClass()"></span>
                  <span class="state__text">{{
                    getStateText() | translate
                  }}</span>
                </ng-container>
              </ion-col>           
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
