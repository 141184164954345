import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorService } from '@services/error/error.service';
import { API_ROUTE } from '@constants/api.routes.constants';
import { buildDayLog, DayLog } from '../models/day-log';
import { YearMonth } from '../models/year-month';

@Injectable({
  providedIn: 'root',
})
export class LogsService {
  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    }),
  };

  public constructor(
    private errorService: ErrorService,
    private readonly httpClient: HttpClient
  ) {}

  public getAvailableYearMonths(
    employeeId: string,
    managerId?: string
  ): Observable<YearMonth[]> {
    const url = managerId
      ? API_ROUTE.LOGS.EMPLOYEE_OTHER_AVAILABLE_YEAR_MONTHS(
          employeeId,
          managerId
        )
      : `${API_ROUTE.LOGS.EMPLOYEE_AVAILABLE_YEAR_MONTHS}${employeeId}`;

    return this.httpClient
      .get<any>(url)
      .pipe(
        map((yearMonthsInfo: any[]): YearMonth[] =>
          yearMonthsInfo.map(
            (yearMonthInfo: any): YearMonth =>
              YearMonth.factory(
                yearMonthInfo.year,
                yearMonthInfo.month,
                yearMonthInfo.isMonthConsolidated
              )
          )
        )
      );
  }

  public getYearMonthLogs(
    employeeId: string,
    yearMonth: YearMonth,
    managerId?: string
  ): Observable<DayLog[]> {
    const url = managerId
      ? API_ROUTE.LOGS.EMPLOYEE_OTHER_MONTH_LOGS(employeeId, managerId)
      : `${API_ROUTE.LOGS.EMPLOYEE_MONTH_LOGS}${employeeId}`;

    return this.httpClient
      .get<any>(url, {
        ...this.httpOptions,
        params: {
          month: yearMonth.month.toString(),
          year: yearMonth.year.toString(),
          systemTime: moment().format('HHmm'),
          systemDate: moment().format('YYYY-MM-DD'),
        },
      })
      .pipe(
        map((rawDayLogs: any[]): DayLog[] => rawDayLogs.map(buildDayLog)),
        catchError(
          (err: Error): Observable<DayLog[]> => {
            this.errorService.set('ERROR_DEFAULT', err);
            return of(null);
          }
        )
      );
  }

  public updateBalanceNegativeByDay(
    compensateDate: string,
    compensateTime: number,
    compensateReason: string,
    employeeId: string
  ) {
    const url = API_ROUTE.LOGS.UPDATE_BALANCE_COMPENSATION(employeeId);

    const body = {
      compensateDate,
      compensateTime,
      compensateReason,
    };
    return this.httpClient.post<any>(url, body);
  }
}
