import { Routes } from '@angular/router';
import { ROUTE } from '@constants/index';
import { DevEnvGuard } from 'src/app/shared/guards/dev-env.guard';
import { EmployeeChooserComponent } from './employee-chooser.component';

export const EmployeeChooserRoutes: Routes = [
  {
    path: ROUTE.EMPLOYEE_CHOOSER,
    canActivate: [DevEnvGuard],
    component: EmployeeChooserComponent,
  },
];

export class EmployeeRoutingChooserModule {}
