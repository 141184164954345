import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  ResumeComponent,
  Step1Component,
  TimeInfoCardComponent,
  TimeInputComponent,
  WizardComponent,
} from './components';
import { AgreementMarginResumeComponent } from './components/agreement-margin-resume/agreement-margin-resume.component';
import { JneLinkComponent } from './components/jne-link/jne-link.component';
import { WorkingDayModalsModule } from './components/modals/working-day-modals.module';
import { DefectUseCaseComponent } from './components/step-2/components/defect-use-case/defect-use-case.component';
import { EffectiveWorkingTimeComponent } from './components/step-2/components/effective-working-time/effective-working-time.component';
import { ExcessUseCaseComponent } from './components/step-2/components/excess-use-case/excess-use-case.component';
import { PerfectUseCaseComponent } from './components/step-2/components/perfect-use-case/perfect-use-case.component';
import { PreliminaryDifferenceComponent } from './components/step-2/components/preliminary-difference/preliminary-difference.component';
import { RootComponent } from './components/step-2/components/root/root.component';
import { SuccessMessageComponent } from './components/success-message/success-message.component';
import { WorkingDayChooserComponent } from './components/working-day-chooser/working-day-chooser.component';
import { WorkplaceComponent } from './components/workplace/workplace.component';
import { WorkingDayPage } from './working-day.page';
import { workingDayRoutes } from './working-day.routes';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule.forChild(workingDayRoutes),
    SharedModule,
    TranslateModule.forChild(),
    WorkingDayModalsModule,
  ],
  declarations: [
    AgreementMarginResumeComponent,
    DefectUseCaseComponent,
    EffectiveWorkingTimeComponent,
    ExcessUseCaseComponent,
    JneLinkComponent,
    PerfectUseCaseComponent,
    PreliminaryDifferenceComponent,
    ResumeComponent,
    RootComponent,
    Step1Component,
    SuccessMessageComponent,
    TimeInfoCardComponent,
    TimeInputComponent,
    WizardComponent,
    WorkingDayChooserComponent,
    WorkplaceComponent,
    WorkingDayPage,
  ],
  exports: [WorkingDayPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WorkingDayPageModule {}
