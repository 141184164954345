import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TeamMember } from '../../../models/team-member';

@Component({
  selector: 'app-team-list-mobile',
  templateUrl: './team-list-mobile.component.html',
  styleUrls: ['./team-list-mobile.component.scss'],
})
export class TeamListMobileComponent {
  @Input() public teamMembers: TeamMember[] = [];
  @Output() public loadHistoric = new EventEmitter<string>();
}
