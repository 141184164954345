import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class PlatformCheckerService {
  public constructor(private platform: Platform) {}

  public isMobile(): boolean {
    return this.platform.width() < 575;
  }

  public isDesktop(): boolean {
    // It's not too accurate... but it works as expected for the purpouses of this application
    return !this.isMobile();
  }

  public isCordova(): boolean {
    return this.platform.is('cordova');
  }
}
