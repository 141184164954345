import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { finalize, map, mergeMap, tap } from 'rxjs/operators';
import { STORAGE_KEY } from '@constants/index';
import {
  ExcessOtherTime,
  Time,
  WorkingDayData,
} from '@models/working-day-data';
import { DayRecordDetailService, StorageService } from '@services/index';
import { DayRecordService } from 'src/app/pages/working-day/services/day-record.service';
import {
  DayRecordDetailInterface,
  EmployeeInfo,
  EmployeeService,
  GenericPostResponse,
  Rubric,
  TimeRecordEmitter,
  WorkingDayDataInterface,
  WorkingDayDetail,
  WorkingDayEmployee,
} from '../..';
import { ServerWorkingDay } from '../../../pages/working-day/interfaces/server-working-day';
import { WorkingDay } from '../../../pages/working-day/models/working-day';
import { buildDateWithTime } from '../../utils/date-parser/date-parser';
import { parseExcessReason } from '../../utils/parsers';
import { isNumberFinite } from '../../utils/validations';
import { EmployeeWorkingDay } from './employee-working-day';

export enum MessageType {
  NoNeedToRegisterToday = 'NO_NEED_REGISTER_TODAY',
  NoPlanning = 'NO_PLANNING',
  NoNeedToRegister = 'NO_NEED_REGISTER',
  None = '',
}

export function createNoNeedToRegisterMessage(
  employeeWorkingDay: EmployeeWorkingDay
): MessageType {
  const {
    needRegisterWorkingDay,
    permissionGTA,
    absenceOrHolidays,
    withoutPlanning,
  } = employeeWorkingDay.rubric.permissionRegister;
  const dateNow = new Date();

  const gods = !needRegisterWorkingDay && !permissionGTA;
  const demigods =
    permissionGTA &&
    !absenceOrHolidays &&
    !withoutPlanning &&
    !needRegisterWorkingDay;

  const isCA1 = permissionGTA && absenceOrHolidays;
  const isCA2 = gods;
  const isCA3 = demigods;
  const isCA5 = permissionGTA && withoutPlanning;

  let message = MessageType.None;
  if (isCA1) {
    message = MessageType.NoNeedToRegisterToday;
  } else if (isCA5) {
    message = MessageType.NoPlanning;
  } else if (isCA2 || isCA3) {
    message = MessageType.NoNeedToRegister;
    if (
      isCA3 &&
      !moment(employeeWorkingDay.workingDayData.date).isSame(dateNow, 'days')
    ) {
      message = MessageType.None;
    }
  }

  return message;
}

export function isBeforeCutoffDate(currentDate: Date): boolean {
  const limitYear = 2023;
  const limitMonth = 2;
  const limitDay = 1;

  const limitDate = new Date(limitYear, limitMonth, limitDay);
  return currentDate < limitDate;
}

export function temporalPatchForBetaTesting(
  hasRemoteWorkInDatabase: boolean,
  currentDate: Date
): boolean {
  if (isBeforeCutoffDate(currentDate) || !hasRemoteWorkInDatabase) {
    return false;
  }

  return hasRemoteWorkInDatabase;
}

export function buildEmployeeInfo(employee: WorkingDayEmployee): EmployeeInfo {
  return {
    firstName: employee.firstName,
    lastName: employee.lastName,
    id: employee.id,
    isAuthorizer: employee.isAuthorizer,
    isManager: employee.isManager,
    isEmployeeDelegate: employee.isEmployeeDelegate,
    currentBalance: employee.currentBalance,
    lockedBalance: employee.lockedBalance,
    shouldHideBalance: employee.shouldHideBalance,
    unconfirmedDaysCounter: employee.unconfirmedDaysCounter,
    currentDate: employee.currentDate,
    manager: employee.manager,
    authorizer: employee.authorizer,
    balancePreviousYears: employee.balancePreviousYears,
    shouldHaveHourlyMedicalLeave: employee.shouldHaveHourlyMedicalLeave,
    hasRemoteWork: employee.hasRemoteWork,
    codCompany: employee.codCompany,
  };
}

export function buildDayRecordDetail(
  dayRecordDetail: DayRecordDetailInterface,
  selectedDate: Date
): WorkingDayDetail {
  const date = moment(selectedDate)
    .startOf('day')
    .toDate();

  const excessReason = parseExcessReason(
    dayRecordDetail.excessWorkingDayReason
  );

  const checkIn = buildDateWithTime(dayRecordDetail.checkIn, selectedDate);
  const checkOut = buildDateWithTime(dayRecordDetail.checkOut, selectedDate);

  const data: WorkingDayDataInterface = {
    authorizationStatus: dayRecordDetail.workingDayExtensionStatus,
    date,
    minRegisterTime: isNumberFinite(dayRecordDetail.minRegisterTime)
      ? dayRecordDetail.minRegisterTime
      : null,
    maxRegisterTime: isNumberFinite(dayRecordDetail.maxRegisterTime)
      ? dayRecordDetail.maxRegisterTime
      : null,
    workingDay: dayRecordDetail.workingDay,
    workLocation: dayRecordDetail.workLocation,
    isConfirmed: dayRecordDetail.isConfirmed,
    isAlternativeChosen: dayRecordDetail.isAlternativeWorkingDay,
    // TODO: Remove || false when feature is consolidated
    isJNE: dayRecordDetail.isJNE || false,
    skipBreakTimeLimit: dayRecordDetail.skipBreakTimeLimit,
    differences: {
      excess: {
        personal: new Time(
          dayRecordDetail.workingDayExtensionPersonalReasonTime
        ),
        other: new ExcessOtherTime(
          dayRecordDetail.workingDayExtensionTime,
          excessReason,
          dayRecordDetail.workingDayExtensionAuthorizeManager
        ),
        negativeCompensation: new Time(
          dayRecordDetail.compensationNegativeBalance
        ),
      },
      deffect: {
        recoverable: new Time(dayRecordDetail.recoveryTime),
        unrecoverable: new Time(dayRecordDetail.notRecoveryTime),
        positiveCompensation: new Time(
          dayRecordDetail.compensationPositiveBalance
        ),
      },
    },
    differenceToCompensate: dayRecordDetail.differenceToCompensate,
    workplace: dayRecordDetail.workplace,
    equalityPlan: dayRecordDetail.equalityPlan,
    equalityPlanDescription: dayRecordDetail.equalityPlanDescription,
  };

  const workingDayData = WorkingDayData.factory(data, checkIn, checkOut);

  const rubric: Rubric = {
    minimumBreakTime: dayRecordDetail.minimumBreakTime,
    workingDayInfo: dayRecordDetail.descriptionDayRecord,
    agreementMargin: dayRecordDetail.agreementMargin,
    permissionRegister: dayRecordDetail.permissionToRegister,
  };

  const alternativeWorkingDay: WorkingDay = dayRecordDetail.alternativeWorkingDay
    ? WorkingDay.factory(
        (dayRecordDetail.alternativeWorkingDay as unknown) as ServerWorkingDay
      )
    : null;

  return {
    rubric,
    workingDayData,
    alternativeWorkingDay,
  };
}

export function getCurrentDate(employeeInfo: EmployeeInfo): Date {
  return employeeInfo && employeeInfo.currentDate
    ? moment(employeeInfo.currentDate, 'YYYY-MM-DD').toDate()
    : moment().toDate();
}
@Injectable()
export abstract class EmployeeWorkingDayCreator {
  protected employeeId: string;

  public constructor(
    protected readonly employeeService: EmployeeService,
    protected readonly dayRecordDetailService: DayRecordDetailService,
    protected readonly dayRecordService: DayRecordService,
    protected readonly storageService: StorageService
  ) {}

  public create(
    date: Date,
    employeeId?: string
  ): Observable<EmployeeWorkingDay> {
    this.employeeId =
      employeeId || this.storageService.get(STORAGE_KEY.EMPLOYEE_ID);

    if (!this.employeeId) {
      return throwError('no employee in storage');
    }

    let employeeInfo: EmployeeInfo;
    return this.getEmployee().pipe(
      tap((employee: EmployeeInfo): void => {
        employeeInfo = employee;
      }),
      mergeMap(
        (employee: EmployeeInfo): Observable<WorkingDayDetail> => {
          return this.getWorkingDayDetail(date || getCurrentDate(employee));
        }
      ),
      map(
        ({
          alternativeWorkingDay,
          workingDayData,
          rubric,
        }): EmployeeWorkingDay => {
          return {
            employeeInfo,
            workingDayData,
            rubric,
            alternativeWorkingDay,
          };
        }
      )
    );
  }

  public abstract setCheckTimes(
    employeeWorkingDay: EmployeeWorkingDay,
    timeRecord: TimeRecordEmitter
  ): Observable<GenericPostResponse>;

  public abstract getRouteToGo(
    employeeWorkingDay: EmployeeWorkingDay,
    isWriteMode: boolean,
    isStep2: boolean
  ): string;

  public getWorkingDayDetailFromHTTP(
    date: Date,
    suscriptionCallback = (): void => null
  ): void {
    const formattedDate = moment(date).format('YYYY-MM-DD');

    this.dayRecordDetailService
      .getDayRecordDetail(this.employeeId, formattedDate)
      .pipe(finalize(suscriptionCallback))
      .subscribe();
  }

  public refreshWorkingDayData(
    date: Date,
    suscriptionCallback?: () => void
  ): void {
    this.getWorkingDayDetailFromHTTP(date, suscriptionCallback);
  }

  public abstract setIsAlternativeWorkingDay(isAlternative: boolean): void;

  protected abstract getEmployee(): Observable<EmployeeInfo>;
  protected abstract getWorkingDayDetail(
    requestedDate: Date
  ): Observable<WorkingDayDetail>;
}
