import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import moment from 'moment';
import { Observable, ObservableInput, throwError } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';
import { ERRORS } from '@constants/errors.constant';
import { API_ROUTE, STORAGE_KEY } from '@constants/index';
import { TreatableErrorType } from '@models/errors.model';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { ErrorService, StorageService } from '@services/index';

function isError5xx(errorStatus: number): boolean {
  return errorStatus.toString().startsWith('5');
}

function isAuthError(error: HttpErrorResponse): boolean {
  return (
    error.url &&
    error.url.toLowerCase().includes(API_ROUTE.AUTHENTICATION.toLowerCase())
  );
}
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  public constructor(
    private authenticationService: AuthenticationService,
    private readonly storageService: StorageService,
    private readonly errorService: ErrorService,
    private readonly http: HttpClient,
    private readonly platform: Platform,
    private readonly router: Router
  ) {}

  private catchRequestError(err: HttpErrorResponse): Observable<any> {
    this.authenticationService.redirectToSSOLogin();
    return throwError(err);
  }

  private addTokenAndContinue(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    const token = this.storageService.get(STORAGE_KEY.TOKEN);

    const request = req.clone({
      setHeaders: {
        authorization: token,
      },
      setParams: {
        _: moment()
          .unix()
          .toString(),
      },
    });

    return next.handle(request);
  }

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let request: HttpRequest<any> = req;
    const token = this.storageService.get(STORAGE_KEY.TOKEN);

    if (token) {
      if (!req.headers.get('authorization')) {
        request = req.clone({
          setHeaders: {
            authorization: token,
          },
        });
      }
    }

    return next.handle(request).pipe(
      catchError(
        (nextHandleError: HttpErrorResponse): Observable<any> => {
          if (nextHandleError.status === 205) {
            return this.treatError205(nextHandleError, req, next);
          }

          if (isError5xx(nextHandleError.status)) {
            this.treatError5xx(nextHandleError);
          } else {
            this.treatError(nextHandleError);
          }

          return throwError(nextHandleError);
        }
      )
    );
  }

  private treatError(err: HttpErrorResponse): void {
    const error = ERRORS[err.error.errorCode] || ERRORS.DEFAULT_400;
    if (error.type === TreatableErrorType.Toast) {
      this.errorService.makeToast(error.message);
    } else {
      this.errorService.set(error.message, err);
    }
  }

  private treatError5xx(err: HttpErrorResponse): void {
    this.errorService.set(ERRORS.DEFAULT_500.message, err);
  }

  private treatError205(
    error: HttpErrorResponse,
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<void> {
    if (isAuthError(error)) {
      return this.catchRequestError(error);
    }

    return this.authenticationService.authenticateEmployee().pipe(
      flatMap(
        (): ObservableInput<any> => {
          return this.addTokenAndContinue(req, next);
        }
      ),
      catchError(
        (reAuthenticationError): Observable<any> => {
          return this.catchRequestError(reAuthenticationError);
        }
      )
    );
  }
}
