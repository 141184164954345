import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { API_ROUTE } from '@constants/index';
import {
  CheckTimes,
  DayRecordDetailBuilder,
  DayRecordDetailInterface,
} from '@models/index';
import { ErrorService } from '@services/error/error.service';
import { FeatureFlagService } from '@services/feature-flag';

@Injectable({
  providedIn: 'root',
})
export class DayRecordDetailService {
  public currentDate: Date;

  private readonly dayRecordDetailSubject = new BehaviorSubject<
    DayRecordDetailInterface
  >(null);

  public readonly dayRecordDetail$ = this.dayRecordDetailSubject.asObservable();

  public constructor(
    private readonly http: HttpClient,
    private readonly errorService: ErrorService,
    private readonly featureFlagService: FeatureFlagService
  ) {}

  public getDayRecordDetail(
    employeeId: string,
    selectedDay: string
  ): Observable<DayRecordDetailInterface> {
    return this.http
      .get<any>(API_ROUTE.DAY_RECORD.DETAIL(employeeId), {
        params: { selectedDay },
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
        }),
      })
      .pipe(
        tap((data: DayRecordDetailInterface): void => {
          this.dayRecordDetailSubject.next(
            DayRecordDetailBuilder.factory(
              data,
              selectedDay,
              this.featureFlagService
            ).dayRecordDetail
          );
        }),
        catchError(
          (err): Observable<any> => {
            this.errorService.set('ERROR_DEFAULT', err);
            return of(null);
          }
        )
      );
  }

  public getOtherDayRecordDetail(
    employeeId: string,
    selectedDay: string,
    managerId: string
  ): Observable<DayRecordDetailInterface> {
    return this.http
      .get<any>(API_ROUTE.DAY_RECORD.OTHER_DETAIL(employeeId, managerId), {
        params: { selectedDay },
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8',
        }),
      })
      .pipe(
        map(
          (data: DayRecordDetailInterface): DayRecordDetailInterface =>
            DayRecordDetailBuilder.factory(
              data,
              selectedDay,
              this.featureFlagService
            ).dayRecordDetail
        ),
        catchError(
          (err): Observable<any> => {
            this.errorService.set('ERROR_DEFAULT', err);
            return of(null);
          }
        )
      );
  }

  public updateCheckTimes(
    checkTimes: CheckTimes,
    alternativeWorkingDay?: boolean
  ): void {
    const newDayRecordDetail = this.dayRecordDetailSubject.value;

    const checkTime = checkTimes.checkInTime || checkTimes.checkOutTime;
    const formattedTime = moment(checkTime).format('HH:mm');

    if (checkTimes.checkInTime) {
      newDayRecordDetail.checkIn = formattedTime;
    } else {
      newDayRecordDetail.checkOut = formattedTime;
    }

    if (alternativeWorkingDay) {
      newDayRecordDetail.isAlternativeWorkingDay = alternativeWorkingDay;
    }

    this.dayRecordDetailSubject.next(newDayRecordDetail);
  }

  public setIsAlternativeWorkingDay(isAlternativeWorkingDay: boolean): void {
    this.dayRecordDetailSubject.next({
      ...this.dayRecordDetailSubject.value,
      isAlternativeWorkingDay,
    });
  }
}
