import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import { API_ROUTE } from '@constants/index';
import {
  EmployeeBuilder,
  EmployeeConfirmDay,
  GenericPostResponse,
  WorkingDayEmployee,
} from '@models/index';
import { ErrorService } from '@services/error/error.service';
import { FeatureFlagService } from '@services/feature-flag';
import { environment } from 'src/environments/environment';

const url = API_ROUTE.EMPLOYEE;

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    }),
  };

  public readonly employeeSubject = new BehaviorSubject<WorkingDayEmployee>(
    null
  );

  public readonly employee$ = this.employeeSubject.asObservable();

  public constructor(
    private readonly http: HttpClient,
    private readonly errorService: ErrorService,
    private readonly featureFlagService: FeatureFlagService
  ) {}

  public confirmDay(
    employeeId: string,
    params: EmployeeConfirmDay
  ): Observable<GenericPostResponse> {
    const confirmUrl = API_ROUTE.DAY_RECORD.CONFIRMATION;
    const callParams = {
      ...params,
      selectedDay: moment(params.selectedDay).format('YYYY-MM-DD'),
    };

    if (params.hasAlternativeWorkingDay) {
      callParams.alternativeWorkingDay = params.alternativeWorkingDay;
    }
    return this.http
      .post<GenericPostResponse>(
        confirmUrl + employeeId,
        callParams,
        this.httpOptions
      )
      .pipe(
        first(),
        tap((): void => {
          this.getEmployee(employeeId).subscribe();
        })
      );
  }

  public updateEmployee(employee?: WorkingDayEmployee): void {
    const currentEmployee = this.employeeSubject.value;
    this.employeeSubject.next(employee || currentEmployee);
  }

  public getEmployee(id: string): Observable<WorkingDayEmployee> {
    const params = new HttpParams({
      fromObject: {
        systemTime: moment().format('HHmm'),
      },
    });

    return this.http
      .get<any>(`${url}${id}`, { params })
      .pipe(
        map(
          (data): WorkingDayEmployee => {
            try {
              return EmployeeBuilder.factory(data, this.featureFlagService)
                .workingDayEmployee;
            } catch (err) {
              this.errorService.set('ERROR_DEFAULT', err);
              return null;
            }
          }
        ),
        tap((employee: WorkingDayEmployee): void => {
          this.updateEmployee(employee);
        }),
        catchError(
          (err): Observable<any> => {
            if (environment.production) {
              this.errorService.set('ERROR_DEFAULT', err);
            }

            return of(null);
          }
        )
      );
  }

  public getOtherEmployee(
    id: string,
    managerId: string
  ): Observable<WorkingDayEmployee> {
    return this.http.get<any>(API_ROUTE.OTHER_EMPLOYEE(id, managerId)).pipe(
      map(
        (data): WorkingDayEmployee =>
          EmployeeBuilder.factory(data, this.featureFlagService)
            .workingDayEmployee
      ),
      catchError(
        (err): Observable<any> => {
          this.errorService.set('ERROR_DEFAULT', err);
          return of(null);
        }
      )
    );
  }
}
