import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { StaticTextService } from '@services/index';
import { StaticText, TextBlock } from '@models/index';
import { WorkingDayInfoComponent } from '../modals/working-day-info/working-day-info.component';
import { TimeCalculationsService } from '../../services/time-calculations/time-calculations.service';

@Component({
  selector: 'app-agreement-margin-resume',
  templateUrl: './agreement-margin-resume.component.html',
  styleUrls: ['./agreement-margin-resume.component.scss'],
})
export class AgreementMarginResumeComponent {
  @Input() public workingDay: number;
  @Input() public agreementMargin: number;
  public messagesMarginAgreement: TextBlock[] = [];

  public constructor(
    private readonly modalController: ModalController,
    private readonly staticTextService: StaticTextService,
    private readonly timeCalculationsService: TimeCalculationsService
  ) {
    this.staticTextService.staticText$.subscribe(
      (staticText: StaticText): void => {
        if (staticText) {
          this.messagesMarginAgreement = staticText.messagesMarginAgreement;
        }
      }
    );
  }

  public refAgreementWorkingDay(): number {
    return this.timeCalculationsService.refAgreementWorkingDay(
      this.workingDay,
      this.agreementMargin
    );
  }

  public async openMarginModal(): Promise<void> {
    const infoAsList = this.messagesMarginAgreement;

    const modalOptions = {
      component: WorkingDayInfoComponent,
      cssClass: 'register-time',
      componentProps: {
        infoAsList,
        title: 'WORKING_DAY.MODAL.INFO.MARGIN',
      },
    };
    const modal = await this.modalController.create(modalOptions);
    modal.present();
  }
}
