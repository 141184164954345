import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ROUTE } from '@constants/routes.constants';
import { ErrorPage } from './error.page';

const routes: Routes = [
  {
    path: ROUTE.ERROR,
    component: ErrorPage,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),
  ],
  declarations: [ErrorPage],
})
export class ErrorPageModule {}
