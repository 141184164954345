import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { TimeRecordEmitter } from '@models/index';
import { SOCKET_ROUTE } from '../../constants/api.routes.constants';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  public checkInSocketSubject: WebSocketSubject<TimeRecordEmitter>;
  public checkInSocket$: Observable<TimeRecordEmitter>;
  public checkOutSocketSubject: WebSocketSubject<TimeRecordEmitter>;
  public checkOutSocket$: Observable<TimeRecordEmitter>;
  public confirmSocketSubject: WebSocketSubject<boolean>;
  public confirmSocket$: Observable<boolean>;
  public initSocketConnections(employeeId: string): void {
    this.checkInSocketSubject = webSocket(SOCKET_ROUTE.CHECK_IN(employeeId));
    this.checkInSocket$ = this.checkInSocketSubject.asObservable();
    this.checkOutSocketSubject = webSocket(SOCKET_ROUTE.CHECK_OUT(employeeId));
    this.checkOutSocket$ = this.checkOutSocketSubject.asObservable();
    this.confirmSocketSubject = webSocket(SOCKET_ROUTE.CONFIRM(employeeId));
    this.confirmSocket$ = this.confirmSocketSubject.asObservable();
  }

  public updateRegisterSocket(
    newTimeRecordEmitterData: TimeRecordEmitter
  ): void {
    if (newTimeRecordEmitterData.isCheckIn) {
      this.checkInSocketSubject.next(newTimeRecordEmitterData);
    } else {
      this.checkOutSocketSubject.next(newTimeRecordEmitterData);
    }
  }

  public updateConfirmSocket(showMessage: boolean): void {
    this.confirmSocketSubject.next(showMessage);
  }
}
