<ion-content class="employee-team-logs page">
  <ion-grid>
    <ion-row class="employee-team-logs__header">
      <ion-col class="header__greeting">
        <a routerLink="/logs/team" class="back-to-historic">
          <ion-icon name="md-arrow-back"></ion-icon>
          {{ 'LOGS.TEAM_LOGS.BACK_LINK' | translate }}
        </a>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="!isMobile" class="employee-team-logs__title-legends">
      <ion-col>
        <app-title-and-legends [isOther]="true"></app-title-and-legends>
      </ion-col>
    </ion-row>
    <ng-container *ngIf="isMobile">
      <ion-row class="employee-team-logs__title">
        <ion-col>
          <h1>
            {{ 'LOGS.TEAM_LOGS.TITLE' | translate }}
          </h1>
        </ion-col>
      </ion-row>
      <ion-row class="employee-team-logs__subtitle">
        <ion-col>
          <p class="header__subtitle">
            {{ 'LOGS.TEAM_LOGS.MOBILE_SUBTITLE' | translate }}
          </p>
        </ion-col>
      </ion-row>
    </ng-container>
    <ion-row class="employee-team-logs__member">
      <ion-col class="member">
        <app-member-avatar
          *ngIf="!isLoading"
          [userName]="userName"
        ></app-member-avatar>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <app-months-list
          *ngIf="!isLoading"
          [yearMonths]="months"
          [readOnly]="true"
          [targetEmployeeId]="targetEmployeeId"
          [currentEmployeeId]="currentEmployeeId"
          [targetHasRemoteWork]="targetHasRemoteWork"
        ></app-months-list>
      </ion-col>
      <ion-spinner *ngIf="isLoading" class="g-spinner"></ion-spinner>
    </ion-row>
  </ion-grid>
</ion-content>
