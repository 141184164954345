<ion-content class="page">
  <app-user-greeting
    *ngIf="!isMobile"
    class="team-log__greeting"
    [userName]="userName"
    translationId="GREETING.LOGS_TEAMS"
  ></app-user-greeting>

  <h1 class="title">{{ 'LOGS.TEAM_LOGS.TITLE' | translate }}</h1>

  <ion-spinner class="g-spinner" *ngIf="isLoading"></ion-spinner>

  <ng-container *ngIf="!isLoading">
    <app-team-list-desktop
      [teamMembers]="teamMembers"
      *ngIf="!isMobile"
      (loadHistoric)="onLoadHistoric($event)"
    ></app-team-list-desktop>
    <app-team-list-mobile
      [teamMembers]="teamMembers"
      *ngIf="isMobile"
      (loadHistoric)="onLoadHistoric($event)"
    ></app-team-list-mobile>

    <ion-infinite-scroll
      class="infinite-scroll"
      threshold="1.25rem"
      (ionInfinite)="loadData($event)"
    >
      <ion-infinite-scroll-content
        [loadingText]="'LOADING' | translate"
        loadingSpinner="bubbles"
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ng-container>
</ion-content>
