<ion-card class="container c-card">
  <div class="line line--text">{{ 'BALANCE.CURRENT' | translate }}</div>
  <div class="line line--value">{{ value | humanizeDuration: true }}</div>
  <div class="line line--locked" *ngIf="lockedValue">
    <span>{{ 'BALANCE.LOCKED' | translate }}</span>
    <span data-test="locked-balance-value">
      + {{ lockedValue | humanizeDuration }}
    </span>
  </div>
</ion-card>
