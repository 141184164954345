import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { concatMap, filter, take, tap } from 'rxjs/operators';
import { ROUTE } from '@constants/routes.constants';
import { WorkingDayEmployee } from '@models/working-day-employee';
import { EmployeeService } from '@services/employee/employee.service';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';
import { TeamMember, TeamMemberListInterface } from '../../models/team-member';
import { TeamService } from '../../services/team.service';

@Component({
  selector: 'app-team-logs',
  templateUrl: './team-logs.component.html',
  styleUrls: ['./team-logs.component.scss'],
})
export class TeamLogsComponent {
  public teamMembers: TeamMember[] = [];
  public userName: string;
  public pages: number;
  public page = 1;
  public managerId: string;
  public isMobile: boolean;
  public isLoading = false;

  private employeeSubscription: Subscription;

  public constructor(
    public readonly platformCheckerService: PlatformCheckerService,
    public readonly teamService: TeamService,
    public readonly employeeService: EmployeeService,
    public readonly router: Router
  ) {
    this.isMobile = this.platformCheckerService.isMobile();
  }

  public ionViewDidEnter(): void {
    this.isLoading = true;

    this.employeeSubscription = this.employeeService.employee$
      .pipe(
        filter((employee: WorkingDayEmployee): boolean => !!employee),
        take(1),
        tap(({ id, firstName }): void => {
          this.managerId = id;
          this.userName = firstName;
          this.page = 1;
        }),
        concatMap(
          (): Observable<TeamMemberListInterface> => {
            return this.teamService.getTeamMembers(
              this.managerId,
              this.page.toString()
            );
          }
        )
      )
      .subscribe((teamMembersObject): void => {
        this.isLoading = false;

        return this.resolveTeamMember(teamMembersObject);
      });
  }

  public onLoadHistoric(teamMemberId: string): void {
    this.router.navigate([ROUTE.TEAM_MEMBER_LOGS(teamMemberId)]);
  }

  public loadData(event: any): void {
    const ev = event;

    if (this.page < this.pages) {
      this.updateList();
    }

    ev.target.complete();
  }

  public ionViewDidLeave(): void {
    this.employeeSubscription.unsubscribe();
  }

  private resolveScrollTeamMember(
    teamMembersObject: TeamMemberListInterface
  ): void {
    this.pages = +teamMembersObject.pages;
    this.teamMembers = this.teamMembers.concat(
      teamMembersObject.listTeamMember
    );
  }

  private resolveTeamMember(teamMembersObject: TeamMemberListInterface): void {
    this.pages = +teamMembersObject.pages;
    this.teamMembers = teamMembersObject.listTeamMember;
  }

  private updateList(): void {
    this.page += 1;
    this.teamService
      .getTeamMembers(this.managerId, this.page.toString())
      .subscribe(this.resolveScrollTeamMember.bind(this));
  }
}
