<ion-card-header class="register-time__header l-register-time">
  <app-register-time-header
    class="register-time__header"
    [class.hide-icon]="shouldHideHeaderIcon"
    [isMobile]="isMobile()"
    [title]="mainTitle"
    [description]="description"
    [hour]="hour"
    [minute]="minute"
    [isEditMode]="isEditMode"
    (edit)="editRegister()"
  ></app-register-time-header>
</ion-card-header>

<ion-card-content [ngClass]="registerTimeContentClass()">
  <ion-text
    *ngIf="isMobileAndNoEditMode()"
    class="register-success__content--info text"
  >
    {{ description | translate }}
  </ion-text>
  <div class="wrapper-form" *ngIf="!isMobileAndNoEditMode()">
    <form class="inputs-wrapper" [formGroup]="registerTimeForm">
      <ion-item [ngClass]="registerFormInputClass()">
        <ion-input
          class="register-time__hour input"
          inputmode="numeric"
          name="hour"
          type="text"
          [value]="hour"
          formControlName="hour"
          (ionChange)="onChangeHour()"
          (ionBlur)="onBlurHour()"
          data-test="hour"
        ></ion-input>
      </ion-item>
      <app-image
        class="inputs-wrapper__dots"
        src="assets/icon/double-point-blue.svg"
        alt=":"
      ></app-image>
      <ion-item [ngClass]="registerFormInputClass()">
        <ion-input
          class="register-time__minute input"
          inputmode="numeric"
          name="minute"
          type="text"
          [value]="minute"
          formControlName="minute"
          (ionChange)="onChangeMinute()"
          (ionBlur)="onBlurMinute()"
          data-test="minute"
        ></ion-input>
      </ion-item>
    </form>
    <ion-text class="error" [class.error--visible]="hasDateError">
      <ion-icon name="warning" class="error__icon"></ion-icon>
      <span class="error__text">{{ dateErrorMessage | translate }}</span>
    </ion-text>
    <div *ngIf="isEditMode" class="actions">
      <app-button-loading
        class="actions__save"
        [isLoading]="isLoading"
        [buttonText]="actionButtonLabel | translate"
        [isDisabled]="!canSave"
        (clickButton)="save()"
      ></app-button-loading>
      <ion-button
        *ngIf="shouldShowCancel"
        class="actions__cancel secondary"
        fill="clear"
        (click)="cancelEdition()"
      >
        {{ 'CANCEL' | translate }}
      </ion-button>
    </div>
  </div>
  <div *ngIf="showEditButton()" (click)="editRegister()" class="edit-register">
    <app-image
      class="edit-register__icon"
      src="assets/icon/edit.svg"
      [alt]="'ALT_IMG.EDIT_ICON' | translate"
    ></app-image>
    <span class="edit-register__text">
      {{ 'EDIT' | translate }}
    </span>
  </div>
</ion-card-content>
