<section class="error__content">
  <app-image
    class="error__image"
    src="assets/icon/warning.svg"
    [alt]="'ALT_IMG.HELP' | translate"
  ></app-image>
  <h3 class="error__title">
    {{ 'LOGS.NEGATIVE_BALANCE.TITLE_ERROR' | translate }}
  </h3>

  <p class="error__text" *ngIf="errorMessage">{{ errorMessage }}</p>
  

  <div class="error__buttons">
    <ion-button class="error__button" (click)="dimiss()">
      {{ 'REGISTER_TIME.MODAL_ERROR.OK' | translate }}
    </ion-button>
  </div>
</section>
