import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import moment from 'moment';
import { EmployeeWorkingDay } from '@models/employee-working-day/employee-working-day';
import { CheckType, Rubric, TextBlock, TimeRecordEmitter } from '@models/index';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';
import { temporalPatchForBetaTesting } from '@models/employee-working-day/employee-working-day-creator';
import { ResumeLoadingChunks } from '../../constants/working-day.constants';
import { timeCalculationsFactory } from '../../factories/time-calculation';
import { TimeCalulationsInterface } from '../../interfaces';
import { WorkingDay } from '../../models/working-day';
import { EditTimeModalComponent } from '../modals/edit-time-modal/edit-time-modal.component';
import { WorkingDayInfoComponent } from '../modals/working-day-info/working-day-info.component';

@Component({
  selector: 'app-wd-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss'],
})
export class ResumeComponent implements OnInit, OnChanges {
  @Input() public employeeWorkingDay: EmployeeWorkingDay;
  @Input() public rubric: Rubric;
  @Input() public isStep2: boolean;
  @Input() public isReadOnly = false;
  @Input() public isRegisterMode = false;
  @Input() public alternativeWorkingDay: WorkingDay;
  @Input() public alternativeWorkingDayChosen = false;
  @Input() public hasPermissions = false;
  @Input() public isPastDay = false;
  @Input() public workLocation: string;
  @Input() public loadingChunks: ResumeLoadingChunks = {
    checkIn: false,
    checkOut: false,
  };

  @Input() public hasRemoteWork: boolean;

  @Output() public floating = new EventEmitter<boolean>();
  @Output() public saveCheckTimesEdition = new EventEmitter<
    TimeRecordEmitter
  >();

  @Output() public alternativeWorkingDayChange = new EventEmitter<{
    isAlternative: boolean;
  }>();

  @Output() public workplaceSelected = new EventEmitter<{
    workplace: string;
  }>();

  public checkType = CheckType;
  public date: string;
  public weekDay: string;
  public moreInfoOpened = false;
  public isMobile: boolean;
  public messagesMarginAgreement: TextBlock[] = [];
  public calculationsStrategy: TimeCalulationsInterface;

  public constructor(
    private readonly modalController: ModalController,
    private readonly platformCheckerService: PlatformCheckerService
  ) {}

  public ngOnInit(): void {
    this.generateDateStrings();
    this.isMobile = this.platformCheckerService.isMobile();
    const { date } = this.employeeWorkingDay.workingDayData;
    const { hasRemoteWork } = this.employeeWorkingDay.employeeInfo;
    this.hasRemoteWork = temporalPatchForBetaTesting(hasRemoteWork, date);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.renewStrategy();

    if (changes.isStep2) {
      this.moreInfoOpened = false;
    }
  }

  private renewStrategy(): void {
    this.calculationsStrategy = timeCalculationsFactory(
      this.employeeWorkingDay.workingDayData,
      this.rubric,
      this.alternativeWorkingDayChosen ? this.alternativeWorkingDay : null
    );
  }

  public getMinimumBreakTime(): number {
    return this.calculationsStrategy.getBypassedMinimumBreakTime();
  }

  public preliminaryRegistration(): number {
    return this.calculationsStrategy.getPreliminaryRegister();
  }

  public preliminaryRegistrationForComputation(): number {
    return this.calculationsStrategy.getPreliminaryTimeToComputed();
  }

  // eslint-disable-next-line class-methods-use-this
  public formatDate(date: Date): string {
    return moment(date).format('HH:mm');
  }

  public async openWorkingDayInfoModal(): Promise<void> {
    const modalOptions = {
      component: WorkingDayInfoComponent,
      cssClass: 'register-time',
      componentProps: {
        infoAsHTML: this.rubric.workingDayInfo,
      },
    };
    const modal = await this.modalController.create(modalOptions);
    await modal.present();
  }

  public generateDateStrings(): void {
    const { date } = this.employeeWorkingDay.workingDayData;
    const momentDate = moment(date);

    this.weekDay = momentDate.format('dddd');
    this.date = momentDate.format('D [de] MMMM [de] YYYY');
  }

  public toggleCollapsible(): void {
    this.moreInfoOpened = !this.moreInfoOpened;
    this.floating.emit(this.moreInfoOpened);
  }

  public async openEditModal(checkType: CheckType): Promise<void> {
    const otherCheckTime =
      checkType === CheckType.CheckIn
        ? this.employeeWorkingDay.workingDayData.checkOut
        : this.employeeWorkingDay.workingDayData.checkIn;

    const editingCheckTime =
      checkType === CheckType.CheckIn
        ? this.employeeWorkingDay.workingDayData.checkIn
        : this.employeeWorkingDay.workingDayData.checkOut;

    const modalOptions = {
      component: EditTimeModalComponent,
      cssClass: 'edit-time',
      componentProps: {
        workingDay: this.alternativeWorkingDayChosen
          ? this.alternativeWorkingDay.duration
          : this.employeeWorkingDay.workingDayData.workingDay,
        checkType,
        otherCheckTime,
        editingCheckTime,
        isPastDay: this.isPastDay,
        workingDayEditing: this.employeeWorkingDay,
      },
    };

    const modal = await this.modalController.create(modalOptions);
    await modal.present();

    const data = await modal.onDidDismiss();
    if (data && data.data) {
      this.saveCheckTimesEdition.emit(data.data as TimeRecordEmitter);

      this.renewStrategy();
    }
  }

  public alternativeWorkingDayChangeHandler({
    isAlternative,
  }: {
    isAlternative: boolean;
  }): void {
    this.alternativeWorkingDayChange.emit({ isAlternative });
  }

  public shouldShowJneLink(): boolean {
    return !this.isMobile && this.employeeWorkingDay.workingDayData.isJNE;
  }

  public addWorkplace({ workplace }: { workplace: string }): void {
    this.workplaceSelected.emit({ workplace });
  }
}
