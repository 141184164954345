import { Routes } from '@angular/router';
import { ROUTE } from '@constants/index';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { AuthorizationsPage } from './authorizations.page';
import { NewAuthorizationsComponent } from './components';

export const authorizationsRoutes: Routes = [
  {
    path: ROUTE.AUTHORIZATIONS,
    component: AuthorizationsPage,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ROUTE.AUTHORIZATIONS_LOG,
      },
      {
        path: ROUTE.AUTHORIZATIONS_LOG,
        component: NewAuthorizationsComponent,
      },
    ],
  },
];

export class AuthorizationsRoutingModule {}
