import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DelegableEmployee } from '@models/delegable-employee.model';
import { Search, WorkingDayEmployee } from '@models/index';
import { EmployeeService } from '@services/index';
import { SelectedDelegateEmployee } from './model/search-employee';

@Component({
  selector: 'app-search-employee',
  templateUrl: './search-employee.component.html',
  styleUrls: ['./search-employee.component.scss'],
})
export class SearchEmployeeComponent implements OnInit, OnDestroy, OnChanges {
  @Output() public selectEmployee = new EventEmitter<
    SelectedDelegateEmployee
  >();

  @Output() public searchMethod = new EventEmitter<Search>();

  @Input() public isMultiSelection = false;
  @Input() public filterText = '';
  @Input() public disabled = false;
  @Input() public resultData = [];

  @ViewChild('searchInput', { read: ElementRef, static: true })
  private searchInput: ElementRef;

  public employeesResults = [];

  public selectedEmployee: DelegableEmployee;

  public managerId: string;
  public inputFocused: boolean;

  private employeeSubscription: Subscription;

  @HostListener('document:click', ['$event'])
  public onClickOutside(event: CustomEvent): void {
    if (!this.searchInput.nativeElement.contains(event.target)) {
      this.inputFocused = false;
    }
  }

  public constructor(private readonly employeeService: EmployeeService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.resultData && changes.resultData.currentValue) {
      this.employeesResults = [...changes.resultData.currentValue];
    }
  }

  public ngOnInit(): void {
    this.employeeSubscription = this.employeeService.employee$
      .pipe(filter((data): boolean => data !== null))
      .subscribe((employee: WorkingDayEmployee): void => {
        this.managerId = employee.id;
      });
  }

  public searchEmployee(): void {
    if (!this.disabled) {
      if (this.isNameEmployee() || this.isEmployeeIdLessSixCharacter()) {
        this.searchMethod.emit({
          managerId: this.managerId,
          filterText: this.filterText,
        });
      } else {
        this.employeesResults = [];
        this.resultData = [];
      }
    }
  }

  public isEmployeeIdLessSixCharacter() {
    const regex = new RegExp('^\\d{6,}$');
    const isValidEmployeeId = regex.test(this.filterText);
    return isValidEmployeeId;
  }

  public isNameEmployee() {
    const regex = new RegExp('^[a-zA-Z]{3,}$');
    const isValidName = regex.test(this.filterText);
    return isValidName;
  }

  public onSearchInputChange(event: CustomEvent): void {
    if (event.detail.value) {
      this.filterText = event.detail.value;
      this.searchEmployee();
    } else {
      this.clean();
    }
  }

  public clean(): void {
    this.filterText = '';
    this.selectedEmployee = null;
    this.employeesResults = [];
    this.resultData = [];
    if (!this.isMultiSelection) {
      this.selectEmployee.emit(null);
    }
  }

  public onEmployeeSelect(employee: DelegableEmployee): void {
    this.selectedEmployee = employee;
    // eslint-disable-next-line no-unused-expressions
    this.isMultiSelection
      ? (this.filterText = '')
      : (this.filterText = `${employee.firstName}`);
    this.selectEmployee.emit(employee);
    this.employeesResults = [];
    this.resultData = [];
  }

  public shouldShowSearchResults(): boolean {
    return (
      !this.disabled &&
      !this.selectedEmployee &&
      this.inputFocused &&
      this.employeesResults.length > 0
    );
  }

  public setInputFocused(isFocused: boolean): void {
    this.inputFocused = isFocused;
  }

  public ngOnDestroy(): void {
    this.employeeSubscription.unsubscribe();
  }
}
