<div class="drop-down-employee" *ngIf="hasEmployees()">
  <ion-item
    lines="none"
    *ngIf="isMultiSelectedOrDisabled()"
    class="summary container"
  >
    <label
      class="summary__text"
      [innerHtml]="summaryText | translate: getTranslation()"
    >
    </label>
    <span slot="end">
      <ion-icon
        *ngIf="!isDisabled"
        [icon]="isOpen ? 'ios-arrow-up' : 'ios-arrow-down'"
        (click)="toggle()"
        class="summary__arrow"
      ></ion-icon>
    </span>
  </ion-item>

  <div *ngIf="shouldShowEmployees()">
    <ion-item
      lines="none"
      *ngFor="let employee of employees"
      [ngClass]="getCssClass()"
    >
      <label class="search-employee__name"> {{ employee.name }} </label>
      <section slot="end" *ngIf="isEditMode">
        <ion-icon
          size="small"
          class="search-employee__remove-icon"
          name="close"
          (click)="removeEmployee(employee)"
        ></ion-icon>
      </section>
    </ion-item>
  </div>
</div>
