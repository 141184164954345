import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { AssignAuthorizerModalComponent } from './assign-authorizer-modal/assign-authorizer-modal.component';
import { AssignmentConfirmationModalComponent } from './assignment-confirmation-modal/assignment-confirmation-modal.component';
import { AuthorizerInfoModalComponent } from './authorizer-info-modal/authorizer-info-modal.component';
import { EditTimeModalComponent } from './edit-time-modal/edit-time-modal.component';
import { ErrorContentComponent } from './error-content/error-content.component';
import { RegisterTimeModalComponent } from './register-time-modal/register-time-modal.component';
import { SuccessContentComponent } from './success-content/success-content.component';
import { UnassignConfirmationModalComponent } from './unassign-confirmation-modal/unassign-confirmation-modal.component';
import { WorkingDayInfoComponent } from './working-day-info/working-day-info.component';
import { NegativeBalanceAdjustmentModalComponent } from './negative-balance-adjustment-modal/negative-balance-adjustment-modal.component';
import { ErrorMessageBalanceComponent } from './error-message-balance/error-message-balance.component';

@NgModule({
  declarations: [
    AssignmentConfirmationModalComponent,
    AuthorizerInfoModalComponent,
    AssignAuthorizerModalComponent,
    EditTimeModalComponent,
    ErrorContentComponent,
    RegisterTimeModalComponent,
    SuccessContentComponent,
    UnassignConfirmationModalComponent,
    WorkingDayInfoComponent,
    NegativeBalanceAdjustmentModalComponent,
    ErrorMessageBalanceComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    TranslateModule.forChild(),
  ],
  entryComponents: [
    AssignmentConfirmationModalComponent,
    AuthorizerInfoModalComponent,
    AssignAuthorizerModalComponent,
    EditTimeModalComponent,
    RegisterTimeModalComponent,
    UnassignConfirmationModalComponent,
    NegativeBalanceAdjustmentModalComponent,
    ErrorMessageBalanceComponent,
    WorkingDayInfoComponent,
  ],
})
export class WorkingDayModalsModule {}
