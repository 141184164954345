import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { filter } from 'rxjs/operators';
import { TabsInterface } from '@models/index';
import { EmployeeService } from '@services/employee/employee.service';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit, OnChanges {
  @Input() public renderTabs: TabsInterface[];
  public isLogged = false;
  public currentTab: TabsInterface;
  public isMobile: boolean;

  public constructor(
    public employeeService: EmployeeService,
    private readonly router: Router,
    private readonly platformCheckerService: PlatformCheckerService,
    private readonly navController: NavController
  ) {
    this.isMobile = this.platformCheckerService.isMobile();

    this.router.events
      .pipe(
        filter((event): boolean => {
          return (
            event instanceof NavigationEnd || event instanceof NavigationCancel
          );
        })
      )
      .subscribe((): void => {
        this.checkForTabActivations();
      });

    this.employeeService.employee$.subscribe((): void => {
      this.isLogged = true;
    });
  }

  public ngOnChanges(): void {
    this.checkForTabActivations();
  }

  public ngOnInit(): void {
    this.checkForTabActivations();
  }

  public async navigateToTabUrl(tab: TabsInterface): Promise<void> {
    if (this.currentTab !== tab) {
      await this.navController.navigateRoot([tab.url]);
      this.currentTab = tab;
    }
  }

  private checkForTabActivations(): void {
    this.renderTabs.forEach((renderTab: TabsInterface): void => {
      if (this.isTabActivated(renderTab)) {
        this.currentTab = renderTab;
      }
    });
  }

  private isTabActivated(renderTab: TabsInterface): boolean {
    const isActivatedByUrl = this.router.url.startsWith(`/${renderTab.url}`);

    const isActivatedByFunction = renderTab.isActivated
      ? renderTab.isActivated(this.router)
      : false;

    return isActivatedByUrl || isActivatedByFunction;
  }

  private shouldDisplayTabsInDesktop(): boolean {
    return this.isLogged;
  }

  private shouldDisplayTabsInMobile(): boolean {
    return this.isLogged && this.renderTabs.length > 1;
  }

  public static isErrorVersion(): boolean {
    return window.location.pathname.indexOf('/error-version') > -1;
  }

  public shouldDisplayTabs(): boolean {
    return this.isMobile
      ? this.shouldDisplayTabsInMobile() && !TabsComponent.isErrorVersion()
      : this.shouldDisplayTabsInDesktop() && !TabsComponent.isErrorVersion();
  }
}
