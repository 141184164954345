import moment from 'moment';

export const ROUTE = {
  ERROR: 'error',
  ERROR_VERSION: 'error-version',
  NOT_FOUND: 'page-not-found',
  AUTHENTICATION: 'auth',
  WORKING_DAY: 'working-day',
  AUTHORIZATIONS: 'authorizations',
  NEW_AUTH: 'new-authorizations',
  NEW_AUTHORIZATIONS: 'new-authorizations',
  AUTHORIZATIONS_LOG: 'authorizations-log',
  DELEGATIONS: 'delegations',
  EMPLOYEE_CHOOSER: 'employee-chooser',
  LOGS: 'logs',
  STEP1: 'step/1',
  STEP2: 'step/2',
  TEAM: 'team',
  EMPLOYEE: 'employee',
  NEGATIVE_BALANCE_ADJUSTMENT: 'negative-balance-adjustment',
  WORKING_DAY_DETAILS: 'working-day-details',
  IN_MAINTENANCE: 'maintenance',
  GET_WORKING_DAY_DETAILS(day: Date): string {
    return `${ROUTE.LOGS}/${ROUTE.WORKING_DAY_DETAILS}/${moment(day).format(
      'YYYY-MM-DD'
    )}/edit`;
  },
  REGISTER_WORKING_DAY_DETAILS(day: Date): string {
    return `${ROUTE.LOGS}/${ROUTE.WORKING_DAY_DETAILS}/${moment(day).format(
      'YYYY-MM-DD'
    )}/register`;
  },
  SHOW_WORKING_DAY_DETAILS(day: Date): string {
    return `${ROUTE.LOGS}/${ROUTE.WORKING_DAY_DETAILS}/${moment(day).format(
      'YYYY-MM-DD'
    )}/show`;
  },
  EMPLOYEE_LOGS: 'employee',
  EMPLOYEE_TEAM_LOGS: 'team',
  TEAM_MEMBER_LOGS(teamMemberId: string): string {
    return `${ROUTE.LOGS}/${ROUTE.EMPLOYEE_TEAM_LOGS}/${teamMemberId}`;
  },
  WORKING_DAY_STEP_1: 'working-day/step/1',
  WORKING_DAY_STEP_2: 'working-day/step/2',
};
