<ion-header *ngIf="isMobile" class="succes-validation-modal__header">
  <app-mobile-header
    (closeModal)="onDismissModal()"
    [title]="headerTitle | translate"
  ></app-mobile-header>
</ion-header>

<ion-content class="succes-validation-content">
  <ion-buttons
    *ngIf="!isMobile"
    class="succes-validation-content__wrapper-icon"
  >
    <ion-icon
      class="close-icon"
      size="large"
      name="close"
      (click)="onDismissModal()"
    ></ion-icon>
  </ion-buttons>

  <div class="wrapper">
    <section class="header">
      <app-image
        class="header__icon"
        src="assets/icon/success-tick.svg"
        alt="{{ 'AUTHORIZATIONS.SUCCES_VALIDATION.ALT_IMG' | translate }}"
      ></app-image>
      <div class="row" *ngIf="requestDate">
        <span class="header__date">
          {{ formatDate(requestDate) }}
        </span>
      </div>
    </section>

    <section class="data">
      <div class="row">
        <span class="header__title">
          {{ title | translate }}
        </span>
      </div>

      <div *ngIf="isEditDelegation()" class="delete-block">
        <div class="data-row__title">{{ employeeName }}</div>
        <app-drop-down-employee
          *ngIf="hasAddedEmployees()"
          [employees]="editInfo.addedEmployees"
          [isEditMode]="false"
          [isDisabled]="true"
          [summaryText]="hasAddedText"
          class="delete-block__added-employees selected-employees"
        >
        </app-drop-down-employee>
        <app-drop-down-employee
          *ngIf="hasDeletedEmployees()"
          [employees]="editInfo.deletedEmployees"
          [isEditMode]="false"
          [isDisabled]="true"
          [summaryText]="hasDeletedText"
          class="delete-block__delete-employees selected-employees"
        >
        </app-drop-down-employee>
      </div>

      <div *ngIf="shouldShowSingleEmployees()">
        <div class="data-row__title">{{ employeeName }}</div>
        <app-drop-down-employee
          [employees]="singleEmployees"
          [isEditMode]="false"
          class="selected-employees"
        >
        </app-drop-down-employee>
      </div>
      <div class="data-row" *ngFor="let dataElement of dataElements">
        <div class="data-row__title">{{ dataElement.title | translate }}</div>
        <div class="data-row__value">
          {{ dataElement.value
          }}<strong class="data-row__value--strong">{{
            dataElement.strongValue
          }}</strong>
        </div>
      </div>
    </section>

    <section class="footer">
      <ion-button class="buttons__validate" (click)="onDismissModal()">
        {{ 'AUTHORIZATIONS.SUCCES_VALIDATION.BUTTON_CLOSE' | translate }}
      </ion-button>
    </section>
  </div>
</ion-content>
