import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment, { MomentInput } from 'moment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { API_ROUTE } from '@constants/index';
import { CheckTimes, DayRecord, GenericPostResponse } from '@models/index';
import { DayRecordDetailService, EmployeeService } from '@services/index';

interface CheckDataElement {
  propertyName: string;
  url: string;
}
interface CheckData {
  checkIn: CheckDataElement;
  checkOut: CheckDataElement;
}

@Injectable({
  providedIn: 'root',
})
export class DayRecordService {
  private readonly checkData: CheckData = {
    checkIn: {
      propertyName: 'checkInTime',
      url: API_ROUTE.DAY_RECORD.CHECK_IN,
    },
    checkOut: {
      propertyName: 'checkOutTime',
      url: API_ROUTE.DAY_RECORD.CHECK_OUT,
    },
  };

  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
    }),
  };

  public constructor(
    private readonly http: HttpClient,
    private readonly dayRecordDetailService: DayRecordDetailService,
    private readonly employeeService: EmployeeService
  ) {}

  public setCheckIn(
    employeeId: string,
    params: DayRecord
  ): Observable<GenericPostResponse> {
    return this.setCheck(this.checkData.checkIn, employeeId, params);
  }

  public setCheckOut(
    employeeId: string,
    params: DayRecord
  ): Observable<GenericPostResponse> {
    return this.setCheck(this.checkData.checkOut, employeeId, params);
  }

  private setCheck(
    checkTimeData: CheckDataElement,
    employeeId: string,
    params: DayRecord
  ): Observable<GenericPostResponse> {
    return this.http
      .post<GenericPostResponse>(
        checkTimeData.url + employeeId,
        params,
        this.httpOptions
      )
      .pipe(
        tap((): void => {
          this.updateCheckTimes(
            checkTimeData.propertyName,
            params.checkTimeHour,
            params.checkTimeMinutes,
            params.alternativeWorkingDay
          );
        })
      );
  }

  public updateCheckTimes(
    checkTimeType: string,
    checkTimeHour: string,
    checkTimeMinutes: string,
    alternativeWorkingDay: boolean
  ): void {
    const RADIX = 10;
    const dates: MomentInput = {
      hour: parseInt(checkTimeHour, RADIX),
      minute: parseInt(checkTimeMinutes, RADIX),
    };

    const newCheckTime = moment(dates).toDate();

    const newCheckTimes: CheckTimes = {
      [checkTimeType]: newCheckTime,
    };

    this.dayRecordDetailService.updateCheckTimes(
      newCheckTimes,
      alternativeWorkingDay
    );
    this.employeeService.updateEmployee();
  }
}
