import { AuthorizationStatus, Rubric, WorkingDayData } from '@models/index';
import { TimeCalulationsInterface } from '../interfaces/index';
import { WorkingDay } from '../models/working-day';
import { getPreliminaryRegister } from './time-calculation-helper';

export class TimeCalculationsForExcess implements TimeCalulationsInterface {
  private checkIn: Date;
  private checkOut: Date;
  private workingDay: number;
  private agreementMargin: number;
  private minimumBreakTime: number;
  private defaultValue = 0;

  public constructor(
    { checkIn, checkOut, workingDay, isAlternativeChosen }: WorkingDayData,
    { minimumBreakTime, agreementMargin }: Rubric,
    private alternativeWorkingDay?: WorkingDay
  ) {
    this.checkIn = checkIn;
    this.checkOut = checkOut;
    this.workingDay =
      this.alternativeWorkingDay && isAlternativeChosen
        ? this.alternativeWorkingDay.duration
        : workingDay;
    this.agreementMargin =
      this.alternativeWorkingDay && isAlternativeChosen
        ? this.alternativeWorkingDay.agreementMargin
        : agreementMargin;
    this.minimumBreakTime =
      this.alternativeWorkingDay && isAlternativeChosen
        ? this.alternativeWorkingDay.minimumBreakTime
        : minimumBreakTime;
  }

  public getBypassedMinimumBreakTime(): number {
    return this.minimumBreakTime;
  }

  public getPreliminaryRegister(): number {
    return getPreliminaryRegister(this.checkIn, this.checkOut);
  }

  public getPreliminaryTimeToComputed(): number {
    return this.getPreliminaryRegister() - this.getBypassedMinimumBreakTime();
  }

  public getPreliminaryDifWorkingDay(): number {
    return this.getPreliminaryTimeToComputed() - this.workingDay;
  }

  // TODO amartinez need a huge refactor
  public getEffectiveWorkingTime(
    personalReasonExtension = 0,
    workingDayExtensionTime = 0,
    extensionStatus?: AuthorizationStatus
  ): number {
    let effectiveWorkingTime =
      this.getPreliminaryTimeToComputed() - personalReasonExtension;

    if (extensionStatus === 'R') {
      effectiveWorkingTime -= this.getPendingExtensionTimeToApprove(
        workingDayExtensionTime
      );
    }

    return effectiveWorkingTime;
  }

  public getDifferenceToCompensate(
    workingDayExtensionTime: number,
    extensionStatus: AuthorizationStatus = null
  ): number {
    let differenceToCompensate = 0;

    if (extensionStatus === 'R') {
      return this.defaultValue;
    }

    if (this.agreementMargin > 0) {
      differenceToCompensate =
        workingDayExtensionTime >= this.agreementMargin
          ? workingDayExtensionTime - this.agreementMargin
          : this.defaultValue;
    } else {
      differenceToCompensate = workingDayExtensionTime;
    }

    return differenceToCompensate;
  }

  public getAppliedMarginAgreement(workingDayExtensionTime: number): number {
    return workingDayExtensionTime < this.agreementMargin
      ? workingDayExtensionTime
      : this.agreementMargin;
  }

  public getPendingExtensionTimeToApprove(
    workingDayExtensionTime: number
  ): number {
    return workingDayExtensionTime <= this.agreementMargin
      ? this.defaultValue
      : workingDayExtensionTime -
          this.getAppliedMarginAgreement(workingDayExtensionTime);
  }
}
