import moment from 'moment';
import { Rubric, WorkingDayData } from '@models/index';
import { TimeCalulationsInterface } from '../interfaces';
import { WorkingDay } from '../models/working-day';
import {
  TimeCalculationsForDefect,
  TimeCalculationsForExcess,
  TimeCalculationsPerfect,
} from '../strategies/index';

export function timeCalculationsFactory(
  workingDayData: WorkingDayData,
  rubric: Rubric,
  alternativeWorkingDay?: WorkingDay
): TimeCalulationsInterface {
  let strategy: TimeCalulationsInterface;

  const { checkIn, workingDay, isAlternativeChosen } = workingDayData;
  let { checkOut } = workingDayData;
  const { minimumBreakTime } = rubric;

  const actualMinimumBreakTime =
    alternativeWorkingDay && isAlternativeChosen
      ? alternativeWorkingDay.minimumBreakTime
      : minimumBreakTime;

  const actualWorkingDay =
    alternativeWorkingDay && isAlternativeChosen
      ? alternativeWorkingDay.duration
      : workingDay;

  if (moment(checkOut).isBefore(moment(checkIn), 'minutes')) {
    checkOut = moment(checkOut)
      .add('24', 'hours')
      .toDate();
  }

  const time =
    moment(checkOut).diff(moment(checkIn), 'minutes') - actualMinimumBreakTime;

  if (time > actualWorkingDay) {
    strategy = new TimeCalculationsForExcess(
      workingDayData,
      rubric,
      alternativeWorkingDay
    );
  } else if (time < actualWorkingDay) {
    strategy = new TimeCalculationsForDefect(
      workingDayData,
      rubric,
      alternativeWorkingDay
    );
  } else {
    strategy = new TimeCalculationsPerfect(
      workingDayData,
      rubric,
      alternativeWorkingDay
    );
  }

  return strategy;
}
