import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { API_ROUTE } from '@constants/index';
import {
  AuthorizationsFilter,
  authsHistoryBuilder,
  AuthsListInterface,
  AutorizableEmployeeList,
  buildAutorizableEmployee,
  ReasonKeyName,
} from '@models/index';
import { ErrorService } from '@services/error/error.service';

function buildListParams(
  page: number,
  filterParams?: AuthorizationsFilter
): string {
  let params = `page=${page}`;

  if (filterParams) {
    const { employeeId, dateFrom, dateTo } = filterParams;

    if (employeeId) {
      params += `&employee_id=${filterParams.employeeId}`;
    }

    if (dateFrom) {
      params += `&start_date=${filterParams.dateFrom}`;
    }

    if (dateTo) {
      params += `&final_date=${filterParams.dateTo}`;
    }
  }

  return params;
}

@Injectable({
  providedIn: 'root',
})
export class AuthorizationsService {
  private readonly historicAuthsListSubject = new BehaviorSubject<
    AuthsListInterface
  >(null);

  public readonly historicAuthsList$ = this.historicAuthsListSubject
    .asObservable()
    .pipe(filter((data): boolean => data !== null));

  public constructor(
    private readonly http: HttpClient,
    private readonly errorService: ErrorService
  ) {}

  public resetHistoryAuthsList(): void {
    this.historicAuthsListSubject.next(null);
  }

  public getRejectReasons(): Observable<ReasonKeyName[]> {
    return this.http.get<any>(API_ROUTE.AUTHORIZATIONS.REASONS_REJECTION);
  }

  public getHistoricAuthList(
    id: string,
    page: number,
    filterParams?: AuthorizationsFilter
  ): Observable<AuthsListInterface> {
    const params = buildListParams(page, filterParams);

    const queryParams = new HttpParams({ fromString: params });

    return this.http
      .get<any>(API_ROUTE.AUTHORIZATIONS.HISTORIC(id), {
        params: queryParams,
      })
      .pipe(
        map(authsHistoryBuilder),
        tap((data: AuthsListInterface): void => {
          this.historicAuthsListSubject.next(data);
        }),
        catchError(
          (err): Observable<AuthsListInterface> => {
            this.errorService.set('ERROR_DEFAULT', err);
            return of(null);
          }
        )
      );
  }

  public resetHistoricAuthList(): void {
    this.historicAuthsListSubject.next(null);
  }

  public findAutorizableEmployees(
    managerId: string,
    text = '',
    page = '1',
    itemsPage = '1000'
  ): Observable<AutorizableEmployeeList> {
    const options = {
      search: text,
      page,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      items_page: itemsPage,
    };

    return this.http
      .get<any>(API_ROUTE.AUTHORIZATIONS.AUTORIZATION_EMPLOYEES(managerId), {
        params: options,
      })
      .pipe(
        map(
          (employeesTeamManager): AutorizableEmployeeList => {
            const listEmployeesTeamManager = _.uniqBy(
              employeesTeamManager.listEmployeesTeamManager,
              'code'
            );
            return {
              listEmployeesTeamManager: listEmployeesTeamManager.map(
                buildAutorizableEmployee
              ),
              numPages: employeesTeamManager.numPages,
            };
          }
        )
      );
  }
}
