<table class="month-log">
  <caption></caption>
  <thead>
    <tr>
      <th class="month-log__row-title month-log__row-title--date" scope="row">
        {{ 'DATE.LABEL' | translate }}
      </th>
      <th
        class="month-log__row-title month-log__row-title--working-day"
        scope="row"
      >
        {{ 'WORKING_DAY.DAILY' | translate | uppercase }}
      </th>
      <th
        class="month-log__row-title month-log__row-title--start-time"
        scope="row"
      >
        {{ 'WORKING_DAY.START_TIME' | translate }}
      </th>
      <th
        class="month-log__row-title month-log__row-title--end-time"
        scope="row"
      >
        {{ 'WORKING_DAY.END_TIME' | translate }}
      </th>
      <th
        class="month-log__row-title month-log__row-title--preliminary-registration"
        scope="row"
      >
        {{ 'WORKING_DAY.PRELIMINARY_REGISTRATION' | translate | uppercase }}
      </th>
      <th
        class="month-log__row-title month-log__row-title--effective-working-time"
        scope="row"
      >
        {{
          'WORKING_DAY.STEP_2.EFFECTIVE_WORKING_TIME.TITLE'
            | translate
            | uppercase
        }}
      </th>
      <th
        class="month-log__row-title month-log__row-title--difference"
        scope="row"
      >
        {{ 'LOGS.DIFFERENCE' | translate }}
      </th>
      <th
        class="month-log__row-title month-log__row-title--compensation"
        scope="row"
      >
        {{ 'LOGS.COMPENSATION' | translate }}
      </th>
      <th
        class="month-log__row-title month-log__row-title--excess-request"
        scope="row"
      >
        {{ 'LOGS.EXCESS_REQUEST' | translate }}
      </th>
      <th
        class="month-log__row-title month-log__row-title--registration-status"
        scope="row"
      >
        {{ 'LOGS.REGISTRATION_STATUS' | translate | uppercase }}
      </th>
      <th *ngIf="targetHasRemoteWork"
        class="month-log__row-title"
        scope="row"
      >
        {{ 'LOGS.WORKPLACE' | translate }}
      </th>
      <th
        class="month-log__row-title month-log__row-title--actions"
        scope="row"
      >
        {{ 'ACTIONS' | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let dayLog of dayLogs">
      <tr [class.highlighted]="shouldHighlight(dayLog)">
        <td class="month-log__row-data month-log__row-data--date">
          <p>{{ formatDate(dayLog.day) | titlecase }}</p>
          <p class="absence" *ngIf="dayLog.workingDayAbsence">
            {{ dayLog.workingDayAbsence.title }}
          </p>
          <p class="absence" *ngIf="dayLog.workingDayHoliday">
            {{ dayLog.workingDayHoliday.title }}
          </p>
        </td>
        <td class="month-log__row-data month-log__row-data--highlighted">
          {{ dayLog.workingDay | humanizeDuration }}
        </td>
        <td class="month-log__row-data">
          {{ formatHour(dayLog.checkIn) | valueOrHyphen }}
          <ion-icon
            *ngIf="
              isManualCheckTime(
                dayLog.checkIn,
                dayLog.checkInSystem,
                dayLog.isManuallyRegistered
              )
            "
            name="person"
            type="md"
            class="manual-check-time--checkin"
          ></ion-icon>
        </td>
        <td class="month-log__row-data">
          {{ formatHour(dayLog.checkOut) | valueOrHyphen }}
          <ion-icon
            *ngIf="
              isManualCheckTime(
                dayLog.checkOut,
                dayLog.checkOutSystem,
                dayLog.isManuallyRegistered
              )
            "
            name="person"
            type="md"
            class="manual-check-time--checkout"
          ></ion-icon>
        </td>
        <td class="month-log__row-data month-log__row-data--highlighted">
          {{
            dayLog.preliminaryRegistration | humanizeDuration | valueOrHyphen
          }}
        </td>
        <td class="month-log__row-data month-log__row-data--highlighted">
          {{ dayLog.effectiveWorkingTime | humanizeDuration }}
        </td>
        <td class="month-log__row-data">
          {{ dayLog.difference > 0 ? '+' : '' }}
          {{ dayLog.difference | humanizeDuration | valueOrHyphen }}
        </td>
        <td class="month-log__row-data month-log__row-data--compensation">
          {{ dayLog.compensation > 0 ? '+' : '' }}
          {{ dayLog.compensation | humanizeDuration | valueOrHyphen }}
        </td>
        <td
          class="month-log__row-data month-log__row-data--working-day-extension-request"
          [ngClass]="
            getWorkingDayExtensionRequestClasses(
              dayLog.workingDayExtensionRequest
            )
          "
        >
          <div class="bullet" *ngIf="dayLog.workingDayExtensionRequest"></div>
          <ng-container *ngIf="!dayLog.workingDayExtensionRequest"
            >-</ng-container
          >
        </td>
        <td
          class="month-log__row-data month-log__row-data--completion-status"
          [class.completion-status--completed]="dayLog.statusCompleted"
          [class.completion-status--incomplete]="!dayLog.statusCompleted"
        >
          {{
            dayLog.statusCompleted
              ? ('LOGS.COMPLETED' | translate)
              : ('LOGS.NOT_COMPLETED' | translate)
          }}
        </td>
        <td class="month-log__row-data" *ngIf="targetHasRemoteWork">
          <p>{{ formatWorkplace(dayLog.workplace) }}</p>
        </td>
        <td class="month-log__row-data month-log__row-data--actions">
          <a
            *ngIf="shouldShowDetail(dayLog)"
            class="day-log-action--show-details"
            [routerLink]="getRoute('show', dayLog.day)"
            [queryParams]="getRouteQueryParams()"
          >
            {{ 'LOGS.DETAIL' | translate }}
          </a>
          <a
            *ngIf="shouldShowModifyAction(dayLog)"
            class="day-log-action--modify"
            [routerLink]="getRoute('edit', dayLog.day)"
          >
            {{ 'LOGS.MODIFY' | translate }}
          </a>
          <a
            class="day-log-action--register"
            [routerLink]="getRoute('register', dayLog.day)"
            *ngIf="shouldShowRegisterAction(dayLog)"
          >
            {{ 'LOGS.REGISTER' | translate }}
          </a>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
