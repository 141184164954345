import { Component, Input } from '@angular/core';
import { HumanizeDurationPipe } from 'src/app/shared/pipes/humanize-duration.pipe';
import { WorkplacesService } from '@services/index';
import { Workplace } from '@models/workplace.model';
import { DayLog, WorkingDayProperty } from '../../../models/day-log';
import {
  formatDate,
  formatDateSlug,
  formatHour,
  getWorkingDayExtensionRequestClasses,
  isManualCheckTime,
} from '../common/month-log-utils';

@Component({
  selector: 'app-working-day-item-mobile',
  templateUrl: './working-day-item-mobile.component.html',
  styleUrls: ['./working-day-item-mobile.component.scss'],
})
export class WorkingDayItemMobileComponent {
  @Input() public readOnly: boolean;
  @Input() public dayLogs: DayLog[];
  @Input() public targetEmployeeId: string;
  @Input() public targetHasRemoteWork: boolean;

  public isManualCheckTime = isManualCheckTime;
  public formatDate = formatDate;
  public formatHour = formatHour;
  public getWorkingDayExtensionRequestClasses = getWorkingDayExtensionRequestClasses;
  public formatDateSlug = formatDateSlug;
  public dayProperties: any[];
  public workplaceDetailsList = [];

  public constructor(private readonly workplacesService: WorkplacesService) {}

  public ngOnInit(): void {
    this.workplacesService.workplacesDetails$.subscribe(
      (value: Workplace[]): void => {
        this.workplaceDetailsList = value;
      }
    );
  }

  public formatWorkplace(key: string): string {
    const workplace = this.workplaceDetailsList.find(
      (wp: Workplace): boolean => wp.key === key
    );
    const workplaceName = workplace ? workplace.name : '';
    return workplaceName;
  }

  // eslint-disable-next-line class-methods-use-this
  public getAbsences(dayLog: DayLog): string[] {
    const absences: string[] = [];
    if (dayLog.workingDayAbsence) {
      absences.push(`${dayLog.workingDayAbsence.title}`);
    }
    if (dayLog.workingDayHoliday) {
      absences.push(`${dayLog.workingDayHoliday.title}`);
    }
    return absences;
  }

  // eslint-disable-next-line class-methods-use-this
  public getDayLogProperties(dayLog: DayLog): WorkingDayProperty[] {
    return [
      {
        label: 'WORKING_DAY.DAILY',
        time: new HumanizeDurationPipe().transform(dayLog.workingDay),
      },
      {
        label: 'LOGS.EFFECTIVE_WORKING_TIME',
        time: new HumanizeDurationPipe().transform(dayLog.effectiveWorkingTime),
      },
      {
        label: 'LOGS.DIFFERENCE',
        time:
          (dayLog.difference > 0 ? '+' : '') +
          new HumanizeDurationPipe().transform(dayLog.difference),
      },
      { label: 'LOGS.EXCESS_REQUEST', time: null },
    ];
  }

  public shouldShowRegisterAction(dayLog: DayLog): boolean {
    return !this.readOnly && dayLog.shouldAllowRegister;
  }

  public shouldShowModifyAction(dayLog: DayLog): boolean {
    return (
      !this.readOnly &&
      dayLog.statusConfirmed &&
      !dayLog.consolidated &&
      !dayLog.workingDayHoliday
    );
  }

  public shouldShowDetail(dayLog: DayLog): boolean {
    return !!(
      dayLog.workingDayAbsence ||
      dayLog.statusConfirmed ||
      this.readOnly ||
      dayLog.workingDayHoliday
    );
  }

  public getRoute(action: string, day: Date): string {
    return `/logs/working-day-details/${this.formatDateSlug(day)}/${action}`;
  }

  // eslint-disable-next-line class-methods-use-this
  public getDayStatusClass(isCompleted: boolean): string {
    return isCompleted
      ? 'record-status__value--completed'
      : 'record-status__value--not-completed';
  }

  public getRouteQueryParams(): { [id: string]: string } {
    return !!this.targetEmployeeId && { id: this.targetEmployeeId };
  }
}
