import moment from 'moment';
import { WorkingDayData } from '@models/index';
import { buildWorkingDayData } from 'src/app/shared/fixtures/employee-working-day';

export function buildWorkingDayDataWithTimes(
  checkIn: string,
  checkOut: string,
  skipBreakTimeLimit = 0,
  isAlternativeChosen = false
): WorkingDayData {
  const checkInDate = moment(checkIn, 'HH:mm').toDate();
  const checkOutDate = moment(checkOut, 'HH:mm').toDate();

  const workingDayData = buildWorkingDayData();
  workingDayData.checkIn = checkInDate;
  workingDayData.checkOut = checkOutDate;
  workingDayData.skipBreakTimeLimit = skipBreakTimeLimit;
  workingDayData.isAlternativeChosen = isAlternativeChosen;

  return workingDayData;
}

export const DEFAULT_WORKING_DAY_DATA_EXCESS = buildWorkingDayDataWithTimes(
  '08:02',
  '20:02'
);

export const DEFAULT_WORKING_DAY_DATA_DEFFECT = buildWorkingDayDataWithTimes(
  '08:02',
  '15:02'
);

export const DEFAULT_WORKING_DAY_DATA_DEFFECT_1HOUR = buildWorkingDayDataWithTimes(
  '08:02',
  '16:02'
);

export const DEFAULT_WORKING_DAY_DATA_PERFECT = buildWorkingDayDataWithTimes(
  '08:02',
  '15:32'
);

export const DEFAULT_WORKING_ALTERNATE_DAY_DATA_PERFECT = buildWorkingDayDataWithTimes(
  '08:02',
  '15:32',
  0,
  true
);

export const DEFAULT_ALTERNATIVE_WORKING_DAY_DATA_DEFFECT_WITHOUT_BREAK_TIME = buildWorkingDayDataWithTimes(
  '08:02',
  '13:02',
  0,
  true
);

export const DEFAULT_WORKING_DAY_DATA_DEFFECT_WITHOUT_BREAK_TIME = buildWorkingDayDataWithTimes(
  '08:02',
  '13:02'
);

export const DEFAULT_WORKING_DAY_DATA_DEFFECT_WITH_SKIP_TIME_SKIPPING = buildWorkingDayDataWithTimes(
  '08:02',
  '12:02',
  300
);

export const DEFAULT_WORKING_DAY_DATA_DEFFECT_WITH_SKIP_TIME = buildWorkingDayDataWithTimes(
  '08:02',
  '18:02',
  300
);

export const DEFAULT_WORKING_DAY_DATA_DEFFECT_WITH_SKIP_TIME_EQUAL = buildWorkingDayDataWithTimes(
  '08:02',
  '14:02',
  360
);
