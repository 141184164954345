import { Component, OnInit } from '@angular/core';
import { STORAGE_KEY } from '@constants/index';
import { StorageService } from '@services/index';

@Component({
  selector: 'app-employee-chooser',
  templateUrl: './employee-chooser.component.html',
  styleUrls: ['./employee-chooser.component.scss'],
})
export class EmployeeChooserComponent implements OnInit {
  public employeeId: string;

  public constructor(private readonly storageService: StorageService) {}

  public static reload(): void {
    window.location.href = '/working-day?token=ourSecretToken';
  }

  public ngOnInit(): void {
    this.employeeId = localStorage.getItem(STORAGE_KEY.EMPLOYEE_ID);
  }

  public setEmployeeById(): void {
    const employeeId = this.employeeId.trim();
    localStorage.setItem(STORAGE_KEY.EMPLOYEE_ID, employeeId);

    this.storageService.set(STORAGE_KEY.EMPLOYEE_ID, employeeId);
    this.storageService.set(STORAGE_KEY.TOKEN, 'ourSecretToken');

    if (this.employeeId) {
      EmployeeChooserComponent.reload();
    }
  }
}
