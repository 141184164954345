import { Component, OnDestroy, ViewChild } from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { concatMap, filter } from 'rxjs/operators';
import { AUTHORIZATION_LIST_SUBSCRIPTIONS } from '@constants/autorizations.constants';
import {
  AuthInterface,
  AuthsListInterface,
  NewAuthItemValidate,
} from '@models/authorizations';
import {
  AuthorizactionListSubscription,
  AuthorizationsFilter,
  ReasonKeyName,
  WorkingDayEmployee,
} from '@models/index';
import {
  AuthorizationsService,
  EmployeeService,
  FeatureFlagService,
} from '@services/index';
import { PlatformCheckerService } from '@services/platform-checker/platform-checker.service';
import { FEATURES } from 'src/environments/common';

@Component({
  selector: 'app-new-authorizations',
  templateUrl: './new-authorizations.component.html',
  styleUrls: ['./new-authorizations.component.scss'],
})
export class NewAuthorizationsComponent implements OnDestroy {
  @ViewChild(IonInfiniteScroll, { static: true })
  public IonInfiniteScroll: IonInfiniteScroll;

  public userName: string;
  public isMobile: boolean;
  public pages: number;
  public page = 1;
  public completeAuthList: AuthInterface[] = [];
  public newAuthItemValidate: NewAuthItemValidate;
  public loadingIndex: number;
  public isDelegatingManager: boolean;
  public firstLoad = true;
  public isListLoading = false;
  public filter = new AuthorizationsFilter();

  public infoMessage: string;

  private employeeId: string;
  private reasonKeyNames: ReasonKeyName[] = [];
  private employeeSubscription: Subscription;
  private rejectReasonsSubscription: Subscription;

  public constructor(
    private readonly employeeService: EmployeeService,
    private readonly authorizationsService: AuthorizationsService,
    public readonly platformCheckerService: PlatformCheckerService,
    private readonly translateService: TranslateService,
    public readonly featureFlagService: FeatureFlagService
  ) {
    this.isMobile = this.platformCheckerService.isMobile();
  }

  public ngOnDestroy(): void {
    if (this.employeeSubscription) {
      this.employeeSubscription.unsubscribe();
    }
    this.unsubscribeToObservable(AUTHORIZATION_LIST_SUBSCRIPTIONS.LOG);
    if (this.rejectReasonsSubscription) {
      this.rejectReasonsSubscription.unsubscribe();
    }
  }

  public ionViewDidEnter(): void {
    this.employeeSubscription = this.employeeService.employee$
      .pipe(
        filter((employee: WorkingDayEmployee): boolean => !!employee),
        concatMap(
          (employee: WorkingDayEmployee): Observable<AuthsListInterface> => {
            this.employeeId = employee.id;
            this.userName = employee.firstName;
            this.isDelegatingManager = employee.isDelegatingManager;
            return this.getListFromHTTPCall();
          }
        )
      )
      .subscribe();

    this.isListLoading = true;
    this.subscribeToObservable(AUTHORIZATION_LIST_SUBSCRIPTIONS.LOG);

    this.rejectReasonsSubscription = this.authorizationsService
      .getRejectReasons()
      .subscribe((reasonKeyNames: ReasonKeyName[]): void => {
        this.reasonKeyNames = reasonKeyNames;
      });
  }

  public isFilterFeatured(): boolean {
    return this.featureFlagService.isFeatureAvailable(
      FEATURES.AUTHORIZATIONS_FILTER
    );
  }

  public loadData(event: any): void {
    const ev = event;
    this.page += 1;
    if (this.page <= this.pages) {
      this.updateList((): void => {
        event.target.complete();
      });
    } else {
      ev.target.disabled = true;
    }
  }

  public subscribeToObservable(list: AuthorizactionListSubscription): void {
    this[list.NAME] = this.authorizationsService[list.OBSERVABLE].subscribe(
      (authsListData: AuthsListInterface): void => {
        this.completeList(authsListData);
        this.afterSubscribe();
      },
      (): void => null,
      (): void => this.afterSubscribe()
    );
  }

  public unsubscribeToObservable(list: AuthorizactionListSubscription): void {
    if (this[list.NAME]) {
      this[list.NAME].unsubscribe();
    }
  }

  private afterSubscribe(): void {
    this.isListLoading = false;
    this.setMessage();
  }

  private completeList(authListData: AuthsListInterface): void {
    this.pages = authListData.pages;

    const authList = authListData.authorizationsList;
    this.completeAuthList = this.completeAuthList.concat(
      authList.filter(
        (newAuth: AuthInterface): boolean =>
          !this.completeAuthList.find(
            (auth: AuthInterface): boolean =>
              auth.id === newAuth.id && auth.requestDate === newAuth.requestDate
          )
      )
    );
  }

  public setMessage(): void {
    if (!this.hasAuthorizations()) {
      this.translateService
        .get(['LOGS.NO_PENDING_AUTORIZATIONS', 'LOGS.NO_RESULTS_MESSAGE'])
        .subscribe((messages): void => {
          this.infoMessage = this.filter.isApplied
            ? messages['LOGS.NO_RESULTS_MESSAGE']
            : messages['LOGS.NO_PENDING_AUTORIZATIONS'];
        });
    }
  }

  public shouldShowInfoMessage(): boolean {
    return !this.hasAuthorizations() && !this.isListLoading;
  }

  public getFilterComponentClass(): { [key: string]: boolean } {
    return {
      'filter-wrapper': true,
      'filter-wrapper--hidden':
        this.completeAuthList.length === 0 && !this.filter.isApplied,
    };
  }

  public hasAuthorizations(): boolean {
    return this.completeAuthList && this.completeAuthList.length > 0;
  }

  public resetList(): void {
    this.completeAuthList = [];
    this.page = 1;
    this.updateList();
  }

  public isLoading(index?: number): boolean {
    if (!Number.isFinite(index)) {
      return Number.isFinite(this.loadingIndex);
    }

    return Number.isFinite(this.loadingIndex) && this.loadingIndex === index;
  }

  public onApplyFilter(appliedFilter: AuthorizationsFilter): void {
    this.filter.dateFrom = appliedFilter.dateFrom;
    this.filter.dateTo = appliedFilter.dateTo;
    this.filter.employeeId = appliedFilter.employeeId;
    this.filter.isApplied = appliedFilter.isApplied;

    this.resetList();
  }

  private getListFromHTTPCall(): Observable<AuthsListInterface> {
    return this.authorizationsService.getHistoricAuthList(
      this.employeeId,
      +this.page,
      this.filter
    );
  }

  private updateList(callback?: () => void): void {
    this.isListLoading = true;

    this.getListFromHTTPCall().subscribe((): void => {
      this.afterSubscribe();

      if (callback) {
        callback();
      }
    });
  }
}
